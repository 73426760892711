import { useState, useEffect } from "react";
import classNames from "classnames";

const SnackBar = ({ className, timeout = 2000, children, hideTime = 500, ...other }) => {
  const [visible, setVisible] = useState(true);
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    const timerId = setTimeout(() => setVisible(false), timeout);
    return () => clearTimeout(timerId);
  }, [timeout]);
  useEffect(() => {
    if (!visible) {
      const raf = {};
      const hideTimerId = setTimeout(() => {
        raf.id = requestAnimationFrame(() => setHidden(true));
      }, hideTime);
      return () => {
        clearTimeout(hideTimerId);
        cancelAnimationFrame(raf.id);
      };
    }
  }, [visible, hideTime]);
  return (
    !hidden && (
      <div
        className={classNames("snackbar", !visible && "invisible", className)}
        style={{ "--hide-time": `${hideTime}ms` }}
        {...other}
      >
        <div className="snackbar__content">{children}</div>
      </div>
    )
  );
};

export default SnackBar;
