import { useCallback, useContext, useState, useMemo, lazy, Suspense, useRef } from "react";
import classNames from "classnames";
import Icon from "../components/icon";
import Error from "../components/error";
import UserContext from "../context/user";
import LanguageContext from "../context/language";
import LoadingError from "./loading-error";

const Select = lazy(() => import("react-select"));

const ValueContainer = ({ children }) => {
  return (
    <div className="homepage-intranet-select__header">
      <Icon name="globe" />
      {children}
    </div>
  );
};

const Option = ({ children, innerProps, isFocused, isSelected }) => {
  return (
    <div
      className={classNames(
        "homepage-intranet-select__label",
        isSelected && "homepage-intranet-select__label--is-selected",
        isFocused && "homepage-intranet-select__label--is-focused"
      )}
      {...innerProps}
    >
      {isSelected && <Icon name="checkmark" />}
      {children}
    </div>
  );
};

export const HomePageLanguageSelect = ({
  options,
  selectedLanguage,
  updateLanguage,
  placeholder,
  label,
  id,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const selectRef = useRef();
  const internalOpenRef = useRef(false);

  return (
    <div className="select loading-highlighted">
      <label htmlFor={id}>
        <span>{label}</span>
      </label>
      <Select
        ref={selectRef}
        options={options}
        placeholder={placeholder}
        value={selectedLanguage}
        onChange={(e) => {
          updateLanguage(e);
          selectRef.current?.blur();
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !(internalOpenRef.current || menuIsOpen)) {
            setMenuIsOpen(true);
          }
        }}
        onInputChange={() => {
          setMenuIsOpen(false);
          selectRef.current?.blur();
        }}
        menuIsOpen={menuIsOpen || undefined}
        onMenuOpen={() => {
          internalOpenRef.current = true;
        }}
        onMenuClose={() => {
          internalOpenRef.current = false;
          setMenuIsOpen(false);
        }}
        inputId={id}
        className="homepage-intranet-select"
        classNamePrefix="homepage-intranet-select"
        aria-label={id}
        isSearchable={false}
        components={{
          Option,
          ValueContainer,
        }}
      />
    </div>
  );
};

const LanguageSelect = ({
  options,
  selectedLanguage,
  setSelectedLanguage,
  placeholder,
  label,
  id,
}) => {
  const [typedLanguage, setTypedLanguage] = useState();

  const onInputChange = useCallback((inputText) => setTypedLanguage(inputText), []);
  const onChange = useCallback(
    (selectedOption) => setSelectedLanguage(selectedOption),
    [setSelectedLanguage]
  );

  return (
    <div className="select select--language">
      <label htmlFor={id}>
        <span>{label}</span>
      </label>
      <Select
        options={options}
        placeholder={placeholder}
        value={selectedLanguage}
        onInputChange={onInputChange}
        onChange={onChange}
        inputId={id}
        className="intranet-select"
        classNamePrefix="intranet-select"
        aria-label={id}
      />
      {typedLanguage && (
        <button
          type="button"
          className="select__clear-btn"
          onClick={() => setSelectedLanguage(null)}
        >
          <Icon name="delete" />
        </button>
      )}
    </div>
  );
};

const Settings = () => {
  const { callApi, notificationLanguage, setNotificationLanguage } = useContext(UserContext);
  const { labels, languages, newsletterLanguages } = useContext(LanguageContext);
  const [selectedLanguage, setSelectedLanguage] = useState(
    newsletterLanguages?.find((lang) => lang.profile === notificationLanguage)
  );

  const [loading, setLoading] = useState();
  const [updateError, setUpdateError] = useState(false);

  const currentLanguage = useMemo(() => {
    if (languages) {
      return languages.find((item) => item.value === (notificationLanguage || "en"));
    }
  }, [notificationLanguage, languages]);

  const onUpdate = useCallback(async () => {
    setNotificationLanguage(selectedLanguage?.profile);
  }, [selectedLanguage, setNotificationLanguage]);

  const updateLanguage = async (e) => {
    e.preventDefault();
    setLoading(true);
    setUpdateError(false);
    if (selectedLanguage?.value && selectedLanguage?.profile !== notificationLanguage) {
      try {
        await callApi("/profile/setNotificationLanguage", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          body: JSON.stringify({
            language: selectedLanguage?.profile,
          }),
        });
        await onUpdate();
      } catch (err) {
        console.error("Error updating notification language: ", err);
        setSelectedLanguage(currentLanguage?.value);
        setUpdateError(true);
      }
    }
    setLoading(false);
  };

  return (
    <section
      className={classNames(
        "profile__section settings",
        (loading || !languages) && "loading-skeleton"
      )}
      aria-label="Profile settings"
    >
      {!languages ? (
        <LoadingError />
      ) : (
        <>
          <div>
            <h2>{labels?.INTRANET_LANGUAGE || "Intranet language"}</h2>
            <div
              className="settings__description settings__description--intranet-lang"
              dangerouslySetInnerHTML={{
                __html: labels?.INTRANET_LANGUAGE_EXPLANATION,
              }}
            />
          </div>
          <div>
            <h2>{labels?.NOTIFICATION_LANGUAGE || "Notification language"}</h2>
            <Suspense>
              <form onSubmit={updateLanguage}>
                {labels?.NOTIFICATION_LANGUAGE && (
                  <div
                    className="settings__description"
                    dangerouslySetInnerHTML={{
                      __html: labels.NOTIFICATION_LANGUAGE_EXPLANATION,
                    }}
                  />
                )}
                <LanguageSelect
                  options={newsletterLanguages}
                  placeholder={currentLanguage?.label}
                  label="Language"
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  id="language"
                />
                {labels?.ONDEMAND_TRANSLATION_EXPLANATION && (
                  <div
                    className="language__description language__description--mb"
                    dangerouslySetInnerHTML={{ __html: labels.ONDEMAND_TRANSLATION_EXPLANATION }}
                  />
                )}
                <button
                  className={classNames("button primary", { active: loading })}
                  disabled={loading}
                >
                  {
                    <>
                      {loading ? <span className="loading-spinner" /> : null}{" "}
                      <span>{labels?.SAVE || "Save"}</span>
                    </>
                  }
                </button>
                {updateError && (
                  <Error
                    variant="small"
                    left={true}
                    msg={
                      labels?.ERROR_UPDATING_NOTIFICATION_LANGUAGE ||
                      "Error updating notification language"
                    }
                  />
                )}
              </form>
            </Suspense>
          </div>
        </>
      )}
    </section>
  );
};

export default Settings;
