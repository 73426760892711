import classNames from "classnames";
import LandingPagePlacements from "../components/landing-page-placements";
import Translate from "../components/translate";
import useColdpage from "../hooks/queries/useColdpage";
import useTealiumOnPageLoad from "../utils/tealium";
import LoadingError from "./loading-error";

const LandingPage = ({ page }) => {
  const origin = page.origin || "top-menu";
  const link = page?.link;

  useTealiumOnPageLoad({
    pageSection1: page?.link?.split("/")?.[1],
    pageSection2: page?.link?.split("/")?.[2],
  });

  const {
    data: pageTemplate,
    error,
    isFetching: loading,
  } = useColdpage({
    path: `${origin}${link}`,
  });

  return error ? (
    <LoadingError />
  ) : (
    <div className={classNames("page page--landing", loading && "loading-skeleton")}>
      <h1 className="page__title wrapper-medium loading-highlighted">
        <Translate text={page?.label} />
      </h1>
      <LandingPagePlacements pageTemplate={pageTemplate} />
    </div>
  );
};

export default LandingPage;
