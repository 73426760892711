import { useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LinkPageContent from "../components/link-page-content";
import NoPage from "./no-page";
import LandingPage from "./landing-page";
import Page from "./page";
import PoliciesProcedures from "./policies-procedures";

const LinkPageChildrenTemplate = ({ page, internalMenuOpen, setInternalMenuOpen }) => {
  return (
    {
      coldpage: (
        <Page
          key={page.link}
          page={page}
          internalMenuOpen={internalMenuOpen}
          setInternalMenuOpen={setInternalMenuOpen}
        />
      ),
      "policies-and-procedures-search-page": <PoliciesProcedures key={page.link} page={page} />,
      landingpage: <LandingPage key={page.link} page={page} />,
      navigationpage: page?.children?.[0]?.link ? (
        <Navigate to={`/${page.detailedOrigin}${page.children?.[0]?.link}`} replace={true} />
      ) : null,
    }[page.pageTemplate] || null
  );
};

const LinkPage = ({ menu, page, internalMenuOpen, setInternalMenuOpen }) => {
  const subMenu = useMemo(() => {
    return menu.find((item) => item.link === page.link)?.children;
  }, [menu, page]);

  return (
    <Routes>
      <Route
        path=""
        element={
          <LinkPageContent
            page={page}
            subMenu={subMenu}
            internalMenuOpen={internalMenuOpen}
            setInternalMenuOpen={setInternalMenuOpen}
          />
        }
      >
        {subMenu?.map((item) => (
          <Route
            path={item.segment}
            element={
              <LinkPageChildrenTemplate
                page={item}
                internalMenuOpen={internalMenuOpen}
                setInternalMenuOpen={setInternalMenuOpen}
              />
            }
            key={item.link}
          />
        ))}
        <Route
          path=""
          element={
            <Navigate
              to={`${
                page.children?.length
                  ? page.children?.[0]?.children?.length
                    ? page.children?.[0]?.children?.[0].link
                    : page.children?.[0]?.link
                  : page.link
              }`}
              replace={true}
            />
          }
        />
        <Route
          path=""
          element={
            <NoPage
              home={
                page.link
                  ? `${
                      page.children?.length
                        ? page.children?.[0]?.children?.length
                          ? page.children?.[0]?.children?.[0].link
                          : page.children?.[0]?.link
                        : page.link
                    }`
                  : "/"
              }
            />
          }
        />
      </Route>
    </Routes>
  );
};

export default LinkPage;
