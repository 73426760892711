import { useMemo } from "react";
import FilterList from "../../components/filters/filter-list";
import AppLinkCardsWrapper from "../../components/app-link-cards-wrapper";

const SectionAll = ({ data, query, setFilterData, pagination, loadMore, updateFav }) => {
  const cards = useMemo(() => data.pinned.concat(data.appLinkDtoList), [data]);

  return (
    <div className="apps-links__section apps-links__section--all">
      <FilterList
        items={cards}
        query={query}
        totalCounter={pagination?.total}
        setFilterData={setFilterData}
        facetView={data?.facetView}
        resourceType="appLink"
      >
        <AppLinkCardsWrapper
          cards={cards}
          updateFav={updateFav}
          pagination={pagination}
          loadMore={loadMore}
        />
      </FilterList>
    </div>
  );
};

export default SectionAll;
