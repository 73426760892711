import ReactDOM from "react-dom";
import Icon from "../../icon";

const ArrowBtn = ({ slideCount, currentSlide, containerRef, ...props }) => {
  return (
    containerRef?.current &&
    ReactDOM.createPortal(
      <button className="leonardo-slider__arrow" aria-label="Arrow" {...props}>
        <Icon name="chevron" />
      </button>,
      containerRef.current
    )
  );
};

export default ArrowBtn;
