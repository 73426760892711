import { useContext } from "react";
import Icon from "./icon";
import LanguageContext from "../context/language";

const ContactDetail = ({ contact, addToFavorite, removeFromFavorites, updating }) => {
  const { labels } = useContext(LanguageContext);
  return (
    <div className="contact-detail">
      <div className="contact-detail__header">
        <div>
          {contact.avatarUrl ? (
            <img
              src={contact.avatarUrl}
              alt={`${contact.firstName?.toLowerCase()} ${contact.lastName?.toLowerCase()}`}
              className="contact-detail__img"
            />
          ) : (
            <div className="contact-detail__img contact-detail__img--placeholder">
              {contact.firstName.slice(0, 1)}
              {contact.lastName.slice(0, 1)}
            </div>
          )}
        </div>
        <div className="contact-detail__info">
          <h1 className="contact-detail__name" translate="no">
            {contact.firstName?.toLowerCase()} {contact.lastName?.toLowerCase()}
          </h1>
          <div className="contact-detail__entity">{contact.zbusTypeT?.toLowerCase()}</div>
          <div className="contact-detail__job">{contact.jobTitle?.toLowerCase()}</div>
        </div>
      </div>
      <hr />
      <div className="contact-detail__main">
        <div>
          <div className="contact-detail__section-name">{labels?.CONTACT_INFO}</div>
          {contact?.country && (
            <p>
              <span className="contact-detail__label">{labels?.COUNTRYFACET}: </span>
              {labels?.[`COUNTRY_${contact?.country?.toUpperCase()}`]}
            </p>
          )}
          {contact?.city && (
            <p>
              <span className="contact-detail__label">{labels?.CITYFACET}: </span>
              {contact?.city?.toLowerCase()}
            </p>
          )}
          {contact?.address && (
            <p>
              <span className="contact-detail__label">{labels?.ADDRESS}: </span>
              {contact?.address?.toLowerCase()}
            </p>
          )}
          {contact?.phone && (
            <p>
              <span className="contact-detail__label">{labels?.PHONE}: </span>
              {contact?.phone}
            </p>
          )}
          {contact?.mail && (
            <p className="text-transform-initial">
              <span className="contact-detail__label">{labels?.MAIL}: </span>
              {contact?.mail?.toLowerCase()}
            </p>
          )}
        </div>
        {(contact?.zbusTypeT || contact?.macFunT || contact?.zbeFuncT) && (
          <div>
            <div className="contact-detail__section-name">{labels?.BUSINESS_INFO}</div>
            <p>
              <span className="contact-detail__label">{labels?.ZBUSTYPETFACET}: </span>
              {contact?.zbusTypeT?.toLowerCase()}
            </p>
            <p>
              <span className="contact-detail__label">{labels?.MACFUNTFACET}: </span>
              {contact?.macFunT?.toLowerCase()}
            </p>
            <p>
              <span className="contact-detail__label">{labels?.FUNCTIONFACET}: </span>
              {contact?.zbeFuncT?.toLowerCase()}
            </p>
          </div>
        )}
      </div>
      <hr />
      <div className="contact-detail__actions">
        {contact?.teamsUrl && (
          <a href={contact.teamsUrl} target="_blank" className="button leading-icon">
            <Icon name="community" className="community" />
            <span>{labels?.CONTACT_ON_TEAMS || "Contact on teams"}</span>
          </a>
        )}
        {contact?.mail && (
          <a href={`mailto:${contact?.mail?.toLowerCase()}`} className="button leading-icon">
            <Icon name="send" />
            <span>{labels?.SEND_EMAIL}</span>
          </a>
        )}
        <button
          className="button leading-icon"
          id={contact.username}
          disabled={updating || contact?.favourite === undefined}
          onClick={contact.favourite ? removeFromFavorites : addToFavorite}
        >
          {updating ? (
            <span className="loading-spinner" />
          ) : (
            <Icon name={contact?.favourite ? "bookmark-fill" : "bookmark"} />
          )}
          <span>
            {contact?.favourite ? labels?.REMOVE_FROM_MY_CONTACTS : labels?.ADD_TO_MY_CONTACTS}
          </span>
        </button>
      </div>
    </div>
  );
};

export default ContactDetail;
