import { useCallback, useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";
import classNames from "classnames";
import Icon from "./icon";
import Like from "./like";
import UserContext from "../context/user";
import { tealiumOnEvent } from "../utils/tealium";
import LanguageContext from "../context/language";

const CommentInteraction = ({ goToComments, basePath, children }) => {
  return goToComments ? (
    <Link smooth to={basePath ? `${basePath}#comments` : "#comments"}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

const Interaction = ({
  likesCounter,
  commentsCounter,
  isLiked,
  newsId,
  goToComments,
  basePath,
  showLabel,
}) => {
  const { callApi } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);

  const fetchLikes = useCallback(
    () => callApi(`/resource/news/${newsId}/likes`),
    [callApi, newsId]
  );

  const postLike = useCallback(
    async (method) => {
      const result = await callApi(`/resource/news/${newsId}/likes`, { method });
      tealiumOnEvent({
        eventType: "article",
        eventAction: method === "DELETE" ? "Remove" : "Add",
        eventScope: "public", //TODO: che valore? [public | private]
        eventLabel: `news/${newsId}`,
      });
      return result;
    },
    [callApi, newsId]
  );

  return (
    <div
      className={classNames(
        "interactions loading-highlighted",
        showLabel && commentsCounter > 0 && "interactions--link"
      )}
    >
      <Like
        isLiked={isLiked}
        likesCounter={likesCounter}
        fetchLikes={fetchLikes}
        postLike={postLike}
      />
      <CommentInteraction
        goToComments={goToComments}
        basePath={basePath}
        commentsCounter={commentsCounter}
      >
        <Icon name="comment" />
        <span className="interactions__comments-count">{commentsCounter ?? 0}</span>
      </CommentInteraction>
      {showLabel && (
        <Link smooth to={basePath ? `${basePath}#comments` : "#comments"} className="cta-link">
          {commentsCounter === 0
            ? labels?.ADD_COMMENTS || "Add comments"
            : commentsCounter > 1
            ? (labels?.COMMENTS_NUMBER || "[N] comments").replace("[N]", commentsCounter)
            : (labels?.COMMENT_NUMBER || "[N] comment").replace("[N]", commentsCounter)}
        </Link>
      )}
    </div>
  );
};

export default Interaction;
