import classNames from "classnames";
import dayjs from "dayjs";
import { useContext } from "react";
import LanguageContext from "../context/language";
import ConditionalLink from "./conditional-link";
import Icon from "./icon";
import Translate from "./translate";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

const NoteCard = ({ note, press, navBack = false, tealiumId }) => {
  const { cmsLang } = useContext(LanguageContext);
  const isSkeletonLoading = note?.init;
  const { masterID, title, customPublicationDate } = note;
  const date = customPublicationDate?.slice(0, -5);
  const detailLink = `/${press ? "press" : "notes"}/${masterID}`;

  return (
    <ConditionalLink
      className={classNames("note-card", isSkeletonLoading && "loading-skeleton")}
      to={detailLink}
      disabled={isSkeletonLoading}
      state={{ navBack }}
      data-element-id={tealiumId}
      data-description={tealiumId ? "text" : undefined}
    >
      <time
        className="note-card__date loading-highlighted"
        dateTime={date}
        itemProp="datePublished"
      >
        {date && dayjs(date).locale(cmsLang).format("LL")}
      </time>
      <h2 className="note-card__title title-h5 loading-highlighted">
        <Translate text={title} />
      </h2>
      <Icon name="arrow" />
    </ConditionalLink>
  );
};

export default NoteCard;
