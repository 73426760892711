import { Suspense, lazy, useCallback, useEffect, useRef, useState } from "react";
import FilterBar from "./filter-bar";
import FilterSummary from "./filter-summary";
import useBlockScroll from "../../hooks/useBlockScroll";

const FilterColumn = lazy(() => import("./filter-column"));

const FilterList = ({
  items,
  query,
  totalCounter,
  customCounterLabels,
  setFilterData,
  facetView,
  checkboxView = {},
  resourceType,
  hideTextSearch,
  children,
  noBooleans = false,
  hideFiltersEmpty = false,
  customElement,
  filterColumnOpen,
  filterButtons,
  filterColumnRef,
  openedFiltersByDefault,
  customFilterLabel,
  customIcon,
}) => {
  const { term = "", dateFrom = "", dateTo = "", booleans = {}, currentButton = "" } = query;
  const [filterColumn, setFilterColumn] = useState();
  const resourceTypeRef = useRef(resourceType);
  const hasFilters = term || dateFrom || dateTo || Object.values(booleans)?.flat()?.length > 0;
  const hideSearch = hideFiltersEmpty && !items.length && !hasFilters;

  useBlockScroll(filterColumn, true);
  const resetFilters = useCallback(() => setFilterData({}), [setFilterData]);

  useEffect(() => {
    if (resourceType !== resourceTypeRef.current) {
      setFilterColumn(false);
    }
  }, [resourceType]);

  useEffect(() => setFilterColumn(filterColumnOpen), [filterColumnOpen]);

  return (
    <>
      <FilterBar
        query={query}
        filterColumn={filterColumn}
        setFilterColumn={setFilterColumn}
        totalCounter={totalCounter}
        customCounterLabels={customCounterLabels}
        setFilterData={setFilterData}
        resourceType={resourceType}
        hidden={hideSearch}
        customElement={customElement}
        customFilterLabel={customFilterLabel}
        customIcon={customIcon}
      />
      <div className="filter-list__content" role="region" aria-label="Filter list">
        <Suspense>
          <FilterColumn
            items={items}
            facetView={facetView}
            checkboxView={checkboxView}
            show={filterColumn && (facetView || (!facetView && !noBooleans))}
            term={term}
            dateFrom={dateFrom}
            dateTo={dateTo}
            currentButton={currentButton}
            booleans={booleans}
            totalCounter={totalCounter}
            setFilterData={setFilterData}
            setFilterColumn={setFilterColumn}
            resetFilters={resetFilters}
            resourceType={resourceType}
            hideTextSearch={hideTextSearch}
            hidden={hideSearch}
            filterButtons={filterButtons}
            filterColumnRef={filterColumnRef}
            openedFiltersByDefault={openedFiltersByDefault}
          />
        </Suspense>
        <div className="filter-list__result-wrapper">
          {hasFilters && (
            <FilterSummary
              term={term}
              dateFrom={dateFrom}
              dateTo={dateTo}
              booleans={booleans}
              setFilterData={setFilterData}
              resetFilters={resetFilters}
              hideTextSearch={hideTextSearch}
              resourceType={resourceType}
            />
          )}
          {children}
        </div>
      </div>
    </>
  );
};

export default FilterList;
