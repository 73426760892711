import { lazy, Suspense } from "react";
const InnerSafeHtml = lazy(() => import("./inner-safe-html.jsx"));

const SafeHtml = ({ html, ...other }) => {
  return (
    <Suspense fallback={<div {...other}></div>}>
      <InnerSafeHtml {...other} html={html} />
    </Suspense>
  );
};

export default SafeHtml;
