import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

const useScrollToLocation = (loaded) => {
  const scrolledRef = useRef(false);
  const { hash } = useLocation();
  useEffect(() => {
    if (hash && loaded && !scrolledRef.current) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        scrolledRef.current = true;
      }
    }
  });
};

export default useScrollToLocation;
