import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import Icon from "../components/icon";
import Translate from "../components/translate";
import UserContext from "../context/user";
import Content from "../components/content";
import LanguageContext from "../context/language";
import useTealiumOnPageLoad from "../utils/tealium";
import LoadingError from "./loading-error";
import getParentFromSegment from "../utils/getParentFromSegment";
import localizedFormat from "dayjs/plugin/localizedFormat";
import Documents from "../components/documents";
import { LightModeBanner } from "../components/toggle-light-mode";
dayjs.extend(localizedFormat);

const NoteDetail = ({ backLink, press = false, menu }) => {
  const { callApi, lightMode, setLightMode } = useContext(UserContext);
  const { labels, cmsLang } = useContext(LanguageContext);
  const { noteId } = useParams();
  const [note, setNote] = useState({});
  const [error, setError] = useState();

  const { state } = useLocation();
  const navigate = useNavigate();

  useTealiumOnPageLoad(
    {
      pageSection1: press ? "press-release-detail" : "announcement-detail",
      pageSection2: note?.title,
    },
    !!note?.title
  );

  const loadedNote = useRef();
  useEffect(() => {
    if (cmsLang && loadedNote.current !== cmsLang) {
      loadedNote.current = cmsLang;
      const loadNote = async () => {
        try {
          const result = await callApi(
            `/resource/${press ? "press" : "notes"}/${noteId}?locale=${cmsLang}`
          );
          if (result) {
            setNote({
              title: result?.title,
              date: result?.customPublicationDate,
              type: result?.newsType,
              image: {
                uri: result?.coverImage?.data?.uri,
                alt: result?.coverImage?.name,
              },
              categories: result?.categoryString,
              entity: result?.entity,
              functions: result?.functionString,
              region: result?.regionString,
              intro: result?.intro,
              description: result?.description,
              video: result?.video,
              photogallery: result?.photoGallery,
              attachments: result?.attachments,
              link: { url: result?.linkURL, label: result?.linkLabel, target: result?.linkTarget },
            });
          }
        } catch (err) {
          console.error("Error loading content", err);
          setError(true);
          loadedNote.current = false;
        }
      };
      loadNote();
    }
  }, [callApi, noteId, cmsLang, press]);

  const getLinkDestination = useMemo(
    () => getParentFromSegment(note.link?.target?.segment, menu)?.childLink,
    [menu, note.link?.target?.segment]
  );

  return error ? (
    <LoadingError />
  ) : (
    <div
      className={classNames("note-detail", Object.keys(note).length === 0 && "loading-skeleton")}
    >
      <LightModeBanner
        status={lightMode}
        setStatus={setLightMode}
        children={
          state?.navBack ? (
            <button className="return-bar__btn" onClick={() => navigate(-1)}>
              <Icon name="chevron" />
              <span>
                {press ? labels?.GO_TO_PRESS_RELEASE_LIST : labels?.GO_TO_ORGANIZATIONAL_NOTES_LIST}
              </span>
            </button>
          ) : (
            <Link to={backLink} className="return-bar__btn">
              <Icon name="chevron" />
              <span>
                {press ? labels?.GO_TO_PRESS_RELEASE_LIST : labels?.GO_TO_ORGANIZATIONAL_NOTES_LIST}
              </span>
            </Link>
          )
        }
      />
      <article
        className="wrapper-notes"
        role="region"
        aria-label={press ? "Press release" : "Organizational note"}
      >
        <div className="note-detail__header">
          <h1 className="loading-highlighted">
            <Translate text={note?.title} />
          </h1>
          <span className="note-detail__date loading-highlighted">
            {note?.date &&
              dayjs(note.date, "YYYY-MM-DDThh:mm:ssZ[GMT]").locale(cmsLang).format("LL")}
          </span>
        </div>
        <div className="note-detail__text text loading-highlighted">
          <Translate className="text__subtitle" html={note?.intro} />
          <Content news={note} />
        </div>
        {press && (note?.link?.url || note?.link?.target?.segment) && (
          <div className="note-detail__link">
            {note?.link?.url ? (
              <a href={note.link.url} target="_blank">
                {note?.link?.label || note.link.url}
              </a>
            ) : (
              getLinkDestination && (
                <Link to={getLinkDestination}>
                  {note?.link?.label || note.link.target?.segment}
                </Link>
              )
            )}
          </div>
        )}
      </article>
      {note?.attachments && <Documents resourceId={note.id} documents={note.attachments} />}
    </div>
  );
};

export default NoteDetail;
