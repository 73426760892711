import { createContext, useState, useCallback, useMemo, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import UserContext from "./user";

const CLIENT_ID = window.MICROSOFT_CLIENT_ID;
const TENANT_ID = window.MICROSOFT_TENANT_ID;

const MSLoginContext = createContext({
  msLogin: () => undefined,
  msData: null,
  msError: false,
  callMSApi: () => undefined,
});
const authRequest = {
  scopes: ["USER.Read", "Presence.Read.All", "Files.ReadWrite.All", "offline_access"],
};
const MS_URL_PATH = "MS_URL_PATH";

const MSLoginInnerProvider = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const { userProfile, callApi } = useContext(UserContext);
  const { microsoftId } = userProfile || {};

  // msData undefined means when don't know yet if the user is logged or not,
  // null means the user is definitely not logged. If whe don't have the profile we can not know yet
  const [msData, setMsData] = useState(!userProfile || microsoftId ? undefined : null);
  const [msError, setMsError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const urlPath = location.pathname + location.search;

  // If userprofile is not loaded yet, when is loaded, if there is no microsoftId set msData to null
  useEffect(() => {
    if (userProfile && !userProfile.microsoftId) {
      setMsData(null);
    }
  }, [userProfile]);

  const acquireToken = useCallback(
    async (microsoftId, refresh) => {
      const accounts = instance.getAllAccounts();
      const myAccount = accounts.find((account) => account.localAccountId === microsoftId);
      if (myAccount) {
        const accessTokenRequest = {
          scopes: ["USER.Read", "Presence.Read.All", "Files.ReadWrite.All", "offline_access"],
          account: accounts[0],
        };
        try {
          const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
          if (refresh || !accessTokenResponse.fromCache) {
            await callApi(`/authorization/setMsToken`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                authToken: accessTokenResponse.accessToken,
                refreshToken: "",
              }),
            });
          }
          setMsData(accessTokenResponse);
        } catch (error) {
          console.warn("Acquire token error: ", error);
          if (error instanceof InteractionRequiredAuthError) {
            setMsData(null);
          } else {
            setMsError(error);
          }
        }
      } else {
        setMsData(null);
      }
    },
    [instance, callApi]
  );

  const msLogin = useCallback(async () => {
    sessionStorage.setItem(MS_URL_PATH, urlPath);
    try {
      await instance.acquireTokenRedirect(authRequest);
    } catch (err) {
      setMsError(err);
    }
  }, [instance, urlPath]);

  const shouldLoadToken = !msData && inProgress === InteractionStatus.None && !!microsoftId;

  useEffect(() => {
    if (shouldLoadToken) {
      const urlPath = sessionStorage.getItem(MS_URL_PATH);
      acquireToken(microsoftId, !!urlPath);
    }
  }, [shouldLoadToken, acquireToken, microsoftId, urlPath]);

  useEffect(() => {
    if (msData) {
      const urlPath = sessionStorage.getItem(MS_URL_PATH);
      if (urlPath) {
        sessionStorage.removeItem(MS_URL_PATH);
        navigate(urlPath);
      }
    }
  }, [msData, navigate]);

  // When the api returns a specific error, reload token and retry once
  const callMSApi = useCallback(
    async (...args) => {
      try {
        return await callApi(...args);
      } catch (err) {
        if (err.status === 410) {
          await acquireToken(microsoftId, true);
          return await callApi(...args);
        }
        throw err;
      }
    },
    [callApi, acquireToken, microsoftId]
  );

  const contextValue = useMemo(
    () => ({
      msLogin,
      msData: msData === undefined && shouldLoadToken ? undefined : msData,
      msError,
      callMSApi,
    }),
    [msLogin, msData, msError, callMSApi, shouldLoadToken]
  );

  return <MSLoginContext.Provider value={contextValue}>{children}</MSLoginContext.Provider>;
};

export const MSLoginProvider = ({ children }) => {
  const pca = useMemo(() => {
    // MSAL configuration
    const configuration = {
      auth: {
        clientId: CLIENT_ID,
        authority: `https://login.microsoftonline.com/${TENANT_ID}`,
        redirectUri: window.location.origin,
      },
    };

    return new PublicClientApplication(configuration);
  }, []);

  return (
    <MsalProvider instance={pca}>
      <MSLoginInnerProvider>{children}</MSLoginInnerProvider>
    </MsalProvider>
  );
};

export default MSLoginContext;
