import { useCallback, useContext } from "react";
import LanguageContext from "../context/language";
import classNames from "classnames";

export const ToggleLightMode = ({ status, setStatus, disabled }) => {
  const { labels } = useContext(LanguageContext);

  const onChange = useCallback(() => setStatus((current) => !current), [setStatus]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter") {
        onChange();
      }
    },
    [onChange]
  );

  return (
    <label
      className={classNames("toggle-light-mode", disabled && "toggle-light-mode--disabled")}
      htmlFor="toggle-light-mode"
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      <span className="toggle-light-mode__label">{labels?.LIGHT_MODE || "Light mode"}</span>
      <input
        type="checkbox"
        disabled={disabled}
        checked={status}
        onChange={onChange}
        readOnly={!onChange}
        id="toggle-light-mode"
        value={status}
      />
      <span className="toggle-light-mode__slider" />
    </label>
  );
};

export const LightModeBanner = ({ status, setStatus, disabled, children }) => {
  return (
    <div
      className={classNames("light-mode-banner", children && "light-mode-banner--link")}
      role="region"
      aria-label="Light mode banner"
    >
      {children}
      <ToggleLightMode status={status} setStatus={setStatus} disabled={disabled} />
    </div>
  );
};
