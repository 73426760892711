/* Cookie Policy Content (Wave 1 - English only. Wave 2 - to be added static IT + FR translations.) */

export const cookiePolicyHtml = `
<p>
  The content - including, but not limited to all data, information, news, pictures, charts
  or drawings (collectively, "Content")- of this intranet is the exclusive property of
  EssilorLuxottica, their affiliates or their licensors and all right and title in and to
  such Content, including all copyrights are owned exclusively by EssilorLuxottica, their
  affiliates or their licensors.
</p>
<p>
  The Content is provided for communication and information purposes only regarding
  EssilorLuxottica Group and its activities. The users of the intranet (hereinafter the
  "Users") are not permitted to either register - totally or partially - said Content on any
  storage device or to copy, broadcast or use the Content for any commercial purpose without
  the relevant owner's prior written consent. The Content is provided by Group sources.
  EssilorLuxottica reserve the right to modify, at any time, at their own discretion and
  without any notice, the said Content and the functional and operational use specifications
  applying to the intranet.
</p>
<p>
  The Content is not to be understood as a public offering of financial products or as a
  means of promoting any specific form of investment or trading activity or as a means of
  promoting or placing financial instruments, investment services, or banking/financial
  products/services. The use of the Content contained in the intranet for personal
  investment decision-making purposes is made at the Users' own risk.
</p>
<p>
  EssilorLuxottica shall not be responsible for any error or omission in the Content.
  <br />
  EssilorLuxottica shall not be responsible for the content of any other website from which
  the Users may have accessed - via hyperlinks through the intranet.
  <br />
  EssilorLuxottica shall not be responsible for any loss or damage suffered, for whatever
  reason, by the Users arising out of the websites accessed.
  <br />
  EssilorLuxottica shall not be responsible for any consequences of brief or prolonged
  interruptions, delays or internet dysfunctions in the intranet due to power blackouts,
  telephone line failures, Internet failures or any other circumstances.. <br />
  <br />
  <strong>USE OF MATERIALS </strong>
</p>
<p>
  All information, video, photographs and materials ("Materials") on the intranet
  https://one.essilorluxottica.com is the exclusive property of EssilorLuxottica, their
  affiliates or their licensors and all right and title in and to such Materials, including
  all copyrights are owned exclusively by EssilorLuxottica, its affiliates or its licensors.
  Materials may only be accessed and used in accordance with the terms applicable to this
  intranet and any misuse of Materials is strictly prohibited. Users may download one copy
  of the Materials from the intranet for internal use only.
</p>
<p>Users may not:</p>
<p>
  (i) reproduce or alter the Materials;
  <br />
  (ii) use the Materials for any commercial purpose or for any public display;
  <br />
  (iii) remove from the Materials any copyright, confidentiality or other notations relating
  to intellectual property rights.
</p>`;
