import { useCallback, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import ConditionalLink from "../conditional-link";
import LanguageContext from "../../context/language";
import Translate from "../translate";
import imagePh from "../../images/placeholder-image.png";
import Icon from "../icon";
import UserContext from "../../context/user";
import { getTamTamyImg } from "../../utils/documents";

const CommunityCard = ({ community, onUpdate, navBack = false }) => {
  const { isPreview, callApi } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);
  const [updating, setUpdating] = useState();
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const isSkeletonLoading = community?.init;
  const { id, name, mainCategory, type, teamsUrl, membershipStatus, coverUrl, deleted } = community;
  const detailLink = `/communities/${id}`;
  const [tempStatus, setTempStatus] = useState("init");

  useEffect(() => {
    if (tempStatus === "init") {
      setTempStatus(membershipStatus);
    }
  }, [membershipStatus, tempStatus]);

  const addToFavorite = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();
      setUpdating(true);
      try {
        const communityId = e.currentTarget?.name;
        await onUpdate({ communityId });
      } catch (err) {
        console.error(`Error adding to favorites`, err);
      }
      setUpdating(false);
    },
    [onUpdate]
  );

  const removeFromFavorites = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();
      setUpdating(true);
      const communityId = e.currentTarget?.name;
      try {
        await onUpdate({ communityId, isDelete: true });
      } catch (err) {
        console.error(`Error removing from favorites`, err);
      }
      setUpdating(false);
    },
    [onUpdate]
  );

  const handleRequest = useCallback(
    async (method) => {
      setUpdatingStatus(true);
      try {
        if (community?.id) {
          await callApi(`/community/${community.id}/request`, { method });
          setTempStatus(method === "POST" ? "PENDING" : "NONE");
        }
      } catch (err) {
        console.error("Error handling access request:", err);
      }
      setUpdatingStatus(false);
    },
    [callApi, community]
  );

  return (
    <article
      className={classNames("community-card", isSkeletonLoading && "loading-skeleton")}
      data-id={id}
    >
      <ConditionalLink
        className="community-card__img loading-highlighted"
        disabled={membershipStatus !== "JOINED" || deleted}
        to={detailLink}
      >
        {!isSkeletonLoading && <img src={getTamTamyImg(coverUrl, 510) || imagePh} alt={name} />}
      </ConditionalLink>
      <button
        className={classNames("favorites__bookmark loading-highlighted", {
          "favorites__bookmark--full": community.bookmarked,
        })}
        onClick={community.bookmarked ? removeFromFavorites : addToFavorite}
        disabled={updating || community.bookmarked === undefined || isPreview}
        // id={community?.id}
        name={community?.id}
        aria-label={community.bookmarked ? "Remove from favourites" : "Add to favourites"}
      >
        {community.bookmarked ? <Icon name="bookmark-fill" /> : <Icon name="bookmark" />}
      </button>
      <div className="community-card__info">
        <span className="community-card__type loading-highlighted">
          {mainCategory
            ? labels?.[mainCategory?.toUpperCase()] ||
              `${mainCategory?.[0]?.toUpperCase()}${mainCategory?.slice(1)?.toLowerCase()}`
            : ""}
        </span>
        <div className="community-card__title">
          <h2 className="title-h5 loading-highlighted">
            <Translate text={name} />
          </h2>
        </div>
        <div className="community-card__chips">
          <span className="community__chip loading-highlighted">
            {labels?.[`COMMUNITY_TYPE_${type?.toUpperCase()}`] ||
              `${type?.[0]?.toUpperCase()}${type?.slice(1)?.toLowerCase()}`}
          </span>
          {["SECRET", "OPEN"]?.includes(type) && teamsUrl && (
            <span className="community__chip community__chip--special loading-highlighted">
              {labels?.MICROSOFT_TEAM || "Microsoft team"}
            </span>
          )}
        </div>
        {type === "PRIVATE" && membershipStatus !== "JOINED" ? (
          (tempStatus || membershipStatus) === "PENDING" ? (
            <button
              className="button secondary light community-card__cta community-card__cta--icon-small"
              onClick={() => handleRequest("DELETE")}
            >
              {updatingStatus ? <span className="loading-spinner" /> : <Icon name="x-icon" />}{" "}
              <span>{labels?.CANCEL_REQUEST || "Cancel Request"}</span>
            </button>
          ) : (
            <button
              className="button secondary light community-card__cta"
              onClick={() => handleRequest("POST")}
              disabled={isSkeletonLoading}
            >
              {updatingStatus ? <span className="loading-spinner" /> : <Icon name="lock" />}{" "}
              <span>{labels?.REQUEST_ACCESS || "Request Access"}</span>
            </button>
          )
        ) : (
          !deleted && (
            <ConditionalLink
              className="button secondary community-card__cta loading-highlighted"
              to={detailLink}
              disabled={isSkeletonLoading}
              state={{ navBack }}
            >
              {labels?.GO_TO_COMMUNITY || "Go to Community"}
            </ConditionalLink>
          )
        )}
      </div>
    </article>
  );
};

export default CommunityCard;
