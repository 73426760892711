const FAQS = {
  en: [
    {
      id: 1,
      title: "What is One EssilorLuxottica?",
      description:
        "The One EssilorLuxottica intranet is an internal corporate communication platform dedicated to keeping employees informed on our latest corporate, local and business news and information as well as our shared resources, links and professional tools/services.",
    },
    {
      id: 2,
      title: "Who can access to One EssilorLuxottica?",
      description:
        "The intranet is accessible to all employees with a company email address or professional user ID, including deskless staff. GrandVision employees are able to connect to One EssilorLuxottica via MyApps on their Microsoft 365 environment.",
    },
    {
      id: 3,
      title: "Will there be a mobile application to access the intranet?",
      description:
        "There is no mobile application, but the intranet is mobile responsive for seamless access via mobile phone and tablet.",
    },
  ],
  fr: [
    {
      id: 1,
      title: "Qu'est-ce que One EssilorLuxottica?",
      description:
        "L'intranet One EssilorLuxottica est une plateforme de communication interne au Groupe dédiée à la communication vers les salariés. L'objectif est de fournir aux collaborateurs les dernières informations du Groupe, les actualités locales et commerciales et de partager les ressources, liens et outils/services professionnels mis à leur disposition.",
    },
    {
      id: 2,
      title: "Qui peut accéder à One EssilorLuxottica?",
      description:
        "L'intranet est accessible à tous les salariés y compris aux salariés nomades avec leur adresse email professionnelle ou leur identifiant. Les employés de GrandVision peuvent se connecter à One EssilorLuxottica via MyApps dans leur environnement Microsoft 365.",
    },
    {
      id: 3,
      title: "Y aura-t-il une application mobile pour accéder à l'intranet?",
      description:
        "Il n'y a pas d'application mobile mais l'intranet est mobile responsive pour un accès facilité via le téléphone mobile et la tablette.",
    },
  ],
  it: [
    {
      id: 1,
      title: "Cos'è One EssilorLuxottica?",
      description:
        "One EssilorLuxottica è la nostra intranet aziendale. Una piattaforma di comunicazione interna pensata per mantenere tutti i dipendenti aggiornati sulle ultime notizie dal mondo corporate, sulle novità locali e di business e dove condividere alcuni strumenti utili come link e risorse.",
    },
    {
      id: 2,
      title: "Chi può accedere a EssilorLuxottica?",
      description:
        "La intranet è accessibile a tutti i dipendenti del Gruppo attraverso le proprie credenziali aziendali come la mail o lo user, compresi gli impiegati senza postazione fissa. I dipendenti di GrandVision possono connettersi a One EssilorLuxottica tramite MyApps nel loro ambiente Microsoft 365.",
    },
    {
      id: 3,
      title: "La intranet è accessibile da mobile tramite app?",
      description:
        "Non esiste una app dedicata ma la intranet è configurata per adattarsi agli schermi di smartphone e tablet.",
    },
  ],
  es: [
    {
      id: 1,
      title: "¿Qué es One EssilorLuxottica?",
      description:
        "La Intranet Corporativa One EssilorLuxottica es una plataforma dedicada a mntener a nuestros empleados informados sobre las novedades de nuestra Compañía, tanto noticias locales y de negocios, como recursos compartidos, enlaces y servicios o herramientas profesionales.",
    },
    {
      id: 2,
      title: "¿Quién puede acceder a One EssilorLuxottica?",
      description:
        "Todos los colaboradores de la Compañía pueden acceder a One EssilorLuxottica con una dirección de correo corporativo o con su ID profesional. Los colaboradores de GrandVision que están en proceso de integración, pueden conectarse a One EssilorLuxottica a través de MyApps en su entorno de Microsoft 365.",
    },
    {
      id: 3,
      title: "¿Habrá una aplicación móvil para acceder a esta Intranet?",
      description:
        "No hay aplicación móvil, pero la Intranet es una plataforma responsiva, por lo que se puede ver sin invonvenientes desde cualquier celular o tablet con acceso a Internet.",
    },
  ],
  pt: [
    {
      id: 1,
      title: "O que é a One EssilorLuxottica?",
      description:
        "A intranet One EssilorLuxottica é uma plataforma de comunicação corporativa interna dedicada a manter os colaboradores informados sobre as últimas notícias corporativas, locais e de negócios, bem como nossos recursos compartilhados, links e ferramentas/serviços profissionais.",
    },
    {
      id: 2,
      title: "Quem pode ter acesso ao One EssilorLuxottica?",
      description:
        "A intranet está acessível a todos os colaboradores com um e-mail corporativo da empresa ou ID de usuário. Os colaboradores da GrandVision podem se conectar ao One EssilorLuxottica através do MyApps no seu ambiente Microsoft 365.",
    },
    {
      id: 3,
      title: "Haverá um aplicativo de telefone celular para acessar a intranet?",
      description:
        "Não há app disponível, mas a intranet possui um design responsivo para dispositivos móveis, permitindo acesso por meio de telefone celular ou tablet.",
    },
  ],
};

const staticFaqs = (browserLanguage) => {
  const lang = localStorage.getItem("lang-interface") || browserLanguage || "en";
  const translatedFaqs = FAQS?.[lang] || FAQS?.["en"];

  return translatedFaqs;
};

export default staticFaqs;
