import { useContext } from "react";
import { Link } from "react-router-dom";
import LanguageContext from "../context/language";
import LoadingError from "../routes/loading-error";
import Error from "./error";
import Icon from "./icon";
import Pagination from "./pagination";
import ResourceCard from "./resource-card";

const scrollToTop = () => window.scrollTo(0, 0);

const FavoriteCtaLink = ({ label }) => {
  return (
    <Link to="" onClick={scrollToTop} className="cta-link">
      <span>{label}</span>
      <Icon name="arrow" />
    </Link>
  );
};

const ResourcesCardWrapper = ({
  resources,
  loadMore,
  onUpdate,
  favourites,
  search,
  isLoading,
  policies = false,
}) => {
  const { labels } = useContext(LanguageContext);

  return resources ? (
    resources?.data?.length > 0 ? (
      <div className="resource__card-wrapper">
        {resources?.data?.map((item) => (
          <ResourceCard
            resource={item}
            key={item.masterID}
            onUpdate={onUpdate}
            policies={policies}
          />
        ))}
        <Pagination
          currentItemCount={resources?.data?.length}
          pagination={{
            resultsAvailableCount: resources.resultsAvailableCount,
          }}
          loadMore={loadMore}
          isLoading={isLoading}
        />
        {favourites && (
          <FavoriteCtaLink
            label={labels?.EDIT_YOUR_FAVORITE_RESOURCES || "Edit your favorite resources"}
          />
        )}
      </div>
    ) : (
      <div className="resource__error">
        <Error
          msg={
            search
              ? labels?.NO_MATCHING_RESULTS
              : favourites
              ? labels?.NO_FAVORITE_RESOURCES
              : labels?.NO_RESOURCES
          }
        />
        {favourites && (
          <FavoriteCtaLink
            label={labels?.EDIT_YOUR_FAVORITE_RESOURCES || "Edit your favorite resources"}
          />
        )}
      </div>
    )
  ) : (
    <LoadingError />
  );
};

export default ResourcesCardWrapper;
