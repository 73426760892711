import { useContext } from "react";
import LanguageContext from "../context/language";
import LoadingError from "../routes/loading-error";
import AppLinkCard from "./app-link-card";
import Error from "./error";
import Pagination from "./pagination";

const AppLinkCardsWrapper = ({ cards, updateFav, pagination, loadMore }) => {
  const { labels } = useContext(LanguageContext);
  return (
    <div className="apps-links__section">
      {cards ? (
        cards?.length > 0 ? (
          <div className="apps-links__list">
            {cards.map((item) => (
              <AppLinkCard
                item={item}
                large={true}
                showType={true}
                onUpdate={updateFav}
                key={item.masterID}
              />
            ))}
          </div>
        ) : (
          <Error msg={labels?.NO_MATCHING_RESULTS} />
        )
      ) : (
        <LoadingError />
      )}

      <div>
        <Pagination currentItemCount={cards?.length} pagination={pagination} loadMore={loadMore} />
      </div>
    </div>
  );
};

export default AppLinkCardsWrapper;
