import { useCallback, useContext, useState, lazy, Suspense } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import classNames from "classnames";
import Favorites from "./favorites.jsx";
import Settings from "./settings.jsx";
import Info from "./info.jsx";
import NoPage from "./no-page.jsx";
import UserContext from "../context/user.jsx";
import Icon from "../components/icon.jsx";
import Tabs from "../components/tabs.jsx";
import Modal from "../components/modal.jsx";
import useClickOut from "../hooks/useClickOut.js";
import LanguageContext from "../context/language.jsx";
import useTealiumOnPageLoad from "../utils/tealium.js";
import LoadingError from "./loading-error.jsx";

const ImgCrop = lazy(() => import("../components/img-crop.jsx"));

const ProfileContent = () => {
  const { userProfile, callApi, loadProfile } = useContext(UserContext);
  const { labels, language } = useContext(LanguageContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownRef] = useClickOut(showDropdown, setShowDropdown);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  const profileTabs = [
    {
      link: "/profile",
      label: labels?.BOOKMARKS,
      end: true,
    },
    {
      link: "/profile/settings",
      label: labels?.MY_LANGUAGE,
    },
    {
      link: "/profile/info",
      label: labels.MY_INFO,
    },
  ];

  const toggleDropdown = useCallback(() => {
    if (userProfile?.avatarUrl) {
      setShowDropdown((value) => !value);
    } else {
      setUploadModalVisible(true);
    }
  }, [userProfile?.avatarUrl]);

  const showUpload = useCallback(() => setUploadModalVisible(true), []);
  const onUpdate = useCallback(async () => {
    await loadProfile();
    //TODO handle reload profile error?
    setUploadModalVisible(false);
  }, [loadProfile]);

  const removeImg = async () => {
    setShowDropdown(false);
    try {
      await callApi(`/profile?avatarId=-1&language=${language || "en"}`, {
        method: "POST",
      });
      console.log("Successfully removed profile image");
      await onUpdate();
    } catch (err) {
      console.error("Error removing profile image: ", err);
      //TODO handle error
    }
  };

  return (
    <div className="profile">
      <div
        className={classNames(
          "profile__intro",
          !userProfile && "loading-skeleton loading-skeleton--transparent"
        )}
        role="region"
        aria-label="Profile details"
      >
        {userProfile === null ? (
          <LoadingError />
        ) : (
          <>
            <div className="profile__avatar">
              <button
                className={classNames("profile__button", { active: showDropdown })}
                onClick={toggleDropdown}
                disabled={!userProfile || showDropdown}
              >
                <div
                  className={classNames(
                    "profile__img loading-highlighted",
                    !userProfile?.avatarUrl && "profile__img--placeholder"
                  )}
                >
                  {userProfile?.avatarUrl ? (
                    <img src={userProfile.avatarUrl} alt="User" />
                  ) : (
                    <span>{`${userProfile?.firstName?.[0]?.toUpperCase()}${userProfile?.lastName?.[0]?.toUpperCase()}`}</span>
                  )}
                </div>
                <span className="profile__edit" aria-label="Edit">
                  <Icon name="camera" />
                </span>
              </button>
              <div
                ref={dropdownRef}
                className={classNames("profile__dropdown", { open: showDropdown })}
              >
                <button className="profile__dropdown-option" onClick={showUpload}>
                  <Icon name="cloud-arrow-up" /> {labels?.CHANGE_IMAGE}
                </button>
                <button className="profile__dropdown-option" onClick={removeImg}>
                  <Icon name="circle-xmark" /> {labels?.REMOVE_IMAGE}
                </button>
              </div>
            </div>
            <div className="profile__info">
              <div>
                <h1 className="profile__name loading-highlighted" translate="no">
                  {userProfile?.firstName} {userProfile?.lastName}
                </h1>
                <div className="profile__category loading-highlighted">
                  {userProfile?.zbusTypeT}
                </div>
                <div className="profile__job-title loading-highlighted">
                  {userProfile?.jobTitle}
                </div>
              </div>
              <div className="profile__contact-and-support">
                <Link to="/contact-and-support" className="profile__highlighted cta-link">
                  {labels?.CONTACT_AND_SUPPORT}
                  <Icon name="info-tooltip" />
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
      <Tabs tabs={profileTabs} />
      <Outlet />
      <Modal
        show={uploadModalVisible}
        setShow={setUploadModalVisible}
        className="intranet-modal--full"
      >
        <Suspense fallback={<div className="loading-spinner" />}>
          <ImgCrop callApi={callApi} onUpdate={onUpdate} />
        </Suspense>
      </Modal>
    </div>
  );
};

const Profile = ({ backLinks }) => {
  useTealiumOnPageLoad({ pageSection1: "Profile" });
  return (
    <Routes>
      <Route path="" element={<ProfileContent />}>
        <Route path="" element={<Favorites backLinks={backLinks} />} />
        <Route path="settings" element={<Settings />} />
        <Route path="info" element={<Info />} />
      </Route>
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
};

export default Profile;
