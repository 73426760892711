import classNames from "classnames";
import useTealiumOnPageLoad from "../utils/tealium";
import useFilters, { searchBodyFromQuery } from "../hooks/useFilters";
import FilterList from "../components/filters/filter-list";
import NewsCardWrapper from "../components/news-card-wrapper.jsx";
import LoadingError from "./loading-error";
import useResourceFetch from "../hooks/queries/useResourceFetch";

const News = ({ page: pageContent }) => {
  const [query, setFilterData] = useFilters();
  useTealiumOnPageLoad({ pageType: "Landing", pageSection1: "News" });

  const {
    data: news,
    error,
    isFetching,
    isInitialLoading,
    fetchNextPage,
  } = useResourceFetch({
    body: searchBodyFromQuery({
      ...query,
      orderBy: query?.orderBy
        ? query?.orderBy === "MOST_RELEVANT"
          ? ""
          : query?.orderBy
        : "NEWEST",
      hubContext: "corporate",
    }),
  });

  return error ? (
    <LoadingError />
  ) : (
    <main className={classNames("page news", isInitialLoading && "loading-skeleton")}>
      <h1 className="loading-highlighted">{pageContent.label}</h1>
      <FilterList
        items={news.data}
        query={query}
        totalCounter={news?.resultsAvailableCount}
        setFilterData={setFilterData}
        facetView={news?.facetView}
      >
        <NewsCardWrapper
          news={news}
          pagination={news?.paginationInfo}
          loadMore={fetchNextPage}
          isLoading={isFetching}
        />
      </FilterList>
    </main>
  );
};

export default News;
