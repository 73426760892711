import { useContext } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import UserContext from "../../context/user";
import placeholders from "../../utils/placeholders";
import { getFilterQueryStringParams, searchBodyFromQuery } from "../useFilters";

const PAGE_SIZE = 12;
const STALE_TIME = 300000; //5 min = 5*60*1000

const useMyCommunities = ({
  query,
  pageSize = PAGE_SIZE,
  placeholderData = { pages: [{ list: placeholders(4, "id"), init: true }] },
  enabled = true,
  staleTime = STALE_TIME,
  onSuccess = () => 0,
}) => {
  const { callApi } = useContext(UserContext);

  return useInfiniteQuery({
    queryKey: ["my-community", pageSize, query],
    queryFn: ({ pageParam = { offset: 0, limit: pageSize } }) => {
      return callApi(
        `/community/my?${new URLSearchParams({
          offset: pageParam.offset,
          limit: pageParam.limit,
          ...(query?.orderBy && { orderBy: query.orderBy }),
          ...Object.fromEntries(getFilterQueryStringParams(query, "communities")),
        })}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", Accept: "*/*" },
          body: JSON.stringify(searchBodyFromQuery(query, "communities")),
        }
      );
    },
    enabled,
    staleTime,
    placeholderData,
    getNextPageParam: (_lastPage, pages) => {
      const offset = pages.map((page) => page.list || []).flat().length;
      return {
        offset,
        limit: (pages.length + 1) * pageSize - offset,
      };
    },
    select: (data) => {
      const list = data?.pages?.map((page) => page?.list)?.flat();
      const lastPage = data?.pages.slice(-1)?.[0];
      return {
        ...lastPage,
        list,
        checkboxView: {
          teamsSynchronizedSearch: { teamsSynchronizedSearch: "teamsSynchronizedSearch" },
        },
        facetView: {
          statusFacet: {
            statusPendingSearch: -1,
            statusJoinedSearch: -1,
          },
          ...lastPage.facetView,
        },
      };
    },
    onSuccess,
    onError: (err) => {
      console.error(`Error loading my communities`, err);
    },
  });
};

export default useMyCommunities;
