import { useContext } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import LanguageContext from "../../../context/language";
import ConditionalLink from "../../conditional-link";
import Image from "../../image";
import imagePh from "../../../images/placeholder-image.png";
import Translate from "../../translate";

const MainNewsCard = ({ news, className, loading }) => {
  const { labels, cmsLang } = useContext(LanguageContext);
  const detailLink = news?.masterID
    ? news?.businessHub?.[0]?.externalReference
      ? `/business-hubs/${news.businessHub[0].externalReference}/news-detail/${news.masterID}`
      : news?.regionHub?.[0]?.externalReference
      ? `/regional-hubs/${news.regionHub[0].externalReference}/news-detail/${news.masterID}`
      : `/news/${news.masterID}`
    : "";

  const hubName =
    news?.newsType?.externalReference === "hub-news"
      ? (news?.regionHub?.[0] || news?.businessHub?.[0])?.externalReference
      : null;

  const date = news?.customPublicationDate?.slice(0, -5);
  return (
    <article className={classNames("main-news__main", className)}>
      <ConditionalLink disabled={loading} to={detailLink} state={{ navBack: true }}>
        {!loading && (
          <Image
            className="main-news__img loading-highlighted"
            cropUrlTemplate={news?.coverImage?.cropUrlTemplate || imagePh}
            crops={[
              {
                crop: "landscape_ratio16x9",
                sizes: [
                  {
                    breakpoint: 768,
                    maxWidth: "100vw",
                  },
                  {
                    breakpoint: 1230,
                    maxWidth: "50vw",
                  },
                  {
                    breakpoint: 1600,
                    maxWidth: "25vw",
                  },
                  {
                    maxWidth: "25vw",
                  },
                ],
              },
            ]}
            alt={news?.title}
          />
        )}
        <div className="main-news__info">
          <span className="main-news__type loading-highlighted">
            {!!hubName
              ? labels?.[`HUB_${hubName.toUpperCase()}_NEWS`] ||
                `${hubName[0]?.toUpperCase()}${hubName.slice(1)?.toLowerCase()} news`
              : labels?.[news?.newsType?.externalReference?.toUpperCase()] ||
                `${news?.newsType?.externalReference?.[0]?.toUpperCase()}${news?.newsType?.externalReference
                  ?.slice(1)
                  ?.toLowerCase()}`}
          </span>
          <h3 className="main-news__title heading-small loading-highlighted">
            <Translate text={news?.title} />
          </h3>
          <time
            className="main-news__date loading-highlighted"
            dateTime={date}
            itemProp="datePublished"
          >
            {date && dayjs(date).locale(cmsLang).format("LL")}
          </time>
        </div>
      </ConditionalLink>
    </article>
  );
};

export default MainNewsCard;
