export const mergeUrlSearchParams = (a, b) => {
  const aEntries = [...a.entries()];
  const bEntries = [...b.entries()];

  const allEntries = aEntries.concat(bEntries);
  const merged = new URLSearchParams();
  for (const [key, value] of allEntries) {
    merged.append(key, value);
  }
  return merged;
};
