import { useContext } from "react";
import { Link } from "react-router-dom";
import SafeHtml from "../components/safe-html";
import LanguageContext from "../context/language";

const NoPage = ({ home = "/" }) => {
  const { labels } = useContext(LanguageContext);
  return (
    <main className="page error-page">
      <div className="error-page__content">
        <div className="error-page__block">
          <div className="error-page__404">{labels?.ERROR_404}</div>
          <div className="error-page__title">{labels?.PAGE_NOT_FOUND}</div>
          <SafeHtml className="error-page__description" html={labels?.PAGE_NOT_FOUND_DESCRIPTION} />
          {labels?.BACK_TO_HOMEPAGE && (
            <Link to={home} className="button primary">
              <span>{labels?.BACK_TO_HOMEPAGE}</span>
            </Link>
          )}
        </div>
      </div>
    </main>
  );
};

export default NoPage;
