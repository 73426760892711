import { useEffect, useRef } from "react";

const useClickOut = (flag, setFlag, customRef) => {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref = customRef || ref1;
  useEffect(() => {
    if (flag) {
      const listener = (event) => {
        const el1 = ref.current;
        const el2 = ref2.current;
        if (
          event.target !== el1 &&
          event.target !== el2 &&
          el1 &&
          !el1.contains(event.target) &&
          (!el2 || !el2.contains(event.target))
        ) {
          setFlag(false);
        }
      };
      const timeout = setTimeout(() =>
        document.addEventListener("click", listener, { capture: true })
      );
      return () => {
        clearTimeout(timeout);
        document.removeEventListener("click", listener);
      };
    }
  }, [flag, setFlag, ref]);
  return [ref, ref2];
};

export default useClickOut;
