import { useCallback, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Image from "../../components/image";
import Icon from "../../components/icon.jsx";
import ConditionalLink from "../../components/conditional-link";
import Translate from "../../components/translate";
import UserContext from "../../context/user";
import placeholders from "../../utils/placeholders";
import imagePh from "../../images/placeholder-image.png";
import LanguageContext from "../../context/language";
import getParentFromSegment from "../../utils/getParentFromSegment";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useReducedMotion from "../../hooks/useReducedMotion.js";

const ArrowBtn = ({ arrowRef, next, setAutoplay, running, setInteracted }) => (
  <button
    className={classNames(
      "leonardo-slider__arrow",
      next ? "leonardo-slider__arrow--next" : "leonardo-slider__arrow--prev"
    )}
    ref={arrowRef}
    aria-label="Arrow"
    onClick={() => {
      setInteracted(true);
      if (running) {
        setAutoplay(!running);
      }
    }}
  >
    <Icon name="chevron" />
  </button>
);

const SliderLeonardo = ({ slides, loading, menu }) => {
  const { labels } = useContext(LanguageContext);
  const prevRef = useRef();
  const nextRef = useRef();
  const swiperRef = useRef();
  const heroRef = useRef();
  const [running, setRunning] = useState(true);
  const reducedMotion = useReducedMotion(false);
  const [interacted, setInteracted] = useState(false);

  const playSwiper = useCallback(() => swiperRef.current?.autoplay?.start(), []);
  const stopSwiper = useCallback(() => swiperRef.current?.autoplay?.stop(), []);

  const setAutoplay = useCallback(
    (enabled) => {
      if (enabled) {
        if (!swiperRef.current?.autoplay?.running) {
          playSwiper();
          setRunning(true);
        }
      } else {
        stopSwiper();
        setRunning(false);
      }
    },
    [playSwiper, stopSwiper]
  );

  useEffect(() => {
    setAutoplay(!reducedMotion);
  }, [reducedMotion, setAutoplay]);

  useEffect(() => {
    const ref = heroRef;
    if (ref?.current && !interacted) {
      const playHero = () => setAutoplay(true);
      const pauseHero = () => setAutoplay(false);
      ref.current.addEventListener("focusin", pauseHero);
      ref.current.addEventListener("focusout", playHero);
      ref.current.addEventListener("mouseenter", pauseHero);
      ref.current.addEventListener("mouseleave", playHero);
      return () => {
        ref.current.removeEventListener("focusin", pauseHero);
        ref.current.removeEventListener("focusout", playHero);
        ref.current.removeEventListener("mouseenter", pauseHero);
        ref.current.removeEventListener("mouseleave", playHero);
      };
    }
  }, [setAutoplay, interacted]);

  return (
    <>
      <div className="leonardo-slider__wrapper">
        <Swiper
          className={classNames(
            "leonardo-slider",
            slides?.length === 1 && "disabled",
            reducedMotion && "leonardo-slider--reduced-motion"
          )}
          modules={[Navigation, Pagination, Autoplay]}
          slidesPerView={1}
          navigation={{
            prevEl: ".leonardo-slider__arrow--prev",
            nextEl: ".leonardo-slider__arrow--next",
          }}
          pagination={{
            el: ".leonardo-slider__pagination",
            clickable: false,
          }}
          loop={slides?.length > 1}
          watchOverflow={true}
          keyboard={{
            enabled: true,
          }}
          ref={heroRef}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          onSliderMove={() => setAutoplay(false)}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
        >
          {slides?.map((slide, i) => (
            <SwiperSlide key={i}>
              <div className="leonardo-slider__slide loading-highlighted" key={i}>
                {!loading &&
                  (slide?.onBoarding ? (
                    <img
                      className="leonardo-slider__slide-img"
                      src={slide?.coverImage}
                      alt={slide?.title || slide?.name || ""}
                    />
                  ) : (
                    <Image
                      cropUrlTemplate={slide.coverImage?.cropUrlTemplate || imagePh}
                      alt={slide?.title || slide?.name || ""}
                      className="leonardo-slider__slide-img"
                      crops={[
                        {
                          crop: "portrait_ratio3x4",
                          breakpoint: 575,
                          sizes: [
                            {
                              maxWidth: "100vw",
                            },
                          ],
                        },
                        {
                          crop: "landscape_ratio16x9",
                          sizes: [
                            {
                              breakpoint: 768,
                              maxWidth: "100vw",
                            },
                            {
                              maxWidth: "70vw",
                            },
                          ],
                        },
                      ]}
                    />
                  ))}
                <div className="leonardo-slider__slide-content">
                  <div className="leonardo-slider__slide-title loading-highlighted">
                    <Translate text={slide?.title} />
                  </div>
                  <Translate
                    className="leonardo-slider__slide-intro loading-highlighted"
                    html={slide?.intro}
                  />
                  {/* OnBoarding slide */}
                  {slide?.onBoarding && !!slide?.linkURL && (
                    <ConditionalLink
                      href={slide?.linkURL}
                      target="_blank"
                      className="leonardo-slider__slide-cta button primary loading-highlighted"
                      disabled={loading}
                      data-element-id={`home_hero_${slide?.masterID}`}
                      data-description="text"
                    >
                      <span>{!loading && labels?.HERO_BANNER_CTA}</span>
                    </ConditionalLink>
                  )}
                  {/* Regular hero slide */}
                  {!slide?.onBoarding &&
                    slide?.linkLabel &&
                    (slide?.linkURL ? (
                      <ConditionalLink
                        href={slide?.linkURL}
                        target="_blank"
                        className="leonardo-slider__slide-cta button primary loading-highlighted"
                        disabled={loading}
                        data-element-id={`home_hero_${slide?.masterID}`}
                        data-description="text"
                      >
                        <span> {!loading && <Translate text={slide?.linkLabel} />}</span>
                      </ConditionalLink>
                    ) : slide?.linkTarget ? (
                      <ConditionalLink
                        to={
                          getParentFromSegment(slide?.linkTarget?.segment, menu)?.childLink || "/"
                        }
                        className="leonardo-slider__slide-cta button primary loading-highlighted"
                        disabled={loading}
                        data-element-id={`home_hero_${slide?.masterID}`}
                        data-description="text"
                      >
                        <span> {!loading && <Translate text={slide?.linkLabel} />}</span>
                      </ConditionalLink>
                    ) : (
                      slide?.related?.length === 1 && (
                        <ConditionalLink
                          to={
                            ["corporate-news", "top-leaders"].includes(
                              slide?.related?.[0]?.newsType?.externalReference
                            )
                              ? `/news/${slide?.related?.[0]?.masterID}`
                              : slide?.related?.[0]?.newsType?.externalReference === "announcements"
                              ? `/notes/${slide?.related?.[0]?.masterID}`
                              : `/press/${slide?.related?.[0]?.masterID}`
                          }
                          className="leonardo-slider__slide-cta button primary loading-highlighted"
                          disabled={loading}
                          data-element-id={`home_hero_${slide?.related?.[0]?.masterID}`}
                          data-description="text"
                        >
                          <span>{!loading && <Translate text={slide?.linkLabel} />}</span>
                        </ConditionalLink>
                      )
                    ))}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="leonardo-slider__action-wrapper">
        <button
          className={classNames(
            "leonardo-slider__action",
            !running && "leonardo-slider__action--play"
          )}
          onClick={() => {
            setAutoplay(!running);
            setInteracted(true);
          }}
          aria-label={running ? "Pause" : "Play"}
        >
          <Icon name={running ? "pause-icon" : "play-icon"} />
        </button>
        <div className="leonardo-slider__pagination" />
        <div className="leonardo-slider__navigation">
          <ArrowBtn
            arrowRef={prevRef}
            setAutoplay={setAutoplay}
            running={running}
            setInteracted={setInteracted}
          />
          <ArrowBtn
            arrowRef={nextRef}
            next={true}
            setAutoplay={setAutoplay}
            running={running}
            setInteracted={setInteracted}
          />
        </div>
      </div>
    </>
  );
};

const SectionLeonardo = ({ id, menu }) => {
  const { labels, cmsLang, language } = useContext(LanguageContext);
  const { callApi } = useContext(UserContext);
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState();
  const loadedSlides = useRef();

  const [leonardoSlides, setLeonardoSlides] = useState(placeholders(3));

  useEffect(() => {
    const loadCourses = async () => {
      if (cmsLang && language && loadedSlides.current !== language + id) {
        loadedSlides.current = language + id;
        setIsLoading(true);
        try {
          if (id) {
            let result = await callApi(`/resource/dql/${id}?locale=${cmsLang}`);
            result.data = result?.data?.slice(0, 9);
            let mappedOnBoardingResults = [];
            if (result) {
              const onBoardingIndex = result?.data?.findIndex(
                (content) => content?.newsType?.externalReference === "onboarding"
              );
              if (onBoardingIndex >= 0) {
                try {
                  const onBoardingResult = await callApi(
                    `/resource/courses/onboarding?language=${language}`
                  );
                  mappedOnBoardingResults = onBoardingResult?.list?.map((course) => ({
                    onBoarding: true,
                    title: course?.courseFullName,
                    intro: course?.courseSummary,
                    coverImage: course.courseOverviewFile,
                    linkURL: course?.linkUrl,
                    linkLabel: labels?.COUNTINUE,
                  }));
                  result.data[onBoardingIndex] = mappedOnBoardingResults?.sort((a, b) =>
                    a?.order > b?.order ? 1 : a?.order < b?.order ? -1 : 0
                  );
                } catch (err) {
                  console.error("Error loading OnBoarding courses", err);
                  result.data[onBoardingIndex] = [];
                }
              }
              setLeonardoSlides(result?.data?.flat());
            }
          } else {
            setError(true);
          }
          setIsLoading(false);
        } catch (err) {
          console.error("Error loading HeroBanner", err);
          setError(err);
          setIsLoading(false);
        }
      }
    };
    loadCourses();
  }, [callApi, id, language, labels, cmsLang]);

  return (
    !error && (
      <section className={classNames("leonardo", loading && "loading-skeleton")}>
        <SliderLeonardo slides={leonardoSlides} loading={loading} menu={menu} />
      </section>
    )
  );
};

export default SectionLeonardo;
