import { useContext, useState } from "react";
import classNames from "classnames";
import LanguageContext from "../../context/language";
import Icon from "../../components/icon";
import UserContext from "../../context/user";
import Error from "../../components/error";
import useListChecked from "../../hooks/useListChecked";

const NewsConfig = ({ preferences, allPreferences, close }) => {
  const { callApi } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);
  const [hubChecked, toggleHub] = useListChecked(preferences?.hubs || []);
  const [topicsChecked, toggleTopic] = useListChecked(preferences?.topics || []);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const saveConfig = async () => {
    setLoading(true);
    try {
      await callApi(`/resource/news/my`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ hubs: hubChecked, topics: topicsChecked }),
      });
      close(true);
    } catch (err) {
      console.error("Error updating preferences", err);
      setError(err);
    }
    setLoading(false);
  };

  return (
    <div className="news-config wrapper">
      <h3 className="news-config__title">{labels?.CUSTOMIZE_YOUR_NEWS_SELECTION}</h3>
      <p className="news-config__description">{labels?.CHOOSE_YOUR_NEWS}</p>
      <hr />
      {!!allPreferences?.regionalHubs?.length && (
        <div className="news-config__box">
          <div className="news-config__box-title">{labels?.SHOW_ME_THESE_REGIONAL_HUBS}</div>
          <ul className="config-list">
            {allPreferences?.regionalHubs?.map((hub) => (
              <li className="config-list__item" key={hub}>
                <button
                  className={classNames(
                    "config-list__option",
                    hubChecked.includes(hub) && "active"
                  )}
                  name={hub}
                  onClick={toggleHub}
                >
                  <span>
                    <Icon name="checkmark" />
                  </span>
                  {labels?.[`HUB_${hub?.toUpperCase()}`] ||
                    `${hub?.[0]?.toUpperCase()}${hub?.slice(1)?.toLowerCase()}`}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {!!allPreferences?.businessHubs?.length && (
        <div className="news-config__box">
          <div className="news-config__box-title">{labels?.SHOW_ME_THESE_BUSINESS_HUBS}</div>
          <ul className="config-list">
            {allPreferences?.businessHubs?.map((hub) => (
              <li className="config-list__item" key={hub}>
                <button
                  className={classNames(
                    "config-list__option",
                    hubChecked.includes(hub) && "active"
                  )}
                  name={hub}
                  onClick={toggleHub}
                >
                  <span>
                    <Icon name="checkmark" />
                  </span>
                  {labels?.[`HUB_${hub?.toUpperCase()}`] ||
                    hub?.[0]?.toUpperCase() + hub?.slice(1)?.toLowerCase()}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {!!allPreferences?.topics?.length && (
        <div className="news-config__box">
          <div className="news-config__box-title">{labels?.SHOW_ME_THESE_TOPICS}</div>
          <ul className="news-config__list">
            {allPreferences?.topics?.map((topic) => (
              <li className="news-config__item" key={topic}>
                <button
                  className={classNames(
                    "news-config__option",
                    topicsChecked.includes(topic) && "active"
                  )}
                  name={topic}
                  onClick={toggleTopic}
                >
                  <span>
                    <Icon name="checkmark" />
                  </span>
                  {labels?.[topic?.toUpperCase()] ||
                    topic?.[0]?.toUpperCase() + topic?.slice(1)?.toLowerCase()}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="news-config__actions">
        <button
          className={classNames("button primary", { active: loading })}
          disabled={loading}
          onClick={saveConfig}
        >
          {
            <>
              {loading ? <span className="loading-spinner" /> : null}{" "}
              <span>{labels?.SAVE || "Save"}</span>
            </>
          }
        </button>
        {error && <Error variant="small" msg={labels?.ERROR_UPDATING_PREFERENCES} />}
        <button className="news-config__cta" onClick={() => close()}>
          {labels?.MAYBE_LATER}
        </button>
      </div>
    </div>
  );
};

export default NewsConfig;
