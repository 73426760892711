import { useState, useMemo } from "react";
import classNames from "classnames";
import WidgetTitle from "./hub-widgets/elements/widget-title";
import Tabs from "./tabs";
import NewsCard from "./news-card";

const NewsTypeWidget = ({ data, title }) => {
  const [tab, setTab] = useState(data?.[0]?.collectionTitle?.replace(/ /g, "-"));

  const hubsTabs = useMemo(
    () =>
      data?.map((item) => ({
        name: item?.collectionTitle?.replace(/ /g, "-"),
        label: item?.collectionTitle,
        end: true,
      })),
    [data]
  );

  return (
    !!data?.length && (
      <section className={classNames("widget news-type-widget", !title && "no-title")}>
        <WidgetTitle title={title} />
        <Tabs tabs={hubsTabs} currentTab={tab} setCurrentTab={setTab} />
        <div className="news__card-wrapper">
          {data
            ?.find((item) => item.collectionTitle?.replace(/ /g, "-") === tab)
            ?.items?.map((news) => (
              <NewsCard news={news} key={news.masterID} showInteractions={false} />
            ))}
        </div>
      </section>
    )
  );
};

export default NewsTypeWidget;
