import { useContext, useState, useCallback } from "react";
import Icon from "./icon";
import UserContext from "../context/user";
import LanguageContext from "../context/language";

export const useToggleTranslate = (text) => {
  const { callApi } = useContext(UserContext);
  const { browserLanguage } = useContext(LanguageContext);
  const [loading, setTranslating] = useState(false);
  const [translated, setTranslatedText] = useState();
  const [resetted, setResettedTranslation] = useState(false);

  const toggleTranslate = useCallback(async () => {
    if (!!translated) {
      setResettedTranslation((value) => !value);
    } else {
      if (!!translated) {
        console.warn("Already translated to " + browserLanguage);
        return;
      }
      console.log("translate to " + browserLanguage);
      setTranslating(true);
      try {
        const result = await callApi(`/translation/translate?to=${browserLanguage}`, {
          method: "POST",
          body: text,
        });
        setTranslatedText(result);
        setTranslating(false);
      } catch (err) {
        console.error("Translate error: ", err);
        setTranslating(false);
        throw err;
      }
    }
  }, [callApi, browserLanguage, text, translated]);

  return {
    toggle: toggleTranslate,
    loading,
    result: translated,
    resetted,
  };
};

const ToggleTranslateButton = ({ toggle, result, loading, resetted, backLabel }) => {
  const { labels, languages, browserLanguage } = useContext(LanguageContext);

  return (
    <button className="toggle-translate-btn" disabled={loading} onClick={toggle}>
      <Icon name="translation" />
      {loading ? (
        <div className="loading-spinner"></div>
      ) : result && !resetted ? (
        backLabel
      ) : (
        `${labels?.TRANSLATE_TO} ${
          languages?.find((lang) => lang.value === browserLanguage)?.label
        }`
      )}
    </button>
  );
};

export default ToggleTranslateButton;
