import { useCallback, useContext, useState } from "react";
import LanguageContext from "../context/language";

const CommentInput = ({
  parentCommentId,
  postComment,
  loadComments,
  increaseCommentsCount,
  focus,
  setFocusOnFirstComment,
}) => {
  const { labels } = useContext(LanguageContext);
  const [typedComment, setTypedComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const onChange = useCallback((event) => setTypedComment(event.target.value), []);

  const insertComment = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      if (typedComment?.length > 0 && typedComment?.length < 500) {
        try {
          await postComment(typedComment, parentCommentId ? parentCommentId : null);
          // await callApi(`/resource/news/${newsId}/comments`, {
          //   method: "POST",
          //   headers: {
          //     "Content-Type": "application/json",
          //     Accept: "*/*",
          //   },
          //   body: JSON.stringify({
          //     commentText: typedComment,
          //     parentCommentId: parentCommentId ? parentCommentId : null,
          //   }),
          // });
          setTypedComment("");
          await loadComments(true);
          setFocusOnFirstComment();
          setIsLoading(false);
          increaseCommentsCount();
        } catch (err) {
          console.error("Error inserting new comment", err);
          setIsLoading(false);
          //TODO: Feedback error inserting comment
        }
      }
    },
    [
      postComment,
      typedComment,
      parentCommentId,
      loadComments,
      setIsLoading,
      increaseCommentsCount,
      setFocusOnFirstComment,
    ]
  );

  return (
    <form className="comments__insert" onSubmit={insertComment}>
      <span className="comments__arrow" />
      <span className="comments__chevron" />
      <textarea
        placeholder={labels?.WRITE_A_COMMENT}
        value={typedComment}
        onChange={onChange}
        autoFocus={focus}
      ></textarea>
      <button className="button secondary" disabled={typedComment?.length === 0}>
        {isLoading ? <span className="loading-spinner" /> : null} <span>{labels?.SEND}</span>
      </button>
    </form>
  );
};

export default CommentInput;
