import { useState, useCallback } from "react";

const useResetCounter = (reset = 2) => {
  const [count, setCount] = useState(0);
  // Toggle count between 0 and reset-1
  const increment = useCallback(() => setCount((value) => (value % reset) + 1), [reset]);
  return [count, increment];
};

export default useResetCounter;
