import { useContext, useEffect, useRef, useState, Suspense, lazy } from "react";
import classNames from "classnames";
import Image from "../image";
import imagePh from "../../images/placeholder-image-3x4.png";
import Translate from "../translate";
import UserContext from "../../context/user";
import placeholders from "../../utils/placeholders";
import LoadingError from "../../routes/loading-error";
import LanguageContext from "../../context/language";
import ArrowBtn from "./elements/arrow-btn";
import WidgetTitle from "./elements/widget-title";
import { useOutletContext } from "react-router-dom";

const Slider = lazy(() => import("react-slick"));

const PersonCard = ({ card, loading }) => {
  return (
    <div className="people-cards__card">
      <div className="people-cards__img-wrapper">
        {!loading && (
          <Image
            cropUrlTemplate={card?.coverImage?.cropUrlTemplate || imagePh}
            crops={[
              {
                crop: "portrait_ratio3x4",
                sizes: [
                  {
                    maxWidth: "30vw",
                  },
                ],
              },
            ]}
            className="people-cards__img"
            alt={card?.title}
          />
        )}
      </div>
      <div className="people-cards__info">
        <Translate className="people-cards__type loading-highlighted" html={card?.intro} />
        <div className="people-cards__title title-h5 loading-highlighted">
          <Translate text={card?.title} />
        </div>
      </div>
    </div>
  );
};

const PeopleCard = ({ data, title, hub }) => {
  /* 
    CELP (Celebrate Essilor Luxottica People)
    Manual configuration: data from CMS
    Default configuration: latest 3 hub CELP from Elastic
    */
  const { cmsLang } = useContext(LanguageContext);
  const { isPreview, callApi } = useContext(UserContext);
  const { sideHubMenu } = useOutletContext() || { sideHubMenu: null };

  const [celp, setCelp] = useState(placeholders(3));
  const loadedCelp = useRef(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const ref = useRef();

  useEffect(() => {
    const loadCelp = async () => {
      setLoading(true);
      loadedCelp.current = cmsLang + isPreview;
      try {
        if (hub?.tag) {
          const result = await callApi(
            `/resource/celp/latest?` +
              new URLSearchParams({
                pageSize: 3,
                locale: cmsLang,
                hubContext: hub.tag,
              })
          );
          setCelp(result?.data);
        }
      } catch (err) {
        loadedCelp.current = false;
        setError(true);
      }
      setLoading(false);
    };
    if (data?.length) {
      setCelp(data);
      setLoading(false);
    } else if (loadedCelp.current !== cmsLang + isPreview) {
      loadCelp();
    }
  }, [callApi, cmsLang, data, hub, isPreview]);

  const settings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    prevArrow: <ArrowBtn containerRef={ref} />,
    nextArrow: <ArrowBtn containerRef={ref} />,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 990 + (sideHubMenu ? 344 : 0),
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575 + (sideHubMenu ? 344 : 0),
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: celp?.length > 1 ? "16px" : "0",
        },
      },
      sideHubMenu && {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      sideHubMenu && {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: celp?.length > 1 ? "16px" : "0",
        },
      },
    ].filter(Boolean),
  };

  return error ? (
    <LoadingError />
  ) : (
    !!celp?.length && (
      <section className={classNames("widget people-cards", !title && "no-title")}>
        <WidgetTitle title={title} arrowsRef={ref} />
        <div className="people-cards__content">
          <Suspense>
            <Slider
              {...settings}
              className={classNames("widget__slider", `widget__slider--${celp?.length}-slides`)}
            >
              {celp?.map((card, i) => (
                <PersonCard card={card} key={i} loading={loading} />
              ))}
            </Slider>
          </Suspense>
        </div>
      </section>
    )
  );
};

export default PeopleCard;
