import { useEffect, useRef, useState, useContext, Suspense, lazy } from "react";
import classNames from "classnames";
import LoadingError from "../../routes/loading-error";
import ContactCard from "../contact-card";
import UserContext from "../../context/user";
import { useFavouriteContacts } from "../../context/favourites";
import { addFavouriteContactsFlags } from "../../utils/addFavouriteFlags";
import placeholders from "../../utils/placeholders";
import ArrowBtn from "./elements/arrow-btn";
import WidgetTitle from "./elements/widget-title";
import { useOutletContext } from "react-router-dom";

const Slider = lazy(() => import("react-slick"));

const ContactList = ({ data, title }) => {
  const { callApi } = useContext(UserContext);
  const { sideHubMenu } = useOutletContext() || { sideHubMenu: null };
  const [contacts, setContacts] = useState(placeholders(4, "username"));
  const [favouriteContacts, updateFav, favError] = useFavouriteContacts();
  const loadedContactsRef = useRef();
  const loading = !favouriteContacts || contacts?.[0]?.init;
  const [error, setError] = useState(false);
  const [isDragging, setIsDragging] = useState();
  const ref = useRef();

  useEffect(() => {
    const loadTeam = async () => {
      loadedContactsRef.current = true;
      try {
        const contactResults = await Promise.all(
          data.map(async ({ title }) => {
            try {
              const result = await callApi(`/resource/contacts/${title}`);
              return result;
            } catch (err) {
              console.error("Error loading user informations for" + title, err);
              return null;
            }
          })
        );
        setContacts(addFavouriteContactsFlags(contactResults?.filter(Boolean), favouriteContacts));
      } catch (err) {
        loadedContactsRef.current = false;
        setError(true);
      }
    };
    if (!loadedContactsRef.current && favouriteContacts) {
      loadTeam();
    }
  }, [callApi, favouriteContacts, data]);

  useEffect(() => {
    if (favouriteContacts) {
      setContacts((contacts) =>
        contacts?.[0]?.init ? contacts : addFavouriteContactsFlags(contacts, favouriteContacts)
      );
    }
  }, [favouriteContacts]);

  const settings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    prevArrow: <ArrowBtn containerRef={ref} />,
    nextArrow: <ArrowBtn containerRef={ref} />,
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
    responsive: [
      {
        breakpoint: 1399 + (sideHubMenu ? 344 : 0),
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 990 + (sideHubMenu ? 344 : 0),
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: contacts?.length > 1 ? "16px" : "0",
        },
      },
    ],
  };

  return favError || error ? (
    <LoadingError />
  ) : (
    !!contacts?.length && (
      <section
        className={classNames(
          "widget contact-list",
          loading && "loading-skeleton",
          !title && "no-title"
        )}
      >
        <WidgetTitle title={title} arrowsRef={ref} />
        <div className="contact-list__content">
          <Suspense>
            <Slider
              {...settings}
              className={classNames("widget__slider", `widget__slider--${contacts?.length}-slides`)}
            >
              {contacts.map(
                (contact, i) =>
                  contact && (
                    <ContactCard
                      contact={contact}
                      key={i}
                      loading={loading}
                      onUpdate={updateFav}
                      isDragging={isDragging}
                    />
                  )
              )}
            </Slider>
          </Suspense>
        </div>
      </section>
    )
  );
};

export default ContactList;
