import { useMemo } from "react";
import classNames from "classnames";
import FilterList from "../../components/filters/filter-list";
import LoadingError from "../loading-error";
import useFilters from "../../hooks/useFilters";
import CommunitiesCardWrapper from "../../components/communities/communities-card-wrapper";
import useMyCommunities from "../../hooks/queries/useMyCommunities";
import useUpdateFavoriteCommunities from "../../hooks/mutations/useUpdateFavoriteCommunities";

const PAGE_SIZE = 12;

const MyCommunities = () => {
  const [query, setFilterData] = useFilters("communities");

  const {
    data: communities,
    error,
    isFetching,
    isInitialLoading,
    fetchNextPage,
  } = useMyCommunities({
    query,
    pageSize: PAGE_SIZE,
  });

  const { mutateAsync: updateFav } = useUpdateFavoriteCommunities({
    // Invalidate all communities cache
    doInvalidateAllCommunities: true,
    // Invalidate my communities cache (except current, on which optimistic update will be performed)
    doInvalidateMyCommunities: false,
    // Perform optimistic update on my community current query
    doOptimisticUpdateMyCommunities: true,
    currentMyQueryKey: ["my-community", PAGE_SIZE, query],
  });

  const noFilters = useMemo(() => {
    const { booleans, term } = query;
    return (!booleans || Object.keys(booleans).length === 0) && !term;
  }, [query]);

  return error ? (
    <LoadingError />
  ) : (
    <div className={classNames(isInitialLoading && "loading-skeleton")}>
      <FilterList
        items={communities?.list}
        query={query}
        totalCounter={communities?.paginationInfo?.total}
        setFilterData={setFilterData}
        facetView={communities?.facetView}
        checkboxView={communities?.checkboxView}
        resourceType="mycommunities"
        hideFiltersEmpty={true}
      >
        <CommunitiesCardWrapper
          communities={communities?.list}
          pagination={communities?.paginationInfo}
          loadMore={fetchNextPage}
          updateFav={updateFav}
          noFilters={noFilters}
          favourites={true}
          isLoading={isFetching}
        />
      </FilterList>
    </div>
  );
};

export default MyCommunities;
