import { useContext } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import UserContext from "../../context/user";
import placeholders from "../../utils/placeholders";
import { mergeUrlSearchParams } from "../../utils/mergeUrlSearchParams";
import {
  getFilterBodyParams,
  getFilterQueryStringParams,
  searchBodyFromQuery,
} from "../useFilters";

const PAGE_SIZE = 12;
const STALE_TIME = 300000; //5 min = 5*60*1000

const useCommunities = ({
  query,
  term = "",
  pageSize = PAGE_SIZE,
  placeholderData = { pages: [{ list: placeholders(4, "id"), init: true }] },
  initialData,
  enabled = true,
  staleTime = STALE_TIME,
  onSuccess = () => 0,
  hideStatusFacet,
}) => {
  const { callApi } = useContext(UserContext);

  return useInfiniteQuery({
    queryKey: ["community", pageSize, query, ...(term ? [term] : [])],
    queryFn: ({ pageParam = 0 }) => {
      const params = new URLSearchParams(
        Object.entries({
          pageNumber: pageParam,
          pageSize,
          ...(query?.orderBy && { orderBy: query.orderBy }),
        })
      );
      const filterParams = getFilterQueryStringParams(query, "communities");
      return callApi(`/community?${mergeUrlSearchParams(params, filterParams).toString()}`, {
        method: "POST",
        headers: { "Content-Type": "application/json", Accept: "*/*" },
        body: JSON.stringify(
          searchBodyFromQuery(
            { ...getFilterBodyParams(query, "communities"), ...(term ? { term } : {}) },
            "communities"
          )
        ),
      });
    },
    enabled,
    staleTime,
    placeholderData,
    initialData,
    getNextPageParam: (_lastPage, pages) => {
      return pages?.length;
    },
    select: (data) => {
      const list = data?.pages?.map((page) => page?.list)?.flat();
      const lastPage = data?.pages.slice(-1)?.[0];
      return {
        ...lastPage,
        list,
        checkboxView: {
          teamsSynchronizedSearch: { teamsSynchronizedSearch: "teamsSynchronizedSearch" },
        },
        facetView: {
          ...(hideStatusFacet
            ? {}
            : {
                statusFacet: {
                  statusPendingSearch: -1,
                  statusJoinedSearch: -1,
                },
              }),
          ...lastPage?.facetView,
        },
      };
    },
    onSuccess,
    onError: (err) => {
      console.error(`Error loading communities`, err);
    },
  });
};

export default useCommunities;
