import React from "react";

const getVideoUrl = (url) => {
  if (url?.includes("youtube") || url?.includes("youtu.be/")) {
    return `${
      url.includes("youtube.com/watch?v=") || url.includes("youtu.be/")
        ? url
            .replace("youtube.com/watch?v=", "youtube-nocookie.com/embed/")
            .replace("youtu.be/", "www.youtube-nocookie.com/embed/")
        : url
    }?mute=1`;
  } else if (url?.includes("vimeo")) {
    return `${
      url?.includes("player.vimeo.com") ? url : url.replace("vimeo.com", "player.vimeo.com/video")
    }`;
  } else if (url?.includes("www.dailymotion.com") || url?.includes("dai.ly")) {
    return url?.includes("embed/video")
      ? url
      : `${
          url?.includes("dai.ly")
            ? url.replace("dai.ly", "www.dailymotion.com/embed/video")
            : url?.replace("video", "embed/video")
        }?mute=true`;
  } else return url;
};

export const staticVideo = ({ url, title }) => {
  return `<iframe src="${getVideoUrl(url)}" title="${title || "Video"}" aria-label="${
    title || "Video"
  }" allowfullscreen frameborder="0" width="100%" height="100%"></iframe>`;
};

const Video = React.forwardRef(
  ({ item, isHovered, isTouched, className, coverCropWidth = "600" }, ref) => {
    return item?.video?.data?.uri ? (
      item?.video?.data?.uri?.includes("crosscast") ? (
        <iframe
          className={className}
          src={item?.video?.data?.uri}
          title={item.title || "Video"}
          aria-label={item?.title || "Video"}
          allowFullScreen
          frameBorder={0}
          width="100%"
          height="100%"
        />
      ) : (
        <video
          className={className}
          src={item?.video?.data?.uri}
          ref={ref}
          controls={isHovered || isTouched}
          poster={item?.video?.picture?.cropUrlTemplate?.replace(
            "{cropName}/{width}",
            `landscape_ratio16x9/${coverCropWidth}`
          )}
          webkit-playsinline="true"
          playsInline
          aria-label={item.title || "Video"}
          controlsList="nodownload"
          muted
        />
      )
    ) : (
      <iframe
        className={className}
        src={getVideoUrl(item?.video?.dataUrl)}
        title={item.title || "Video"}
        aria-label={item?.title || "Video"}
        allowFullScreen
        frameBorder={0}
        width="100%"
        height="100%"
      />
    );
  }
);

export default Video;
