const capitalizeAll = (str) => {
  const words = str?.split(/\s+/);
  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
};

export default capitalizeAll;
