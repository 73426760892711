import { useContext } from "react";

import LanguageContext from "../../context/language";
import dayjs from "dayjs";
import "dayjs/locale/it";
import "dayjs/locale/fr";
import localizedFormat from "dayjs/plugin/localizedFormat";
import classNames from "classnames";
import Translate from "../translate";
import ConditionalLink from "../conditional-link";
import tealiumWording from "../../utils/tealiumWording";
dayjs.extend(localizedFormat);

const CommunityMiniCard = ({ item, backLink }) => {
  const { labels, language } = useContext(LanguageContext);
  const { id, name, lastPostCreationDate, type, membershipStatus, deleted } = item;
  const recent = dayjs(lastPostCreationDate).add(24, "hour").isAfter(dayjs());

  return (
    <ConditionalLink
      className="community-minicard"
      disabled={deleted}
      to={`/communities/${id}`}
      state={{ backLink: backLink }}
      data-element-id={`home_banner_${tealiumWording(name)}_myCommunity`}
      data-description="image"
    >
      <div className="community-minicard__name">
        <Translate text={name} />
      </div>
      {type === "PRIVATE" && (
        <div className="community-minicard__detail">
          {membershipStatus === "PENDING"
            ? labels?.PENDING_REQUEST || "Pending request"
            : membershipStatus !== "JOINED" && (labels?.REQUEST_ACCESS || "Request access")}
        </div>
      )}
      {(type === "PRIVATE" ? membershipStatus === "JOINED" : true) &&
        (lastPostCreationDate ? (
          <div
            className={classNames(
              "community-minicard__last-date",
              recent && "community-minicard__last-date--recent"
            )}
          >
            <span>{labels.LAST_POST || "Last post"}:</span>
            <time dateTime={lastPostCreationDate} itemProp="datePublished">
              {dayjs(lastPostCreationDate)
                .locale(language || "en")
                .format("LL")}
            </time>
          </div>
        ) : (
          <div className="community-minicard__detail">{labels?.NO_POSTS_YET || "No posts yet"}</div>
        ))}
    </ConditionalLink>
  );
};

export default CommunityMiniCard;
