import classNames from "classnames";
import useTealiumOnPageLoad from "../utils/tealium";
import useFilters, { searchBodyFromQuery } from "../hooks/useFilters";
import FilterList from "../components/filters/filter-list";
import NotesCardWrapper from "../components/notes-card-wrapper";
import LoadingError from "./loading-error";
import useResourceFetch from "../hooks/queries/useResourceFetch";

const Notes = ({ page: pageContent, press }) => {
  const [query, setFilterData] = useFilters();
  useTealiumOnPageLoad({
    pageType: "Landing",
    pageSection1: press ? "Press Releases" : "Organizational Notes",
  });

  const {
    data: notes,
    error,
    isFetching,
    isInitialLoading,
    fetchNextPage,
  } = useResourceFetch({
    body: searchBodyFromQuery({ ...query, hubContext: "corporate" }),
    pageSize: 4,
    contentType: press ? "press" : "notes",
  });

  return error ? (
    <LoadingError />
  ) : (
    <main className={classNames("page notes", isInitialLoading && "loading-skeleton")}>
      <h1 className="loading-highlighted">{pageContent.label}</h1>
      <FilterList
        items={notes.data}
        query={query}
        totalCounter={notes?.resultsAvailableCount}
        setFilterData={setFilterData}
        facetView={notes?.facetView}
        resourceType={press ? "press" : "notes"}
      >
        <NotesCardWrapper
          notes={notes}
          pagination={notes?.paginationInfo}
          loadMore={fetchNextPage}
          press={press}
          isLoading={isFetching}
        />
      </FilterList>
    </main>
  );
};

export default Notes;
