import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import UserContext from "../../context/user";

const useCustomResourceDetail = ({ resourceId }) => {
  const { logged, callApi, isPreview } = useContext(UserContext);

  return useQuery(
    ["custom-resource", resourceId, isPreview],
    () => callApi(`/resource/custom/${resourceId}`),
    {
      enabled: logged && !!resourceId,
      onError: (error) => console.error("Error loading content", error),
    }
  );
};

export default useCustomResourceDetail;
