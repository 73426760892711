import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import UserContext from "../../context/user";
import LanguageContext from "../../context/language";

const usePolicyDetail = ({ policyId }) => {
  const { callApi } = useContext(UserContext);
  const { cmsLang: locale } = useContext(LanguageContext);

  return useQuery({
    queryKey: ["policy-detail", policyId, locale],
    queryFn: () => callApi(`/policy/${policyId}?locale=${locale}`),
    placeholderData: {},
  });
};

export default usePolicyDetail;
