import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import Tabs from "../../components/tabs";
import SectionFavourites from "./section-favourites";
import SectionAllContacts from "./section-all-contacts";
import LanguageContext from "../../context/language";
import useTealiumOnPageLoad from "../../utils/tealium";

const WhoIsWho = ({ page: pageContent }) => {
  const { labels } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();
  const myContacts = searchParams.get("section");

  useTealiumOnPageLoad({ pageSection1: pageContent?.segment });

  return (
    <main className="page who-is-who">
      <h1 className="loading-highlighted">{pageContent.label}</h1>
      <Tabs
        tabs={[
          {
            link: "/whos-who/?section=my",
            label: labels?.MY_CONTACTS_WHOSWHO,
            end: false,
          },
          {
            link: "/whos-who/",
            label: labels?.ALL_EMPLOYEES,
            end: true,
          },
        ]}
      />
      {myContacts ? <SectionFavourites /> : <SectionAllContacts />}
    </main>
  );
};

export default WhoIsWho;
