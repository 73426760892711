import { useMemo, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import Tabs from "../../components/tabs";
import LanguageContext from "../../context/language";
import AllResources from "./all-resources";
import MyResources from "./my-resources";

const Resources = ({ page: pageContent }) => {
  const { labels } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();
  const section = searchParams.get("section");

  const tabs = useMemo(
    () => [
      { link: "?section=myResources", label: labels?.MY_RESOURCES || "MY RESOURCES" },
      { link: "", label: labels?.ALL_RESOURCES || "ALL RESOURCES" },
    ],
    [labels]
  );

  return (
    <main className="page resources">
      <h1 className="loading-highlighted"> {pageContent.label}</h1>
      <Tabs tabs={tabs} />
      {
        {
          myResources: <MyResources hub={{ tag: "corporate" }} />,
          all: <AllResources hub={{ tag: "corporate" }} />,
        }[section || "all"]
      }
    </main>
  );
};

export default Resources;
