import { useContext, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Modal from "../../components/modal.jsx";
import NewsCard from "../../components/news-card.jsx";
import UserContext from "../../context/user";
import LanguageContext from "../../context/language.jsx";
import useHomeData from "./use-home-data";
import LoadingError from "../loading-error.jsx";
import Icon from "../../components/icon.jsx";
import placeholders from "../../utils/placeholders.js";
import NewsConfig from "./news-config.jsx";
import Error from "../../components/error.jsx";

const SectionMyNews = ({
  data,
  sectionId,
  newsLink,
  onLoad,
  setHomeData,
  editNewsVisible,
  setEditNewsVisible,
}) => {
  const { callApi, isPreview } = useContext(UserContext);
  const { labels, cmsLang } = useContext(LanguageContext);
  const [error, setError] = useState();

  const closeEditNews = async (update) => {
    setEditNewsVisible(false);
    if (update) {
      const data = await loadMyNews(cmsLang);
      setHomeData((current) => ({ ...current, ...data }));
      /* console.log("TODO reload my news");
      setNewsConfig({});
      setHomeData((current) => ({ ...current, mynews: null })); */
    }
  };

  const loadMyNews = useCallback(
    async (cmsLang) => {
      if (cmsLang && isPreview && sectionId) {
        // const result = await callApi(`/resource/news/${sectionId}/preview`, { skipPreview: true });
        return { myNews: [] /*result?.data*/, newsKey: cmsLang + isPreview };
      } else {
        const params = new URLSearchParams(
          Object.entries({ pageNumber: 0, pageSize: 3, orderBy: "NEWEST" })
        );
        const data = await callApi(
          `/resource/news/my?${params.toString(params)}&locale=${cmsLang}`
        );
        return {
          myNews: data?.data,
          myNewsKey: cmsLang + isPreview,
          myPreferences: data?.preferences,
          allPreferences: data?.allPreferences,
        };
      }
    },
    [isPreview, sectionId, callApi]
  );

  useHomeData(data.myNewsKey, onLoad, loadMyNews, setError);
  const news = data?.myNews || placeholders(3);
  const preferences = data?.myPreferences || { init: true };

  return error ? (
    <LoadingError />
  ) : (
    <div className="home__section home__section--mynews">
      {!(preferences?.hubs || preferences?.topics || preferences?.init) ? (
        !isPreview && (
          <div className="home__news home__news--my-news">
            <button className="my-news__news-btn cta-link" onClick={() => setEditNewsVisible(true)}>
              <span>{labels?.CUSTOMIZE_NEWS} </span>
              <Icon name="arrow" />
            </button>
            <div className="my-news__news-placeholder"></div>
            <div className="my-news__news-placeholder"></div>
          </div>
        )
      ) : !!news?.length ? (
        <>
          <div className="home__news home__news--my-news">
            {news.map((item, i) => (
              <NewsCard
                news={item}
                key={item.id || i}
                tealiumId={`home_globalUpdates_${item.masterID}_myNews`}
              />
            ))}
          </div>
          <div className="text-center">
            <Link to={newsLink} className="button secondary">
              {labels?.SEE_ALL}
            </Link>
          </div>
        </>
      ) : (
        <Error msg={labels?.NO_MY_NEWS} />
      )}
      <Modal
        show={editNewsVisible}
        setShow={setEditNewsVisible}
        className="intranet-modal--full intranet-modal--top"
      >
        <NewsConfig
          preferences={preferences}
          allPreferences={data?.allPreferences || { hubs: [], topics: [] }}
          close={closeEditNews}
        />
      </Modal>
    </div>
  );
};

export default SectionMyNews;
