import classNames from "classnames";
import { useCallback, useContext, useEffect, useState } from "react";
import UserContext from "../context/user";
import Icon from "./icon";

const FavoriteBtn = ({ item, onUpdate }) => {
  const { isPreview } = useContext(UserContext);
  const [updating, setUpdating] = useState(false);
  const [internalBookmarkedState, setInternalBookmarkedState] = useState();

  useEffect(() => setInternalBookmarkedState(item?.bookmarked), [item]);

  const handleFavorite = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setUpdating(true);
        await onUpdate({
          id: item.masterID,
          isDelete: internalBookmarkedState,
          internalBookmarkedState,
          setInternalBookmarkedState,
        });
      } catch (err) {
        console.error(`Error updating favorite items`, err);
      }
      setUpdating(false);
    },
    [item, onUpdate, internalBookmarkedState]
  );

  return (
    <button
      className={classNames(
        "favorites__bookmark loading-highlighted",
        internalBookmarkedState && "favorites__bookmark--full"
      )}
      aria-label={internalBookmarkedState ? "Remove from favourites" : "Add to favourites"}
      onClick={handleFavorite}
      disabled={updating || internalBookmarkedState === undefined || isPreview}
    >
      <Icon name={internalBookmarkedState ? "bookmark-fill" : "bookmark"} />
    </button>
  );
};

export default FavoriteBtn;
