import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import LanguageContext from "../../context/language";
import UserContext from "../../context/user";

const useMenu = ({ path, select }) => {
  const { callApi, isPreview } = useContext(UserContext);
  const { cmsLang: locale } = useContext(LanguageContext);
  const navigate = useNavigate();

  return useQuery({
    queryKey: ["menu", locale, path, isPreview],
    queryFn: () => callApi(`/resource/${locale}/${path}`),
    keepPreviousData: true,
    enabled: !!locale && !!path,
    ...(select ? { select } : []),
    onError: (error) => {
      console.error("Error loading menu", path, error);
      if (error.status !== 401) {
        navigate("/error");
      }
    },
  });
};

export default useMenu;
