import { useCallback, useContext, useEffect, useRef, useState, useMemo } from "react";
import classNames from "classnames";
import FilterList from "../../components/filters/filter-list";
import ContactsCardWrapper from "../../components/contacts-card-wrapper";
import LoadingError from "../loading-error";
import useFilters, { queryStringFromQuery } from "../../hooks/useFilters";
import UserContext from "../../context/user";
import FavouriteContext from "../../context/favourites";
import placeholders from "../../utils/placeholders";

const pageSize = 12;

const SectionFavourites = () => {
  const { callApi } = useContext(UserContext);
  const { updateFavContacts } = useContext(FavouriteContext);
  const [contacts, setContacts] = useState({
    userDtoList: placeholders(3, "username"),
    init: true,
  });
  const [query, setFilterData] = useFilters("contact");
  const [page, setPage] = useState(0);
  const [error, setError] = useState();
  const loadMore = useCallback(() => setPage((current) => current + 1), []);
  const currentPage = contacts?.page || 0;
  const loadedPage = useRef();

  const currentContacts = useMemo(() => (contacts.init ? [] : contacts.userDtoList), [contacts]);

  const updateFav = useCallback(
    async (removedId) => {
      try {
        if (removedId) {
          await updateFavContacts(100, true);
          setContacts((current) => {
            const hasRemoved = current.userDtoList.some((item) => item.username === removedId);
            return {
              ...current,
              userDtoList: current.userDtoList.filter((item) => item.username !== removedId),
              paginationInfo: {
                ...current.paginationInfo,
                total: hasRemoved ? current.paginationInfo.total - 1 : current.paginationInfo.total,
              },
            };
          });
        } else {
          throw new Error("Should not enter here");
        }
      } catch (err) {
        console.error("Error updating favourites: ", err);
      }
    },
    [updateFavContacts]
  );

  useEffect(() => {
    const loadFilteredFavouriteContacts = async (currentContacts) => {
      const pageNumber = page === currentPage ? 0 : page;
      const offset = pageNumber === 0 ? 0 : currentContacts.length;
      const limit = (pageNumber + 1) * pageSize - offset;
      try {
        loadedPage.current = JSON.stringify(query);
        const { orderBy, ...otherQuery } = query;
        const allData = await callApi(
          `/profile/favorites/contacts?${queryStringFromQuery(
            {
              orderBy: orderBy ? (orderBy === "MOST_RELEVANT" ? "" : orderBy) : "SURNAME_AZ",
              ...otherQuery,
            },
            offset,
            limit
          ).toString()}`
        );
        setPage(pageNumber);
        setContacts((current) => ({
          ...current,
          ...allData,
          paginationInfo: {
            ...allData.paginationInfo,
            limit: pageSize,
          },
          userDtoList:
            page > currentPage
              ? current.userDtoList.concat(
                  allData.userDtoList?.map((item) => ({ ...item, favourite: true }))
                )
              : allData.userDtoList?.map((item) => ({ ...item, favourite: true })),
          page: pageNumber,
          init: false,
        }));
      } catch (err) {
        loadedPage.current = false;
        console.error(`Error loading favourites contacts`, err);
        setError(true);
      }
    };
    if (page > currentPage || JSON.stringify(query) !== loadedPage.current) {
      loadFilteredFavouriteContacts(currentContacts);
    }
  }, [query, page, currentPage, callApi, currentContacts]);

  return error ? (
    <LoadingError />
  ) : (
    <div className={classNames(contacts.init && "loading-skeleton")}>
      <FilterList
        items={contacts?.userDtoList}
        query={query}
        totalCounter={contacts.paginationInfo?.total}
        setFilterData={setFilterData}
        facetView={contacts?.facetView}
        resourceType="contact"
        hideFiltersEmpty={true}
      >
        <ContactsCardWrapper
          contacts={contacts.userDtoList}
          pagination={contacts?.paginationInfo}
          loadMore={loadMore}
          viewAll={false}
          updateFav={updateFav}
          search={query?.term}
        />
      </FilterList>
    </div>
  );
};

export default SectionFavourites;
