const defineHubProperties = (hub) => {
  return {
    ...hub,
    target: hub?.linkTarget?.parent?.segment
      ? `/${hub?.regionHub?.length > 0 ? "regional-hubs" : "business-hubs"}/${
          hub?.linkTarget?.parent?.segment
        }`
      : null,
  };
};

export default defineHubProperties;
