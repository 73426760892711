import { useContext, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import NoteCard from "../../components/note-card.jsx";
import UserContext from "../../context/user";
import LanguageContext from "../../context/language.jsx";
import useHomeData from "./use-home-data";
import placeholders from "../../utils/placeholders";
import LoadingError from "../loading-error.jsx";

const SectionPress = ({ data, sectionId, onLoad, pressLink }) => {
  const { isPreview, callApi } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);
  const [error, setError] = useState();

  const loadPress = useCallback(
    async (cmsLang) => {
      if (cmsLang && isPreview && sectionId) {
        const result = await callApi(`/resource/press/${sectionId}/preview`, { skipPreview: true });
        return { press: result?.data, pressKey: cmsLang + isPreview };
      }
      const params = new URLSearchParams(Object.entries({ pageNumber: 0, pageSize: 4 }));
      const data = await callApi(`/resource/press?${params.toString(params)}&locale=${cmsLang}`, {
        method: "POST",
        skipPreview: true,
      });
      return { press: data?.data, pressKey: cmsLang + isPreview };
    },
    [callApi, isPreview, sectionId]
  );
  useHomeData(data.pressKey, onLoad, loadPress, setError);
  const press = data.press || placeholders(3);

  return error ? (
    <LoadingError />
  ) : (
    <div className="home__section">
      <div className="home__notes ">
        {press?.map((item, i) => (
          <NoteCard
            note={item}
            key={item.id || i}
            press={true}
            tealiumId={`home_globalUpdates_${item.masterID}_pressReleases`}
          />
        ))}
      </div>
      <div className="text-center">
        <Link to={pressLink} className="button secondary">
          {labels?.SEE_ALL}
        </Link>
      </div>
    </div>
  );
};

export default SectionPress;
