import { useContext, useState, useCallback, memo, lazy, Suspense, useMemo } from "react";
import classNames from "classnames";
import Icon from "./icon";
import Image from "./image";
import UserContext from "../context/user";
import LanguageContext from "../context/language";
import ConditionalLink from "./conditional-link";
import { tealiumOnEvent } from "../utils/tealium";
import CategoryBadges from "./category-badges";

const ReactTooltip = lazy(() => import("react-tooltip"));
const ActionButton = lazy(() => import("./action-button"));

const AppLinkCard = ({
  item,
  large,
  draggable,
  dragListeners,
  isDragging,
  showType,
  onUpdate,
  className,
  setAddLinkVisible,
  setDeleteLinkModalVisible,
  tealiumId,
  showCategory = true,
}) => {
  const { isPreview, openAuthLink, callApi } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);
  const [updating, setUpdating] = useState();
  const [showActions, setShowActions] = useState(false);
  const isSkeletonLoading = Object.keys(item).length <= 2;
  const intro = item.intro;
  const name = item.title || item.name;
  const type = item.newsType?.externalReference;
  const categories = item?.categoryString?.map((cat, i) => ({
    id: i,
    label: labels?.[cat] || cat?.[0]?.toUpperCase() + cat?.slice(1)?.toLowerCase(),
    url: `/app-links?section=all&category=${cat}`,
  }));

  const addToFavorite = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();
      setUpdating(true);
      const serviceType = type === "app" ? "apps" : "links";
      try {
        const cardId = e.currentTarget?.id;
        await callApi(`/profile/favorites/${serviceType}/${cardId}`, {
          method: "POST",
        });
        tealiumOnEvent({
          eventType: "appTool",
          eventAction: "Add",
          eventLabel: name,
        });
        await onUpdate(serviceType);
      } catch (err) {
        console.error(`Error adding app from favorites`, err);
      }
      setUpdating(false);
    },
    [callApi, type, onUpdate, name]
  );

  const removeFromFavorites = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();
      setUpdating(true);
      const serviceType = type === "app" ? "apps" : "links";
      try {
        const cardId = e.currentTarget?.id;
        await callApi(`/profile/favorites/${serviceType}/${cardId}`, {
          method: "DELETE",
        });
        tealiumOnEvent({
          eventType: "appTool",
          eventAction: "Remove",
          eventLabel: cardId,
        });
        await onUpdate(serviceType);
      } catch (err) {
        console.error(`Error removing ${serviceType} from favorites`, err);
      }
      setUpdating(false);
    },
    [callApi, type, onUpdate]
  );

  const href = useMemo(() => {
    if (item?.linkURL) {
      if (item?.subjectTaxonomy?.[0]?.externalReference) {
        const params = new URLSearchParams();
        params.append("target", item.linkURL);
        params.append("externalReference", item.subjectTaxonomy[0].externalReference);
        return `/bff/authorization/samlRequest?${params.toString()}`;
      } else {
        return item.linkURL;
      }
    } else {
      return "#";
    }
  }, [item]);

  return item ? (
    <div
      className={classNames(
        "favorites__card",
        className,
        large && "favorites__card--large",
        ["link", "custom_link"].includes(type) && "favorites__card--link",
        draggable && "favorites__card--draggable",
        isDragging && "dragged",
        isSkeletonLoading && "loading-skeleton"
      )}
    >
      <ConditionalLink
        className="favorites__card-link"
        href={href}
        target="_blank"
        disabled={updating || isDragging || isSkeletonLoading}
        onClick={openAuthLink}
        data-id={item.masterID}
        data-element-id={tealiumId}
        data-description={tealiumId ? "image" : undefined}
        aria-label={name}
      />
      {draggable && (
        <button
          {...dragListeners}
          className="favorites__card-draghandle"
          aria-label="Card drag handle"
        >
          <Icon name="drag" />
        </button>
      )}
      {isSkeletonLoading ? (
        <div className="favorites__card-img loading-highlighted">
          <span>{name}</span>
        </div>
      ) : item.coverImage ? (
        <Image
          cropUrlTemplate={item?.coverImage.cropUrlTemplate}
          crops={[
            {
              crop: "portrait_ratio1x1",
              sizes: [
                {
                  maxWidth: "64px",
                },
              ],
            },
          ]}
          alt={name}
          className="favorites__card-img"
        />
      ) : (
        <div className="favorites__card-img favorites__card-img--placeholder" />
      )}
      <div className="favorites__card-content">
        {/* {showType && <div className="favorites__card-type loading-highlighted">{TYPES[type]}</div>} */}
        <div className="favorites__card-name loading-highlighted">{name}</div>
        {type === "custom_link" ? (
          <div className="favorites__card-description">{labels?.CUSTOMIZED || "Customized"}</div>
        ) : (
          intro && (
            <div
              className="favorites__card-description"
              dangerouslySetInnerHTML={{ __html: intro }}
            />
          )
        )}
        {showCategory && categories?.length > 0 && <CategoryBadges categories={categories} />}
      </div>
      {item.pinned ? (
        <button
          className="favorites__bookmark-pinned"
          aria-label={labels?.ITEM_CANT_BE_MOVED}
          data-tip={labels?.ITEM_CANT_BE_MOVED}
          data-for={item.masterID}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Icon name="pin-fill" />
          <Suspense>
            <ReactTooltip id={item.masterID} className="tooltip" place="left" effect="solid" />
          </Suspense>
        </button>
      ) : type === "custom_link" ? (
        <Suspense>
          <ActionButton
            showActions={showActions}
            setShowActions={setShowActions}
            className={"loading-highlighted"}
            id={item.masterID}
            offset={[0, 6]}
            actions={[
              {
                action: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setAddLinkVisible({
                    customLink: { id: item.masterID, linkURL: item.linkURL, title: item.name },
                  });
                },
                hasCondition: false,
                icon: "edit",
                label: labels?.EDIT || "Edit",
              },
              {
                action: (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setDeleteLinkModalVisible({
                    customLink: { id: item.masterID },
                  });
                },
                hasCondition: false,
                icon: "trash",
                label: labels?.DELETE || "Delete",
              },
            ]}
          />
        </Suspense>
      ) : (
        <button
          className={classNames("favorites__bookmark loading-highlighted", {
            "favorites__bookmark--full": item.favourite,
          })}
          onClick={item.favourite ? removeFromFavorites : addToFavorite}
          disabled={updating || item.favourite === undefined || isPreview}
          id={item?.masterID}
          name={name}
          aria-label={item.favourite ? "Remove from favourites" : "Add to favourites"}
        >
          {item.favourite || !showType ? <Icon name="bookmark-fill" /> : <Icon name="bookmark" />}
        </button>
      )}
    </div>
  ) : null;
};

export default memo(AppLinkCard);
