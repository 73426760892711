import { useContext, useCallback } from "react";
import Comments from "./comments";
import UserContext from "../context/user";
import LanguageContext from "../context/language";

const pageSize = 10;

const NewsComments = ({ newsId, commentsCount, hubName }) => {
  const { callApi } = useContext(UserContext);
  const { labels, cmsLang } = useContext(LanguageContext);

  const fetchComments = useCallback(
    (page) => {
      return callApi(
        `/resource/news/${newsId}/comments?` +
          new URLSearchParams({
            limit: pageSize,
            offset: page * pageSize,
            locale: cmsLang,
          })
      );
    },
    [callApi, cmsLang, newsId]
  );

  const postComment = useCallback(
    (typedComment, parentCommentId) => {
      return callApi(`/resource/news/${newsId}/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify({
          commentText: typedComment,
          parentCommentId: parentCommentId ? parentCommentId : null,
        }),
      });
    },
    [callApi, newsId]
  );

  const fetchReplies = useCallback(
    (commentId, limit, offset) => {
      return callApi(
        `/resource/news/${newsId}/comments?` +
          new URLSearchParams({
            limit: limit,
            offset: offset,
            locale: cmsLang,
            parentCommentId: commentId,
          })
      );
    },
    [callApi, cmsLang, newsId]
  );

  const fetchLikes = useCallback(
    (commentId) => {
      return callApi(`/resource/news/${newsId}/comments/${commentId}/likes`);
    },
    [callApi, newsId]
  );

  const postLike = useCallback(
    (commentId, method) => {
      return callApi(`/resource/news/${newsId}/comments/${commentId}/like`, {
        method,
      });
    },
    [callApi, newsId]
  );

  const updateComment = useCallback(
    (commentId, typedComment) => {
      const url = `/resource/news/${newsId}/comments/${commentId}`;
      return callApi(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify({
          commentText: typedComment,
        }),
      });
    },
    [callApi, newsId]
  );

  const deleteComment = useCallback(
    (commentId) => {
      const url = `/resource/news/${newsId}/comments/${commentId}`;
      return callApi(url, {
        method: "DELETE",
      });
    },
    [callApi, newsId]
  );

  return (
    <section className="news-detail__comments full-width" id="comments" aria-label="News Comments">
      <div className="wrapper-medium">
        <h2>{labels?.COMMENTS}</h2>
        <Comments
          commentsCount={commentsCount}
          postComment={postComment}
          fetchComments={fetchComments}
          updateComment={updateComment}
          deleteComment={deleteComment}
          fetchReplies={fetchReplies}
          fetchLikes={fetchLikes}
          postLike={postLike}
          hubName={hubName}
        />
      </div>
    </section>
  );
};

export default NewsComments;
