import { useCallback, useState } from "react";

const useListChecked = (initialValues) => {
  const [listChecked, setListChecked] = useState(initialValues);
  const toggleValue = useCallback((event) => {
    const { name } = event.currentTarget;
    setListChecked((current) =>
      current.includes(name) ? current.filter((item) => item !== name) : current.concat(name)
    );
  }, []);
  const clearList = useCallback(() => setListChecked([]), []);
  return [listChecked, toggleValue, clearList];
};

export default useListChecked;
