import { useCallback, useContext, useEffect, useRef, useState, useMemo } from "react";
import classNames from "classnames";
import LanguageContext from "../context/language";
import LoadingError from "../routes/loading-error";
import Comment from "./comment";
import CommentInput from "./comment-input";
import Pagination from "./pagination";

const CommentCounter = ({ labels, totalComments }) => {
  return (
    <div className="comments__counter">
      {totalComments === 0
        ? labels?.NO_COMMENTS_YET
        : totalComments > 1
        ? `${totalComments} ${labels?.COMMENTS?.toLowerCase()}`
        : `1 ${labels?.COMMENT?.toLowerCase()}`}
    </div>
  );
};

const Comments = ({
  className,
  commentsCount,
  postComment,
  fetchComments,
  updateComment,
  deleteComment,
  fetchReplies,
  fetchLikes,
  postLike,
  post = false,
  isAdmin,
  hubName,
}) => {
  const { labels } = useContext(LanguageContext);
  const [comments, setComments] = useState();
  const [pagination, setPagination] = useState();
  const [error, setError] = useState();
  const [page, setPage] = useState(0);
  const [tempcommentsCount, setTempcommentsCount] = useState(null);

  const loadComments = useCallback(
    async (reset = false) => {
      try {
        const result = await fetchComments(reset ? 0 : page);
        if (result?.list && result?.paginationInfo) {
          setComments((current) =>
            current?.length > 0 && !reset ? current.concat(result.list) : result.list
          );
          setPage((current) => (reset ? 0 : current));
          setPagination(result.paginationInfo);
          if (reset) {
            loadedComments.current = 0;
          }
        }
      } catch (err) {
        console.error("Error loading comments", err);
        setError(true);
      }
    },
    [fetchComments, page]
  );

  const loadedComments = useRef();
  useEffect(() => {
    if (loadedComments.current !== page) {
      loadedComments.current = page;
      loadComments();
    }
  }, [loadComments, page]);

  const totalComments = useMemo(
    () => (tempcommentsCount ? tempcommentsCount : commentsCount) || 0,
    [commentsCount, tempcommentsCount]
  );

  const increaseCommentsCount = useCallback(() => {
    setTempcommentsCount((current) => (current ? current + 1 : commentsCount + 1));
  }, [commentsCount]);

  const onUpdate = useCallback((removedPostId) => {
    if (removedPostId) {
      setComments((current) => current.filter((comment) => comment?.comment?.id !== removedPostId));
      setPagination((current) => ({ ...current, total: current.total - 1 }));
    }
  }, []);

  const handleUpdateComment = useCallback(
    (updatedPostId, updatedComment) => {
      if (updatedPostId && updatedComment) {
        updateComment(updatedPostId, updatedComment);
        setComments((current) =>
          current.map((comment) =>
            comment?.comment?.id === updatedPostId
              ? { ...comment, comment: { ...comment.comment, comment: updatedComment } }
              : comment
          )
        );
      }
    },
    [updateComment]
  );

  const scrollToRef = useRef();
  const [focusOnFirstComment, setFocusOnFirstComment] = useState();

  useEffect(() => {
    if (focusOnFirstComment) {
      scrollToRef?.current?.scrollIntoView({ behavior: "smooth" });
      setFocusOnFirstComment(false);
    }
  }, [focusOnFirstComment]);

  return error ? (
    <LoadingError />
  ) : (
    <div className={classNames("comments", className, !comments && "loading-skeleton")}>
      {post && <CommentCounter labels={labels} totalComments={totalComments} />}
      <CommentInput
        postComment={postComment}
        loadComments={loadComments}
        increaseCommentsCount={increaseCommentsCount}
        setFocusOnFirstComment={() => setFocusOnFirstComment(true)}
      />
      <hr />
      {!post && <CommentCounter labels={labels} totalComments={totalComments} />}
      {comments?.map((comment, i) => (
        <Comment
          comment={comment}
          key={comment?.comment?.id}
          postComment={postComment}
          loadComments={loadComments}
          updateComment={handleUpdateComment}
          deleteComment={deleteComment}
          fetchReplies={fetchReplies}
          fetchLikes={fetchLikes}
          postLike={postLike}
          onUpdate={onUpdate}
          increaseCommentsCount={increaseCommentsCount}
          post={post}
          isAdmin={isAdmin}
          hubName={hubName}
          ref={i === 0 ? scrollToRef : undefined}
        />
      ))}
      <Pagination
        currentItemCount={comments?.length}
        pagination={pagination}
        loadMore={() => setPage((current) => current + 1)}
        hideCounter={true}
      />
    </div>
  );
};

export default Comments;
