import { Link } from "react-router-dom";

const NUMBER_FIXED_TABS = 2;

const scrollToTop = () => window.scrollTo(0, 0);

const CategoryBadges = ({ categories }) => {
  return (
    <div className="category-badges loading-highlighted">
      {categories?.slice(0, NUMBER_FIXED_TABS)?.map((cat) => (
        <Link to={cat.url} className="category-badges__label" onClick={scrollToTop} key={cat?.id}>
          {cat?.label}
        </Link>
      ))}
      {categories?.length > NUMBER_FIXED_TABS && (
        <span className="category-badges__label category-badges__label--other">{`+${
          categories.length - NUMBER_FIXED_TABS
        }`}</span>
      )}
    </div>
  );
};

export default CategoryBadges;
