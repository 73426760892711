import { Link } from "react-router-dom";
import classNames from "classnames";
import Icon from "./icon";
import useBlockScroll from "../hooks/useBlockScroll";

const InternalMenu = ({ page, mobileLabel, mobileMenu, setMobileMenu }) => {
  const toggleMobileMenu = () => setMobileMenu((value) => !value);

  useBlockScroll(mobileMenu, true);

  return (
    <div className="internal-menu full-width">
      <button
        className={classNames(
          "internal-menu__mobile-btn-wrapper d-none d-tsmall-inline-flex",
          mobileLabel && "internal-menu__mobile-btn-wrapper--with-label"
        )}
        onClick={toggleMobileMenu}
      >
        {!!mobileMenu && (
          <span className="internal-menu__mobile-btn d-none d-tsmall-inline-flex">
            <Icon name="x-icon" />
          </span>
        )}
        {mobileLabel && (
          <span className="internal-menu__mobile-label d-none d-tsmall-inline-flex">
            {mobileLabel}
          </span>
        )}
      </button>
      <nav
        className={classNames("internal-menu__nav", {
          "internal-menu__nav--open": mobileMenu,
        })}
      >
        <ul className="internal-menu__list">
          {page.siblings.map((item) => (
            <li className="internal-menu__item" key={item.link}>
              {item.link === page.link ? (
                <span>{item.label}</span>
              ) : (
                <Link to={item.link}>{item.label}</Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default InternalMenu;
