import classNames from "classnames";
import WidgetTitle from "./elements/widget-title";

const SimpleContentWidget = ({ data, title }) => {
  return (
    data?.[0]?.html && (
      <section className={classNames("widget simple-content-widget", !title && "no-title")}>
        <WidgetTitle title={title} />
        <div
          className="simple-content-widget__content loading-highlighted"
          dangerouslySetInnerHTML={{ __html: data[0].html }}
        />
      </section>
    )
  );
};

export default SimpleContentWidget;
