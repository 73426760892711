import { useContext, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import NewsCard from "../../components/news-card.jsx";
import UserContext from "../../context/user";
import LanguageContext from "../../context/language.jsx";
import useHomeData from "./use-home-data";
import placeholders from "../../utils/placeholders";
import LoadingError from "../loading-error.jsx";

const SectionNews = ({ data, sectionId, newsLink, onLoad }) => {
  const { isPreview, callApi } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);
  const [error, setError] = useState();

  const loadNews = useCallback(
    async (cmsLang) => {
      if (cmsLang && isPreview && sectionId) {
        const result = await callApi(`/resource/news/${sectionId}/preview`, { skipPreview: true });
        return { news: result?.data, newsKey: cmsLang + isPreview };
      } else {
        const params = new URLSearchParams(Object.entries({ pageNumber: 0, pageSize: 6 }));
        const data = await callApi(`/resource/news?${params.toString(params)}&locale=${cmsLang}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          skipPreview: true,
          body: JSON.stringify({
            hubContext: "corporate",
            pinnedNumber: 1,
          }),
        });
        return { news: data?.data, newsKey: cmsLang + isPreview };
      }
    },
    [callApi, isPreview, sectionId]
  );

  useHomeData(data.newsKey, onLoad, loadNews, setError);
  const news = data.news || placeholders(5);

  return error ? (
    <LoadingError />
  ) : (
    <div className="home__section">
      <div className="home__news">
        {news?.map((item, i) => (
          <NewsCard
            news={item}
            key={item.id || i}
            tealiumId={`home_globalUpdates_${item.masterID}_corporateNews`}
          />
        ))}
      </div>
      <div className="text-center">
        <Link to={newsLink} className="button secondary">
          {labels?.SEE_ALL_CORPORATE_NEWS}
        </Link>
      </div>
    </div>
  );
};

export default SectionNews;
