import { useState, useMemo } from "react";
import classNames from "classnames";
import Icon from "./icon";
import Pagination from "./pagination";

const PAGE_SIZE = 8;

const Faqs = ({ title, faqs }) => {
  const [showAccordion, setShowAccordion] = useState({});
  const [page, setPage] = useState(0);

  const currentFaqs = useMemo(() => faqs?.slice(0, PAGE_SIZE * (page + 1)), [faqs, page]);

  const toggleAccordion = (id) => {
    setShowAccordion((current) => ({
      ...current,
      [id]: !Boolean(current[id]),
    }));
  };

  return (
    <div className="accordion loading-highlighted">
      {title && <h3 className="accordion__name">{title}</h3>}
      {currentFaqs?.map((accordion) => (
        <div key={accordion?.id} className="accordion__item-wrapper">
          <button
            className={classNames(
              "accordion__item",
              showAccordion[accordion?.id] && "accordion__item--open"
            )}
            id={accordion?.id}
            onClick={() => toggleAccordion(accordion?.id)}
          >
            <span className="accordion__title">{accordion?.title}</span>
            <Icon name="chevron-right" />
          </button>
          <div
            className="accordion__panel"
            dangerouslySetInnerHTML={{ __html: accordion?.description }}
          />
        </div>
      ))}
      {faqs?.length > PAGE_SIZE && (
        <Pagination
          pagination={{
            resultsAvailableCount: faqs?.length,
          }}
          currentItemCount={currentFaqs?.length}
          loadMore={() => setPage((current) => current + 1)}
        />
      )}
    </div>
  );
};

export default Faqs;
