import { useContext, useEffect, useRef } from "react";
import LanguageContext from "../../context/language";
import UserContext from "../../context/user";

const useHomeData = (loadKey, onLoad, loadItems, setError) => {
  const loadingRef = useRef(false);
  const loadedRef = useRef(false);
  const { cmsLang } = useContext(LanguageContext);
  const { isPreview } = useContext(UserContext);
  useEffect(() => {
    const loadData = async (lang) => {
      loadingRef.current = true;
      loadedRef.current = isPreview;
      try {
        const result = await loadItems(lang);
        onLoad(result);
      } catch (err) {
        console.error("Error loading data: ", err);
        setError(err);
        loadedRef.current = undefined;
      }
      loadingRef.current = false;
    };
    // Reload when is not loading, the preview status is known
    // and the data has not already been loaded in the same language or preview status
    if (!loadingRef.current && loadKey !== cmsLang + isPreview && isPreview !== undefined) {
      loadData(cmsLang);
    }
  }, [loadKey, onLoad, loadItems, cmsLang, setError, isPreview]);
};

export default useHomeData;
