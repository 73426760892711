import { useContext } from "react";
import LanguageContext from "../context/language";

const Pagination = ({ pagination, currentItemCount, loadMore, hideCounter, isLoading }) => {
  const { labels } = useContext(LanguageContext);

  const total = pagination?.resultsAvailableCount
    ? pagination?.resultsAvailableCount
    : pagination?.total;

  return (
    total > 0 && (
      <div className="pagination">
        {!hideCounter && (
          <span>
            {new Intl.NumberFormat("it").format(currentItemCount)} {labels?.OF?.toLowerCase()}{" "}
            {new Intl.NumberFormat("it").format(total)}{" "}
            {total === 1 ? labels?.RESULT?.toLowerCase() : labels?.RESULTS?.toLowerCase()}
          </span>
        )}
        {currentItemCount < total && (
          <button className="button secondary" onClick={loadMore}>
            {isLoading && <span className="loading-spinner" />}
            {labels?.LOAD_MORE}
          </button>
        )}
      </div>
    )
  );
};

export default Pagination;
