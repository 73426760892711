import { useRef, useState } from "react";
import useHover from "../hooks/useHover";
import Video from "./video";

const VideoPlayer = ({ video }) => {
  const [hoverRef, isHovered] = useHover();
  const videoRef = useRef();
  const [isTouched, setIsTouched] = useState(false);

  return video ? (
    <span className="video-player">
      <span
        ref={hoverRef}
        onTouchStart={() => {
          setIsTouched(true);
          videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause();
        }}
      >
        <Video
          item={{
            video: { data: video?.data, dataUrl: video?.dataUrl, picture: video?.picture },
            title: video?.title,
          }}
          isHovered={isHovered}
          isTouched={isTouched}
          ref={videoRef}
          className="video-player__video"
          coverCropWidth="1200"
        />
      </span>
    </span>
  ) : null;
};

export default VideoPlayer;
