import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import UserContext from "../../context/user";

const useUpdateFavoriteCommunities = ({
  doInvalidateAllCommunities,
  doInvalidateMyCommunities,
  doOptimisticUpdateAllCommunities,
  doOptimisticUpdateMyCommunities,
  doInvalidateCommunityDetails = true,
  currentAllQueryKey,
  currentMyQueryKey,
}) => {
  const { callApi } = useContext(UserContext);
  const queryClient = useQueryClient();

  return useMutation(
    ({ communityId, isDelete }) =>
      callApi(`/profile/favorites/community/${communityId}`, {
        method: isDelete ? "DELETE" : "POST",
      }),
    {
      onSuccess: (_data, { communityId, isDelete }) => {
        if (doInvalidateAllCommunities) {
          //Invalidate cache for all communities
          console.log("Invalidating cache for", ["community"]);
          queryClient.removeQueries(["community"]);
        } else {
          //Invalidate cache for all communities except current
          queryClient.removeQueries({
            predicate: (q) => {
              const doInvalidate =
                q.queryKey[0] === "community" &&
                JSON.stringify(q.queryKey[2]) !== JSON.stringify(currentAllQueryKey[2]);
              if (doInvalidate) {
                console.log("Invalidating cache for query", q.queryKey);
              }
              return doInvalidate;
            },
          });
        }
        if (doInvalidateMyCommunities) {
          //Invalidate cache for my communities
          console.log("Invalidating cache for", ["my-community"]);
          queryClient.removeQueries(["my-community"]);
        } else {
          //Invalidate cache for my communities except current
          queryClient.removeQueries({
            predicate: (q) => {
              const doInvalidate =
                q.queryKey[0] === "my-community" &&
                JSON.stringify(q.queryKey[2]) !== JSON.stringify(currentMyQueryKey[2]);
              if (doInvalidate) {
                console.log("Invalidating cache for query", q.queryKey);
              }
              return doInvalidate;
            },
          });
        }
        if (doInvalidateCommunityDetails) {
          //Invalidate cache for community details
          console.log("Invalidating cache for", ["detail-community"]);
          queryClient.removeQueries(["detail-community"]);
        }
        // Perform optimistic update on current query (all)
        if (doOptimisticUpdateAllCommunities) {
          queryClient.setQueryData(currentAllQueryKey, (oldData) => {
            const newData = oldData?.pages.map((page) => ({
              ...page,
              list: page.list.map((item) => {
                if (item.id === Number(communityId)) {
                  return {
                    ...item,
                    bookmarked: !isDelete,
                  };
                } else {
                  return item;
                }
              }),
            }));
            return {
              ...oldData,
              pages: newData,
            };
          });
        }
        // Perform optimistic update on current query (my)
        if (doOptimisticUpdateMyCommunities) {
          const pageSize = currentMyQueryKey[1];
          queryClient.setQueryData(currentMyQueryKey, (oldData) => {
            const allData = oldData?.pages.map((page) => page.list).flat();
            if (isDelete) {
              const filteredData = allData.filter((item) => item.id !== Number(communityId));
              const pages = oldData.pages
                .map((page, index) => ({
                  ...page,
                  paginationInfo: { ...page.paginationInfo, total: page.paginationInfo.total - 1 },
                  list: filteredData.slice(index * pageSize, (index + 1) * pageSize),
                }))
                .filter((page) => !!page.list.length);
              return {
                pageParams: oldData.pageParams.slice(0, pages.length),
                pages,
              };
            } else if (currentAllQueryKey) {
              const addedCommunity = queryClient
                .getQueryData(currentAllQueryKey)
                ?.pages?.map((page) => page.list)
                ?.flat()
                ?.find((c) => Number(communityId) === c.id);
              if (addedCommunity) {
                const newData = oldData?.pages
                  .map((page) => page.list)
                  .flat()
                  .concat(addedCommunity);
                const pages = oldData.pages
                  .map((page, index) => ({
                    ...page,
                    paginationInfo: {
                      ...page.paginationInfo,
                      total: page.paginationInfo.total - 1,
                    },
                    list: newData.slice(index * pageSize, (index + 1) * pageSize),
                  }))
                  .filter((page) => !!page.list.length);
                return {
                  pageParams: oldData.pageParams.slice(0, pages.length),
                  pages,
                };
              }
            }
          });
        }
      },
    }
  );
};

export default useUpdateFavoriteCommunities;
