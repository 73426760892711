import { useCallback, useRef, useState } from "react";
import throttle from "lodash.throttle";

const useScrollable = () => {
  const [scrollOffset, setScrollOffset] = useState(0);
  const [maxOffset, setMaxOffset] = useState(0);
  const cleanupRef = useRef();

  const refCallback = useCallback((element) => {
    const config = { childList: true };
    if (!!element) {
      const scrollListener = throttle(() => {
        setScrollOffset(element.scrollHeight - element.offsetHeight - element.scrollTop);
      }, 100);
      const cleanupListener = () => element.removeEventListener("scroll", scrollListener);

      const callback = (mutationList, _observer) => {
        for (const mutation of mutationList) {
          if (mutation.type === "childList") {
            const maxOffset = element.scrollHeight - element.offsetHeight;
            setMaxOffset(maxOffset);
            setScrollOffset(element.scrollHeight - element.offsetHeight - element.scrollTop);
            if (maxOffset > 0) {
              cleanupListener();
              element.addEventListener("scroll", scrollListener);
            }
          }
        }
      };
      const observer = new MutationObserver(callback);
      observer.observe(element, config);

      const maxOffset = element.scrollHeight - element.offsetHeight;
      setMaxOffset(maxOffset);
      setScrollOffset(element.scrollHeight - element.offsetHeight - element.scrollTop);
      if (maxOffset > 0) {
        cleanupListener();
        element.addEventListener("scroll", scrollListener);
      }

      cleanupRef.current = () => {
        observer.disconnect();
        cleanupListener();
      };
    } else if (typeof cleanupRef.current === "function") {
      cleanupRef.current();
    }
  }, []);

  return [refCallback, maxOffset > 0 ? scrollOffset : 0, maxOffset];
};

export default useScrollable;
