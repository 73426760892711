import Translate from "../translate";
import { cookiePolicyHtml } from "./cookie-policy-content";

const CookiePolicyIT = ({ logged }) => {
  return (
    <main className="page text-center">
      <div className="page__header wrapper-small">
        <h1>{logged ? <Translate text="Cookie Policy" /> : "Cookie Policy"}</h1>
      </div>
      <div className="text wrapper-small">
        {logged ? (
          <Translate html={cookiePolicyHtml} />
        ) : (
          <div className="html" dangerouslySetInnerHTML={{ __html: cookiePolicyHtml }} />
        )}
      </div>
    </main>
  );
};

export default CookiePolicyIT;
