import { useState, useMemo, useContext, Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import AppLinkCard from "../../components/app-link-card";
import Icon from "../../components/icon";
import LanguageContext from "../../context/language";
import DeleteCustomLinkModal from "../../components/delete-custom-link-modal";

const SortableList = lazy(() => import("../../components/sortable-list"));
const AddCustomLinkModal = lazy(() => import("../../components/add-custom-link-modal"));

const scrollToTop = () => window.scrollTo(0, 0);

const SectionLinks = ({ data, updateFav, onDrop }) => {
  const { labels } = useContext(LanguageContext);
  const [addLinkVisible, setAddLinkVisible] = useState(false);
  const [deleteLinkModalVisible, setDeleteLinkModalVisible] = useState(false);

  const localList = useMemo(
    () =>
      data.appLinkDtoList.map((item) => (
        <AppLinkCard
          item={item}
          large={true}
          draggable={true}
          showType={true}
          onUpdate={updateFav}
          key={item.masterID}
          setAddLinkVisible={setAddLinkVisible}
          setDeleteLinkModalVisible={setDeleteLinkModalVisible}
        />
      )),
    [data.appLinkDtoList, updateFav]
  );

  return (
    <div className="apps-links__section">
      <div className="apps-links__list">
        <button
          className="favorites__card favorites__card--large favorites__card--border"
          onClick={() => setAddLinkVisible(true)}
        >
          <div className="favorites__card-img favorites__card-img--icon">
            <Icon name="share-link" />
          </div>
          <div className="favorites__card-content">
            <div className="favorites__card-name loading-highlighted">
              {labels?.ADD_PERSONAL_LINK || "Add a new personal link"}
            </div>
          </div>
        </button>
        {data.pinned.map((item) => (
          <AppLinkCard
            item={item}
            large={true}
            showType={true}
            onUpdate={updateFav}
            key={item.masterID}
          />
        ))}
        <Suspense fallback={<>{localList}</>}>
          <SortableList onDrop={onDrop}>{localList}</SortableList>
        </Suspense>
      </div>
      <Link to="?section=all" onClick={scrollToTop} className="cta-link apps-links__add-favourite">
        <span>{labels?.EDIT_YOUR_FAVORITE_LINKS}</span>
        <Icon name="arrow" />
      </Link>
      <Suspense>
        <AddCustomLinkModal
          key={!!addLinkVisible}
          show={!!addLinkVisible}
          setShow={setAddLinkVisible}
          customLink={addLinkVisible?.customLink}
          onUpdate={updateFav}
        />
      </Suspense>
      <DeleteCustomLinkModal
        show={!!deleteLinkModalVisible}
        setShow={setDeleteLinkModalVisible}
        linkId={deleteLinkModalVisible?.customLink?.id}
        onUpdate={updateFav}
      />
    </div>
  );
};

export default SectionLinks;
