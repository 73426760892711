import { useContext, useRef } from "react";
import classNames from "classnames";
import Icon from "../../components/icon.jsx";
import Translate from "../../components/translate";
import LanguageContext from "../../context/language";
import ConditionalLink from "../../components/conditional-link";
import LoadingError from "../loading-error";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useLeonardoCourses from "../../hooks/queries/useLeonardoCourses.js";

const ArrowBtn = ({ arrowRef, next = false }) => {
  return (
    <button
      className={classNames("swiper-button", next ? "swiper-button--next" : "swiper-button--prev")}
      ref={arrowRef}
      aria-label="Arrow"
    >
      <Icon name="chevron" />
    </button>
  );
};

const CourseCard = ({ data, isSkeletonLoading, labels }) => {
  return (
    <article className="course-card">
      <ConditionalLink
        href={data?.linkUrl}
        target="_blank"
        className="course-card__img loading-highlighted"
        data-element-id={`home_leonardoTop10_${data?.courseId}`}
        data-description="image"
      >
        {!isSkeletonLoading && <img src={data?.courseOverviewFile} alt={data?.courseFullName} />}
      </ConditionalLink>
      <div className="course-card__content">
        <div className="course-card__type loading-highlighted">
          {labels?.[data?.complexity] || data?.complexity}
        </div>
        <ConditionalLink
          href={data?.linkUrl}
          target="_blank"
          className="course-card__title title-h5 loading-highlighted"
          data-element-id={`home_leonardoTop10_${data?.courseId}`}
          data-description="text"
        >
          <Translate text={data?.courseFullName} />
        </ConditionalLink>
        {!["available", "new"]?.includes(data?.status) && (
          <span className="course-card__status loading-highlighted">
            {labels?.[`COURSE_STATUS_${data?.status?.toUpperCase()}`] || data?.status}
          </span>
        )}
      </div>
    </article>
  );
};

const SectionCourses = ({ title, tagCMS }) => {
  const { labels } = useContext(LanguageContext);
  const prevRef = useRef();
  const nextRef = useRef();

  const { data: leonardoCourses, error, isFetching: loading } = useLeonardoCourses(tagCMS);

  return error ? (
    <LoadingError />
  ) : (
    leonardoCourses?.length > 0 && (
      <section
        className={classNames(
          tagCMS ? "widget leonardo-courses" : "courses",
          loading && "loading-skeleton",
          tagCMS && !title && "no-title"
        )}
      >
        {tagCMS ? (
          <div className="widget__title-container">
            {title && (
              <div className="widget__title loading-highlighted">
                <h2>
                  <Translate text={title} />
                </h2>
              </div>
            )}
            <div className="widget__arrow-portal">
              <ArrowBtn arrowRef={prevRef} />
              <ArrowBtn arrowRef={nextRef} next={true} />
            </div>
          </div>
        ) : (
          <div className="home__title-container">
            <h2 className="home__title home__title--slider title-h3 loading-highlighted">
              {labels?.YOUR_LEONARDO_COURSES}
            </h2>
            <div className="home__slider-arrow-container">
              <ArrowBtn arrowRef={prevRef} />
              <ArrowBtn arrowRef={nextRef} next={true} />
            </div>
          </div>
        )}
        <div className={classNames("courses-slider__wrapper")}>
          <Swiper
            className="courses-slider"
            slidesPerView={leonardoCourses?.length > 1 ? "auto" : 1}
            spaceBetween={16}
            modules={[Navigation]}
            loop={false}
            navigation={{
              prevEl: ".swiper-button--prev",
              nextEl: ".swiper-button--next",
            }}
            breakpoints={{
              575: {
                slidesPerView: Math.min(leonardoCourses?.length, 2),
                spaceBetween: 22,
              },
              1240: {
                slidesPerView: Math.min(leonardoCourses?.length, 3),
                spaceBetween: 22,
              },
            }}
          >
            {leonardoCourses.map((slide, i) => (
              <SwiperSlide key={i}>
                <CourseCard data={slide} key={i} labels={labels} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {!tagCMS && (
          <a
            href={window.LEONARDO_URL}
            className="courses-slider__link cta-link loading-highlighted"
            target="_blank"
          >
            <span>{labels?.GO_TO_LEONARDO}</span>
            <Icon name="arrow" />
          </a>
        )}
      </section>
    )
  );
};

export default SectionCourses;
