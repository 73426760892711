import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import SafeHtml from "./safe-html";
import UserContext from "../context/user";
import LanguageContext from "../context/language";

const TranslateContext = createContext({
  texts: {},
  translating: false,
  translate: () => undefined,
  registerText: () => undefined,
});

export const TranslateProvider = ({ children }) => {
  const { pathname, search } = useLocation();
  const { callApi } = useContext(UserContext);
  const { browserLanguage } = useContext(LanguageContext);
  const translationContext = pathname + search;

  const [translatedLang, setTranslatedLang] = useState();
  const [translating, setTranslating] = useState(false);
  const [texts, setTexts] = useState({});
  const [translations, setTranslations] = useState();
  const pathRef = useRef(translationContext);
  useEffect(() => {
    if (pathRef.current !== translationContext) {
      setTexts({});
      setTranslations();
      setTranslatedLang();
      pathRef.current = translationContext;
    }
  }, [translationContext]);

  const registerText = useCallback((id, text) => {
    setTexts((current) => ({ ...current, [id]: text }));
  }, []);

  const translate = useCallback(async () => {
    if (browserLanguage === translatedLang) {
      console.warn("Already translated to " + browserLanguage);
      return;
    }
    console.log("translate to " + browserLanguage);
    const textList = Object.entries(texts);
    setTranslating(true);
    try {
      const translatedList = await Promise.all(
        textList.map(async ([id, text]) => {
          const result = await callApi(`/translation/translate?to=${browserLanguage}`, {
            method: "POST",
            body: text,
          });
          return [id, result];
        })
      );
      setTranslations(Object.fromEntries(translatedList));
      setTranslating(false);
      setTranslatedLang(browserLanguage);
    } catch (err) {
      console.error("Translate error: ", err);
      setTranslating(false);
      throw err;
    }
  }, [texts, translatedLang, browserLanguage, callApi]);

  const translateData = useMemo(() => {
    return { texts: translations || texts, registerText, translating, translate, translatedLang };
  }, [translations, texts, registerText, translating, translate, translatedLang]);

  return <TranslateContext.Provider value={translateData}>{children}</TranslateContext.Provider>;
};

export const useTranslate = () => {
  const { translating, translate, translatedLang } = useContext(TranslateContext);
  return [translating, translate, translatedLang];
};

export const useTranslation = (text) => {
  const textId = useId();
  const textRef = useRef();
  const { texts, registerText } = useContext(TranslateContext);
  useEffect(() => {
    if (textRef.current !== text) {
      textRef.current = text;
      if (text) {
        registerText(textId, text);
      }
    }
  }, [text, registerText, textId]);
  return texts[textId] || text;
};

const Translate = ({ text, html, ...other }) => {
  const resultText = useTranslation(text || html);
  return text ? resultText : <SafeHtml {...other} html={resultText} />;
};

export default Translate;
