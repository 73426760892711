import classNames from "classnames";
import ColdPageContent from "../coldpage-content";
import Translate from "../translate";

const ColdPageWidget = ({ data, title }) => {
  return (
    !!data?.length && (
      <section className={classNames("widget cold-page-widget", !title && "no-title")}>
        {title && (
          <h2 className="widget__title wrapper-small loading-highlighted">
            <Translate text={title} />
          </h2>
        )}
        <ColdPageContent page={null} pageContent={data[0]} loading={false} />
      </section>
    )
  );
};

export default ColdPageWidget;
