import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import capitalizeAll from "../utils/capitalizeAll";

const SKIP_PARAMS = ["view-all", "section"];
const SINGLE_PARAMS = ["term", "dateFrom", "dateTo", "orderBy", "option"];
export const BOOLEANS_WITH_SEARCH = {
  contact: ["macFunTFacet", "countryFacet"],
  "contact-search": ["macFunTFacet", "countryFacet"],
  post: ["communitiesNames"],
};
const MAPPINGS = {
  news: {
    regionHubsFacet: "regionHubs",
    businessHubsFacet: "businessHubs",
    categoryFacet: "category",
    functionFacet: "function",
    locationFacet: "location",
    newsTypeFacet: "newsType",
    entityFacet: "entity",
  },
  appLink: {
    categoryFacet: "category",
    countryFacet: "country",
    appLinkFacet: "appLink",
  },
  contact: {
    zbusTypeTFacet: "businessSegment",
    zbeFuncTFacet: "function",
    jobTitleFacet: "jobTitle",
    macFunTFacet: "macrofunction",
    countryFacet: "country",
    cityFacet: "city",
    // zOriginFacet: "zOrigin"
    //lastNameFirstCharFacet: "lastNameFirstChar",
    //zbeFuncFacet: "zbeFunc",
    // macFunFacet: "macFun"
  },
  "contact-search": {
    zbusTypeTFacet: "businessSegment",
    zbeFuncTFacet: "function",
    jobTitleFacet: "jobTitle",
    macFunTFacet: "macrofunction",
    countryFacet: "country",
    cityFacet: "city",
    // zOriginFacet: "zOrigin"
    //lastNameFirstCharFacet: "lastNameFirstChar",
    //zbeFuncFacet: "zbeFunc",
    // macFunFacet: "macFun"
  },
  communities: {
    mainCategoryFacet: "categories",
    countryFacet: "countries",
    functionFacet: "functions",
    statusPendingSearch: "statusPendingSearch",
    statusJoinedSearch: "statusJoinedSearch",
    teamsSynchronizedSearch: "teamsSynchronizedSearch",
  },
  members: { adminsOnly: "adminsOnly" },
  "shared-documents": {},
  resources: {
    categoryFacet: "category",
    resourcesAttachmentsLanguages: "languages",
    regionHubsFacet: "regionHubs",
    businessHubsFacet: "businessHubs",
    fileFormatsFacet: "fileFormats",
    functionFacet: "function",
  },
  policies: {
    categoryFacet: "category",
    resourcesAttachmentsLanguages: "languages",
    regionHubsFacet: "regionHubs",
    businessHubsFacet: "businessHubs",
    fileFormatsFacet: "fileFormats",
    functionFacet: "function",
    countryFacet: "countries",
    subfunctionFacet: "subfunction",
  },
};

const TERM_MAPPINGS = {
  news: "term",
  appLink: "term",
  contact: "term",
  communities: "search",
};

const QS_PARAMS = {
  communities: ["statusPendingSearch", "statusJoinedSearch", "teamsSynchronizedSearch"],
};

export const NESTED_BOOLEANS = {
  macFunTFacet: "zbeFuncTFacet",
  countryFacet: "cityFacet",
  functionFacet: "subfunctionFacet",
};

const CUSTOM_NAMING_FACETS = [
  { aggregation: "newsTypeFacet", resourceType: "contents" },
  { aggregation: "categoryFacet", resourceType: "news" },
  { aggregation: "functionFacet", resourceType: "news" },
  { aggregation: "locationFacet", resourceType: "news" },
  { aggregation: "communitiesNames", resourceType: "post" },
];

/* 
  In case of service requiring both qs and body parameters use 
  - getFilterQueryStringParams to isolate relevant qs params
  - getFilterBodyParams to isolate relevant body params
*/

export const getFilterQueryStringParams = (query, resourceType = "news") => {
  const { booleans = {} } = query;
  const qsParams = QS_PARAMS?.[resourceType];
  const newQsParams = new URLSearchParams();
  const mappedBooleans = Object.fromEntries(
    Object.entries(booleans)
      ?.filter(([param]) => qsParams.includes(param))
      ?.map(([key, value]) => [MAPPINGS[resourceType]?.[key], value])
  );
  const allParams = { ...mappedBooleans };
  for (const [key, value] of Object.entries(allParams)) {
    if (Array.isArray(value)) {
      value.forEach((val) => newQsParams.append(key, val));
    } else if (value !== "") {
      newQsParams.append(key, value);
    }
  }
  return newQsParams;
};

export const getFilterBodyParams = (query, resourceType = "news") => {
  const bodyParams = Object.keys(MAPPINGS?.[resourceType]);
  const qsParams = QS_PARAMS?.[resourceType];
  return bodyParams && qsParams
    ? {
        ...query,
        booleans: query.booleans
          ? Object.fromEntries(
              Object.entries(query.booleans)?.filter(
                ([param]) => bodyParams.includes(param) && !qsParams.includes(param)
              )
            )
          : {},
      }
    : { booleans: {} };
};

export const getQueryStringParamsFromQuery = (query, resourceType = "news") => {
  const newQsParams = new URLSearchParams();
  const { booleans = {}, orderBy, ...other } = query;
  const mappedBooleans = Object.fromEntries(
    Object.entries(booleans).map(([key, value]) => [MAPPINGS[resourceType]?.[key], value])
  );
  const allParams = { ...other, ...mappedBooleans, ...(orderBy && { orderBy }) };
  for (const [key, value] of Object.entries(allParams)) {
    if (Array.isArray(value)) {
      value.forEach((val) => newQsParams.append(key, val));
    } else if (value !== "") {
      newQsParams.append(key, value);
    }
  }
  return newQsParams;
};

export const filterFacetView = (facetView) => {
  const { zbusTypeTFacet, zbeFuncTFacet, macFunTFacet, countryFacet, cityFacet } = facetView;
  return {
    zbusTypeTFacet,
    countryFacet,
    cityFacet,
    macFunTFacet,
    zbeFuncTFacet,
  };
};

export const parseParams = (urlSearchParams, inverseMapping) => {
  const currentQuery = { booleans: {} };
  for (const [key, value] of urlSearchParams.entries()) {
    const mappedKey = inverseMapping[key];
    if (SINGLE_PARAMS.includes(key) || SKIP_PARAMS.includes(key)) {
      currentQuery[key] = value;
    } else {
      currentQuery.booleans[mappedKey] = currentQuery.booleans[mappedKey]
        ? currentQuery.booleans[mappedKey].concat(value)
        : [value];
    }
  }
  return currentQuery;
};

export const stringifyQuery = (queryObj, mapping) => {
  const newQsParams = new URLSearchParams();
  const { booleans = {}, ...other } = queryObj;
  const mappedBooleans = Object.fromEntries(
    Object.entries(booleans).map(([key, value]) => [mapping[key], value])
  );
  const allParams = { ...other, ...mappedBooleans };
  for (const [key, value] of Object.entries(allParams)) {
    if (Array.isArray(value)) {
      value.forEach((val) => newQsParams.append(key, val));
    } else if (value !== "") {
      newQsParams.append(key, value);
    }
  }
  // console.log(newQsParams.toString());
  return newQsParams;
};

export const searchBodyFromQuery = (query, resourceType = "news") => {
  const { booleans = {}, orderBy, term, ...other } = query;
  const mapping = MAPPINGS[resourceType];
  const mappedBooleans = Object.fromEntries(
    Object.entries(booleans).map(([key, value]) => [mapping[key], value])
  );
  const termMapping = TERM_MAPPINGS[resourceType];
  const mappedTerm = { [termMapping]: term };
  const body = {
    ...other,
    ...mappedTerm,
    ...mappedBooleans,
    ...(orderBy && { orderBy }),
  };
  return Object.fromEntries(Object.entries(body).filter(([_key, value]) => value !== ""));
};

export const queryStringFromQuery = (query, offset, limit, sortField = "composite") => {
  return new URLSearchParams(
    Object.entries({
      offset,
      limit,
      ...(query?.term && { search: query.term }),
      ...(query?.orderBy &&
        (sortField === "composite"
          ? {
              sortField: query.orderBy.startsWith("NAME") ? "firstName" : "lastName" || "lastName",
              sortDir: query.orderBy.split("_")[1] === "AZ" ? "ASC" : "DESC" || "ASC",
            }
          : {
              [sortField]: query.orderBy,
            })),
    })
  );
};

export const normalizeFlagFilters = (query) => {
  const { booleans = {}, ...other } = query;
  const entries = Object.entries(booleans).map(([key, value]) => {
    const resValue = SINGLE_PARAMS.includes(key) ? value : value === true ? "true" : value;
    return Array.isArray(value) ? [key, resValue] : [key, [resValue]];
  });
  return { booleans: Object.fromEntries(entries), ...other };
};

export const normalizeQuery = (query, oldQuery = {}) => {
  const { booleans = {}, orderBy, ...others } = query;
  const entries = Object.entries(booleans);
  const keepParams = Object.fromEntries(
    Object.entries(oldQuery).filter(([key]) => SKIP_PARAMS?.includes(key))
  );

  let cleanedEntries = entries;
  //Remove nested params if parent facet is removed
  cleanedEntries = cleanedEntries.filter(([key, values]) => {
    const index = Object.values(NESTED_BOOLEANS).indexOf(key);
    return (
      (typeof values === "boolean" || values.length > 0) &&
      (index === -1 || booleans[Object.keys(NESTED_BOOLEANS)[index]]?.length > 0)
    );
  });

  const newBooleans = Object.fromEntries(cleanedEntries);

  // const selectedValue = Object.values(newBooleans)?.[0]?.some((item) => item === "FINANCE");

  return {
    ...others,
    ...(Object.keys(newBooleans).length > 0 ? { booleans: newBooleans } : {}),
    ...(orderBy && orderBy !== "" ? { orderBy } : {}),
    ...keepParams,
  };
};
export const getFacetLabel = (aggregation, valueName, resourceType, labels) => {
  if (["contact", "contact-search", "appLink"].includes(resourceType)) {
    if (aggregation === "countryFacet") {
      return labels?.[`COUNTRY_${valueName.toUpperCase()}`];
    } else if (resourceType === "appLink") {
      return labels?.[valueName.toUpperCase()];
    } else {
      return capitalizeAll(valueName);
    }
  } else if (resourceType === "policies") {
    if (aggregation === "countryFacet") {
      return labels?.[`COUNTRY_${valueName.toUpperCase()}`];
    } else {
      return labels?.[valueName.toUpperCase()] || capitalizeAll(valueName);
    }
  } else if (valueName === "true") {
    /* Case: flag filter */
    return labels?.[aggregation.toUpperCase()] || capitalizeAll(aggregation);
  } else if (["businessHubsFacet", "regionHubsFacet"].includes(aggregation)) {
    return labels?.[`HUB_${valueName.toUpperCase()}`] || capitalizeAll(valueName);
  } else {
    return labels?.[valueName.toUpperCase()] || capitalizeAll(valueName);
  }
};

export const getFilterTitle = (aggregation, resourceType, labels) => {
  return CUSTOM_NAMING_FACETS.some(
    (facet) => facet.aggregation === aggregation && facet.resourceType === resourceType
  )
    ? labels?.[`${aggregation}_${resourceType}`.toUpperCase()]
    : labels?.[aggregation.replace("_aggregation", "").toUpperCase()];
};

const useFilters = (resourceType = "news") => {
  const [searchParams, setSearchParams] = useSearchParams();
  const mapping = useMemo(() => MAPPINGS[resourceType], [resourceType]);
  const inverseMapping = useMemo(
    () => Object.fromEntries(Object.entries(mapping).map(([key, value]) => [value, key])),
    [mapping]
  );

  const query = useMemo(
    () => parseParams(searchParams, inverseMapping),
    [searchParams, inverseMapping]
  );
  // console.log("query: ", query);
  const setFilters = useCallback(
    (params) => {
      let newQuery = params;
      if (typeof params === "function") {
        newQuery = params(query);
      }
      const cleanParams = normalizeQuery(newQuery, query);
      const newParams = stringifyQuery(cleanParams, mapping);
      setSearchParams(newParams);
    },
    [setSearchParams, query, mapping]
  );

  const cleanQuery = useMemo(
    () => Object.fromEntries(Object.entries(query).filter(([key]) => !SKIP_PARAMS.includes(key))),
    [query]
  );
  return [cleanQuery, setFilters];
};

export default useFilters;
