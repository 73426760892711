import FilterList from "../../components/filters/filter-list";
import CommunitiesCardWrapper from "../../components/communities/communities-card-wrapper";
import LoadingError from "../loading-error";
import useFilters from "../../hooks/useFilters";
import useCommunities from "../../hooks/queries/useCommunities";
import useUpdateFavoriteCommunities from "../../hooks/mutations/useUpdateFavoriteCommunities";

const PAGE_SIZE = 12;

const CommunitiesAll = () => {
  const [query, setFilterData] = useFilters("communities");

  const {
    data: communities,
    error,
    isFetching,
    fetchNextPage,
  } = useCommunities({
    query,
    pageSize: PAGE_SIZE,
  });

  const { mutateAsync: updateFav } = useUpdateFavoriteCommunities({
    // Invalidate all communities cache except current, on which optimistic update will be performed
    doInvalidateAllCommunities: false,
    doOptimisticUpdateAllCommunities: true,
    // Invalidate my communities cache
    doInvalidateMyCommunities: true,
    currentAllQueryKey: ["community", PAGE_SIZE, query],
  });

  const handleUpdateFav = async ({ communityId, isDelete }) => {
    await updateFav({ communityId, isDelete });
  };

  return error ? (
    <LoadingError />
  ) : (
    <FilterList
      items={communities.list}
      query={query}
      totalCounter={communities?.paginationInfo?.total}
      setFilterData={setFilterData}
      facetView={communities?.facetView}
      checkboxView={communities?.checkboxView}
      resourceType="communities"
    >
      <CommunitiesCardWrapper
        communities={communities?.list}
        pagination={communities?.paginationInfo}
        loadMore={fetchNextPage}
        updateFav={handleUpdateFav}
        isLoading={isFetching}
      />
    </FilterList>
  );
};

export default CommunitiesAll;
