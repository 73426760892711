import classNames from "classnames";
import Cookies from "js-cookie";
import { useCallback, useContext, useEffect, useState } from "react";
import parse, { domToReact } from "html-react-parser";
import LanguageContext from "../context/language";
import Icon from "./icon";
import useBlockScroll from "../hooks/useBlockScroll";
import { Link } from "react-router-dom";

const COOKIE_NAME = "one-essilor-luxottica";

const currentCookie = () => {
  if (Cookies.get(COOKIE_NAME)) {
    try {
      const cookie = JSON.parse(Cookies.get(COOKIE_NAME));
      return cookie;
    } catch (err) {
      Cookies.remove(COOKIE_NAME);
      return false;
    }
  }
  return false;
};

const CookieSettings = ({
  analytics,
  setAnalytics,
  setCookieSettingsVisible,
  handleCookieStatusChange,
}) => {
  const { labels } = useContext(LanguageContext);

  return (
    <div className="cookie-settings" role="region" aria-label="Cookie settings">
      <button
        className="close-button"
        onClick={() => setCookieSettingsVisible(false)}
        aria-label="Close"
      >
        <Icon name="x-icon" />
      </button>
      <div className="cookie-settings__content">
        <div className="cookie-settings__title">{labels?.MY_COOKIES_SETTINGS}</div>
        <div>{labels?.COOKIE_DESCRIPTION}</div>
        <div className="cookie-settings__options">
          <button
            className={classNames("switch", analytics && "active")}
            onClick={() => setAnalytics((current) => !current)}
            aria-label={analytics ? "Turn off analytics" : "Turn on analytics"}
          >
            <span className="switch__slider" />
          </button>
          <div className="cookie-settings__type-block">
            <div className="cookie-settings__type">{labels?.COOKIE_NAME_ANALYTICS}</div>
            <div>{labels?.ANALYTICS_DESCRIPTION}</div>
          </div>
        </div>
        <div className="cookie-settings__btn-wrapper">
          <button
            className="cookie-settings__save button primary"
            name="custom"
            onClick={(e) => {
              setCookieSettingsVisible(false);
              handleCookieStatusChange(e);
            }}
          >
            <span>{labels?.SAVE_CHANGES}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const CookieBanner = () => {
  const { labels } = useContext(LanguageContext);
  const [cookieSettingsVisible, setCookieSettingsVisible] = useState();
  const [show, setShow] = useState(false);
  const [cookieStatus, setCookieStatus] = useState(currentCookie());
  const [analytics, setAnalytics] = useState(true);

  useBlockScroll(cookieSettingsVisible);

  useEffect(() => {
    setShow(!cookieStatus?.accepted);
  }, [cookieStatus]);

  const handleCookieStatusChange = useCallback(
    (e) => {
      e.preventDefault();
      const target = e.target.name;
      const value = {
        analytics: { all: true, false: false, custom: analytics }[target],
        marketing: target === "false" ? false : true,
        accepted: true,
      };
      setCookieStatus(value);
      Cookies.set(COOKIE_NAME, JSON.stringify(value), { expires: 180, sameSite: "Lax" });
    },
    [setCookieStatus, analytics]
  );

  const parseOpt = {
    replace: ({ name, attribs, children }) => {
      if (name === "a" && attribs?.href) {
        return (
          <Link to={attribs.href} alt="test">
            {domToReact(children)}
          </Link>
        );
      }
    },
  };

  return (
    <>
      <div
        className={classNames(
          "cookie-banner",
          show && "cookie-banner--show",
          cookieStatus && "cookie-banner--hidden"
        )}
        role="region"
        aria-label="Cookie policy"
      >
        {labels?.COOKIE_POLICY && (
          <div className="cookie-banner__policy">{parse(labels.COOKIE_POLICY || "", parseOpt)}</div>
        )}
        <div className="cookie-banner__actions">
          <button className="cookie-banner__link" onClick={() => setCookieSettingsVisible(true)}>
            {labels?.COOKIE_SETTINGS}
          </button>
          <div className="cookie-banner__buttons">
            <button
              className="button secondary small"
              name="false"
              onClick={handleCookieStatusChange}
            >
              {labels?.DENY}
            </button>
            <button
              className="button secondary small"
              name="all"
              onClick={handleCookieStatusChange}
            >
              {labels?.ACCEPT_ALL_COOKIES}
            </button>
          </div>
        </div>
      </div>
      {cookieSettingsVisible && (
        <CookieSettings
          analytics={analytics}
          setAnalytics={setAnalytics}
          setCookieSettingsVisible={setCookieSettingsVisible}
          handleCookieStatusChange={handleCookieStatusChange}
        />
      )}
    </>
  );
};

export default CookieBanner;
