import { useContext, useEffect, useState } from "react";
import Icon from "./icon";
import Modal from "./modal";
import LanguageContext from "../context/language";
import Cookies from "js-cookie";
import useBreakpoint from "../hooks/useBreakpoint";
import Step1 from "../images/home-screen-prompt/step1.png";
import Step2 from "../images/home-screen-prompt/step2.png";
import Step3 from "../images/home-screen-prompt/step3.png";

const CLOSE_ADD_TO_HOME = "closeAddToHomescreen";

const HomeScreenModal = () => {
  const { labels } = useContext(LanguageContext);

  return (
    <div className="intranet-modal__wrapper">
      <p className="home-screen-modal__title">
        {labels?.HOME_SCREEN_MODAL_TITLE || "Add One EssilorLuxottica to your home screen"}
      </p>
      <div
        className="home-screen-modal__description"
        dangerouslySetInnerHTML={{
          __html: `<span>1.</span>
      ${labels?.HOME_SCREEN_MODAL_STEP_1 || "Tap on <strong>share</strong> button"}`,
        }}
      />
      <div className="home-screen-modal__image">
        <img src={Step1} alt="Step 1" />
      </div>
      <div
        className="home-screen-modal__description"
        dangerouslySetInnerHTML={{
          __html: `
      <span>2.</span>
      ${
        labels?.HOME_SCREEN_MODAL_STEP_2 ||
        "Scroll down and tap <strong>Add to Home Screen</strong>"
      } `,
        }}
      />
      <div className="home-screen-modal__image">
        <img src={Step2} alt="Step 2" />
      </div>
      <div
        className="home-screen-modal__description"
        dangerouslySetInnerHTML={{
          __html: `
      <span>3.</span>
      ${labels?.HOME_SCREEN_MODAL_STEP_3 || "Tap on <strong>Add</strong>"}`,
        }}
      />
      <div className="home-screen-modal__image">
        <img src={Step3} alt="Step 3" />
      </div>
    </div>
  );
};

const iOS = () =>
  /iPad|iPhone|iPod/.test(navigator.userAgent) ||
  (/Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 1);

const useAddToHomescreenPrompt = () => {
  const [promptMethod, setPromptMethod] = useState();

  const promptToInstall = () => {
    if (promptMethod) {
      return promptMethod.prompt();
    }
    return Promise.reject(
      new Error('Tried installing before browser sent "beforeinstallprompt" event')
    );
  };

  useEffect(() => {
    const ready = (e) => {
      e.preventDefault();
      setPromptMethod(e);
    };

    const handleAppInstalled = (evt) => {
      console.log("PWA installed - appinstalled event triggered");
      setPromptMethod(null);
    };

    window.addEventListener("beforeinstallprompt", ready);
    window.addEventListener("appinstalled", handleAppInstalled);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
      window.removeEventListener("appinstalled", ready);
    };
  }, []);

  return [promptMethod, promptToInstall];
};

const HomeScreenPrompt = ({ isHomeScreenPrompt, setIsHomeScreenPrompt }) => {
  const { labels } = useContext(LanguageContext);
  const [showIosModal, setShowIosModal] = useState(false);

  const isMobile = useBreakpoint(575);
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  const isChromeIos = () => navigator.userAgent.match("CriOS");

  const isInStandaloneMode = () =>
    window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone === true;

  const hide = () => {
    Cookies.set(CLOSE_ADD_TO_HOME, "true", new Date().getTime() + 1000 * 60 * 60 * 24 * 365);
    setIsHomeScreenPrompt(false);
  };

  const handleAddToHomescreen = () => {
    if (iOS()) {
      setShowIosModal(true);
    } else {
      promptToInstall();
    }
  };

  useEffect(() => {
    const closeAddToHomescreen = Cookies.get(CLOSE_ADD_TO_HOME);
    let isVisibleTemp = false;

    if (!closeAddToHomescreen && !isInStandaloneMode()) {
      if (iOS() && !isChromeIos()) {
        isVisibleTemp = true;
      } else if (prompt) {
        isVisibleTemp = true;
      }
    }
    if (!isMobile) {
      isVisibleTemp = false;
    }

    setIsHomeScreenPrompt(isVisibleTemp);
  }, [prompt, isMobile, setIsHomeScreenPrompt]);

  return (
    <>
      {isHomeScreenPrompt && (
        <div className="home-screen-prompt" onClick={handleAddToHomescreen}>
          <button
            className="home-screen-prompt__close"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              hide();
            }}
            aria-label="Close"
          >
            <Icon name="x-icon" />
          </button>
          <div className="home-screen-prompt__content">
            <img src="/android-icon-48x48.png" alt="One EssilorLuxottica Img" />
            <div className="home-screen-prompt__info">
              <span className="home-screen-prompt__title">
                {labels?.HOME_SCREEN_TITLE || "Add to home screen"}
              </span>
              <p className="home-screen-prompt__description">
                {labels?.HOME_SCREEN_DESCRIPTION ||
                  "Get easy access to One EssilorLuxottica by downloading the shortcut for your device."}
              </p>
            </div>
          </div>
          <button className="home-screen-prompt__add" aria-label="Add">
            <Icon name="plus" />
            <span>{labels?.ADD}</span>
          </button>
        </div>
      )}
      <Modal
        show={showIosModal}
        setShow={() => setShowIosModal(false)}
        className="intranet-modal--full intranet-modal--add-to-home"
      >
        <HomeScreenModal />
      </Modal>
    </>
  );
};

export default HomeScreenPrompt;
