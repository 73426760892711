import classNames from "classnames";
import { useContext, useState } from "react";
import LanguageContext from "../context/language";
import { tealiumOnEvent } from "../utils/tealium";
import Icon from "./icon";
import {
  downloadZipDocuments,
  getFilenameDetails,
  getResourceDocDownloadUrl,
  getResourceLinkViewUrl,
} from "../utils/documents";
import dayjs from "dayjs";
import useBreakpoint from "../hooks/useBreakpoint";

export const DownloadResource = ({ documents, externalLinks, policies }) => {
  const { labels, cmsLang } = useContext(LanguageContext);

  return documents?.map((item, i) => (
    <div className="documents__doc" key={i}>
      <div className="documents__text">
        <a
          className="documents__descr"
          href={externalLinks ? item?.url : getResourceLinkViewUrl(item?.id, policies)}
          target="_blank"
        >
          {getFilenameDetails(item?.title || item?.name)?.clearFilename}
        </a>
        {item?.customPublicationDate && (
          <time className="documents__date">
            {dayjs(item.customPublicationDate, [
              "YYYY-MM-DDThh:mm:ssZ[GMT]",
              "YYYY-MM-DDThh:mmZ[GMT]",
            ])
              .locale(cmsLang)
              .format("LL")}
          </time>
        )}
      </div>
      <a
        className="documents__info"
        href={externalLinks ? item?.url : getResourceDocDownloadUrl(item?.id, policies)}
        download={externalLinks ? false : item?.title}
        target="_blank"
        onClick={() => {
          tealiumOnEvent({
            eventType: "EditorialFile",
            eventAction: "Download",
            eventLabel: item?.data?.uri,
          });
        }}
      >
        <span
          className={classNames(
            "documents__extension",
            externalLinks ? "documents__extension--notransform" : "documents__extension--lowercase"
          )}
        >
          {externalLinks
            ? labels?.GO_TO_LINK || "Go to link"
            : getFilenameDetails(item?.data?.uri)?.dotExtension}
        </span>
        <span className="documents__icon documents__icon--download">
          <Icon name={externalLinks ? "link-external" : "download"} />
        </span>
      </a>
    </div>
  ));
};

const Documents = ({ resourceId, documents, externalLinks, policies }) => {
  const { labels } = useContext(LanguageContext);
  const [seeMore, setSeeMore] = useState(false);
  const isMobile = useBreakpoint(575);

  const [downloading, setDownloading] = useState(false);
  const downloadZip = async () => {
    setDownloading(true);
    try {
      await downloadZipDocuments(
        documents?.map((item) => ({
          filename: `${item.title}.${item?.data?.uri?.slice(
            item?.data?.uri?.lastIndexOf(".") + 1
          )}`,
          ...item,
        })),
        `resource-${resourceId}-documents-${dayjs().format("DD-MM-YYYY")}.zip`
      );
    } catch (err) {
      console.error("Error in download zip: ", err);
    }
    setDownloading(false);
  };

  return !!documents?.length ? (
    <section className="section documents wrapper-medium" aria-label="Documents">
      <div className="documents__container">
        <div className="documents__header">
          <h2>
            {documents?.length === 1
              ? externalLinks
                ? labels.EXTERNAL_LINK || "External Link"
                : labels.AVAILABLE_DOCUMENT || "Available Document"
              : externalLinks
              ? labels.EXTERNAL_LINKS || "External Links"
              : labels.AVAILABLE_DOCUMENTS || "Available Documents"}
          </h2>
          {!externalLinks && documents?.length > 1 && (
            <button className="documents__download" onClick={downloadZip}>
              <span>{labels.DOWNLOAD_ALL || "Download all"}</span>
              {downloading ? (
                <span className="loading-spinner" />
              ) : (
                <span className="documents__icon documents__icon--download">
                  <Icon name="download" />
                </span>
              )}
            </button>
          )}
        </div>
        <div className="documents__docs-wrapper">
          <DownloadResource
            documents={documents?.slice(0, seeMore ? documents.length : isMobile ? 5 : 10)}
            externalLinks={externalLinks}
            seeMore={seeMore}
            policies={policies}
          />
          {documents?.length > (isMobile ? 5 : 10) && (
            <div
              className={classNames(
                "documents__see-more",
                seeMore && "documents__see-more--expanded"
              )}
            >
              <button className="cta-link" onClick={() => setSeeMore((current) => !current)}>
                {seeMore ? labels?.SEE_LESS || "See less" : labels?.SEE_MORE || "See more"}
                <Icon name="chevron" />
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  ) : null;
};

export default Documents;
