import { Suspense, lazy } from "react";
import classNames from "classnames";
import ConditionalLink from "../conditional-link";
import Icon from "../icon";
import imagePh from "../../images/temp/Link.png";
import Image from "../image";
import { useLocation, useOutletContext } from "react-router-dom";
import Translate from "../translate";
import ArrowBtn from "./elements/arrow-btn";
import { useRef, useState } from "react";
import WidgetTitle from "./elements/widget-title";
import { getCMChannelPath } from "../../utils/internal-links";

const Slider = lazy(() => import("react-slick"));

const ContentLink = ({ item, children, loading, hubSection, className, ...others }) => {
  const internalLink = item?.linkTarget ? getCMChannelPath(item?.linkTarget) : null;
  /* 
    linkURL -> link esterno 
    linkTarget -> link a pagina interna all'applicazione, interna o esterna a hub
  */
  return item?.linkURL ? (
    <a
      disabled={loading}
      href={item?.linkURL}
      target={item?.targetWindow || "_blank"}
      aria-label={item.title}
      className={`link-list__link-wrapper ${className}`}
      {...others}
    >
      {children}
    </a>
  ) : internalLink ? (
    <ConditionalLink
      to={internalLink}
      disabled={loading}
      aria-label={item.title}
      className={`link-list__link-wrapper ${className}`}
      {...others}
    >
      {children}
    </ConditionalLink>
  ) : (
    <div className={`link-list__link-wrapper ${className}`}>{children}</div>
  );
};

const LinkCard = ({ card, icon, image, loading, hubSection, isDragging }) => {
  return image ? (
    <div className="link-list__card link-list__card--image">
      {loading ? (
        <div className="link-list__img loading-highlighted" />
      ) : (
        <ContentLink
          disabled={loading || isDragging}
          item={card}
          hubSection={hubSection}
          className="link-list__img-wrapper"
        >
          <Image
            cropUrlTemplate={card?.coverImage?.cropUrlTemplate || imagePh}
            crops={[
              {
                crop: "landscape_ratio16x9",
                sizes: [
                  {
                    maxWidth: "30vw",
                  },
                ],
              },
            ]}
            className="link-list__img"
            alt={card?.title}
          />
        </ContentLink>
      )}
      <div className="link-list__text">
        <ContentLink
          disabled={loading || isDragging}
          item={card}
          hubSection={hubSection}
          className="link-list__title title-h5 loading-highlighted"
        >
          <Translate text={card?.title} />
        </ContentLink>
      </div>
    </div>
  ) : (
    <ContentLink
      disabled={loading}
      item={card}
      hubSection={hubSection}
      className={classNames("link-list__card", icon && "link-list__card--icon")}
    >
      {icon &&
        (card?.coverImage?.cropUrlTemplate ? (
          <Image
            cropUrlTemplate={card?.coverImage?.cropUrlTemplate}
            crops={[
              {
                crop: "portrait_ratio1x1",
                sizes: [
                  {
                    maxWidth: "64px",
                  },
                ],
              },
            ]}
            alt={card?.title}
            className="link-list__icon"
          />
        ) : (
          <span className="link-list__icon link-list__icon--placeholder" />
        ))}
      <div className="link-list__text loading-highlighted">
        <div className="link-list__title title-h5">
          <Translate text={card?.title} />
        </div>
        <Translate className="link-list__descr loading-highlighted" html={card?.intro} />
      </div>
      <Icon name="arrow" />
    </ContentLink>
  );
};

const LinkList = ({ icon = false, image = false, title, data, loading }) => {
  const { sideHubMenu } = useOutletContext() || { sideHubMenu: null };
  const [isDragging, setIsDragging] = useState();
  const hubSection = useLocation()?.pathname?.split("/")[1];
  const ref = useRef();

  const settings = {
    infinite: false,
    slidesToShow: Math.min(4, data?.length),
    slidesToScroll: 1,
    dots: true,
    prevArrow: <ArrowBtn containerRef={ref} />,
    nextArrow: <ArrowBtn containerRef={ref} />,
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
    responsive: [
      {
        breakpoint: 9999,
        settings: { arrows: false },
      },
      {
        breakpoint: 1229 + (sideHubMenu ? 334 : 0),
        settings: { slidesToShow: Math.min(3, data?.length) },
      },
      {
        breakpoint: 990 + (sideHubMenu ? 344 : 0),
        settings: { slidesToShow: Math.min(2, data?.length) },
      },
      {
        breakpoint: 575 + (sideHubMenu ? 344 : 0),
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: data?.length > 1 ? "16px" : "0",
        },
      },
      sideHubMenu && {
        breakpoint: 767,
        settings: {
          slidesToShow: Math.min(2, data?.length),
        },
      },
      sideHubMenu && {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: data?.length > 1 ? "16px" : "0",
        },
      },
    ].filter(Boolean),
  };

  return (
    !!data?.length && (
      <section
        className={classNames(
          "widget link-list",
          loading && "loading-skeleton",
          !title && "no-title"
        )}
      >
        <WidgetTitle title={title} arrowsRef={ref} />
        <div
          className={classNames(
            "link-list__content",
            icon ? "link-list__content--icon" : image && "link-list__content--image"
          )}
        >
          {image ? (
            <Suspense>
              <Slider
                {...settings}
                className={classNames(
                  "widget__slider widget__slider--full-width",
                  `widget__slider--${data?.length}-slides`
                )}
              >
                {data?.slice(0, 4)?.map((card, i) => (
                  <LinkCard
                    card={card}
                    key={i}
                    loading={loading}
                    icon={icon}
                    image={!icon && image}
                    hubSection={hubSection}
                    isDragging={isDragging}
                  />
                ))}
              </Slider>
            </Suspense>
          ) : (
            data
              ?.slice(0, 4)
              ?.map((card, i) => (
                <LinkCard
                  card={card}
                  key={i}
                  loading={loading}
                  icon={icon}
                  image={!icon && image}
                  hubSection={hubSection}
                />
              ))
          )}
        </div>
      </section>
    )
  );
};

export default LinkList;
