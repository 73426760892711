import { useContext } from "react";
import { Link } from "react-router-dom";
import Icon from "../components/icon";
import LanguageContext from "../context/language";
import useTealiumOnPageLoad from "../utils/tealium";
import ContactUsFooter from "../components/contact-us-footer";
import Faqs from "../components/faqs";
import staticFaqs from "../utils/static-faqs";
import classNames from "classnames";

const ForgotPassword = () => {
  const { labels, browserLanguage } = useContext(LanguageContext);

  useTealiumOnPageLoad({ notLogged: true });

  return (
    <main
      className={classNames(
        "page forgot-password wrapper-small",
        !browserLanguage && "loading-skeleton"
      )}
    >
      <div className="forgot-password__back">
        <Link to="/login" aria-label={labels?.BACK_TO_LOGIN}>
          <Icon name="arrow" /> {labels?.BACK_TO_LOGIN}
        </Link>
      </div>
      <div className="forgot-password__box">
        <h1 className="forgot-password__title title" data-label="CONTACT_AND_SUPPORT">
          {labels?.CONTACT_AND_SUPPORT || "Contact and support"}
        </h1>
        <div
          data-label="FORGOT_PASSWORD_TEXT"
          dangerouslySetInnerHTML={{
            __html:
              labels?.FORGOT_PASSWORD_TEXT ||
              "Use your corporate credentials to login into EssilorLuxottica Intranet.</br>We are working to provide all users with a Microsoft Office 365 account.",
          }}
        />
        <div className="forgot-password__cards-wrapper">
          <div className="forgot-password__cards">
            <div className="forgot-password__card">
              <div className="forgot-password__card-header">
                <div
                  className="forgot-password__card-name"
                  data-label="FORMER_LUXOTTICA"
                  dangerouslySetInnerHTML={{
                    __html: labels?.FORMER_LUXOTTICA,
                  }}
                />
                <div
                  data-label="FORMER_LUXOTTICA_DESCRIPTION"
                  className="forgot-password__card-description"
                  dangerouslySetInnerHTML={{
                    __html: labels?.FORMER_LUXOTTICA_DESCRIPTION,
                  }}
                />
              </div>
              <a
                href="https://account.luxottica.com/password/?returnURL=https%3A%2F%2Flogon.luxottica.com%2Fnidp%2Fapp%2Flogin"
                target="_blank"
                className="forgot-password__label cta-link"
                data-label="FORGOT_PASSWORD"
              >
                {labels?.FORGOT_YOUR_PASSWORD || "FORGOT YOUR PASSWORD?"}
              </a>
            </div>
            <div className="forgot-password__card forgot-password__card--gv">
              <div className="forgot-password__card-header">
                <div
                  className="forgot-password__card-name"
                  data-label="FORMER_GRANDVISION"
                  dangerouslySetInnerHTML={{
                    __html: labels?.FORMER_GRANDVISION,
                  }}
                />
                <div
                  data-label="FORMER_GRANDVISION_DESCRIPTION"
                  className="forgot-password__card-description"
                  dangerouslySetInnerHTML={{
                    __html: labels?.FORMER_GRANDVISION_DESCRIPTION,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="forgot-password__card">
            <div className="forgot-password__card-header">
              <div
                className="forgot-password__card-name"
                data-label="FORMER_ESSILOR"
                dangerouslySetInnerHTML={{
                  __html: labels?.FORMER_ESSILOR,
                }}
              />
              <div
                data-label="FORMER_LUXOTTICA_DESCRIPTION"
                className="forgot-password__card-description"
                dangerouslySetInnerHTML={{
                  __html: labels?.FORMER_LUXOTTICA_DESCRIPTION,
                }}
              />
              <a
                href="https://account.luxottica.com/password/?returnURL=https%3A%2F%2Flogon.luxottica.com%2Fnidp%2Fapp%2Flogin"
                target="_blank"
                className="forgot-password__label cta-link"
                data-label="FORGOT_PASSWORD"
              >
                {labels?.FORGOT_YOUR_PASSWORD || "FORGOT YOUR PASSWORD?"}
              </a>
              <div
                data-label="FORMER_ESSILOR_DESCRIPTION"
                className="forgot-password__card-description forgot-password__card-description--essilor"
                dangerouslySetInnerHTML={{
                  __html: labels?.FORMER_ESSILOR_DESCRIPTION,
                }}
              />
            </div>
            <a
              href="https://accounts.google.com/signin/v2/recoveryidentifier?flowName=GlifWebSignIn&flowEntry=AccountRecovery"
              target="_blank"
              className="forgot-password__label cta-link"
              data-label="FORGOT_PASSWORD"
            >
              {labels?.FORGOT_YOUR_PASSWORD || "FORGOT YOUR PASSWORD?"}
            </a>
          </div>
          <div className="forgot-password__card forgot-password__card--gv">
            <div className="forgot-password__card-header">
              <div
                className="forgot-password__card-name"
                data-label="FORMER_GRANDVISION"
                dangerouslySetInnerHTML={{
                  __html: labels?.FORMER_GRANDVISION,
                }}
              />
              <div
                data-label="FORMER_GRANDVISION_DESCRIPTION"
                className="forgot-password__card-description"
                dangerouslySetInnerHTML={{
                  __html: labels?.FORMER_GRANDVISION_DESCRIPTION,
                }}
              />
            </div>
          </div>
        </div>
        <h1 className="forgot-password__title title" data-label="CONTACT_AND_SUPPORT">
          {labels?.FREQUENTLY_ASKED_QUESTIONS || "Frequently asked questions"}
        </h1>
      </div>
      <Faqs faqs={staticFaqs(browserLanguage)} />
      <ContactUsFooter />
    </main>
  );
};

export default ForgotPassword;
