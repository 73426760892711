import { useContext } from "react";
import LanguageContext from "../context/language";
import LoadingError from "../routes/loading-error";
import Error from "./error";
import NoteCard from "./note-card";
import Pagination from "./pagination";

const NotesCardWrapper = ({ notes, press, loadMore, navBack = true, isLoading }) => {
  const { labels } = useContext(LanguageContext);

  return (
    <div className="notes__section wrapper-small">
      {notes ? (
        notes?.data?.length > 0 ? (
          <div className="notes__card-wrapper">
            {notes?.data?.map((item) => (
              <NoteCard note={item} navBack={navBack} key={item.masterID} press={press} />
            ))}
          </div>
        ) : (
          <Error msg={labels?.NO_MATCHING_RESULTS} />
        )
      ) : (
        <LoadingError />
      )}
      <Pagination
        currentItemCount={notes?.data?.length}
        pagination={{
          resultsAvailableCount: notes.resultsAvailableCount,
        }}
        loadMore={loadMore}
        isLoading={isLoading}
      />
    </div>
  );
};

export default NotesCardWrapper;
