import { useContext } from "react";
import LanguageContext from "../context/language";
import LoadingError from "../routes/loading-error";
import Error from "./error";
import NewsCard from "./news-card";
import Pagination from "./pagination";

const NewsCardWrapper = ({ news, loadMore, navBack = true, isLoading }) => {
  const { labels } = useContext(LanguageContext);

  return (
    <div className="news__section">
      {news ? (
        news?.data?.length > 0 ? (
          <div className="news__card-wrapper">
            {news?.data?.map((item) => (
              <NewsCard news={item} navBack={navBack} key={item.masterID} showInteractions={true} />
            ))}
          </div>
        ) : (
          <Error msg={labels?.NO_MATCHING_RESULTS} />
        )
      ) : (
        <LoadingError />
      )}
      <Pagination
        currentItemCount={news?.data?.length}
        pagination={{
          resultsAvailableCount: news.resultsAvailableCount,
        }}
        loadMore={loadMore}
        isLoading={isLoading}
      />
    </div>
  );
};

export default NewsCardWrapper;
