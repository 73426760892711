import { useContext, useState, useCallback, useMemo, Suspense, lazy } from "react";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import SafeHtml from "../../components/safe-html";
import Tabs from "../../components/tabs.jsx";
import SectionLeonardo from "./section-leonardo.jsx";
import SectionCourses from "./section-courses.jsx";
import SectionNews from "./section-news";
import SectionNotes from "./section-notes";
import SectionPress from "./section-press";
import SideBar from "./sidebar";
import LoadingError from "../loading-error.jsx";
import UserContext from "../../context/user";
import LanguageContext from "../../context/language.jsx";
import useTealiumOnPageLoad from "../../utils/tealium.js";
import SectionMyNews from "./section-my-news.jsx";
import useColdpage from "../../hooks/queries/useColdpage";
import { pagePlaceholder } from "../../utils/placeholders";

const EventBookingWidget = lazy(() => import("../../components/event-booking-widget"));
const HighlightedContent = lazy(() => import("../../components/highligthed-content"));
const WidgetSurvey = lazy(() => import("../../components/widget-survey"));

const TabsRow = ({ row, newsLink, organizationalNotesLink, pressLink, myNewsLink }) => {
  const { labels } = useContext(LanguageContext);
  const { isPreview } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const section = searchParams.get("section");
  const [homeData, setHomeData] = useState({});
  const onLoad = useCallback((data) => {
    setHomeData((current) => ({ ...current, ...data }));
  }, []);
  const homeSectionsMapping = useMemo(
    () => [
      {
        name: "Corporate News DQL",
        link: "/",
        label: labels?.CORPORATE_NEWS,
        section: "news",
        end: true,
      },
      {
        name: "Organizational Announcements DQL",
        link: "/?section=notes",
        label: labels?.ORGANIZATIONAL_NOTES,
        section: "notes",
        end: true,
      },
      {
        name: "Press Release DQL",
        link: "/?section=press",
        label: labels?.PRESS_RELEASES,
        section: "press",
        end: true,
      },
      {
        name: "My News",
        link: "/?section=mynews",
        label: labels?.MY_NEWS,
        section: "mynews",
        end: true,
      },
    ],
    [labels]
  );

  const homeTabs = useMemo(
    () =>
      row.items?.some((item) => Object.keys(item).length)
        ? row.items
            .map((item) =>
              homeSectionsMapping.find((homeSection) => homeSection.name === item?.name)
            )
            .filter(Boolean)
        : [],
    [homeSectionsMapping, row]
  );

  const sectionIds = useMemo(
    () =>
      row.items.reduce((res, item) => {
        const homeTab = homeSectionsMapping.find((homeSection) => homeSection.name === item?.name);
        if (homeTab) {
          res[homeTab.section] = item.id;
        }
        return res;
      }, {}),
    [homeSectionsMapping, row]
  );

  const [editNewsVisible, setEditNewsVisible] = useState(false);
  const showEditNews = () => setEditNewsVisible(true);

  return (
    <section>
      <h1 className="home__title loading-highlighted title-h3">{labels?.LATEST_UPDATES}</h1>
      {homeTabs?.[0] ? (
        <div className="my-news__tabs-wrapper">
          <Tabs tabs={homeTabs} className="tabs--small" />
          {section === "mynews" &&
          homeData?.myPreferences &&
          (homeData.myPreferences?.hubs || [])?.concat(homeData.myPreferences?.topics || [])?.length
            ? !isPreview && (
                <button className="my-news__edit-news cta-link" onClick={showEditNews}>
                  {labels?.EDIT_NEWS}
                </button>
              )
            : null}
        </div>
      ) : (
        <div className="tabs">
          {homeTabs?.map((_tab, i) => (
            <span className="tabs__tab button tertiary small loading-highlighted" key={i} />
          ))}
        </div>
      )}
      <>
        {!!homeTabs.length &&
          {
            news: (
              <SectionNews
                data={homeData}
                sectionId={sectionIds?.news}
                newsLink={newsLink}
                onLoad={onLoad}
              />
            ),
            notes: (
              <SectionNotes
                data={homeData}
                sectionId={sectionIds?.notes}
                organizationalNotesLink={organizationalNotesLink}
                onLoad={onLoad}
              />
            ),
            press: (
              <SectionPress
                data={homeData}
                sectionId={sectionIds?.press}
                pressLink={pressLink}
                onLoad={onLoad}
              />
            ),
            mynews: (
              <SectionMyNews
                data={homeData}
                sectionId={sectionIds?.mynews}
                newsLink={myNewsLink}
                onLoad={onLoad}
                setHomeData={setHomeData}
                editNewsVisible={editNewsVisible}
                setEditNewsVisible={setEditNewsVisible}
              />
            ),
          }[section || "news"]}
      </>
    </section>
  );
};

const Row = ({ row, menu }) => {
  const { config } = useContext(UserContext);

  if (row.name === "side-column") {
    const hpWidgets = row.items?.filter((item) => item.newsType?.externalReference === "hp-widget");
    return (
      hpWidgets?.length > 0 && (
        <Suspense>
          <section className="home__highlighted">
            {hpWidgets?.map((item) => (
              <HighlightedContent key={item.id} content={item} />
            ))}
          </section>
        </Suspense>
      )
    );
  } else if (row.viewtype === "Survey" && row.name !== "side-column" && !config.hideSurveys) {
    const surveyContents = row.items?.filter(
      (item) => item.newsType?.externalReference === "survey" && !!item?.linkURL
    );
    return (
      surveyContents?.length > 0 && (
        <Suspense>
          {surveyContents?.map((item) => (
            <WidgetSurvey
              key={item.id}
              survey={{
                id: item?.linkURL,
                imageUrl: item?.coverImage?.cropUrlTemplate,
                categories: item?.intro,
                intro: item?.description,
                linkLabel: item?.linkLabel,
              }}
              tealiumId={`home_banner_survey_${item.id}`}
            />
          ))}
        </Suspense>
      )
    );
  } else if (row.viewtype === "Event" && row.name !== "side-column" && !config.hideEvents) {
    const eventContents = row.items?.filter(
      (item) => item.newsType?.externalReference === "event" && !!item?.linkURL
    );
    return (
      eventContents?.length > 0 && (
        <Suspense>
          {eventContents?.map((item) => (
            <EventBookingWidget
              key={item.id}
              event={{
                id: item?.linkURL,
                imageUrl: item?.coverImage?.cropUrlTemplate,
                categories: item?.intro,
                intro: item?.description,
              }}
            />
          ))}
        </Suspense>
      )
    );
  } else {
    switch (row.items?.[0]?.name) {
      case "Hero Banner DQL":
        return <SectionLeonardo id={row.items[0]?.id} menu={menu} />;
      case "Leonardo courses carousel":
        return <SectionCourses />;
      default:
        return null;
    }
  }
};

const RowItem = ({ row, newsLink, organizationalNotesLink, pressLink, myNewsLink, menu }) => {
  if (
    row.items?.length > 1 &&
    !["Survey", "Event"].includes(row.viewtype) &&
    row.name !== "side-column"
  ) {
    return (
      <TabsRow
        row={row}
        newsLink={newsLink}
        organizationalNotesLink={organizationalNotesLink}
        pressLink={pressLink}
        myNewsLink={myNewsLink}
      />
    );
  } else {
    return <Row row={row} menu={menu} />;
  }
};

const Home = ({
  newsLink,
  organizationalNotesLink,
  pressLink,
  myNewsLink,
  appsLinksLink,
  communitiesLink,
  hubsLink,
  menu,
}) => {
  const { userProfile } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);

  useTealiumOnPageLoad({ pageType: "Home", pageSection1: "Home" });

  const { data: rows, error } = useColdpage({
    path: "homepage",
    placeholderData: pagePlaceholder([{ items: [{}, {}, {}] }]),
    select: (data) => {
      const rows = data?.grid?.rows
        ?.filter(
          (row) =>
            row?.placements?.[0]?.items?.length > 0 && row?.placements?.[0]?.name !== "side-column"
        )
        ?.map((row) => row.placements[0]);
      const sideColumnWidget = data?.grid?.rows
        ?.filter(
          (row) =>
            row?.placements?.[0]?.items?.length > 0 && row?.placements?.[0]?.name === "side-column"
        )
        ?.map((row) => row.placements[0])?.[0];
      const newRows = [rows?.slice(0, 1), sideColumnWidget, rows?.slice(1)]
        ?.flat()
        ?.filter(Boolean);
      return newRows;
    },
  });

  return error ? (
    <LoadingError />
  ) : (
    <div
      className={classNames(
        "page home",
        (rows?.[0]?.items?.[0]?.name === "Loading" || Object.keys(labels).length === 0) &&
          "loading-skeleton"
      )}
    >
      <main className="home__main">
        <div
          className={classNames(
            "home__welcome d-tablet-none",
            userProfile === undefined && "loading-skeleton"
          )}
        >
          <div className="home__welcome-intro loading-highlighted">
            {userProfile && (
              <>
                {labels?.HELLO}{" "}
                <em>
                  <strong translate="no">
                    {userProfile.firstName} {userProfile.lastName}
                  </strong>
                </em>
              </>
            )}
          </div>
          <SafeHtml
            className="home__welcome-content loading-highlighted"
            html={userProfile ? labels?.WELCOME_TO_INTRANET : ""}
          />
        </div>
        {rows?.map((row, i) => (
          <RowItem
            row={row}
            newsLink={newsLink}
            organizationalNotesLink={organizationalNotesLink}
            pressLink={pressLink}
            myNewsLink={myNewsLink}
            key={i}
            menu={menu}
          />
        ))}
      </main>
      <SideBar
        appsLinksLink={appsLinksLink}
        communitiesLink={communitiesLink}
        hubsLink={hubsLink}
        sideContent={rows?.find((row) => row.name === "side-column")}
      />
    </div>
  );
};

export default Home;
