import dayjs from "dayjs";
import sortOnField from "./sortOnField.js";

/* COMMUNITY SHARED DOCUMENTS */

/* Shared document download url */
const getSharedDocUrl = (doc) => {
  if (doc.type === "EXTERNAL_VIDEO") {
    return doc.videoUrl;
  }
  return `${window.location.origin}/bff/community/documents/${doc.id}`;
};

/* Shared document download fucntion */
export const downloadSharedDocument = async (doc) => {
  const docLink = document.createElement("a");
  docLink.download = doc.filename;
  docLink.href = getSharedDocUrl(doc);
  docLink.click();
  setTimeout(() => docLink.remove(), 500);
};

/* Shared document view url */
export const getSharedDocViewUrl = (doc) => {
  if (doc.type === "EXTERNAL_VIDEO") {
    return doc.videoUrl;
  }
  return `${window.location.origin}/bff/community/documents/${doc.id}/view`;
};

/* Shared document open in browser function */
export const openSharedDocInBrowser = async (doc) => {
  window.open(getSharedDocViewUrl(doc), "_blank");
};

/* Get shared document image from TamTamy */
export const getTamTamyImg = (url, size) => {
  const matches = /(.+)-\d+-\d+(\.\w+)?/.exec(url);
  if (matches?.length) {
    const [, baseUrl, extension = ""] = matches;
    if (baseUrl) {
      return `${baseUrl}-${size}-0${extension}`;
    }
    return;
  }
  return url;
};

/* Get shared document thumbnail image */
export const getImgThumb = (img) => getTamTamyImg(img.thumbUrl, 80);

/* Shared documents sort options */
export const SHARED_DOCS_SORT_OPTIONS = {
  DATE: [
    {
      value: "NEWEST",
      label: "DATE_NEWEST",
    },
    {
      value: "OLDEST",
      label: "DATE_OLDEST",
    },
  ],
  TYPE: [
    {
      value: "TYPE_AZ",
      label: "TYPE_AZ",
    },
    {
      value: "TYPE_ZA",
      label: "TYPE_ZA",
    },
  ],
  DOCUMENT: [
    {
      value: "DOCUMENT_AZ",
      label: "DOCUMENT_AZ",
    },
    {
      value: "DOCUMENT_ZA",
      label: "DOCUMENT_ZA",
    },
  ],
  OWNER: [
    {
      value: "OWNER_AZ",
      label: "OWNER_AZ",
    },
    {
      value: "OWNER_ZA",
      label: "OWNER_ZA",
    },
  ],
};

/* DOCUMENT REPOSITORY RESOURCES AND POLICIES AND PROCEDURES */

/* Resource download document url */
export const getResourceDocDownloadUrl = (fileId, policies) => {
  return `${window.location.origin}/bff/${policies ? "policy" : "resource"}/document/${fileId}`;
};

/* Resource download document url */
export const getResourceLinkViewUrl = (fileId, policies) => {
  return `${window.location.origin}/bff/${
    policies ? "policy" : "resource"
  }/document/${fileId}/view`;
};

/* TEAMS DOCUMENTS  */

/* Teams documents sort options */
export const TEAMS_DOCS_SORT_OPTIONS = {
  DATE: [
    {
      value: "DATE_ASC",
      label: "TEAMS_DOC_SORT_DATE_ASC",
    },
    {
      value: "DATE_DESC",
      label: "TEAMS_DOC_SORT_DATE_DESC",
    },
  ],
  TYPE: [
    {
      value: "TYPE_ASC",
      label: "TEAMS_DOC_SORT_TYPE_ASC",
    },
    {
      value: "TYPE_DESC",
      label: "TEAMS_DOC_SORT_TYPE_DESC",
    },
  ],
  DOCUMENT: [
    {
      value: "DOCUMENT_DESC",
      label: "TEAMS_DOC_SORT_DOCUMENT_DESC",
    },
    {
      value: "DOCUMENT_ASC",
      label: "TEAMS_DOC_SORT_DOCUMENT_ASC",
    },
  ],
  OWNER: [
    {
      value: "OWNER_DESC",
      label: "TEAMS_DOC_SORT_EDITOR_DESC",
    },
    {
      value: "OWNER_ASC",
      label: "TEAMS_DOC_SORT_EDITOR_ASC",
    },
  ],
};

export const sortOptionToQuery = (sortOpt) => {
  const [sorting, sortingDesc] = sortOpt?.split("_");
  return { sorting: sorting, sortingDesc: sortingDesc === "DESC" };
};

export const queryToSortOption = (sorting, sortingDesc) => {
  return `${sorting}_${sortingDesc ? "DESC" : "ASC"}`;
};

/* Teams document download url */
const getTeamsDocumentDownloadUrl = (communityId, fileId, driveId) => {
  return `${window.location.origin}/bff/community/${communityId}/teamsDocuments/${driveId}/${fileId}/download`;
};

/* Teams document open in browser function */
export const viewTeamsDocument = async (doc) => {
  const docLink = document.createElement("a");
  docLink.href = doc.webUrl;
  docLink.target = "_blank";
  docLink.click();
  setTimeout(() => docLink.remove(), 500);
};

/* Teams document download function */
const downloadTeamsDocument = async (doc, communityId) => {
  const docLink = document.createElement("a");
  docLink.download = doc.name || true;
  docLink.href = getTeamsDocumentDownloadUrl(communityId, doc.id, doc.driveId);
  docLink.click();
  setTimeout(() => docLink.remove(), 500);
};

const getDocPromise = async (doc, communityId, folder) => {
  const docUrl = getTeamsDocumentDownloadUrl(communityId, doc?.id, doc?.driveId);
  const response = await fetch(docUrl);
  if (response.ok) {
    const blob = await response.blob();
    return {
      filename: folder ? `${folder.name}/${doc.name}` : doc.name,
      blob,
      folder: !!doc.folder,
    };
  } else {
    throw new Error(`Error calling ${docUrl} - ${response.status} ${response.statusText}`);
  }
};

const getRecursiveFolderDocuments = async (folder, communityId, getFolderContents) => {
  const contents = await getFolderContents(folder.id, folder.driveId);
  if (contents.length) {
    const folders = contents.filter((doc) => doc.folder);
    const documents = contents.filter((doc) => !doc.folder);
    const docsPromises = documents.map((doc) => getDocPromise(doc, communityId, folder));
    const nestedDocumentPromises = (
      await Promise.all(
        folders.map((nestedFolder) => {
          return getRecursiveFolderDocuments(
            {
              ...nestedFolder,
              name: `${folder.name}/${nestedFolder.name}`,
            },
            communityId,
            getFolderContents
          );
        })
      )
    ).flat();
    return nestedDocumentPromises.concat(docsPromises);
  } else {
    return [
      {
        filename: folder.name,
        folder: true,
      },
    ];
  }
};

/* Teams download element -> zip if it's folder, file download otherwise */
export const downloadTeamsElement = async (item, communityId, getFolderContents) => {
  if (item?.folder) {
    console.log("Preparing zip...");
    const contentPromises = await getRecursiveFolderDocuments(item, communityId, getFolderContents);
    const { downloadZip } = await import("./download-zip.js");
    await downloadZip(contentPromises, `${item.name}.zip`);
    console.log("Zip ready");
  } else {
    console.log(item);
    await downloadTeamsDocument(item, communityId);
  }
};

/* Teams download multiple elements -> zip */
export const downloadTeamsElements = async (items, communityId, getFolderContents) => {
  if (items.length > 1) {
    console.log("Preparing zip...");
    const allContentPromises = await Promise.all(
      items.map(async (item) => {
        if (item.folder) {
          return await getRecursiveFolderDocuments(item, communityId, getFolderContents);
        } else {
          return [getDocPromise(item, communityId)];
        }
      })
    );
    const contentPromises = allContentPromises.flat();
    const { downloadZip } = await import("./download-zip.js");
    await downloadZip(contentPromises, `teams-documents-${communityId}.zip`);
    console.log("Zip ready");
  } else {
    await downloadTeamsElement(items[0], communityId, getFolderContents);
  }
};

/* 
  Teams file/folder name validation 
  https://support.microsoft.com/en-us/office/restrictions-and-limitations-in-onedrive-and-sharepoint-64883a5d-228e-48f5-b3d2-eb39e07630fa
*/
const NAME_REGEX = /^(?!\s)[^"*:<>?/\\|]+$/;
export const validateName = (name) =>
  NAME_REGEX.test(name) && !name.includes("_vti_") && !name.endsWith(" ");

/* Sort function */
const sortFunction = (a, b, query) => {
  return {
    TYPE: sortOnField(
      a,
      b,
      (x) => x.mimeType,
      query.sortingDesc,
      (x) => dayjs(x.lastModifiedDateTime, "YYYY-MM-DDThh:mm:ssZ").unix()
    ),
    DOCUMENT: sortOnField(
      a,
      b,
      (x) => x.name.toUpperCase(),
      query.sortingDesc,
      (x) => dayjs(x.lastModifiedDateTime, "YYYY-MM-DDThh:mm:ssZ").unix()
    ),
    DATE: sortOnField(
      a,
      b,
      (x) => dayjs(x.lastModifiedDateTime, "YYYY-MM-DDThh:mm:ssZ").unix(),
      query.sortingDesc
    ),
    OWNER: sortOnField(
      a,
      b,
      (x) =>
        `${x.lastModifiedBy?.lastName?.toUpperCase()} ${x.lastModifiedBy?.firstName?.toUpperCase()}`,
      query.sortingDesc,
      (x) => dayjs(x.lastModifiedDateTime, "YYYY-MM-DDThh:mm:ssZ").unix()
    ),
  }[query.sorting];
};

/* Teams sort: folder + file handling */
export const sortElements = (data, query) => {
  const sortedFolders = data
    .filter((elem) => elem.folder)
    .sort((a, b) => sortFunction(a, b, query));
  const sortedFiles = data.filter((elem) => !elem.folder).sort((a, b) => sortFunction(a, b, query));
  return sortedFolders.concat(sortedFiles);
};

/* GLOBAL DOCUMENTS FUNCTIONS */

export const getFilenameDetails = (filename) => {
  if (filename) {
    const pathlessFilename = filename?.slice(filename.lastIndexOf("/") + 1);
    if (
      pathlessFilename?.lastIndexOf(".") > 0 &&
      pathlessFilename.length - pathlessFilename?.lastIndexOf(".") <= 5
    ) {
      return {
        clearFilename: pathlessFilename.slice(0, pathlessFilename.lastIndexOf(".")),
        extension: pathlessFilename.slice(pathlessFilename.lastIndexOf(".") + 1),
        dotExtension: `.${pathlessFilename.slice(pathlessFilename.lastIndexOf(".") + 1)}`,
      };
    } else return { clearFilename: pathlessFilename, extension: null, dotExtension: null };
  } else return { clearFilename: null, extension: null, dotExtension: null };
};

const normalizeDocumentList = (list) => {
  const normalizeFileName = (filename, list, count = 1) => {
    if (list.some((item) => item.filename === filename)) {
      const dotIdx = filename.lastIndexOf(".");
      const suffix = `-${count}`;
      const newFilename =
        dotIdx < 0
          ? filename + suffix
          : filename.substring(0, dotIdx) + suffix + filename.substring(dotIdx);
      if (list.some((item) => item.filename === newFilename)) {
        return normalizeFileName(filename, list, count + 1);
      }
      return newFilename;
    }
    return filename;
  };
  return list.reduce((res, doc) => {
    if (res.some((item) => item.filename === doc.filename)) {
      const newFilename = normalizeFileName(doc.filename, res);
      res.push({
        ...doc,
        filename: newFilename,
      });
    } else {
      res.push(doc);
    }
    return res;
  }, []);
};

export const downloadZipDocuments = async (documents, filename, type = "resource") => {
  const normalizedDocumentList = normalizeDocumentList(documents);
  const items = normalizedDocumentList.map(async (doc) => {
    const docUrl =
      type === "shared-document" ? getSharedDocUrl(doc) : getResourceDocDownloadUrl(doc?.id);
    const response = await fetch(docUrl);
    if (response.ok) {
      const blob = await response.blob();
      return {
        filename: doc.filename,
        blob,
      };
    } else {
      throw new Error(`Error calling ${docUrl} - ${response.status} ${response.statusText}`);
    }
  });
  const { downloadZip } = await import("./download-zip.js");
  await downloadZip(items, filename);
};

export const getItemIcon = (doc) => {
  const { filename } = doc;
  const ext = filename?.includes(".") ? filename.split(".").reverse()[0] : "";
  return (
    {
      doc: "file-word",
      docx: "file-word",
      xls: "file-excel",
      xlsx: "file-excel",
      ppt: "file-powerpoint",
      pptx: "file-powerpoint",
      pdf: "file-pdf",
      zip: "file-zip",
      png: "image",
      gif: "image",
      tiff: "image",
      jpg: "image",
      jpeg: "image",
      svg: "image",
    }[ext] || "file"
  );
};
