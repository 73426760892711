import { useState, useContext, useMemo } from "react";
import classNames from "classnames";
import LanguageContext from "../context/language";
import Tabs from "./tabs";
import LoadingError from "../routes/loading-error";
import VideoList from "./hub-widgets/video-list";
import VideoPlayerWidget from "./hub-widgets/video-player-widget";
import getPageName from "../utils/getPageName";
import Faqs from "./faqs";
import ContactUsFooter from "./contact-us-footer";
import useColdpage from "../hooks/queries/useColdpage";

const FaqWidget = ({ data }) => {
  const { labels } = useContext(LanguageContext);
  const [tab, setTab] = useState(data?.map((item) => item.categoryString).flat()?.[0]);

  const faqTabs = useMemo(
    () =>
      data
        ?.map((item) => item.categoryString)
        ?.flat()
        ?.filter((item, i, self) => self.indexOf(item) === i)
        ?.map((item) => ({
          name: item,
          label: labels?.[`FAQ_CATEGORY_${item}`] || item,
          end: true,
        })),
    [data, labels]
  );

  const currentCategoryFaqs = useMemo(
    () => data?.filter((item) => item?.categoryString?.includes(tab)),
    [data, tab]
  );

  const selectedTab = useMemo(() => faqTabs?.find((item) => item.name === tab), [faqTabs, tab]);

  return (
    !!data?.length && (
      <section className="support-page__item">
        <h1 className="support-page title loading-highlighted">
          {labels?.FREQUENTLY_ASKED_QUESTIONS || "Frequently asked questions"}
        </h1>
        <Tabs tabs={faqTabs} currentTab={tab} setCurrentTab={setTab} />
        <Faqs title={selectedTab?.label} faqs={currentCategoryFaqs} />
      </section>
    )
  );
};

const ContactAndSupportWidget = ({ page, labels }) => {
  return (
    <section className="support-page__item">
      <h1 className="support-page title loading-highlighted">{getPageName(page, labels)}</h1>
      <div
        className="loading-highlighted"
        dangerouslySetInnerHTML={{ __html: labels?.CONTACT_AND_SUPPORT_DEFAULT_DESCRIPTION }}
      />
    </section>
  );
};

const SupportPage = ({ page }) => {
  const { labels } = useContext(LanguageContext);

  const {
    error,
    data: pageTemplate,
    isFetching,
  } = useColdpage({ path: "footer/contact-and-support" });

  return error ? (
    <LoadingError />
  ) : (
    <main
      className={classNames("page support-page wrapper-small", isFetching && "loading-skeleton")}
    >
      {pageTemplate?.map(
        ({ placements }, i) =>
          ({
            "Contact and Support": (
              <ContactAndSupportWidget key={`section-${i}`} page={page} labels={labels} />
            ),
            FAQ: (
              <FaqWidget
                key={`section-${i}`}
                data={placements?.[0]?.items?.filter(
                  (item) => item?.newsType?.externalReference !== "placement-title"
                )}
              />
            ),
            "Video List":
              placements?.[0]?.items?.filter(
                (item) => item?.newsType?.externalReference !== "placement-title"
              )?.length > 1 ? (
                <VideoList
                  key={`section-${i}`}
                  data={placements?.[0]?.items?.filter(
                    (item) => item?.newsType?.externalReference !== "placement-title"
                  )}
                  title={
                    placements?.[0]?.items?.find(
                      (item) => item?.newsType?.externalReference === "placement-title"
                    )?.title
                  }
                />
              ) : (
                <VideoPlayerWidget
                  key={`section-${i}`}
                  data={placements?.[0]?.items?.filter(
                    (item) => item?.newsType?.externalReference !== "placement-title"
                  )}
                  title={
                    placements?.[0]?.items?.find(
                      (item) => item?.newsType?.externalReference === "placement-title"
                    )?.title
                  }
                />
              ),
          }[placements?.[0]?.viewtype])
      )}
      <ContactUsFooter />
    </main>
  );
};

export default SupportPage;
