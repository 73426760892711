import { Link } from "react-router-dom";

const ConditionalLink = ({ children, disabled, to, state, ...rest }) => {
  return disabled ? (
    <div {...rest}>{children}</div>
  ) : to ? (
    <Link to={to} state={state} {...rest}>
      {children}
    </Link>
  ) : (
    <a {...rest}>{children}</a>
  );
};

export default ConditionalLink;
