import classNames from "classnames";
import { useContext, useRef, useState } from "react";
import LanguageContext from "../context/language";
import useHover from "../hooks/useHover";
import Translate from "./translate";
import Video from "./video";

const VideoCard = ({ item }) => {
  const { labels } = useContext(LanguageContext);
  const [hoverRef, isHovered] = useHover();
  const videoRef = useRef();
  const [isTouched, setIsTouched] = useState(false);

  //item.video = { ...item.video, dataUrl: "https://www.youtube.com/watch?v=O4AnyCM6Dsc"};
  //item.video = { ...item.video, dataUrl: "https://vimeo.com/723429393" };
  //item.video = { ...item.video, dataUrl: "https://www.dailymotion.com/video/x8e6ues" };

  return item?.video ? (
    <div
      className={classNames(
        "video-list__card",
        isTouched && "video-list__card--touched",
        !!(
          videoRef.current?.currentTime > 0 &&
          !videoRef.current?.ended &&
          videoRef.current?.readyState > 2
        ) && "video-list__card--playing"
      )}
      ref={hoverRef}
      onTouchStart={() => {
        setIsTouched(true);
        videoRef.current?.paused ? videoRef.current.play() : videoRef.current.pause();
      }}
    >
      <Video
        item={item}
        isHovered={isHovered}
        isTouched={isTouched}
        ref={videoRef}
        className="video-list__video"
      />
      <div className="video-list__text">
        <div className="video-list__type">
          {item?.categoryString?.[0] &&
            (labels?.[item?.categoryString?.[0]?.toUpperCase()] ||
              `${item?.categoryString?.[0]?.toUpperCase()}${item?.categoryString?.[0]
                ?.slice(1)
                ?.toUpperCase()}`)}
        </div>
        <div className="video-list__title title-h5">
          <Translate text={item?.title} />
        </div>
      </div>
    </div>
  ) : null;
};

export default VideoCard;
