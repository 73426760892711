import { useMemo } from "react";
import PropTypes from "prop-types";

const cropMap = {
  landscape_ratio16x9: {
    sizes: [375, 414, 600, 1200, 1920],
  },
  landscape_ratio4x3: {
    sizes: [375, 414, 768, 1200],
  },
  portrait_ratio1x1: {
    sizes: [115, 185, 220, 384, 555],
  },
  portrait_ratio3x4: {
    //sizes: [281, 310, 900],
    sizes: [300, 600, 1200],
  },
};

const Image = ({ loading = "lazy", cropUrlTemplate, crops, alt, ...other }) => {
  const items = useMemo(() => {
    return crops.map((item) => {
      const cropData = cropMap[item.crop];
      return {
        breakpoint: item.breakpoint,
        srcSet: cropData.sizes.map((size) => ({
          size,
          url: cropUrlTemplate.replace("{cropName}/{width}", `${item.crop}/${size}`),
        })),
        sizes: item.sizes?.map((size) => ({
          breakpoint: size.breakpoint,
          size: size.maxWidth,
        })),
      };
    });
  }, [cropUrlTemplate, crops]);

  const imgCut = items[items.length - 1];
  return (
    <picture>
      {items.slice(0, -1).map((item, i) => (
        <source
          media={`(max-width: ${item.breakpoint}px)`}
          srcSet={item.srcSet.map((img) => `${img.url} ${img.size}w`).join(", ")}
          sizes={item.sizes
            ?.map(
              (img) => `${img.breakpoint ? `(max-width: ${img.breakpoint}px) ` : ""}${img.size}`
            )
            .join(", ")}
          key={i}
        />
      ))}
      <img
        src={imgCut.srcSet[imgCut.srcSet.length - 1].url}
        srcSet={imgCut.srcSet.map((img) => `${img.url} ${img.size}w`).join(", ")}
        sizes={imgCut.sizes
          ?.map((img) => `${img.breakpoint ? `(max-width: ${img.breakpoint}px) ` : ""}${img.size}`)
          .join(", ")}
        loading={loading}
        alt={alt || ""}
        {...other}
      />
    </picture>
  );
};

Image.propTypes = {
  cropUrlTemplate: PropTypes.string.isRequired,
  crops: PropTypes.arrayOf(
    PropTypes.shape({
      crop: PropTypes.oneOf([
        "landscape_ratio16x9",
        "landscape_ratio4x3",
        "portrait_ratio1x1",
        "portrait_ratio3x4",
      ]),
      breakpoint: PropTypes.number,
      sizes: PropTypes.arrayOf(
        PropTypes.shape({
          breakpoint: PropTypes.number,
          maxWidth: PropTypes.string,
        })
      ),
    })
  ).isRequired,
};

export default Image;
