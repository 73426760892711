import { useState, useContext } from "react";
import Modal from "./modal";
import UserContext from "../context/user";
import LanguageContext from "../context/language";
import Error from "./error";

const DeleteCustomLinkModal = ({ show, setShow, linkId, onUpdate }) => {
  const { labels } = useContext(LanguageContext);
  const { callApi } = useContext(UserContext);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(false);

  const handleDeleteLink = async () => {
    setDeleting(true);
    try {
      await callApi(`/profile/favorites/customLink/${linkId}`, {
        method: "DELETE",
      });
      await onUpdate("links");
      setShow(false);
    } catch (err) {
      console.error("Error deleting link: ", err);
      setError(true);
    }
    setDeleting(false);
  };

  return (
    <Modal show={show} setShow={setShow} className="intranet-modal--warning">
      <div className="intranet-modal__title">
        {labels?.DELETE_LINK_WARNING || "Do you want to delete personalized link?"}
      </div>
      <div className="intranet-modal__buttons">
        <button className="button tertiary" onClick={() => setShow(false)}>
          {labels?.NO_KEEP_IT || "No, keep it"}
        </button>
        <button className="button primary" onClick={handleDeleteLink}>
          {deleting ? <span className="loading-spinner" /> : null}
          <span>{labels?.YES_DELETE || "Yes, delete"}</span>
        </button>
      </div>
      {error && (
        <Error msg={labels?.ERROR_DELETING_LINK || "Error deleting link"} variant="small" />
      )}
    </Modal>
  );
};

export default DeleteCustomLinkModal;
