import { useContext } from "react";
import classNames from "classnames";
import LanguageContext from "../../context/language";
import ServiceCard from "../service-card";
import LoadingError from "../../routes/loading-error";
import WidgetTitle from "./elements/widget-title";
import useHubServices from "../../hooks/queries/useHubServices";

const AllHrContentWidget = ({ hub, title }) => {
  const { labels } = useContext(LanguageContext);

  const { data: hrContent, error } = useHubServices({ hub });

  return error ? (
    <LoadingError />
  ) : (
    !!hrContent?.length && (
      <section
        className={classNames("widget service-list service-list--full", !title && "no-title")}
      >
        <WidgetTitle title={title} />
        <div className="service-list__content">
          {hrContent.map((service, i) => (
            <ServiceCard card={service} key={i} labels={labels} />
          ))}
        </div>
      </section>
    )
  );
};

export default AllHrContentWidget;
