import { useContext } from "react";
import classNames from "classnames";
import Icon from "./icon";
import SnackBar from "./snackbar";
import useResetCounter from "../hooks/useResetCounter";
import LanguageContext from "../context/language";

const CopyLink = ({ link, timeout = 2000, className, children }) => {
  const [copyCount, incrementCopyCount] = useResetCounter();
  const { labels } = useContext(LanguageContext);
  const copyToClipboard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await navigator.clipboard.writeText(e.currentTarget.name);
    incrementCopyCount();
  };
  return (
    <>
      <button className={classNames("copy-link", className)} name={link} onClick={copyToClipboard}>
        {children}
      </button>
      {!!copyCount && (
        <SnackBar className="copy-link__snackbar" timeout={timeout} key={copyCount}>
          <Icon name="checkmark" />
          {labels?.URL_COPIED_CLIPBOARD || "Url link copied to clipboard"}
        </SnackBar>
      )}
    </>
  );
};

export default CopyLink;
