import { useCallback, useContext, useMemo } from "react";
import { matchPath, Outlet, useLocation } from "react-router-dom";
import LanguageContext from "../context/language";
import LinkPageMenu from "./link-page-menu";

const LinkPageContent = ({ page, subMenu, internalMenuOpen, setInternalMenuOpen }) => {
  const { labels } = useContext(LanguageContext);
  const location = useLocation();

  const isActive = useCallback(
    (link) => !!matchPath({ path: `${link}/*`, end: false }, location.pathname),
    [location]
  );

  const firstLevelParent = useMemo(
    () => subMenu?.find((page) => isActive(page.link)),
    [subMenu, isActive]
  );

  return (
    <main className="page hub">
      <div className="page__header page__header--menu">
        <div className="hub__title-wrapper">
          <h1>{page?.label}</h1>
        </div>
        <LinkPageMenu
          menu={subMenu}
          mobileLabel={internalMenuOpen ? labels?.CLOSE : labels?.MOBILE_MENU || "Menu"}
          mobileMenu={internalMenuOpen}
          setMobileMenu={setInternalMenuOpen}
        />
      </div>
      <div className="page__content" key={page?.segment}>
        {/* {firstLevelParent?.children?.length ? (
          <HubSideMenu menu={firstLevelParent} path={path} />
        ) : null} */}
        <Outlet context={{ sideHubMenu: !!firstLevelParent?.children?.length }} />
      </div>
    </main>
  );
};

export default LinkPageContent;
