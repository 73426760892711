import classNames from "classnames";
import VideoPlayer from "../video-player";
import Translate from "../translate";

const VideoPlayerWidget = ({ data, title }) => {
  const loading = false;

  return (
    !!data?.length && (
      <section
        className={classNames(
          "widget video-player-widget",
          loading && "loading-skeleton",
          !title && "no-title"
        )}
      >
        {title && (
          <h2 className="widget__title loading-highlighted">
            <Translate text={title} />
          </h2>
        )}
        <div className="video-player-widget__content">
          <VideoPlayer
            video={{
              data: data?.[0]?.video?.data,
              title: data?.[0]?.title,
              picture: data?.[0]?.video?.picture,
              category: data?.[0]?.category,
            }}
          />
        </div>
      </section>
    )
  );
};

export default VideoPlayerWidget;
