import Modal from "react-overlays/Modal";
import classNames from "classnames";
import Icon from "./icon.jsx";
import { useCallback, useContext } from "react";
import LanguageContext from "../context/language.jsx";

const renderBackdrop = (props) => <div className="backdrop" {...props} />;

const IntranetModal = ({ show, setShow, className, onCustomClose, readOnly, children }) => {
  const { labels } = useContext(LanguageContext);
  const closeModal = useCallback(() => {
    if (onCustomClose) {
      onCustomClose();
    } else if (setShow) {
      setShow(false);
    }
  }, [onCustomClose, setShow]);

  return (
    <Modal
      show={show}
      onHide={closeModal}
      aria-labelledby="modal-label"
      className={classNames("intranet-modal", className)}
      renderBackdrop={renderBackdrop}
      onBackdropClick={readOnly ? () => null : closeModal}
      aria-hidden="false"
      aria-modal="true"
    >
      <>
        {!readOnly && (
          <button
            type="button"
            onClick={closeModal}
            className="close-button"
            aria-label={labels?.CLOSE}
          >
            <Icon name="x-icon" />
          </button>
        )}
        <div className="intranet-modal__body wrapper">{children}</div>
      </>
    </Modal>
  );
};

export default IntranetModal;
