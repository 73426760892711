import useFilters from "../../hooks/useFilters";
import LoadingError from "../loading-error";
import useTealiumOnPageLoad from "../../utils/tealium";
import FilterList from "../../components/filters/filter-list";
import ResourcesCardWrapper from "../../components/resource-card-wrapper";
import useBreakpoint from "../../hooks/useBreakpoint";
import useResources from "../../hooks/queries/useResources";
import useUpdateFavoriteResources from "../../hooks/mutations/useUpdateFavoriteResources";
import { useContext } from "react";
import LanguageContext from "../../context/language";

const PAGE_SIZE = 12;

const AllResources = ({ hub }) => {
  const { cmsLang: locale } = useContext(LanguageContext);

  const isMobile = useBreakpoint(991);
  const [query, setFilterData] = useFilters("resources");

  useTealiumOnPageLoad({ pageType: "Landing", pageSection1: "All Resources" });

  const {
    data: resources,
    error,
    isFetching,
    fetchNextPage,
  } = useResources({
    hub,
    query,
    pageSize: PAGE_SIZE,
  });

  const { mutateAsync: updateFav } = useUpdateFavoriteResources({
    // Invalidate all resources cache except current, on which optimistic update will be performed
    doInvalidateAllResources: false,
    doOptimisticUpdateAllResources: true,
    // Invalidate my resources cache
    doInvalidateMyResources: true,
    currentQueryKey: ["resources-all", PAGE_SIZE, locale, query, hub],
  });

  const handleUpdateFav = async ({ id, isDelete }) => {
    await updateFav({ id, isDelete });
  };

  return error ? (
    <LoadingError />
  ) : (
    <FilterList
      items={resources.data}
      query={query}
      totalCounter={resources?.resultsAvailableCount}
      setFilterData={setFilterData}
      facetView={resources?.facetView}
      resourceType="resources"
      filterColumnOpen={!isMobile}
      openedFiltersByDefault={["categoryFacet"]}
    >
      <ResourcesCardWrapper
        resources={resources}
        loadMore={fetchNextPage}
        isLoading={isFetching}
        onUpdate={handleUpdateFav}
      />
    </FilterList>
  );
};

export default AllResources;
