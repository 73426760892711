import { useCallback, useContext, useRef, useState } from "react";
import classNames from "classnames";
import UserContext from "../context/user";
import Icon from "./icon";

const Like = ({ likesCounter, isLiked, fetchLikes, postLike }) => {
  const { isPreview } = useContext(UserContext);
  const [likes, setLikes] = useState();
  const loadedInteractions = useRef(false);
  /* Temporary variables to handle real-time front-end likes */
  const [isTempStatus, setIsTempStatus] = useState(false);
  const [tempStatus, setTempStatus] = useState(/* { isTempLiked, tempLikes } */);

  const loadInteractions = useCallback(async () => {
    loadedInteractions.current = true;
    try {
      const result = await fetchLikes();
      if (result) {
        setLikes(result?.likeCounter);
      }
    } catch (err) {
      console.error("Error loading interactions", err);
    }
  }, [fetchLikes]);

  const toggleLike = async () => {
    try {
      await postLike((isTempStatus ? tempStatus?.isTempLiked : isLiked) ? "DELETE" : "POST");
      setIsTempStatus(true);
      setTempStatus({
        isTempLiked: isTempStatus ? !tempStatus?.isTempLiked : !isLiked,
        tempLikes: isTempStatus
          ? tempStatus?.isTempLiked
            ? tempStatus?.tempLikes - 1
            : tempStatus?.tempLikes + 1
          : isLiked
          ? likesCounter ?? likes ?? 0
            ? (likesCounter ?? likes ?? 0) - 1
            : 0
          : (likesCounter ?? likes ?? 0) + 1,
      });
      loadInteractions();
    } catch (err) {
      console.error("Error toggling like", err);
    }
  };

  return (
    <div className="like">
      <button
        onClick={toggleLike}
        disabled={isPreview}
        className={classNames(
          "like__button",
          (isTempStatus ? tempStatus?.isTempLiked : isLiked) && "like__button--is-liked"
        )}
        aria-label="Like"
      >
        <Icon name="like" />
      </button>
      <span className="like__counter">
        {isTempStatus
          ? tempStatus?.tempLikes
          : isLiked && (!likesCounter || likesCounter === 0) //liked news && (not initialized counter || counter = 0)
          ? 1
          : likesCounter ?? likes ?? 0}
      </span>
    </div>
  );
};

export default Like;
