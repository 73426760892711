import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import UserContext from "../../context/user";

const useUpdateFavoriteResources = ({
  doInvalidateAllResources,
  doInvalidateMyResources,
  doOptimisticUpdateAllResources,
  doOptimisticUpdateMyResources,
  currentQueryKey,
}) => {
  const { callApi } = useContext(UserContext);
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, isDelete }) =>
      callApi(`/resource/favorites/${id}`, {
        method: isDelete ? "DELETE" : "POST",
      }),
    {
      onSuccess: (_data, { id, isDelete }) => {
        if (doInvalidateAllResources) {
          //Invalidate cache for all resources
          console.log("Invalidating cache for", ["resources-all"]);
          queryClient.removeQueries(["resources-all"]);
        } else {
          //Invalidate cache for all resources except current
          queryClient.removeQueries({
            predicate: (q) => {
              const doInvalidate =
                q.queryKey[0] === "resources-all" &&
                JSON.stringify(q.queryKey[3]) !== JSON.stringify(currentQueryKey[3]);
              if (doInvalidate) {
                console.log("Invalidating cache for query", q.queryKey);
              }
              return doInvalidate;
            },
          });
        }
        if (doInvalidateMyResources) {
          //Invalidate cache for my resources
          console.log("Invalidating cache for", ["resources-my"]);
          queryClient.removeQueries(["resources-my"]);
        } else {
          //Invalidate cache for my resources except current
          queryClient.removeQueries({
            predicate: (q) => {
              const doInvalidate =
                q.queryKey[0] === "resources-my" &&
                (JSON.stringify(q.queryKey[3]) !== JSON.stringify(currentQueryKey[3]) ||
                  !q.queryKey[4]);
              if (doInvalidate) {
                console.log("Invalidating cache for query", q.queryKey);
              }
              return doInvalidate;
            },
          });
        }
        // Perform optimistic update on current query (all)
        if (doOptimisticUpdateAllResources) {
          queryClient.setQueryData(currentQueryKey, (oldData) => {
            const newData = oldData?.pages.map((page) => ({
              ...page,
              data: page.data.map((item) => {
                if (item.masterID === id) {
                  return {
                    ...item,
                    bookmarked: !isDelete,
                  };
                } else {
                  return item;
                }
              }),
            }));
            return {
              ...oldData,
              pages: newData,
            };
          });
        }
        // Perform optimistic update on current query (my)
        if (doOptimisticUpdateMyResources && isDelete) {
          const pageSize = currentQueryKey[1];
          queryClient.setQueryData(currentQueryKey, (oldData) => {
            const allData = oldData?.pages.map((page) => page.data).flat();
            const filteredData = allData.filter((item) => item.masterID !== id);
            const pages = oldData.pages
              .map((page, index) => ({
                ...page,
                resultsAvailableCount: page.resultsAvailableCount - 1,
                data: filteredData.slice(index * pageSize, (index + 1) * pageSize),
              }))
              .filter((page) => !!page.data.length);
            return {
              pageParams: oldData.pageParams.slice(0, pages.length),
              pages,
            };
          });
        }
      },
    }
  );
};

export default useUpdateFavoriteResources;
