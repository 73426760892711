import { useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import classNames from "classnames";
import dayjs from "dayjs";
import Content from "../../components/content";
import Icon from "../../components/icon";
import FavoriteBtn from "../../components/favorite-btn";
import Translate from "../../components/translate";
import LanguageContext from "../../context/language";
import useTealiumOnPageLoad from "../../utils/tealium";
import LoadingError from "../loading-error";
import Documents from "../../components/documents";
import useUpdateFavoriteResources from "../../hooks/mutations/useUpdateFavoriteResources";
import useResourceDetail from "../../hooks/queries/useResourceDetail";

const ResourceDetail = ({ backLink }) => {
  const queryClient = useQueryClient();
  const { labels, cmsLang } = useContext(LanguageContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { hubName, resourceId } = useParams();

  const { data: resource, error } = useResourceDetail({ resourceId });

  useTealiumOnPageLoad(
    { pageSection1: "news-detail", pageSection2: resource?.title },
    !!resource?.title
  );

  const { mutateAsync: updateFav } = useUpdateFavoriteResources({
    // Invalidate all resources cache
    doInvalidateAllResources: true,
    // Invalidate my resources cache
    doInvalidateMyResources: true,
  });

  const handleUpdateFav = async ({ id, isDelete }) => {
    await updateFav({ id, isDelete });
    // Optimistic update on current resource
    queryClient.setQueryData(["resource-detail", resourceId, cmsLang], (oldData) => ({
      ...oldData,
      bookmarked: !oldData.bookmarked,
    }));
  };

  return error ? (
    <LoadingError />
  ) : (
    <div className={classNames("resource-detail", hubName && "hub-page")}>
      <div className="return-bar full-width" role="region" aria-label="Return bar">
        <div className="wrapper">
          {state?.navBack ? (
            <button className="return-bar__btn" onClick={() => navigate(-1)}>
              <Icon name="chevron" />
              <span>{labels?.GO_TO_NEWS_LIST}</span>
            </button>
          ) : (
            <Link className="return-bar__btn" to={backLink?.link}>
              <Icon name="chevron" />
              <span>{backLink?.label}</span>
            </Link>
          )}
        </div>
      </div>
      <article className="wrapper-medium" role="region" aria-label="article">
        <div
          className={classNames(
            "resource-detail__header",
            Object.keys(resource).length === 0 && "loading-skeleton"
          )}
        >
          <div className="resource-detail__header-top">
            <div className="resource-detail__category loading-highlighted">
              {resource?.categoryString?.map(
                (cat, i) =>
                  `${
                    labels?.[cat?.toUpperCase()] ||
                    `${cat?.[0]?.toUpperCase()}${cat.slice(1)?.toLowerCase()}`
                  }${i < resource.categoryString.length - 1 ? ", " : ""}`
              )}
            </div>
            <FavoriteBtn item={resource} onUpdate={handleUpdateFav} />
          </div>
          {hubName ? (
            <h2 className="loading-highlighted resource-detail__title">
              <Translate text={resource?.title} />
            </h2>
          ) : (
            <h1 className="title-h2 loading-highlighted resource-detail__title">
              <Translate text={resource?.title} />
            </h1>
          )}
          {(resource?.intro || resource?.description) && (
            <div className="text wrapper-small">
              <Translate className="resource-detail__subtitle" html={resource?.intro} />
              <Content news={resource} />
            </div>
          )}
          {resource?.customPublicationDate &&
            resource?.customPublicationDate !== resource?.creationDate && (
              <time className="resource-detail__date loading-highlighted">
                {dayjs(resource.customPublicationDate, [
                  "YYYY-MM-DDThh:mm:ssZ[GMT]",
                  "YYYY-MM-DDThh:mmZ[GMT]",
                ])
                  .locale(cmsLang)
                  .format("LL")}
              </time>
            )}
        </div>
      </article>
      <div
        className={classNames(
          "wrapper-medium",
          Object.keys(resource).length === 0 && "loading-skeleton"
        )}
      >
        {resource?.attachments?.filter((attachment) => attachment.type === "CMDownload")?.length >
          0 && (
          <Documents
            documents={resource.attachments.filter(
              (attachment) => attachment.type === "CMDownload"
            )}
            resourceId={resource?.id}
          />
        )}
        {resource?.attachments?.filter((attachment) => attachment.type === "CMExternalLink")
          ?.length > 0 && (
          <Documents
            documents={resource.attachments.filter(
              (attachment) => attachment.type === "CMExternalLink"
            )}
            resourceId={resource?.id}
            externalLinks={true}
          />
        )}
      </div>
    </div>
  );
};

export default ResourceDetail;
