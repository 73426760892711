import ConditionalLink from "./conditional-link";
import imagePh from "../images/temp/HRService.png";
import { useParams } from "react-router-dom";
import Image from "./image";
import { useMemo } from "react";
import Translate from "./translate";
import { getHRContentPath } from "../utils/internal-links";

const ServiceCard = ({ card, loading, labels, isDragging }) => {
  const params = useParams();

  const serviceCat = params?.serviceCat;

  const category = useMemo(
    () => serviceCat?.toUpperCase() || card?.categoryString?.[0],
    [serviceCat, card]
  );
  const serviceUrl = getHRContentPath({
    id: card?.masterID,
    newsType: card?.newsType,
    regionHub: card?.regionHub,
    businessHub: card?.businessHub,
  });

  return (
    <div className="service-list__card">
      <ConditionalLink
        disabled={loading || isDragging}
        to={serviceUrl}
        className="service-list__title service-list__img-wrapper loading-highlighted"
        state={{ forcedCategory: serviceCat?.toUpperCase() }}
      >
        {!loading && (
          <Image
            cropUrlTemplate={card?.coverImage?.cropUrlTemplate || imagePh}
            crops={[
              {
                crop: "landscape_ratio16x9",
                sizes: [
                  {
                    maxWidth: "30vw",
                  },
                ],
              },
            ]}
            className="service-list__img"
            alt={card?.title}
          />
        )}
      </ConditionalLink>
      <div className="service-list__info">
        <div className="service-list__category loading-highlighted">
          {category
            ? labels?.[category] || category?.[0]?.toUpperCase() + category?.slice(1)?.toLowerCase()
            : ""}
        </div>
        <ConditionalLink
          disabled={loading || isDragging}
          to={serviceUrl}
          className="service-list__title title-h5 loading-highlighted"
        >
          <Translate text={card?.title} />
        </ConditionalLink>
      </div>
    </div>
  );
};

export default ServiceCard;
