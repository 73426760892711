import { Link } from "react-router-dom";
import Icon from "./icon.jsx";
import logo from "../images/OneEssilorLuxotticaLogo.svg";

const Footer = ({ footerMenu, supportPage }) => {
  return (
    <footer className="footer">
      <div className="wrapper">
        <div className="footer__links-wrapper">
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img src={logo} alt="OneEssilorLuxottica" />
          </Link>
          {footerMenu?.length > 0 && (
            <ul className="footer__links">
              {footerMenu.map((page) => (
                <li key={page.link}>
                  <Link to={page.link} className="footer__link">
                    <span>{page.label}</span>
                    <Icon name="arrow" />
                  </Link>
                </li>
              ))}
              {supportPage?.segment && (
                <li>
                  <Link to={supportPage.segment} className="footer__link">
                    <span>{supportPage?.label}</span>
                    <Icon name="arrow" />
                  </Link>
                </li>
              )}
            </ul>
          )}
        </div>
        <div className="footer__social">
          <a
            href="https://www.facebook.com/EssilorLuxottica/"
            target="_blank"
            rel="noreferrer"
            aria-label="Facebook"
          >
            <Icon name="facebook" />
          </a>
          <a
            href="https://twitter.com/EssiLux"
            target="_blank"
            rel="noreferrer"
            aria-label="Twitter"
          >
            <Icon name="twitter" />
          </a>
          <a
            href="https://www.instagram.com/essilorluxottica/"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
          >
            <Icon name="instagram" />
          </a>
          <a
            href="https://www.linkedin.com/company/essilorluxottica/"
            target="_blank"
            rel="noreferrer"
            aria-label="Linkedin"
          >
            <Icon name="linkedin" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
