import Translate from "../../translate";

const WidgetTitle = ({ title, arrowsRef }) => {
  //console.log(title, arrowsRef);
  return (
    <div className="widget__title-container">
      {title && (
        <div className="widget__title loading-highlighted">
          <h2>
            <Translate text={title} />
          </h2>
        </div>
      )}
      <span ref={arrowsRef} className="widget__arrow-portal" />
    </div>
  );
};

export default WidgetTitle;
