import classNames from "classnames";
import Photogallery from "../photogallery";
import Translate from "../translate";

const PhotogalleryWidget = ({ title, data }) => {
  return (
    !!data?.length && (
      <section className={classNames("widget photogallery-widget", !title && "no-title")}>
        {title && (
          <h2 className="widget__title loading-highlighted">
            <Translate text={title} />
          </h2>
        )}
        <div className="photogallery-widget__content">
          <Photogallery
            photogallery={data?.filter((item) => item?.coverImage)?.map((item) => item.coverImage)}
          />
        </div>
      </section>
    )
  );
};

export default PhotogalleryWidget;
