export const addFavouriteAppsLinksFlags = (items, favLinks) => {
  return items?.map((item) => ({
    ...item,
    favourite: favLinks?.some((link) => `${link.masterID}` === item.masterID),
  }));
};

export const addFavouriteContactsFlags = (contacts, favourites) => {
  return contacts.map((item) => ({
    ...item,
    favourite: favourites?.some((user) => `${user.username}` === item.username),
  }));
};

export const addFavouriteCommunitiesFlags = (communities, favourites) => {
  return communities.map((item) => ({
    ...item,
    bookmarked: favourites?.some((community) => community.id === item.id),
  }));
};

export const addFavouriteHubsFlags = (hubs, favourites) => {
  return hubs.map((item) => ({
    ...item,
    bookmarked: favourites?.some((hubs) => hubs.masterID === item.masterID),
  }));
};
