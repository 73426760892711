import dayjs from "dayjs";
import { useContext } from "react";
import LanguageContext from "../../../context/language";
import ConditionalLink from "../../conditional-link";
import Interaction from "../../interactions";
import Translate from "../../translate";

const SideNewsCard = ({ news, loading }) => {
  const { labels, cmsLang } = useContext(LanguageContext);

  const detailLink = news?.masterID
    ? news?.businessHub?.[0]?.externalReference
      ? `/business-hubs/${news.businessHub[0].externalReference}/news-detail/${news.masterID}`
      : news?.regionHub?.[0]?.externalReference
      ? `/regional-hubs/${news.regionHub[0].externalReference}/news-detail/${news.masterID}`
      : `/news/${news.masterID}`
    : "";

  const hubName =
    news?.newsType?.externalReference === "hub-news"
      ? (news?.regionHub?.[0] || news?.businessHub?.[0])?.externalReference
      : null;

  const date = news?.customPublicationDate?.slice(0, -5);

  return (
    <article className="main-news__side">
      <div className="main-news__info">
        <span className="main-news__type loading-highlighted">
          {!!hubName
            ? labels?.[`HUB_${hubName.toUpperCase()}_NEWS`] ||
              `${hubName[0]?.toUpperCase()}${hubName.slice(1)?.toLowerCase()} news`
            : labels?.[news?.newsType?.externalReference?.toUpperCase()] ||
              `${news?.newsType?.externalReference?.[0]?.toUpperCase()}${news?.newsType?.externalReference
                ?.slice(1)
                ?.toLowerCase()}`}
        </span>
        <ConditionalLink disabled={loading} to={detailLink} state={{ navBack: true }}>
          <h3 className="main-news__title title-h5 loading-highlighted">
            <Translate text={news?.title} />
          </h3>
        </ConditionalLink>
        <time
          className="main-news__date loading-highlighted"
          dateTime={date}
          itemProp="datePublished"
        >
          {date && dayjs(date).locale(cmsLang).format("LL")}
        </time>
      </div>
      <Interaction
        newsId={news?.masterID}
        likesCounter={news?.likesCount}
        commentsCounter={news?.commentsCount}
        isLiked={news?.liked}
      />
    </article>
  );
};

export default SideNewsCard;
