import { useContext } from "react";
import LanguageContext from "../context/language.jsx";
import UserContext from "../context/user.jsx";
import LoadingError from "./loading-error.jsx";

const Info = () => {
  const { userProfile } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);

  return userProfile === null ? (
    <LoadingError />
  ) : (
    <section className="profile__section info" aria-label="Profile info">
      <div className="info__content">
        <div>
          <h2>{labels?.CONTACT_INFO}</h2>
          {userProfile?.country && (
            <p>
              <span className="info__label">{labels?.COUNTRYFACET}: </span>
              <span className="info__value">
                {labels?.[`COUNTRY_${userProfile?.country?.toUpperCase()}`]}
              </span>
            </p>
          )}
          {userProfile?.city && (
            <p>
              <span className="info__label">{labels?.CITYFACET}: </span>
              <span className="info__value">{userProfile?.city?.toLowerCase()}</span>
            </p>
          )}
          {userProfile?.address && (
            <p>
              <span className="info__label">{labels?.ADDRESS}: </span>
              <span className="info__value">{userProfile?.address?.toLowerCase()}</span>
            </p>
          )}
          {userProfile?.phoneNumber && (
            <p>
              <span className="info__label">{labels?.PHONE}: </span>
              <span className="info__value">{userProfile?.phoneNumber?.toLowerCase()}</span>
            </p>
          )}
          {userProfile?.mail && (
            <p className="text-transform-initial">
              <span className="info__label">{labels?.MAIL}: </span>
              <span className="info__value">{userProfile?.mail?.toLowerCase()}</span>
            </p>
          )}
        </div>
        {(userProfile?.zbusTypeT || userProfile?.macFunT || userProfile?.zbeFuncT) && (
          <div>
            <h2>{labels?.BUSINESS_INFO}</h2>
            {userProfile?.zbusTypeT && (
              <p>
                <span className="info__label">{labels?.ZBUSTYPETFACET}: </span>
                <span className="info__value">{userProfile?.zbusTypeT?.toLowerCase()}</span>
              </p>
            )}
            {userProfile?.macFunT && (
              <p>
                <span className="info__label">{labels?.MACFUNTFACET}: </span>
                <span className="info__value">{userProfile?.macFunT?.toLowerCase()}</span>
              </p>
            )}
            {userProfile?.zbeFuncT && (
              <p>
                <span className="info__label">{labels?.FUNCTIONFACET}: </span>
                <span className="info__value">{userProfile?.zbeFuncT?.toLowerCase()}</span>
              </p>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default Info;
