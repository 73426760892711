import useFilters from "../../hooks/useFilters";
import LoadingError from "../loading-error";
import useTealiumOnPageLoad from "../../utils/tealium";
import FilterList from "../../components/filters/filter-list";
import ResourcesCardWrapper from "../../components/resource-card-wrapper";
import useBreakpoint from "../../hooks/useBreakpoint";
import { useContext } from "react";
import LanguageContext from "../../context/language";
import usePolicies from "../../hooks/queries/usePolicies";
import useUpdateFavoritePolicies from "../../hooks/mutations/useUpdateFavoritePolicies";

const PAGE_SIZE = 12;

const AllPoliciesProcedures = ({ hub }) => {
  const { cmsLang: locale } = useContext(LanguageContext);

  const isMobile = useBreakpoint(991);
  const [query, setFilterData] = useFilters("policies");

  useTealiumOnPageLoad({ pageType: "Landing", pageSection1: "All Policies & Procedures" });

  const {
    data: policies,
    error,
    isFetching,
    fetchNextPage,
  } = usePolicies({
    hub,
    query,
    pageSize: PAGE_SIZE,
  });

  const { mutateAsync: updateFav } = useUpdateFavoritePolicies({
    // Invalidate all policies cache except current, on which optimistic update will be performed
    doInvalidateAllPolicies: false,
    doOptimisticUpdateAllPolicies: true,
    // Invalidate my policies cache
    doInvalidateMyPolicies: true,
    currentQueryKey: ["policies-all", PAGE_SIZE, locale, query, hub],
  });

  const handleUpdateFav = async ({ id, isDelete }) => {
    await updateFav({ id, isDelete });
  };

  return error ? (
    <LoadingError />
  ) : (
    <FilterList
      items={policies.data}
      query={query}
      totalCounter={policies?.resultsAvailableCount}
      setFilterData={setFilterData}
      facetView={policies?.facetView}
      resourceType="policies"
      filterColumnOpen={!isMobile}
      openedFiltersByDefault={["categoryFacet"]}
    >
      <ResourcesCardWrapper
        resources={policies}
        loadMore={fetchNextPage}
        isLoading={isFetching}
        onUpdate={handleUpdateFav}
        policies={true}
      />
    </FilterList>
  );
};

export default AllPoliciesProcedures;
