const sortOnField = (a, b, fun, dir, fallback) => {
  return fun(a) < fun(b)
    ? dir
      ? -1
      : 1
    : fun(a) > fun(b)
    ? dir
      ? 1
      : -1
    : fallback
    ? sortOnField(a, b, fun, true)
    : 0;
};

export default sortOnField;
