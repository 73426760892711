import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import UserContext from "../../context/user";
import LanguageContext from "../../context/language";

const useHubServices = ({ hub, category, placeholderData }) => {
  const { isPreview, callApi } = useContext(UserContext);
  const { cmsLang: locale } = useContext(LanguageContext);

  return useQuery(
    ["hr-contents", hub?.tag, locale, isPreview, ...(category ? [category] : [])],
    () =>
      callApi(
        `/resource/hr?` +
          new URLSearchParams({
            hubContext: hub.tag,
            ...(category ? { category } : {}),
            locale,
          })
      ),
    {
      enabled: !!hub?.tag && !!locale,
      ...(placeholderData ? { placeholderData } : {}),
      select: (data) => data?.data,
      onError: (error) => console.error("Error loading hr contents", error),
    }
  );
};

export default useHubServices;
