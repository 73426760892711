import { useCallback, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import FilterList from "../../components/filters/filter-list";
import ContactsCardWrapper from "../../components/contacts-card-wrapper";
import LoadingError from "../loading-error";
import useFilters, { searchBodyFromQuery, filterFacetView } from "../../hooks/useFilters";
import { useFavouriteContacts } from "../../context/favourites";
import UserContext from "../../context/user";
import placeholders from "../../utils/placeholders";
import { addFavouriteContactsFlags } from "../../utils/addFavouriteFlags";
import useBreakpoint from "../../hooks/useBreakpoint";

const pageSize = 12;

const SectionAllContacts = () => {
  const { callApi } = useContext(UserContext);
  const [favouriteContacts, updateFav, favError] = useFavouriteContacts();
  const [contacts, setContacts] = useState({
    userDtoList: placeholders(3, "username"),
    init: true,
  });
  const [error, setError] = useState();
  const [query, setFilterData] = useFilters("contact");
  const [page, setPage] = useState(0);
  const loadMore = useCallback(() => setPage((current) => current + 1), []);
  const currentPage = contacts?.page || 0;
  const loadedPage = useRef();
  const isMobile = useBreakpoint(991);

  useEffect(() => {
    setContacts((current) => {
      return {
        ...current,
        userDtoList: current?.userDtoList
          ? addFavouriteContactsFlags(current.userDtoList, favouriteContacts)
          : current?.userDtoList,
        init: false,
      };
    });
  }, [favouriteContacts]);

  useEffect(() => {
    const loadFilteredContacts = async () => {
      const pageNumber = page === currentPage ? 0 : page;
      const params = new URLSearchParams(Object.entries({ pageNumber, pageSize }));
      try {
        loadedPage.current = JSON.stringify(query);
        const { orderBy, ...otherQuery } = query;
        const allData = await callApi(`/resource/contacts?${params.toString()}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            searchBodyFromQuery(
              {
                orderBy: orderBy
                  ? orderBy === "MOST_RELEVANT"
                    ? ""
                    : orderBy
                  : query?.term
                  ? ""
                  : "SURNAME_AZ",
                ...otherQuery,
              },
              "contact"
            )
          ),
        });
        setPage(pageNumber);
        setContacts((current) => ({
          ...current,
          ...allData,
          facetView: filterFacetView(allData.facetView),
          userDtoList:
            page > currentPage
              ? current.userDtoList.concat(
                  addFavouriteContactsFlags(allData.userDtoList, favouriteContacts)
                )
              : addFavouriteContactsFlags(allData.userDtoList, favouriteContacts),
          page: pageNumber,
          init: false,
        }));
      } catch (err) {
        loadedPage.current = false;
        console.error(`Error loading contacts`, err);
        setError(true);
      }
    };
    if (favouriteContacts && (page > currentPage || JSON.stringify(query) !== loadedPage.current)) {
      loadFilteredContacts();
    }
  }, [favouriteContacts, query, page, currentPage, callApi]);

  return error || favError ? (
    <LoadingError />
  ) : (
    <div className={classNames(contacts.init && "loading-skeleton")}>
      <FilterList
        items={contacts?.userDtoList}
        query={query}
        totalCounter={contacts?.paginationInfo?.total}
        setFilterData={setFilterData}
        facetView={contacts?.facetView}
        resourceType="contact"
        filterColumnOpen={!isMobile}
      >
        <ContactsCardWrapper
          contacts={contacts.userDtoList}
          pagination={contacts?.paginationInfo}
          loadMore={loadMore}
          viewAll={false}
          updateFav={updateFav}
          search={true}
        />
      </FilterList>
    </div>
  );
};

export default SectionAllContacts;
