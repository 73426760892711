import { useContext, useEffect, useState, useRef, Suspense, lazy } from "react";
import classNames from "classnames";
import Image from "./image";
import Translate from "./translate";
import Modal from "./modal";
import LanguageContext from "../context/language";
import Icon from "./icon";
import ArrowBtn from "./hub-widgets/elements/arrow-btn";
import useBlockScroll from "../hooks/useBlockScroll";

const Slider = lazy(() => import("react-slick"));

const maxSlideNumber = 12;

const ArrowBtnPhoto = ({ slideCount, currentSlide, ...props }) => (
  <button type="button" aria-label="Arrow" {...props}>
    <Icon name="chevron" />
  </button>
);

const Photogallery = ({ photogallery }) => {
  const { labels } = useContext(LanguageContext);
  const slicedPhotogallery = photogallery?.slice(0, maxSlideNumber);
  const [detailModalVisible, setDetailModalVisible] = useState(false);

  const [slider1, setSlider1] = useState();
  const [slider2, setSlider2] = useState();
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const ref = useRef();

  useBlockScroll(detailModalVisible, true);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
    if (slider1 && detailModalVisible) {
      slider1.slickGoTo(detailModalVisible);
    }
    if (slider2 && detailModalVisible) {
      slider2.slickGoTo(detailModalVisible);
    }
  }, [slider1, slider2, detailModalVisible]);

  const settings = {
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          infinite: slicedPhotogallery?.length > 2,
          variableWidth: true,
          slidesToScroll: 1,
          prevArrow: <ArrowBtnPhoto />,
          nextArrow: <ArrowBtnPhoto />,
          dots: true,
          centerMode: true,
          centerPadding: "60px",
          customPaging: (i) => {
            return <button id={i} aria-label={`Go to slide ${i}`}></button>;
          },
        },
      },
      {
        breakpoint: 767,
        settings: {
          infinite: false,
          variableWidth: true,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          centerMode: true,
          centerPadding: "0",
          customPaging: (i) => {
            return <button id={i} aria-label={`Go to slide ${i}`}></button>;
          },
        },
      },
    ],
  };

  const settingsModal = {
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: <ArrowBtnPhoto />,
          nextArrow: <ArrowBtnPhoto />,
          afterChange: (current) => setDetailModalVisible(current),
          customPaging: (i) => {
            return <button id={i} aria-label={`Go to slide ${i}`}></button>;
          },
        },
      },
      {
        breakpoint: 767,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: <ArrowBtn containerRef={ref} />,
          nextArrow: <ArrowBtn containerRef={ref} />,
          afterChange: (current) => setDetailModalVisible(current),
          customPaging: (i) => {
            return <button id={i} aria-label={`Go to slide ${i}`}></button>;
          },
        },
      },
    ],
  };

  if (slicedPhotogallery?.length > 0) {
    return (
      <section
        className={classNames(
          "section photogallery",
          slicedPhotogallery.length === 1 && "photogallery--single-image"
        )}
      >
        <Suspense>
          <Slider {...settings} asNavFor={nav2} ref={(slider) => setSlider1(slider)}>
            {slicedPhotogallery.map(
              (image, i) =>
                image && (
                  <button
                    className="photogallery__slide"
                    key={i}
                    onClick={() => {
                      setDetailModalVisible(i);
                    }}
                  >
                    <div className="photogallery__img">
                      <Image
                        key={i}
                        cropUrlTemplate={image.cropUrlTemplate}
                        alt={image?.title || ""}
                        crops={[
                          {
                            crop: "landscape_ratio16x9",
                            sizes: [
                              {
                                breakpoint: 768,
                                maxWidth: "100vw",
                              },
                              {
                                breakpoint: 1230,
                                maxWidth: "70vw",
                              },
                              {
                                maxWidth: "720px",
                              },
                            ],
                          },
                        ]}
                      />
                    </div>
                    <div className="photogallery__text">
                      <div>{i < 9 ? `0${i + 1}` : i + 1}</div>
                      <div className="title-h5">
                        <Translate text={image?.title} />
                      </div>
                    </div>
                  </button>
                )
            )}
          </Slider>
        </Suspense>
        <Modal
          show={detailModalVisible !== false}
          setShow={setDetailModalVisible}
          className="intranet-modal--slider"
        >
          <Slider
            {...settingsModal}
            asNavFor={nav1}
            ref={(slider) => setSlider2(slider)}
            className={!slicedPhotogallery[detailModalVisible]?.title && "slick-slider--no-title"}
          >
            {slicedPhotogallery.map(
              (image, i) =>
                image && (
                  <div className="photogallery__img" key={i}>
                    <Image
                      cropUrlTemplate={image?.cropUrlTemplate}
                      alt={image?.title || ""}
                      crops={[
                        {
                          crop: "landscape_ratio16x9",
                          sizes: [
                            {
                              maxWidth: "100vw",
                            },
                          ],
                        },
                      ]}
                    />
                  </div>
                )
            )}
          </Slider>
          <div className="photogallery__content" ref={ref}>
            <div className="photogallery__wrapper-info">
              {!!slicedPhotogallery[detailModalVisible]?.title && (
                <div className="photogallery__name">
                  <Translate text={slicedPhotogallery[detailModalVisible]?.title} />
                </div>
              )}
              <div className="photogallery__index">
                {new Intl.NumberFormat("it").format(detailModalVisible + 1)}{" "}
                {labels?.OF?.toLowerCase()}{" "}
                {new Intl.NumberFormat("it").format(slicedPhotogallery.length)}
              </div>
            </div>
          </div>
        </Modal>
      </section>
    );
  } else return null;
};

export default Photogallery;
