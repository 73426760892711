import {
  createContext,
  useMemo,
  useRef,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import defineHubProperties from "../utils/defineHubProperties";
import UserContext from "./user";

const FavouriteContext = createContext({
  getFavourite: () => [],
  getFavouriteContacts: () => [],
  getFavouriteHubs: () => [],
  updateFavAppLink: () => [],
  updateFavContacts: () => [],
  updateFavHubs: () => [],
  updateAppsLinks: () => undefined,
});

export default FavouriteContext;

export const FavouritesProvider = ({ children }) => {
  const { callApi } = useContext(UserContext);
  const loadedFavouriteApps = useRef(false);
  const loadedFavouriteLinks = useRef(false);
  const loadedFavouriteHubs = useRef(false);
  const [apps, setApps] = useState({});
  const [links, setLinks] = useState({});
  const [contacts, setContacts] = useState({});
  const [hubs, setHubs] = useState({});

  const updateFavAppLink = useCallback(
    async (type, locale, limit = 50, reload = true) => {
      const currentCount =
        type === "apps" ? apps?.appLinkDtoList?.length || 0 : links?.appLinkDtoList?.length || 0;
      const appendData = !reload && currentCount < limit && currentCount > 0;
      const favData = await callApi(
        `/profile/favorites/${type}?` +
          new URLSearchParams({
            limit: appendData ? limit - currentCount : limit,
            offset: appendData ? currentCount : 0,
            locale,
          })
      );
      if (type === "apps") {
        loadedFavouriteApps.current = locale;
        setApps((apps) => ({
          appLinkDtoList: appendData
            ? apps.appLinkDtoList.concat(favData.appLinkDtoList)
            : favData.appLinkDtoList,
          limit,
        }));
      } else {
        loadedFavouriteLinks.current = locale;
        setLinks((links) => ({
          appLinkDtoList: appendData
            ? links.appLinkDtoList.concat(favData.appLinkDtoList)
            : favData.appLinkDtoList,
          limit,
        }));
      }
      return favData;
    },
    [callApi, apps, links]
  );

  const updateFavContacts = useCallback(
    async (limit = 100, reload = true) => {
      const currentCount = contacts?.userDtoList?.length || 0;
      const appendData = !reload && currentCount < limit && currentCount > 0;
      const favContacts = await callApi(
        `/profile/favorites/contacts?${new URLSearchParams({
          limit: appendData ? limit - currentCount : limit,
          offset: appendData ? currentCount : 0,
        })}`
      );
      setContacts((contacts) => ({
        userDtoList: appendData
          ? contacts.userDtoList.concat(favContacts.userDtoList)
          : favContacts.userDtoList,
        limit,
      }));
      return favContacts;
    },
    [callApi, contacts]
  );

  const updateFavHubs = useCallback(
    async (locale, limit = 50) => {
      const favHubs = await callApi(
        `/resource/hubs/my?${new URLSearchParams({
          locale: locale,
          limit,
        })}`
      );
      if (hubs?.limits ? limit > hubs.limit : true) {
        loadedFavouriteHubs.current = locale;
        setHubs({
          list: favHubs.map((hub) => defineHubProperties({ ...hub, bookmarked: true })),
          limit,
        });
      }
      return {
        list: favHubs.map((hub) => defineHubProperties({ ...hub, bookmarked: true })),
        limit,
      };
    },
    [callApi, hubs]
  );

  const getFavouriteContacts = useCallback(
    async (limit = 100) => {
      if (contacts?.userDtoList) {
        if (limit > contacts.limit) {
          const contactsData = await updateFavContacts(limit, false);
          return contacts.userDtoList.concat(contactsData.userDtoList);
        }
        return contacts.userDtoList.slice(0, limit);
      } else {
        const contactsData = await updateFavContacts(limit);
        return contactsData.userDtoList.slice(0, limit);
      }
    },
    [contacts, updateFavContacts]
  );

  const getFavouriteHubs = useCallback(
    async (locale, limit = 100) => {
      if (hubs?.list && loadedFavouriteHubs.current === locale) {
        if (limit > hubs.limit) {
          const hubsData = await updateFavHubs(locale, limit);
          return hubsData.list;
        }
        return hubs.list.slice(0, limit);
      } else {
        const hubsData = await updateFavHubs(locale, limit);
        return hubsData.list.slice(0, limit);
      }
    },
    [hubs, updateFavHubs]
  );

  const getFavourite = useCallback(
    async (type, locale, limit = 50) => {
      if (type === "apps" && apps?.appLinkDtoList && loadedFavouriteApps.current === locale) {
        if (limit > apps.limit) {
          const appsLinksData = await updateFavAppLink(type, locale, limit, false);
          return apps.appLinkDtoList.concat(appsLinksData.appLinkDtoList);
        }
        return apps.appLinkDtoList.slice(0, limit);
      } else if (
        type === "links" &&
        links?.appLinkDtoList &&
        loadedFavouriteLinks.current === locale
      ) {
        if (limit > links.limit) {
          const appsLinksData = await updateFavAppLink(type, locale, limit, false);
          return links.appLinkDtoList.concat(appsLinksData.appLinkDtoList);
        }
        return links.appLinkDtoList.slice(0, limit);
      } else {
        const appsLinksData = await updateFavAppLink(type, locale, limit);
        return appsLinksData.appLinkDtoList.slice(0, limit);
      }
    },
    [apps, links, updateFavAppLink]
  );

  const updateAppsLinks = useCallback((type, list) => {
    if (type === "apps") {
      setApps((apps) => ({
        appLinkDtoList: list,
        limit: apps.limit,
      }));
    } else {
      setLinks((links) => ({
        appLinkDtoList: list,
        limit: links.limit,
      }));
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      getFavourite,
      getFavouriteContacts,
      getFavouriteHubs,
      updateFavAppLink,
      updateFavContacts,
      updateFavHubs,
      updateAppsLinks,
    }),
    [
      getFavourite,
      getFavouriteContacts,
      getFavouriteHubs,
      updateFavAppLink,
      updateFavContacts,
      updateFavHubs,
      updateAppsLinks,
    ]
  );

  return <FavouriteContext.Provider value={contextValue}>{children}</FavouriteContext.Provider>;
};

export const useFavouriteContacts = () => {
  const { getFavouriteContacts, updateFavContacts } = useContext(FavouriteContext);
  const [favouriteContacts, setFavouriteContacts] = useState();
  const loadedFavourites = useRef();
  const [error, setError] = useState();

  useEffect(() => {
    if (!loadedFavourites.current) {
      loadedFavourites.current = true;
      const loadFavorites = async () => {
        setError(false);
        try {
          const contacts = await getFavouriteContacts(100);
          setFavouriteContacts(contacts);
        } catch (err) {
          console.error("Err loading all favourite contacts: ", err);
          loadedFavourites.current = false;
          setError(true);
        }
      };
      loadFavorites();
    }
  }, [getFavouriteContacts]);

  const updateFav = useCallback(async () => {
    try {
      const favContacts = await updateFavContacts(100, true);
      setFavouriteContacts(favContacts.userDtoList);
    } catch (err) {
      console.error("Error updating favourites: ", err);
    }
  }, [updateFavContacts]);

  return [favouriteContacts, updateFav, error];
};
