import React, { useCallback, useContext, Fragment } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import dayjs from "dayjs";
import Icon from "../icon";
import LanguageContext from "../../context/language";
import { getFacetLabel } from "../../hooks/useFilters";
import useBreakpoint from "../../hooks/useBreakpoint";

const ConditionalSwiper = ({ children, breakpoint, ...options }) => {
  const showSwiper = useBreakpoint(breakpoint);

  return showSwiper ? (
    <Swiper {...options}>
      {React.Children.map(
        children,
        (child, i) => child && <SwiperSlide key={i}>{child}</SwiperSlide>
      ).filter(Boolean)}
    </Swiper>
  ) : (
    <div className="swiper-disabled">{children}</div>
  );
};

const FilterSummary = ({
  term,
  dateFrom,
  dateTo,
  booleans,
  setFilterData,
  resetFilters,
  hideTextSearch,
  resourceType,
}) => {
  const { labels } = useContext(LanguageContext);
  // const [isDragging, setIsDragging] = useState();

  const removeTerm = useCallback(() => {
    setFilterData((current) => {
      const newFilterData = { ...current };
      delete newFilterData.term;
      return newFilterData;
    });
  }, [setFilterData]);

  const removeDate = useCallback(
    (e) => {
      const { name } = e.currentTarget;
      setFilterData((current) => {
        const newFilterData = { ...current };
        delete newFilterData[name];
        return newFilterData;
      });
    },
    [setFilterData]
  );

  const removeFromFilters = useCallback(
    (e) => {
      const [aggr, filter] = e.currentTarget.name.split(":");
      const value = filter.replace("country_", "");
      setFilterData((current) => {
        /* Value === "true" => flag filter */
        const updatedBooleans = Object.fromEntries(
          value === "true"
            ? Object.entries(current.booleans)?.filter(([aggregation]) => aggr !== aggregation)
            : Object.entries(current.booleans)?.map(([aggregation, values]) => {
                return [
                  aggregation,
                  values?.includes(value) ? values?.filter((x) => x !== value) : values,
                ];
              })
        );
        return {
          ...current,
          booleans: updatedBooleans,
        };
      });
    },
    [setFilterData]
  );

  return (
    <div className="filter-summary">
      <div className="filter-summary__chips">
        <ConditionalSwiper
          spaceBetween={0}
          slidesPerView="auto"
          centeredSlides={false}
          loop={false}
          breakpoint={991}
        >
          {term && !hideTextSearch ? (
            <button
              className="filter-summary__chip"
              name={term}
              onClick={removeTerm}
              // disabled={isDragging}
            >
              <span>"{term}"</span>
              <span className="filter-summary__icon-wrapper">
                <Icon name="delete" />
              </span>
            </button>
          ) : null}
          {dateFrom && (
            <button
              className="filter-summary__chip"
              name="dateFrom"
              onClick={removeDate}
              // disabled={isDragging}
            >
              <span>
                {labels?.FROM}: {dayjs(dateFrom).format("DD/MM/YYYY")}
              </span>
              <span className="filter-summary__icon-wrapper">
                <Icon name="delete" />
              </span>
            </button>
          )}
          {dateTo && (
            <button
              className="filter-summary__chip"
              name="dateTo"
              onClick={removeDate}
              // disabled={isDragging}
            >
              <span>
                {labels?.TO}: {dayjs(dateTo).format("DD/MM/YYYY")}
              </span>
              <span className="filter-summary__icon-wrapper">
                <Icon name="delete" />
              </span>
            </button>
          )}
          {Object.entries(booleans)?.map(([aggregation, arr]) =>
            arr?.map((filter) => (
              <Fragment key={filter}>
                <button
                  className="filter-summary__chip"
                  name={`${aggregation}:${filter}`}
                  onClick={removeFromFilters}
                  // disabled={isDragging}
                >
                  <span>{getFacetLabel(aggregation, filter, resourceType, labels)}</span>
                  <span className="filter-summary__icon-wrapper">
                    <Icon name="delete" />
                  </span>
                </button>
              </Fragment>
            ))
          )}
          <button
            className="filter-summary__reset"
            onClick={resetFilters}
            // disabled={isDragging}
          >
            <span>{labels?.RESET_FILTERS}</span>
            <Icon name="refresh" />
          </button>
        </ConditionalSwiper>
        <button
          className="filter-summary__reset"
          onClick={resetFilters}
          // disabled={isDragging}
        >
          <span>{labels?.RESET_FILTERS}</span>
          <Icon name="refresh" />
        </button>
      </div>
    </div>
  );
};

export default FilterSummary;
