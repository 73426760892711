import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./routes/login.jsx";
import ForgotPassword from "./routes/forgot-password.jsx";
import PrivateRoutes from "./routes/app-routes.jsx";
import CookiePolicy from "./routes/cookie-policy.jsx";
import ScrollToTop from "./utils/scrollToTop.jsx";
import CookieBanner from "./components/cookie-banner.jsx";
import UserContext, { UserProvider } from "./context/user.jsx";
import { LanguageProvider } from "./context/language.jsx";
import { FavouritesProvider } from "./context/favourites.jsx";
import { MSLoginProvider } from "./context/ms-login.jsx";

const AppRoutes = () => {
  const { logged } = useContext(UserContext);
  return (
    // Reset favourites data at login/logout
    <FavouritesProvider key={logged}>
      <MSLoginProvider key={logged}>
        <ScrollToTop>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            {!logged && <Route path="/cookie-policy" element={<CookiePolicy logged={logged} />} />}
            <Route path="/*" element={<PrivateRoutes />} />
          </Routes>
          <CookieBanner />
        </ScrollToTop>
      </MSLoginProvider>
    </FavouritesProvider>
  );
};

function App() {
  return (
    <div className="app">
      <UserProvider>
        <LanguageProvider>
          <AppRoutes />
        </LanguageProvider>
      </UserProvider>
    </div>
  );
}

export default App;
