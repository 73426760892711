import { useContext } from "react";
import FilterList from "../../components/filters/filter-list";
import ResourcesCardWrapper from "../../components/resource-card-wrapper";
import useUpdateFavoritePolicies from "../../hooks/mutations/useUpdateFavoritePolicies";
import LanguageContext from "../../context/language";
import useBreakpoint from "../../hooks/useBreakpoint";
import useFilters from "../../hooks/useFilters";
import useTealiumOnPageLoad from "../../utils/tealium";
import LoadingError from "../loading-error";
import usePolicies from "../../hooks/queries/usePolicies";

const PAGE_SIZE = 12;

const MyPoliciesProcedures = ({ hub }) => {
  const { cmsLang: locale } = useContext(LanguageContext);

  const isMobile = useBreakpoint(991);
  const [query, setFilterData] = useFilters("policies");

  useTealiumOnPageLoad({ pageType: "Landing", pageSection1: "My Policies & Procedures" });

  const {
    data: policies,
    error,
    isFetching,
    fetchNextPage,
  } = usePolicies({
    query,
    my: true,
    pageSize: PAGE_SIZE,
  });

  const { mutateAsync: updateFav } = useUpdateFavoritePolicies({
    // Invalidate all policies cache except current, on which optimistic update will be performed
    doInvalidateAllPolicies: true,
    // Invalidate my policies cache
    doInvalidateMyPolicies: false,
    doOptimisticUpdateMyPolicies: true,
    currentQueryKey: ["policies-my", PAGE_SIZE, locale, query, hub],
  });

  const handleUpdateFav = async ({ id, isDelete }) => {
    await updateFav({ id, isDelete });
  };

  return error ? (
    <LoadingError />
  ) : (
    <FilterList
      items={policies?.data}
      query={query}
      totalCounter={policies?.resultsAvailableCount}
      setFilterData={setFilterData}
      facetView={policies?.facetView}
      resourceType="policies"
      filterColumnOpen={!isMobile}
      hideFiltersEmpty={true}
    >
      <ResourcesCardWrapper
        resources={policies}
        loadMore={fetchNextPage}
        isLoading={isFetching}
        onUpdate={handleUpdateFav}
        favourites={true}
        search={query?.term}
        policies={true}
      />
    </FilterList>
  );
};

export default MyPoliciesProcedures;
