import { useContext } from "react";
import LanguageContext from "../context/language";

const ContactUsFooter = () => {
  const { labels } = useContext(LanguageContext);

  return (
    <section className="support-page__item">
      <div className="support-page__description loading-highlighted">
        {labels?.SUPPORT_PAGE_DESCRIPTION_CONTACT ||
          "If you have questions about the intranet, please contact the Corporate Communications Team."}
      </div>
      <a
        className="button secondary loading-highlighted"
        href="mailto:internal.communications@essilorluxottica.com"
      >
        {labels?.CONTACT_US}
      </a>
    </section>
  );
};

export default ContactUsFooter;
