import { useEffect, useState } from "react";

const useBreakpoint = (breakpoint) => {
  const [isBelow, setIsBelow] = useState(false);
  useEffect(() => {
    const resizeListener = () => {
      const mql = window.matchMedia(`(max-width: ${breakpoint}px)`);
      setIsBelow(mql.matches);
    };
    resizeListener();
    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, [breakpoint]);
  return isBelow;
};

export default useBreakpoint;
