import { useContext, useRef, Suspense, lazy } from "react";
import { useOutletContext } from "react-router-dom";
import classNames from "classnames";
import LanguageContext from "../../context/language";
import ServiceCard from "../service-card";
import ArrowBtn from "./elements/arrow-btn";
import WidgetTitle from "./elements/widget-title";

const Slider = lazy(() => import("react-slick"));

const ServiceList = ({ data, title }) => {
  const { labels } = useContext(LanguageContext);
  const { sideHubMenu } = useOutletContext() || { sideHubMenu: null };
  const ref = useRef();

  const settings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    prevArrow: <ArrowBtn containerRef={ref} />,
    nextArrow: <ArrowBtn containerRef={ref} />,
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 1229 + (sideHubMenu ? 334 : 0),
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 990 + (sideHubMenu ? 344 : 0),
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575 + (sideHubMenu ? 344 : 0),
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: data?.length > 1 ? "16px" : "0",
        },
      },
      sideHubMenu && {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      sideHubMenu && {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: data?.length > 1 ? "16px" : "0",
        },
      },
    ].filter(Boolean),
  };

  return (
    !!data?.length && (
      <section className={classNames("widget service-list", !title && "no-title")}>
        <WidgetTitle title={title} arrowsRef={ref} />
        <div className="service-list__content">
          <Suspense>
            <Slider
              {...settings}
              className={classNames("widget__slider", `widget__slider--${data?.length}-slides`)}
            >
              {data?.slice(0, 4)?.map((card, i) => (
                <ServiceCard card={card} key={i} labels={labels} />
              ))}
            </Slider>
          </Suspense>
        </div>
      </section>
    )
  );
};

export default ServiceList;
