import { useMemo, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import LanguageContext from "../context/language";
import Tabs from "../components/tabs";
import MyPoliciesProcedures from "./policy/my-policies-procedures";
import AllPoliciesProcedures from "./policy/all-policies-procedures";

const PoliciesProcedures = ({ page: pageContent }) => {
  const { labels } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();
  const section = searchParams.get("section");

  const tabs = useMemo(
    () => [
      {
        link: "?section=myPoliciesProcedures",
        label: labels?.MY_POLICIES_AND_PROCEDURES || "MY POLICIES & PROCEDURES",
      },
      { link: "", label: labels?.ALL_POLICIES_AND_PROCEDURES || "ALL POLICIES & PROCEDURES" },
    ],
    [labels]
  );

  return (
    <main className="page resources hub-page--resources">
      <h2 className="loading-highlighted">{pageContent.label}</h2>
      <Tabs tabs={tabs} />
      {
        {
          myPoliciesProcedures: <MyPoliciesProcedures hub={{ tag: "corporate" }} />,
          allPoliciesProcedures: <AllPoliciesProcedures hub={{ tag: "corporate" }} />,
        }[section || "allPoliciesProcedures"]
      }
    </main>
  );
};

export default PoliciesProcedures;
