import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import LanguageContext from "../../context/language";
import UserContext from "../../context/user";
import { pagePlaceholder } from "../../utils/placeholders";

const useColdpage = ({
  path,
  select = (data) => data.grid.rows,
  placeholderData = pagePlaceholder(),
}) => {
  const { logged, callApi, isPreview } = useContext(UserContext);
  const { cmsLang: locale } = useContext(LanguageContext);

  return useQuery(
    ["coldpage", locale, path, isPreview],
    () =>
      callApi(`/resource/coldpage?${new URLSearchParams({ path: `intranet-${locale}/${path}` })}`),
    {
      enabled: logged && !!locale && !!path,
      placeholderData,
      select,
      onError: (error) => console.error("Error loading page", error),
    }
  );
};

export default useColdpage;
