const matchInChildren = (segment, page) => {
  if (page?.children) {
    for (const child of page.children) {
      if (child?.link?.split("/")?.reverse()[0] === segment) {
        return { label: page.label, childLink: child.link };
      }
      const result = matchInChildren(segment, child);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

const getParentFromSegment = (segment, menu) => {
  const parent = menu?.find((page) =>
    page?.link && !page?.children?.length ? null : !!matchInChildren(segment, page)
  );
  if (parent) {
    return matchInChildren(segment, parent);
  }
  const firstLevel = menu?.find((page) => page?.link?.split("/")?.reverse()[0] === segment);
  return { childLink: firstLevel?.link };
};

export default getParentFromSegment;
