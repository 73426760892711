import { useContext, useEffect, useRef } from "react";
import UserContext from "../context/user";
import LanguageContext from "../context/language";
import Cookies from "js-cookie";
import md5 from "md5";

const LOGGING = false;
const COOKIE_NAME = "one-essilor-luxottica";

const acceptedCookies = () => {
  if (Cookies.get(COOKIE_NAME)) {
    try {
      const cookie = JSON.parse(Cookies.get(COOKIE_NAME));
      return cookie?.accepted && cookie?.analytics;
    } catch (err) {
      Cookies.remove(COOKIE_NAME);
      return false;
    }
  }
  return false;
};

const useTealiumOnPageLoad = (
  { notLogged, pageType, pageSection1 = "", pageSection2 = "" },
  isReady = true
) => {
  const pageLoaded = useRef(false);
  const { userProfile } = useContext(UserContext);
  const { cmsLang } = useContext(LanguageContext);

  useEffect(() => {
    /* console.log(
      "notLogged",
      notLogged,
      "acceptedCookies()",
      acceptedCookies(),
      "pageLoaded.current",
      pageLoaded.current,
      "isReady",
      isReady
    ); */
    if ((notLogged ? true : userProfile) && acceptedCookies() && !pageLoaded.current && isReady) {
      pageLoaded.current = true;

      const pageLanguage =
        cmsLang ||
        localStorage?.getItem("lang-interface") ||
        localStorage?.getItem("lang") ||
        navigator?.language ||
        navigator?.userLanguage ||
        "en-us";

      const tealiumOnPageLoadObj = {
        id: "VirtualPage-View",
        Page_Brand: "1LX",
        Page_Environment: window?.tealium_env || "", // QA or PROD
        Page_Language: pageLanguage.split("-")[0].toUpperCase(), // two-letter uppercase	ISO 639-1 Eg. 'EN"
        Page_Country: (pageLanguage.split("-")[1] || "us").toUpperCase(), // paese ISO 2 caratteri uppercase i.e. 'IT', 'US', 'FR' (cp4)
        Page_Type: pageType || "Static", // 'Home' (solo hompage), 'Landing' (pagine con elenco notizie/link) , 'Static' (tutto il resto)
        Page_Section1: pageSection1, // sezione primo livello i.e. 'Home', 'news-detail'  (cg4)
        Page_Section2: pageSection2, // sezione secondo livello (COMMUNITYNAME cg3 o CATEGORY o solo per le pagine del dettaglio news DETAILPAGETITLE cp6)
        User_Id: userProfile?.username ? md5(userProfile.username.toLowerCase()?.trim()) : "", // id dell'utente 'Z9eAeIHPA6O07O9EC6OOCLHP86OG6N1E6MGHCJ1PA6OG6OHOCMQOCJPD0' (uc8)
        User_Country: (userProfile?.country || "")?.toUpperCase(), // user country (uc4)
        User_LoginStatus: userProfile ? "Logged" : "Guest", // user status can be "Logged" or "Guest"
        "1LX_User_WorkLocation": userProfile?.city || "", // uc1
        "1LX_User_BusinessUnit": `${userProfile?.zbusTypeT}:${userProfile?.macFunT}` || "", // uc2
        "1LX_User_BusinessFuntion": userProfile?.zbeFuncT || "", // uc3
        "1LX_User_GroupIdap": userProfile?.geographicalArea || "", // uc6
        "1LX_User_BrandRetail": userProfile?.subArea || "", // uc7
      };
      LOGGING && console.log("OnLoadPage Tealium push");
      LOGGING && console.table(tealiumOnPageLoadObj);
      window.tealium_data2track.push(tealiumOnPageLoadObj);
    }
  }, [userProfile, notLogged, pageSection1, pageSection2, pageType, isReady, cmsLang]);
};

export const tealiumOnEvent = ({ eventType, eventAction, eventLabel, eventScope, eventSource }) => {
  if (acceptedCookies()) {
    const tealiumOnEventObj = {
      id: { appTool: "EditorialApp", article: "EditorialPage", file: "EditorialFile" }[eventType],
      Event_Action: eventAction,
      Event_Label: eventLabel,
      ...(eventScope && { Event_Scope: eventScope }),
      ...(eventSource && { Event_Source: eventSource }),
    };
    LOGGING && console.log("OnEvent Tealium push");
    LOGGING && console.table(tealiumOnEventObj);
    window.tealium_data2track.push(tealiumOnEventObj);
  }
};

export default useTealiumOnPageLoad;
