import { useContext, useState, useCallback, useMemo } from "react";
import classNames from "classnames";
import NewsCardWrapper from "../components/news-card-wrapper.jsx";
import FilterBar from "../components/filters/filter-bar";
import LoadingError from "./loading-error";
import Tabs from "../components/tabs.jsx";
import Icon from "../components/icon.jsx";
import Modal from "../components/modal.jsx";
import NewsConfig from "./home/news-config.jsx";
import useFilters from "../hooks/useFilters.js";
import LanguageContext from "../context/language";
import useTealiumOnPageLoad from "../utils/tealium";
import useMyNews from "../hooks/queries/useMyNews.js";
import useResourceFetch from "../hooks/queries/useResourceFetch.js";

const MyNews = ({ page: contentPage }) => {
  const { labels } = useContext(LanguageContext);
  const [query, setFilterData] = useFilters();
  const { option = "all" } = query;

  useTealiumOnPageLoad({ pageType: "Landing", pageSection1: "My news" });

  const setCurrentTab = useCallback(
    (tab) => setFilterData(() => ({ option: tab })),
    [setFilterData]
  );

  const {
    data: { preferences, allPreferences, aggregationResults, resultsAvailableCount, data: myNews },
    isFetching: isMyNewsFetching,
    error: myNewsError,
    fetchNextPage: fetchNextPageMyNews,
    refetch: fetchMyNews,
  } = useMyNews({
    orderBy: query.orderBy,
  });

  const {
    data: tabNews,
    isFetching: isTabNewsFetching,
    error: tabNewsError,
    fetchNextPage: fetchNextPageTabNews,
  } = useResourceFetch({
    body: {
      hub: {
        hubContext: query.option?.slice(4),
        orderBy: query.orderBy || "NEWEST",
      },
      cat: {
        category: [query.option?.slice(4)],
        orderBy: query.orderBy || "NEWEST",
      },
    }[query.option?.split("-")?.[0]],
    enabled: option !== "all" && !!myNews,
  });

  const newsConfigTabs = useMemo(
    () =>
      [
        {
          name: `all`,
          label: labels?.ALL,
          count: resultsAvailableCount || 0,
        },
      ]
        ?.concat(
          preferences?.hubs?.map((hub) => ({
            name: `hub-${hub}`,
            label: labels?.[`HUB_${hub.toUpperCase()}`] || hub.toUpperCase(),
            count: aggregationResults?.[hub] || 0,
          })) || []
        )
        ?.concat(
          preferences?.topics?.map((cat) => ({
            name: `cat-${cat}`,
            label: labels?.[cat.toUpperCase()] || cat.toUpperCase(),
            count: aggregationResults?.[cat] || 0,
          })) || []
        ),
    [labels, aggregationResults, preferences, resultsAvailableCount]
  );

  const [editNewsVisible, setEditNewsVisible] = useState(false);
  const showEditNews = () => setEditNewsVisible(true);
  const closeEditNews = (update) => {
    setEditNewsVisible(false);
    if (update) {
      setFilterData((current) => ({ ...current, option: "all" }));
      fetchMyNews();
    }
  };

  return myNewsError || tabNewsError ? (
    <LoadingError />
  ) : (
    <main className={classNames("page my-news", isMyNewsFetching && "loading-skeleton")}>
      <h1 className="loading-highlighted">{contentPage?.label}</h1>
      {!preferences?.hubs && !preferences?.topics && preferences ? (
        <div className="news__section">
          <div className="news__card-wrapper">
            <button className="my-news__news-btn cta-link" onClick={showEditNews}>
              {labels?.CUSTOMIZE_NEWS} <Icon name="arrow" />
            </button>
            <div className="my-news__news-placeholder"></div>
            <div className="my-news__news-placeholder"></div>
            <div className="my-news__news-placeholder"></div>
          </div>
        </div>
      ) : myNews ? (
        <>
          <div className="my-news__tabs-wrapper">
            <Tabs
              tabs={newsConfigTabs}
              currentTab={option}
              setCurrentTab={setCurrentTab}
              className="tabs--small"
              count={true}
            />
            <button className="my-news__edit-news cta-link" onClick={showEditNews}>
              {labels?.EDIT_NEWS}
            </button>
          </div>
          <FilterBar
            query={query}
            totalCounter={option === "all" ? resultsAvailableCount : tabNews?.resultsAvailableCount}
            setFilterData={setFilterData}
            resourceType="mynews"
          />
          <NewsCardWrapper
            news={option === "all" ? { data: myNews, resultsAvailableCount } : tabNews}
            loadMore={option === "all" ? fetchNextPageMyNews : fetchNextPageTabNews}
            isLoading={option === "all" ? isMyNewsFetching : isTabNewsFetching}
          />
        </>
      ) : null}
      <Modal
        show={editNewsVisible}
        setShow={setEditNewsVisible}
        className="intranet-modal--full intranet-modal--top"
      >
        <NewsConfig
          preferences={preferences}
          allPreferences={allPreferences || { hubs: [], topics: [] }}
          close={closeEditNews}
        />
      </Modal>
    </main>
  );
};

export default MyNews;
