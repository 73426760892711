import React, { useState, useMemo, useEffect, useRef, Fragment, useContext } from "react";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import Icon from "./icon";
import LanguageContext from "../context/language";

const isActive = (link, params, diffParams, name, currentTab, setCurrentTab) => {
  if (setCurrentTab) {
    return name === currentTab;
  }
  const linkParams = new URL(link, window.location.origin).searchParams;
  for (const key of diffParams) {
    if (params.get(key) !== linkParams.get(key)) {
      return false;
    }
  }
  return true;
};

const Tabs = ({ tabs, className, currentTab, setCurrentTab, count = false, hideEmpty = false }) => {
  const { labels } = useContext(LanguageContext);
  const containerRef = useRef();
  const [showMore, setShowMore] = useState();
  const [isShowMore, setIsShowMore] = useState(false);
  const diffParams = useMemo(() => {
    if (!setCurrentTab) {
      const diffByQuery =
        [
          ...new Set(
            tabs.map((tab) => {
              const url = new URL(tab.link, window.location.origin);
              return url.origin + url.pathname;
            })
          ),
        ].length < tabs.length;
      if (diffByQuery) {
        const setParams = tabs.reduce((res, tab) => {
          const params = new URL(tab.link, window.location.origin).searchParams;
          res.add(...params.keys());
          return res;
        }, new Set());
        return [...setParams].filter(Boolean);
      }
      return null;
    }
  }, [tabs, setCurrentTab]);

  const [params] = useSearchParams();
  const mappedTabs = useMemo(() => {
    const mapped =
      diffParams || setCurrentTab
        ? tabs.map((tab) => ({
            ...tab,
            active: isActive(tab.link, params, diffParams, tab.name, currentTab, setCurrentTab),
          }))
        : tabs;
    return count && hideEmpty ? mapped.filter((tab) => tab.count !== 0) : mapped;
  }, [tabs, params, diffParams, currentTab, setCurrentTab, count, hideEmpty]);

  useEffect(() => {
    if (containerRef.current) {
      const computeContainerHeight = () => {
        const h = containerRef.current.parentElement.clientHeight;
        const offset = containerRef.current.clientHeight > h;
        setShowMore(offset);
      };

      computeContainerHeight();
      window.addEventListener("resize", computeContainerHeight);
      return () => {
        window.removeEventListener("resize", computeContainerHeight);
      };
    }
  }, []);

  return (
    <div className={classNames("tabs", className)} role="navigation">
      <div className={classNames("tabs__wrapper", isShowMore && "tabs__wrapper--show-more")}>
        <div className="tabs__container" ref={containerRef}>
          {mappedTabs.map((tab) => (
            <Fragment key={tab.link ? tab.link : tab.link === "" ? "" : tab.name}>
              {setCurrentTab ? (
                <button
                  name={tab.name}
                  className={classNames("tabs__tab button tertiary small", {
                    active: tab.active,
                  })}
                  onClick={(e) => setCurrentTab(e.currentTarget.name)}
                  disabled={tab.count === 0}
                >
                  <span className="tabs__tab-label">{tab.label}</span>
                  {count && (
                    <span
                      className={classNames(
                        "tabs__tab-count",
                        isNaN(tab.count) && "tabs__tab-count--nan"
                      )}
                    >
                      {!isNaN(tab.count) ? tab.count : ""}
                    </span>
                  )}
                </button>
              ) : diffParams ? (
                <Link
                  to={tab.link}
                  className={classNames("tabs__tab button tertiary small", {
                    active: tab.active,
                  })}
                >
                  <span className="tabs__tab-label">{tab.label}</span>
                  {count && (
                    <span
                      className={classNames(
                        "tabs__tab-count",
                        isNaN(tab.count) && "tabs__tab-count--nan"
                      )}
                    >
                      {!isNaN(tab.count) ? tab.count : ""}
                    </span>
                  )}
                </Link>
              ) : (
                <NavLink to={tab.link} end={!!tab.end} className="tabs__tab button tertiary small">
                  <span className="tabs__tab-label">{tab.label}</span>
                  {count && (
                    <span
                      className={classNames(
                        "tabs__tab-count",
                        isNaN(tab.count) && "tabs__tab-count--nan"
                      )}
                    >
                      {!isNaN(tab.count) ? tab.count : ""}
                    </span>
                  )}
                </NavLink>
              )}
            </Fragment>
          ))}
        </div>
      </div>
      {showMore && (
        <button
          className={classNames("tabs__cta", isShowMore && "tabs__cta--open")}
          onClick={() => setIsShowMore((current) => !current)}
        >
          <span>{isShowMore ? labels?.SHOW_LESS : labels?.SHOW_MORE}</span>
          <Icon name="chevron" />
        </button>
      )}
    </div>
  );
};

export default Tabs;
