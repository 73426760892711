import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserContext from "../context/user";
import { getResourceLinkViewUrl } from "../utils/documents";
import { getCMChannelPath, getCMINContentPath } from "../utils/internal-links";
import LoadingError from "./loading-error";

const InternalLink = () => {
  const { callApi } = useContext(UserContext);
  const { contentId } = useParams();
  const loadedContent = useRef();
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (loadedContent.current !== contentId) {
      loadedContent.current = contentId;
      const loadFooter = async () => {
        try {
          const result = await callApi(`/resource/custom/${contentId}`);
          let destination = null;
          if (result?.type && result?.id) {
            switch (result.type) {
              case "CMDownload":
                window.open(getResourceLinkViewUrl(result.id), "_blank");
                navigate(-1);
                break;
              case "CMINContent":
                destination = getCMINContentPath(result);
                break;
              case "CMChannel":
                destination = getCMChannelPath(result);
                break;
              default:
                setError(true);
            }
            if (destination) {
              navigate(destination, { replace: true });
            } else {
              setError(true);
            }
          } else {
            setError(true);
          }
        } catch (err) {
          console.error("Error loading footer menu: ", err);
          loadedContent.current = null;
        }
      };
      loadFooter();
    }
  }, [callApi, contentId, navigate]);

  return error ? (
    <LoadingError />
  ) : (
    <div key={contentId} className="loading-spinner internal-link" />
  );
};

export default InternalLink;
