const getPageName = (page, labels, hub = false) => {
  const label =
    labels?.[`MENU_${hub ? `HUB_${hub.toUpperCase()}_` : ""}${page?.segment?.toUpperCase()}`];

  /* 
  Menu language handling
    if label is present -> label
    fallback -> CMS language
  */
  return label || page?.title;
};

export default getPageName;
