import { useCallback, useEffect, useRef } from "react";

const useDelayedCall = (asyncCall, condition) => {
  const pending = useRef([]);

  const delayedCall = useCallback(
    async (...args) => {
      if (condition) {
        return asyncCall(...args);
      } else {
        const pendingPromise = new Promise((resolve, reject) => {
          pending.current.push((updatedAsyncCall) =>
            updatedAsyncCall(...args)
              .then(resolve)
              .catch(reject)
          );
        });
        return pendingPromise;
      }
    },
    [asyncCall, condition]
  );

  useEffect(() => {
    if (condition && pending.current.length) {
      while (pending.current.length) {
        const firstPending = pending.current.shift();
        firstPending(asyncCall);
      }
    }
  }, [asyncCall, condition]);

  return delayedCall;
};

export default useDelayedCall;
