import { useEffect } from "react";

const useBlockScroll = (block, mobileOnly) => {
  useEffect(() => {
    if (block) {
      const scrollbarWidth = window.innerWidth - document.body.clientWidth;
      if (scrollbarWidth > 0) {
        document.body.style.paddingRight = `${scrollbarWidth}px`;
      }
      document.body.classList.add("block-scroll");
      if (mobileOnly) {
        document.body.classList.add("mobile-only");
      } else {
        document.body.classList.remove("mobile-only");
      }
      return () => {
        if (scrollbarWidth) {
          document.body.style.paddingRight = "";
        }
        document.body.classList.remove("block-scroll");
        if (mobileOnly) {
          document.body.classList.remove("mobile-only");
        }
      };
    }
  }, [block, mobileOnly]);
};

export default useBlockScroll;
