import { useEffect, useState } from "react";

const useReducedMotion = (defaultValue = true) => {
  const [reducedMotion, setReducedMotion] = useState(defaultValue);

  const queryChangeHandler = (event) => {
    setReducedMotion(event.target.matches);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

    if (mediaQuery) {
      setReducedMotion(mediaQuery.matches);
      mediaQuery.addEventListener("change", queryChangeHandler);
      return () => mediaQuery.removeEventListener("change", queryChangeHandler);
    }
  }, []);

  return reducedMotion;
};

export default useReducedMotion;
