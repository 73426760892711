import { useState, useContext, useMemo } from "react";
import classNames from "classnames";
import LanguageContext from "../context/language";
import Icon from "./icon";
import { useTranslate } from "./translate";
import useClickOut from "../hooks/useClickOut";

const TranslationButton = () => {
  const [open, setOpen] = useState(false);
  const { labels, languages, browserLanguage } = useContext(LanguageContext);
  const [translating, handleTranslation, translatedLang] = useTranslate();
  const canTranslate = !translating && browserLanguage !== translatedLang;
  const translateLabel = useMemo(
    () =>
      `${labels?.TRANSLATE_TO} ${languages?.find((lang) => lang.value === browserLanguage)?.label}`,
    [labels, languages, browserLanguage]
  );
  const [ref] = useClickOut(open, setOpen);
  return (
    <>
      <button
        className="translation-button d-mobile-none"
        disabled={!canTranslate}
        onClick={handleTranslation}
        role="region"
        aria-label={translateLabel}
      >
        <span className="translation-button__text">{translateLabel}</span>
        <span className="translation-button__icon">
          {translating ? <span className="loading-spinner" /> : <Icon name="translation" />}
        </span>
      </button>
      <button
        ref={ref}
        className={classNames("translation-button d-none d-tsmall-inline-flex", {
          open: canTranslate && open,
        })}
        disabled={!canTranslate}
        onClick={open ? handleTranslation : () => setOpen(true)}
        role="region"
        aria-label={translateLabel}
      >
        <span className="translation-button__text">{translateLabel}</span>
        <span className="translation-button__icon">
          {translating ? <span className="loading-spinner" /> : <Icon name="translation" />}
        </span>
      </button>
    </>
  );
};

export default TranslationButton;
