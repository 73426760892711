import { useState, useEffect, useRef, useContext, useMemo, useCallback } from "react";
import LanguageContext from "../context/language";
import useTealiumOnPageLoad from "../utils/tealium";
import Tabs from "../components/tabs";
import { Link, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import LoadingError from "./loading-error";
import placeholders from "../utils/placeholders";
import Error from "../components/error";
import HubCard from "../components/hub-card";
import defineHubProperties from "../utils/defineHubProperties";
import FavouriteContext from "../context/favourites";
import { addFavouriteHubsFlags } from "../utils/addFavouriteFlags";
import Icon from "../components/icon";
import getHubName from "../utils/getHubName";
import useMenu from "../hooks/queries/useMenu";

const scrollToTop = () => window.scrollTo(0, 0);

const sortHubs = (a, b, labels) => {
  const hubAName = getHubName(a, labels, true)?.toUpperCase();
  const hubBName = getHubName(b, labels, true)?.toUpperCase();

  return hubAName > hubBName ? 1 : hubAName < hubBName ? -1 : 0;
};

const FavoriteCtaLink = ({ label }) => {
  return (
    <Link to="?section=business" onClick={scrollToTop} className="cta-link">
      <span>{label}</span>
      <Icon name="arrow" />
    </Link>
  );
};

const SectionHub = ({ hubList, errormsg, labels, loading, updateHub, favorite }) => {
  return (
    <div className="hubs__section">
      {hubList?.length > 0 ? (
        <div className="page__content hubs__content">
          {hubList.map(
            (hub, i) =>
              (hub.target || hub.target === 0) && (
                <HubCard hub={hub} key={i} loading={loading} onRemoveFromFavorites={updateHub} />
              )
          )}
        </div>
      ) : (
        <div className="hubs__error">
          <Error msg={errormsg ? errormsg : labels?.NO_CONTENT} />{" "}
        </div>
      )}
      {favorite && <FavoriteCtaLink label={labels?.EDIT_YOUR_FAVORITE_HUBS || "Edit your hubs"} />}
    </div>
  );
};

const Hubs = ({ page }) => {
  const { labels, cmsLang } = useContext(LanguageContext);
  const loadedFavourites = useRef();
  const { getFavouriteHubs, updateFavHubs } = useContext(FavouriteContext);
  const [favError, setFavError] = useState();
  const [hubList, setHubList] = useState({
    init: true,
    regionalHubs: placeholders(4, "target"),
    businessHubs: placeholders(4, "target"),
    myHubs: placeholders(4, "target"),
  });
  const initFavs = !hubList?.init;

  const [searchParams] = useSearchParams();
  const section = searchParams.get("section");

  const tabs = useMemo(
    () => [
      { link: "", label: labels?.MY_HUBS || "My hubs" },
      {
        link: "?section=business",
        label: labels?.BUSINESS_HUBS || "Business hubs",
      },
      {
        link: "?section=regional",
        label: labels?.REGIONAL_HUBS || "Regional hubs",
      },
    ],
    [labels]
  );

  useTealiumOnPageLoad({
    pageSection1: page?.link?.split("/")?.[1],
    pageSection2: page?.link?.split("/")?.[2],
  });

  const updateHub = useCallback(async () => {
    const updated = await updateFavHubs(cmsLang, 50);
    setHubList((current) => ({
      ...current,
      myHubs: updated.list,
      regionalHubs: addFavouriteHubsFlags(current.regionalHubs, updated.list),
      businessHubs: addFavouriteHubsFlags(current.businessHubs, updated.list),
    }));
  }, [updateFavHubs, cmsLang]);

  useEffect(() => {
    if (cmsLang && loadedFavourites.current !== cmsLang) {
      loadedFavourites.current = cmsLang;
      const loadFavourites = async () => {
        try {
          const favHubs = await getFavouriteHubs(cmsLang, 50);
          setHubList((current) => ({
            ...current,
            init: false,
            myHubs: favHubs,
          }));
        } catch (err) {
          console.error("Error getting favorite hubs: ", err);
          loadedFavourites.current = false;
          setFavError(true);
        }
      };
      loadFavourites();
    }
  }, [cmsLang, getFavouriteHubs]);

  const {
    data: regionalHubs,
    error: regionalHubError,
    isFetching: isRegionalHubsFetching,
  } = useMenu({ path: "regional-hubs" });
  const {
    data: businessHubs,
    error: businessHubError,
    isFetching: isBusinessHubsFetching,
  } = useMenu({ path: "business-hubs" });

  const error = useMemo(
    () => regionalHubError || businessHubError || favError,
    [regionalHubError, businessHubError, favError]
  );

  const loading = useMemo(
    () => isRegionalHubsFetching || isBusinessHubsFetching,
    [isRegionalHubsFetching, isBusinessHubsFetching]
  );

  useEffect(() => {
    if (initFavs && !!regionalHubs && !!businessHubs) {
      setHubList((current) => ({
        ...current,
        regionalHubs: addFavouriteHubsFlags(
          regionalHubs.grid.rows[0].placements[0].items
            .map((hub) => defineHubProperties(hub))
            .filter((hub) => hub.target)
            .sort((a, b) => sortHubs(a, b, labels)),
          current.myHubs
        ),
        businessHubs: addFavouriteHubsFlags(
          businessHubs.grid.rows[0].placements[0].items
            .map((hub) => defineHubProperties(hub))
            .filter((hub) => hub.target)
            .sort((a, b) => sortHubs(a, b, labels)),
          current.myHubs
        ),
      }));
    }
  }, [initFavs, regionalHubs, businessHubs, labels]);

  const hubsTabs = useMemo(() => {
    return tabs.map((section, i) => ({
      ...section,
      count: {
        0: !hubList.init && hubList.myHubs.length,
        1: !hubList.init && hubList.businessHubs.length,
        2: !hubList.init && hubList.regionalHubs.length,
      }[i],
    }));
  }, [hubList, tabs]);

  return error ? (
    <LoadingError />
  ) : (
    <main className={classNames("page hubs", loading && "loading-skeleton")}>
      <h1 className="loading-highlighted">{page?.label || "Hubs"}</h1>
      {hubsTabs && <Tabs tabs={hubsTabs} count={true} />}

      {
        {
          my: (
            <SectionHub
              hubList={hubList.myHubs}
              errormsg={labels?.NO_FAVORITE_HUBS}
              labels={labels}
              loading={loading}
              updateHub={updateHub}
              favorite={true}
            />
          ),
          business: (
            <SectionHub
              hubList={hubList.businessHubs}
              labels={labels}
              loading={loading}
              updateHub={updateHub}
            />
          ),
          regional: (
            <SectionHub
              hubList={hubList.regionalHubs}
              labels={labels}
              loading={loading}
              updateHub={updateHub}
            />
          ),
        }[section || "my"]
      }
    </main>
  );
};

export default Hubs;
