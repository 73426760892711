import classNames from "classnames";
import { useContext } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import LanguageContext from "../context/language";
import NewsCard from "./news-card";
import ServiceCard from "./service-card";

const RelatedNews = ({ related }) => {
  const { labels } = useContext(LanguageContext);

  return related?.length > 0 ? (
    <section className="related-news" aria-label="Related news">
      <h2>{labels?.RELATED_NEWS}</h2>
      <Swiper
        className={classNames("related-news__slider")}
        slidesPerView={related?.length > 1 ? "auto" : 1}
        spaceBetween={22}
        modules={[Pagination]}
        loop={false}
        watchOverflow={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          575: { slidesPerView: 2 },
          768: { slidesPerView: 3 },
          1229: { slidesPerView: 4 },
        }}
      >
        {related.map((item, i) => (
          <SwiperSlide key={i}>
            {item?.newsType?.externalReference === "hr-content" ? (
              <ServiceCard
                card={item}
                key={i}
                //loading={loading}
                labels={labels}
              />
            ) : (
              <NewsCard news={item} key={i} showInteractions={true} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  ) : (
    <div className="loader" />
  );
};

export default RelatedNews;
