import errorLoading from "../images/error-loading.svg";

const LoadingError = () => {
  return (
    <div className="page error-page">
      <div className="error-page__content">
        <div className="error-page__block">
          <img src={errorLoading} alt="Error" className="error-page__image" />
          <div>
            <span className="loading-spinner" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingError;
