import { useContext, useEffect, useMemo, useRef, useState, Suspense, lazy } from "react";
import { useOutletContext } from "react-router-dom";
import classNames from "classnames";
import LanguageContext from "../../context/language";
import UserContext from "../../context/user";
import LoadingError from "../../routes/loading-error";
import placeholders from "../../utils/placeholders";
import ConditionalLink from "../conditional-link";
import NewsCard from "../news-card";
import ArrowBtn from "./elements/arrow-btn";
import MainNewsCard from "./elements/main-news-card";
import SideNewsCard from "./elements/side-news-card";
import WidgetTitle from "./elements/widget-title";

const Slider = lazy(() => import("react-slick"));

const NewsListMain = ({ news, loading, title, labels, newsPage }) => {
  const { sideHubMenu } = useOutletContext() || { sideHubMenu: null };
  const [isDragging, setIsDragging] = useState();
  const ref = useRef();

  const settings = {
    infinite: false,
    slidesToScroll: 1,
    dots: true,
    prevArrow: <ArrowBtn containerRef={ref} />,
    nextArrow: <ArrowBtn containerRef={ref} />,
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 990 + (sideHubMenu ? 344 : 0),
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575 + (sideHubMenu ? 344 : 0),
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: news?.length > 1 ? "16px" : "0",
        },
      },
      sideHubMenu && {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      sideHubMenu && {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: news?.length > 1 ? "16px" : "0",
        },
      },
    ].filter(Boolean),
  };
  return (
    !!news?.length && (
      <section
        className={classNames(
          "widget main-news",
          loading && "loading-skeleton",
          !title && "no-title"
        )}
      >
        <WidgetTitle title={title} arrowsRef={ref} />
        <div className="main-news__content">
          <MainNewsCard
            news={news?.[0]}
            className={news?.length === 1 && "main-news__main--single"}
            loading={loading}
          />
          {news?.length > 1 && (
            <div className="main-news__list">
              {news?.slice(1, 4)?.map((item, i) => (
                <SideNewsCard news={item} key={item?.masterID || i} loading={loading} />
              ))}
            </div>
          )}
        </div>
        <div className="main-news__content main-news__content--mobile">
          <Suspense>
            <Slider
              {...settings}
              className={classNames("widget__slider", `widget__slider--${news?.length}-slides`)}
            >
              {news?.slice(0, 4)?.map((item, i) => (
                <NewsCard
                  news={item}
                  labels={labels}
                  key={item?.masterID || i}
                  isDragging={isDragging}
                  navBack={true}
                />
              ))}
            </Slider>
          </Suspense>
        </div>
        {!loading && newsPage?.link && (
          <ConditionalLink
            to={`/${newsPage?.detailedOrigin}${newsPage?.link}`}
            className="button secondary see-all"
          >
            {labels?.SEE_ALL}
          </ConditionalLink>
        )}
      </section>
    )
  );
};

const NewsListRegular = ({ news, loading, title, labels, newsPage }) => {
  const { sideHubMenu } = useOutletContext() || { sideHubMenu: null };
  const [isDragging, setIsDragging] = useState();
  const ref = useRef();

  const settings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: true,
    prevArrow: <ArrowBtn containerRef={ref} />,
    nextArrow: <ArrowBtn containerRef={ref} />,
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
    responsive: [
      {
        breakpoint: 9999,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 1229 + (sideHubMenu ? 334 : 0),
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 990 + (sideHubMenu ? 344 : 0),
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575 + (sideHubMenu ? 344 : 0),
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: news?.length > 1 ? "16px" : "0",
        },
      },
      sideHubMenu && {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      sideHubMenu && {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: news?.length > 1 ? "16px" : "0",
        },
      },
    ].filter(Boolean),
  };

  return (
    news?.length > 0 && (
      <section className={classNames("widget news-list")}>
        <WidgetTitle title={title} arrowsRef={ref} />
        <div className="news-list__content">
          <Suspense>
            <Slider
              className={classNames(
                "news-list__slider widget__slider",
                `widget__slider--${news?.length}-slides`
              )}
              {...settings}
            >
              {news?.slice(0, 4)?.map((item, i) => (
                <NewsCard
                  news={item}
                  key={i}
                  showInteractions={true}
                  isDragging={isDragging}
                  navBack={true}
                />
              ))}
            </Slider>
          </Suspense>
        </div>
        {!loading && newsPage?.link && (
          <ConditionalLink
            to={`/${newsPage?.detailedOrigin}${newsPage?.link}`}
            className="button secondary see-all"
          >
            {labels?.SEE_ALL}
          </ConditionalLink>
        )}
      </section>
    )
  );
};

const NewsList = ({ data, title, routes, hub, mainTemplate = false }) => {
  const { callApi } = useContext(UserContext);
  const { labels, cmsLang } = useContext(LanguageContext);
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState(placeholders(4));
  const [error, setError] = useState();
  const loadedNews = useRef();

  const newsPage = useMemo(
    () => routes?.find((page) => page?.pageTemplate === "news-hub-search-page"),
    [routes]
  );

  useEffect(() => {
    const locale = cmsLang || "en-us";
    if (!hub) {
      setNews(data);
      setLoading(false);
    }
    if (!data?.[0]?.init && loadedNews.current !== locale && hub?.tag) {
      loadedNews.current = locale;
      const loadNews = async () => {
        try {
          /* Manual CMS news selection (no DQL list, explicitly selected news) */
          let result = { data: data };
          if (data?.[0]?.type !== "CMINContent") {
            /* Default (if no news are selected from CMS): get latest 4 hub news */
            result = await callApi(
              `/resource/news?${new URLSearchParams(
                Object.entries({ pageNumber: 0, pageSize: 4, locale: cmsLang })
              ).toString()}`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json", Accept: "*/*" },
                body: JSON.stringify({ hubContext: hub.tag, orderBy: "NEWEST" }),
              }
            );
          }
          if (result) {
            setNews(result?.data);
          }
        } catch (err) {
          console.error("Error loading hub news from DQL", err);
          setError(err);
        }
        setLoading(false);
      };
      loadNews();
    }
  }, [callApi, cmsLang, data, hub]);

  return error ? (
    <LoadingError />
  ) : mainTemplate ? (
    <NewsListMain news={news} labels={labels} title={title} loading={loading} newsPage={newsPage} />
  ) : (
    <NewsListRegular
      news={news}
      labels={labels}
      title={title}
      loading={loading}
      newsPage={newsPage}
    />
  );
};

export default NewsList;
