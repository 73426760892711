import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import LanguageContext from "../../context/language";
import UserContext from "../../context/user";
import placeholders from "../../utils/placeholders";

const PAGE_SIZE = 12;

const useLeonardoCourses = (tagCMS) => {
  const { language } = useContext(LanguageContext);
  const { callApi } = useContext(UserContext);

  return useQuery({
    queryKey: ["leonardo-courses", language, tagCMS],
    queryFn: () =>
      callApi(
        `/resource/courses?${new URLSearchParams({
          language,
          ...(tagCMS ? { tagCarouselCMS: tagCMS } : {}),
          pageSize: PAGE_SIZE,
        })}`,
        {
          preventLogout: true,
        }
      ),
    enabled: !!language,
    placeholderData: { list: placeholders(3) },
    select: (data) =>
      data?.list?.sort((a, b) => (a?.order > b?.order ? 1 : a?.order < b?.order ? -1 : 0)),
    onError: (error) => console.error("Error loading Leonardo courses", error),
  });
};

export default useLeonardoCourses;
