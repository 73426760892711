import { useCallback, useContext, useEffect, useRef, useState, Suspense, lazy } from "react";
import { Link } from "react-router-dom";
import Icon from "../components/icon";
import AppLinkCard from "../components/app-link-card";
import ContactCard from "../components/contact-card";
import Error from "../components/error";
import LanguageContext from "../context/language";
import FavouriteContext from "../context/favourites";
import placeholders from "../utils/placeholders";
import LoadingError from "./loading-error";
import CommunityCard from "../components/communities/community-card";
import ResourceCard from "../components/resource-card";
import HubCard from "../components/hub-card";
import DeleteCustomLinkModal from "../components/delete-custom-link-modal";
import useMyCommunities from "../hooks/queries/useMyCommunities.js";
import useUpdateFavoriteCommunities from "../hooks/mutations/useUpdateFavoriteCommunities";
import useResources from "../hooks/queries/useResources";
import useUpdateFavoriteResources from "../hooks/mutations/useUpdateFavoriteResources";
import useUpdateFavoritePolicies from "../hooks/mutations/useUpdateFavoritePolicies.js";
import usePolicies from "../hooks/queries/usePolicies.js";

const AddCustomLinkModal = lazy(() => import("../components/add-custom-link-modal"));

const PAGE_SIZE = 12;

const Favorites = ({ backLinks }) => {
  const { labels, cmsLang } = useContext(LanguageContext);
  const {
    getFavourite,
    getFavouriteContacts,
    getFavouriteHubs,
    updateFavAppLink,
    updateFavContacts,
    updateFavHubs,
  } = useContext(FavouriteContext);
  const [favoriteLinks, setFavoriteLinks] = useState(placeholders(6));
  const [favoriteContacts, setFavoriteContacts] = useState(placeholders(3, "username"));
  const [favoriteHubs, setFavoriteHubs] = useState(placeholders(2, "id"));
  const loadedFavorites = useRef(false);
  const [addLinkVisible, setAddLinkVisible] = useState(false);
  const [deleteLinkModalVisible, setDeleteLinkModalVisible] = useState(false);

  const { data: favoriteCommunities } = useMyCommunities({
    query: { booleans: {} },
    pageSize: PAGE_SIZE,
  });

  const { data: favoriteResources } = useResources({
    query: { booleans: {} },
    my: true,
    pageSize: PAGE_SIZE,
  });

  const { data: favoritePolicies } = usePolicies({
    query: { booleans: {} },
    my: true,
    pageSize: PAGE_SIZE,
    hub: {
      tag: "corporate",
    },
  });

  const loadHubs = useCallback(async () => {
    try {
      const updatedHubs = await updateFavHubs(cmsLang, 4);
      setFavoriteHubs(updatedHubs?.list);
    } catch (err) {
      console.error(`Error reloading document favorites`, err);
    }
  }, [cmsLang, updateFavHubs]);

  const loadFavorites = useCallback(
    async ({ category, limit }) => {
      loadedFavorites.current = cmsLang;
      try {
        if (category === "contacts") {
          const contacts = await getFavouriteContacts(limit);
          setFavoriteContacts(contacts.map((item) => ({ ...item, favourite: true })));
        } else if (category === "hubs") {
          const hubs = await getFavouriteHubs(cmsLang, limit);
          setFavoriteHubs(hubs);
        } else if (category === "links") {
          const data = await getFavourite(category, cmsLang, limit);
          setFavoriteLinks(data.map((item) => ({ ...item, favourite: true })));
        }
      } catch (err) {
        console.error(`Error loading favorite ${category}`, err);
        if (category === "contacts") {
          setFavoriteContacts();
        } else if (category === "hubs") {
          setFavoriteHubs();
        } else if (category === "links") {
          setFavoriteLinks();
        }
        loadedFavorites.current = false;
      }
    },
    [getFavourite, getFavouriteContacts, getFavouriteHubs, cmsLang]
  );

  useEffect(() => {
    if (loadedFavorites.current !== cmsLang) {
      loadFavorites({ category: "links", limit: 6 });
      loadFavorites({ category: "contacts", limit: 3 });
      loadFavorites({ category: "hubs", limit: 4 });
    }
  }, [loadFavorites, cmsLang]);

  const reloadLinks = useCallback(async () => {
    try {
      const updatedLinks = await updateFavAppLink("links", cmsLang, 6);
      setFavoriteLinks(updatedLinks.appLinkDtoList.map((item) => ({ ...item, favourite: true })));
    } catch (err) {
      console.error(`Error reloading link favorites`, err);
    }
  }, [updateFavAppLink, cmsLang]);

  const reloadContacts = useCallback(async () => {
    try {
      const updatedContacts = await updateFavContacts(3, true);
      setFavoriteContacts(
        updatedContacts.userDtoList.map((item) => ({ ...item, favourite: true }))
      );
    } catch (err) {
      console.error(`Error reloading contact favorites`, err);
    }
  }, [updateFavContacts]);

  const { mutateAsync: reloadCommunities } = useUpdateFavoriteCommunities({
    // Invalidate all communities cache
    doInvalidateAllCommunities: true,
    // Invalidate my communities cache (except current, on which optimistic update will be performed)
    doInvalidateMyCommunities: false,
    // Perform optimistic update on my community current query
    doOptimisticUpdateMyCommunities: true,
    currentMyQueryKey: ["my-community", PAGE_SIZE, { booleans: {} }],
  });

  const { mutateAsync: reloadResources } = useUpdateFavoriteResources({
    // Invalidate all resources cache except current, on which optimistic update will be performed
    doInvalidateAllResources: true,
    // Invalidate my resources cache
    doInvalidateMyResources: false,
    doOptimisticUpdateMyResources: true,
    currentQueryKey: ["resources-my", PAGE_SIZE, cmsLang, { booleans: {} }],
  });

  const { mutateAsync: reloadPolicies } = useUpdateFavoritePolicies({
    // Invalidate all policies cache except current, on which optimistic update will be performed
    doInvalidateAllPolicies: true,
    // Invalidate my policies cache
    doInvalidateMyPolicies: false,
    doOptimisticUpdateMyPolicies: true,
    currentQueryKey: ["policies-my", PAGE_SIZE, cmsLang, { booleans: {} }, { tag: "corporate" }],
  });

  return (
    <section className="profile__section favorites" aria-label="Profile favorites">
      <div className="favorites__section">
        <div className="section-header">
          <h2>{labels?.PROFILE_FAVORITES_MY_LINKS}</h2>
          <Link to={`${backLinks?.appsLinks}`} className="section-header__link cta-link">
            {labels?.SEE_ALL_LINKS} <Icon name="arrow" />
          </Link>
        </div>
        {favoriteLinks ? (
          favoriteLinks.length > 0 ? (
            <div className="favorites__card-wrapper favorites__card-wrapper--link">
              {favoriteLinks.map((link) => (
                <AppLinkCard
                  item={link}
                  key={link.masterID}
                  onUpdate={reloadLinks}
                  large={true}
                  setAddLinkVisible={setAddLinkVisible}
                  setDeleteLinkModalVisible={setDeleteLinkModalVisible}
                />
              ))}
            </div>
          ) : (
            <Error msg={labels?.NO_FAVORITE_LINKS} />
          )
        ) : (
          <LoadingError />
        )}
      </div>
      <div className="favorites__section">
        <div className="section-header">
          <h2>{labels?.PROFILE_FAVORITES_MY_CONTACTS}</h2>
          <Link to={backLinks?.contacts} className="section-header__link cta-link">
            {labels?.SEE_ALL_CONTACTS} <Icon name="arrow" />
          </Link>
        </div>
        {favoriteContacts ? (
          favoriteContacts.length > 0 ? (
            <div className="favorites__card-wrapper favorites__card-wrapper--contacts">
              {favoriteContacts.map((contact) => (
                <ContactCard contact={contact} key={contact.username} onUpdate={reloadContacts} />
              ))}
            </div>
          ) : (
            <Error msg={labels?.NO_FAVORITE_CONTACTS} />
          )
        ) : (
          <LoadingError />
        )}
      </div>
      <div className="favorites__section">
        <div className="section-header">
          <h2>{labels?.PROFILE_FAVORITES_MY_COMMUNITIES || "My communities"}</h2>
          <Link to={backLinks?.communities} className="section-header__link cta-link">
            {labels?.SEE_ALL_COMMUNITIES} <Icon name="arrow" />
          </Link>
        </div>
        {favoriteCommunities ? (
          favoriteCommunities.list?.length > 0 ? (
            <div className="favorites__card-wrapper favorites__card-wrapper--communities">
              {favoriteCommunities.list.slice(0, 4).map((community) => (
                <CommunityCard
                  community={community}
                  key={community.id}
                  onUpdate={reloadCommunities}
                />
              ))}
            </div>
          ) : (
            <Error msg={labels?.NO_FAVORITE_COMMUNITIES || "No favourite communities yet"} />
          )
        ) : (
          <LoadingError />
        )}
      </div>
      <div className="favorites__section">
        <div className="section-header">
          <h2>{labels?.PROFILE_FAVORITES_MY_RESOURCES || "My documents"}</h2>
          <Link
            to={`${backLinks.resources}?section=myResources`}
            className="section-header__link cta-link"
          >
            {labels?.SEE_ALL_RESOURCES} <Icon name="arrow" />
          </Link>
        </div>
        {favoriteResources ? (
          favoriteResources.data?.length > 0 ? (
            <div className="favorites__card-wrapper favorites__card-wrapper--resources">
              {favoriteResources.data.slice(0, 2).map((resource) => (
                <ResourceCard
                  resource={resource}
                  key={resource.masterID}
                  onUpdate={reloadResources}
                />
              ))}
            </div>
          ) : (
            <Error msg={labels?.NO_FAVORITE_RESOURCES || "No favourite documents yet"} />
          )
        ) : (
          <LoadingError />
        )}
      </div>
      <div className="favorites__section">
        <div className="section-header">
          <h2>
            {labels?.PROFILE_FAVORITES_MY_POLICIES_AND_PROCEDURES || "My policies & procedures"}
          </h2>
          <Link
            to={`${backLinks.policies}?section=myPoliciesProcedures`}
            className="section-header__link cta-link"
          >
            {labels?.SEE_ALL_POLICIES_AND_PROCEDURES || "See all policies & procedures"}
            <Icon name="arrow" />
          </Link>
        </div>
        {favoritePolicies ? (
          favoritePolicies.data?.length > 0 ? (
            <div className="favorites__card-wrapper favorites__card-wrapper--resources">
              {favoritePolicies.data.slice(0, 2).map((resource) => (
                <ResourceCard
                  resource={resource}
                  key={resource.masterID}
                  onUpdate={reloadPolicies}
                />
              ))}
            </div>
          ) : (
            <Error
              msg={
                labels?.NO_FAVORITE_POLICIES_AND_PROCEDURES ||
                "No favourite policies & procedures yet"
              }
            />
          )
        ) : (
          <LoadingError />
        )}
      </div>
      <div className="favorites__section">
        <div className="section-header">
          <h2>{labels?.PROFILE_FAVORITES_MY_HUBS || "My hubs"}</h2>
          <Link to={backLinks?.hubs} className="section-header__link cta-link">
            {labels?.SEE_ALL_HUBS} <Icon name="arrow" />
          </Link>
        </div>
        {favoriteHubs ? (
          favoriteHubs.length > 0 ? (
            <div className="favorites__card-wrapper favorites__card-wrapper--hubs ">
              {favoriteHubs.map((hub) => (
                <HubCard hub={hub} key={hub.id} labels={labels} onRemoveFromFavorites={loadHubs} />
              ))}
            </div>
          ) : (
            <Error msg={labels?.NO_FAVORITE_HUBS || "No favorite hubs"} />
          )
        ) : (
          <LoadingError />
        )}
      </div>
      <Suspense>
        <AddCustomLinkModal
          key={!!addLinkVisible}
          show={!!addLinkVisible}
          setShow={setAddLinkVisible}
          customLink={addLinkVisible?.customLink}
          onUpdate={reloadLinks}
        />
      </Suspense>
      <DeleteCustomLinkModal
        show={!!deleteLinkModalVisible}
        setShow={setDeleteLinkModalVisible}
        linkId={deleteLinkModalVisible?.customLink?.id}
        onUpdate={reloadLinks}
      />
    </section>
  );
};

export default Favorites;
