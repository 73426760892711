const getBaseUrl = (content) => {
  return `/${
    content?.regionHub?.[0]?.externalReference &&
    content?.regionHub?.[0]?.externalReference !== "corporate"
      ? `regional-hubs/${content?.regionHub?.[0]?.externalReference}/`
      : content?.businessHub?.[0]?.externalReference
      ? `business-hubs/${content?.businessHub?.[0]?.externalReference}/`
      : ""
  }`;
};

export const getHRContentPath = (content) => {
  const baseUrl = getBaseUrl(content);
  return `${baseUrl}hr-detail/${content.id}`;
};

export const getCMINContentPath = (content) => {
  const baseUrl = getBaseUrl(content);

  return (
    content?.newsType?.externalReference &&
    content?.id &&
    {
      "document-repository-resource": `${baseUrl}resource/${content.id}`,
      "hub-news": `${baseUrl}news-detail/${content.id}`,
      "corporate-news": `/news/${content.id}`,
      "top-leaders": `/news/${content.id}`,
      announcements: `/notes/${content.id}`,
      "press-release": `/press/${content.id}`,
      "hr-content": `${baseUrl}hr-detail/${content.id}`,
    }[content.newsType.externalReference]
  );
};

export const getCMChannelPath = (content) => {
  const baseUrl = getBaseUrl(content);

  return baseUrl !== "/"
    ? `${baseUrl}${
        content?.parent?.parent?.parent?.parent?.segment === "hubs"
          ? `${content.parent.parent.segment}/${content.parent.segment}/`
          : ""
      }${content?.parent?.parent?.parent?.segment === "hubs" ? `${content.parent.segment}/` : ""}${
        content.segment
      }`
    : `${
        content?.parent?.parent &&
        content?.parent?.parent?.segment !== "top-menu" &&
        !content?.parent?.parent?.segment.startsWith("intranet-")
          ? `/${content.parent.parent.segment}`
          : ""
      }${
        content?.parent && content?.parent?.segment !== "top-menu"
          ? `/${content.parent.segment}`
          : ""
      }/${content.segment}`;
};
