import { useContext } from "react";
import UserContext from "../context/user";
import LanguageContext from "../context/language";
import ConditionalLink from "./conditional-link";
import Image from "./image";
import Translate from "./translate";
import imagePh from "../images/placeholder-image.png";
import FavoriteBtn from "./favorite-btn";

const HubCard = ({ hub, loading, onRemoveFromFavorites }) => {
  const { callApi } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);

  const onUpdate = async ({ id, internalBookmarkedState, setInternalBookmarkedState }) => {
    await callApi(`/resource/hubs/favourites/${id}`, {
      method: internalBookmarkedState ? "DELETE" : "POST",
    });
    onRemoveFromFavorites();
    setInternalBookmarkedState((current) => !current);
  };

  return (
    <div className="hub-card">
      <ConditionalLink
        disabled={loading}
        to={hub.target}
        className="hub-card__img-wrapper loading-highlighted"
      >
        <Image
          className="hub-card__img"
          cropUrlTemplate={hub?.coverImage?.cropUrlTemplate || (loading ? "" : imagePh)}
          crops={[
            {
              crop: "landscape_ratio16x9",
              sizes: [
                {
                  breakpoint: 768,
                  maxWidth: "100vw",
                },
                {
                  breakpoint: 1230,
                  maxWidth: "50vw",
                },
                {
                  breakpoint: 1600,
                  maxWidth: "25vw",
                },
                {
                  maxWidth: "25vw",
                },
              ],
            },
          ]}
          alt={hub?.title}
        />
        <FavoriteBtn item={hub} onUpdate={onUpdate} />
      </ConditionalLink>
      <div className="hub-card__content">
        <ConditionalLink to={hub.target} className="hub-card__title title-h5 loading-highlighted">
          <Translate text={hub?.title} />
        </ConditionalLink>
        <Translate className="hub-card__description loading-highlighted" html={hub?.intro} />
        <div className="hub-card__lang loading-highlighted">
          {hub?.hubLanguagesString?.map(
            (lang, i) =>
              `${labels?.[lang.toUpperCase()] || lang.toUpperCase()}${
                i < hub?.hubLanguagesString?.length - 1 ? ", " : ""
              }`
          )}
        </div>
      </div>
    </div>
  );
};

export default HubCard;
