const placeholders = (n = 3, key = "masterID") =>
  new Array(n)
    .fill(0)
    .map((_, i) =>
      key.includes(".")
        ? { [key.split(".")[0]]: { [key.split(".")[1]]: i }, init: true }
        : { [key]: i, init: true }
    );

export const pagePlaceholder = (items = [{ viewtype: "skeleton" }]) => ({
  grid: { rows: [{ placements: items }] },
});

export default placeholders;
