import { useContext, Suspense, lazy } from "react";
import UserContext from "../context/user";
import ColdPageWidget from "./hub-widgets/cold-page-widget";
import ContactList from "./hub-widgets/contact-list";
import LinkList from "./hub-widgets/link-list";
import NewsList from "./hub-widgets/news-list";
import PeopleCard from "./hub-widgets/people-cards";
import PhotogalleryWidget from "./hub-widgets/photogallery-widget";
import ServiceList from "./hub-widgets/service-list";
import SimpleContentWidget from "./hub-widgets/simple-content-widget";
import VideoList from "./hub-widgets/video-list";
import VideoPlayerWidget from "./hub-widgets/video-player-widget";
import NewsTypeWidget from "./news-type-widget.jsx";
import AllHrContentWidget from "./hub-widgets/all-hr-content-widget";
import SectionCourses from "../routes/home/section-courses.jsx";

const EventBookingWidget = lazy(() => import("./event-booking-widget"));
const WidgetSurvey = lazy(() => import("./widget-survey"));

const LandingPagePlacements = ({ pageTemplate, hub, routes }) => {
  const { config } = useContext(UserContext);

  return pageTemplate?.map(
    ({ placements }, i) =>
      ({
        "Celebrate EssilorLuxottica People": (
          <PeopleCard
            key={`section-${i}`}
            data={placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference !== "placement-title"
            )}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
            hub={hub}
          />
        ),
        "Latest News Big": (
          <NewsList
            key={`section-${i}`}
            data={placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference !== "placement-title"
            )}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
            hub={hub}
            routes={routes}
            mainTemplate={true}
          />
        ),
        "Latest News": (
          <NewsList
            key={`section-${i}`}
            data={placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference !== "placement-title"
            )}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
            hub={hub}
            routes={routes}
          />
        ),
        Banner: (
          <LinkList
            key={`section-${i}`}
            image={true}
            data={placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference !== "placement-title"
            )}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
          />
        ),
        "Banner Only Text": (
          <LinkList
            key={`section-${i}`}
            data={placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference !== "placement-title"
            )}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
          />
        ),
        "Banner with Icon": (
          <LinkList
            key={`section-${i}`}
            icon={true}
            data={placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference !== "placement-title"
            )}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
          />
        ),
        "Video List":
          placements?.[0]?.items?.filter(
            (item) => item?.newsType?.externalReference !== "placement-title"
          )?.length > 1 ? (
            <VideoList
              key={`section-${i}`}
              data={placements?.[0]?.items?.filter(
                (item) => item?.newsType?.externalReference !== "placement-title"
              )}
              title={
                placements?.[0]?.items?.find(
                  (item) => item?.newsType?.externalReference === "placement-title"
                )?.title
              }
            />
          ) : (
            <VideoPlayerWidget
              key={`section-${i}`}
              data={placements?.[0]?.items?.filter(
                (item) => item?.newsType?.externalReference !== "placement-title"
              )}
              title={
                placements?.[0]?.items?.find(
                  (item) => item?.newsType?.externalReference === "placement-title"
                )?.title
              }
            />
          ),
        "Suggested HR Contents": (
          <ServiceList
            key={`section-${i}`}
            data={placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference !== "placement-title"
            )}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
          />
        ),
        Contacts: (
          <ContactList
            key={`section-${i}`}
            data={placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference !== "placement-title"
            )}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
          />
        ),
        Photogallery: (
          <PhotogalleryWidget
            key={`section-${i}`}
            data={placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference !== "placement-title"
            )}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
          />
        ),
        "General Content": (
          <ColdPageWidget
            key={`section-${i}`}
            data={placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference !== "placement-title"
            )}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
          />
        ),
        Survey: config.hideSurveys
          ? null
          : !!placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference === "survey" && !!item.linkURL
            )?.length && (
              <Suspense>
                {placements?.[0]?.items
                  ?.filter(
                    (item) => item?.newsType?.externalReference === "survey" && !!item.linkURL
                  )
                  ?.map((item, i) => (
                    <WidgetSurvey
                      key={`section-${i}`}
                      survey={{
                        id: item?.linkURL,
                        imageUrl: item?.coverImage?.cropUrlTemplate,
                        categories: item?.intro,
                        intro: item?.description,
                        linkLabel: item?.linkLabel,
                      }}
                    />
                  ))}
              </Suspense>
            ),
        Event: config.hideEvents
          ? null
          : !!placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference === "event" && !!item.linkURL
            )?.length && (
              <Suspense>
                {placements?.[0]?.items
                  ?.filter(
                    (item) => item?.newsType?.externalReference === "event" && !!item.linkURL
                  )
                  ?.map((item, i) => (
                    <EventBookingWidget
                      key={`section-${i}`}
                      event={{
                        id: item?.linkURL,
                        imageUrl: item?.coverImage?.cropUrlTemplate,
                        categories: item?.intro,
                        intro: item?.description,
                      }}
                    />
                  ))}
              </Suspense>
            ),
        "all-hr-contents": (
          <AllHrContentWidget
            key={`section-${i}`}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
            hub={hub}
          />
        ),
        "Hub News Component": (
          <NewsTypeWidget
            key={`section-${i}`}
            data={placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference !== "placement-title"
            )}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
          />
        ),
        "Leonardo Courses Carousel": (
          <SectionCourses
            key={`section-${i}`}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
            tagCMS={
              placements?.[0]?.items?.find(
                (item) => item?.leonardoCoursesCarousel?.[0]?.externalReference
              )?.leonardoCoursesCarousel?.[0]?.externalReference
            }
          />
        ),
        HTML: (
          <SimpleContentWidget
            key={`section-${i}`}
            data={placements?.[0]?.items?.filter(
              (item) => item?.newsType?.externalReference !== "placement-title"
            )}
            title={
              placements?.[0]?.items?.find(
                (item) => item?.newsType?.externalReference === "placement-title"
              )?.title
            }
          />
        ),
        default: <SimpleContentWidget key={`section-${i}`} data={placements?.[0]?.items} />,
        skeleton: <div className="loading-spinner" key={`section-${i}`} />,
      }[placements?.[0]?.viewtype])
  );
};

export default LandingPagePlacements;
