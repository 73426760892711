import { useContext, useState, useCallback } from "react";
import classNames from "classnames";
import Icon from "./icon";
import Modal from "./modal";
import ContactDetail from "./contact-detail";
import UserContext from "../context/user";
import { tealiumOnEvent } from "../utils/tealium";
import LanguageContext from "../context/language";
import RemoveMemberModal from "./communities/remove-member-modal";

const ContactCard = ({
  contact,
  onUpdate,
  remove,
  communityId,
  onRemoveUpdate,
  pending,
  onPendingUpdate,
  isDragging,
}) => {
  const { isPreview, callApi } = useContext(UserContext);
  const { labels } = useContext(LanguageContext);
  const [updating, setUpdating] = useState();
  const isSkeletonLoading = Object.keys(contact).length === 0;
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [handling, setHandling] = useState(false);

  const addToFavorite = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();
      setUpdating(true);
      try {
        const contactId = e.currentTarget?.id;
        await callApi(`/profile/favorites/contacts/${contactId}`, {
          method: "POST",
        });
        tealiumOnEvent({
          eventType: "appTool",
          eventAction: "Add",
          eventLabel: contactId,
        });
        await onUpdate();
      } catch (err) {
        console.error(`Error adding contact to favorites`, err);
      }
      setUpdating(false);
    },
    [callApi, onUpdate]
  );

  const removeFromFavorites = useCallback(
    async (e) => {
      e.stopPropagation();
      setUpdating(true);
      const contactId = e.currentTarget.id;
      try {
        await callApi(`/profile/favorites/contacts/${contactId}`, {
          method: "DELETE",
        });
        tealiumOnEvent({
          eventType: "appTool",
          eventAction: "Remove",
          eventLabel: contactId,
        });
        await onUpdate(contactId);
      } catch (err) {
        console.error(`Error removing contact from favorites`, err);
      }
      setUpdating(false);
    },
    [callApi, onUpdate]
  );

  const handlePendingRequest = useCallback(
    async (e) => {
      e.stopPropagation();
      const operation = e.currentTarget.name;
      setHandling(operation);
      try {
        await callApi(`/community/${communityId}/members/join/${contact.username}/${operation}`, {
          method: "PUT",
        });
        onPendingUpdate(contact.username, operation);
      } catch (err) {
        console.error("Error handling request: ", err);
      }
      setHandling(false);
    },
    [callApi, communityId, contact.username, onPendingUpdate]
  );

  return (
    <>
      <div
        className={classNames(
          "contact-card",
          isSkeletonLoading && "loading-skeleton",
          contact?.admin && "contact-card--admin",
          pending && "contact-card--pending"
        )}
        onClick={() => !isSkeletonLoading && !isDragging && setDetailModalVisible(true)}
        role="region"
        aria-label="Contact card"
      >
        <div className="contact-card__header">
          <div className="contact-card__img-wrapper">
            {contact?.avatarUrl ? (
              <img
                src={contact?.avatarUrl}
                alt={
                  contact?.firstName &&
                  contact?.lastName &&
                  `${contact?.firstName} ${contact?.lastName}`
                }
                className="contact-card__img loading-highlighted"
              />
            ) : (
              <div className="contact-card__img loading-highlighted">
                {contact?.firstName && contact?.lastName
                  ? `${contact.firstName.slice(0, 1)}${contact.lastName.slice(0, 1)}`
                  : ""}
              </div>
            )}
            {contact?.admin && (
              <div className="contact-card__admin-tag loading-highlighted">
                <span>{labels?.ADMIN}</span>
              </div>
            )}
          </div>
          <div className="contact-card__info loading-highlighted">
            <div
              className="contact-card__info-value contact-card__info-value--name title-h5"
              translate="no"
            >
              {contact.firstName}
              <br />
              {contact.lastName}
            </div>
            <div className="contact-card__info-value contact-card__info-value--entity text-transform-capitalize">
              {contact.zbusTypeT?.toLowerCase()}
            </div>
            <div className="contact-card__info-value text-transform-capitalize">
              {contact.jobTitle?.toLowerCase()}
            </div>
          </div>
        </div>
        {remove ? (
          !contact.admin && (
            <div className="contact-card__info contact-card__info--body contact-card__info--btn-wrapper loading-highlighted">
              <hr />
              <button
                className="button leading-icon contact-card__remove-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setRemoveModalVisible(true);
                }}
              >
                <Icon name="trash" />
                {labels?.REMOVE_MEMBER || "Remove member"}
              </button>
            </div>
          )
        ) : pending ? (
          <div className="contact-card__info contact-card__info--body contact-card__info--btn-wrapper contact-card__info--pending loading-highlighted">
            <hr />
            <div>
              <button
                className="button leading-icon contact-card__remove-btn"
                name="accept"
                onClick={(e) => handlePendingRequest(e)}
              >
                {handling === "accept" ? (
                  <span className="loading-spinner" />
                ) : (
                  <Icon name="checkmark" />
                )}{" "}
                <span> {labels?.ACCEPT || "Accept"}</span>
              </button>
              <button
                className="button leading-icon contact-card__remove-btn"
                name="reject"
                onClick={(e) => handlePendingRequest(e)}
              >
                {handling === "reject" ? (
                  <span className="loading-spinner" />
                ) : (
                  <Icon name="x-icon" />
                )}{" "}
                <span> {labels?.REJECT || "Reject"}</span>
              </button>
            </div>
          </div>
        ) : (
          <div className="contact-card__info contact-card__info--body loading-highlighted">
            {(contact?.phone || contact?.mail) && <hr />}
            <div className="contact-card__info-value ">
              {contact?.phone && (
                <>
                  <Icon name="phone" />
                  {contact.phone}
                </>
              )}
            </div>
            <div className="contact-card__info-value text-transform-initial ">
              {contact?.mail && (
                <>
                  <Icon name="email" />
                  {contact.mail?.toLowerCase()}
                </>
              )}
            </div>
          </div>
        )}
        <button
          className={classNames("favorites__bookmark loading-highlighted", {
            "favorites__bookmark--full": contact.favourite,
          })}
          disabled={updating || contact.favourite === undefined || isPreview}
          id={contact.username}
          onClick={contact.favourite ? removeFromFavorites : addToFavorite}
          aria-label={contact.favourite ? "Remove from favourites" : "Add to favourites"}
        >
          <Icon name={contact?.favourite ? "bookmark-fill" : "bookmark"} />
        </button>
      </div>
      <Modal
        show={detailModalVisible}
        setShow={setDetailModalVisible}
        className="intranet-modal--contact"
      >
        <ContactDetail
          contact={contact}
          addToFavorite={addToFavorite}
          removeFromFavorites={removeFromFavorites}
          updating={updating}
        />
      </Modal>
      {remove && (
        <RemoveMemberModal
          show={removeModalVisible}
          setShow={setRemoveModalVisible}
          communityId={communityId}
          username={contact.username}
          onRemoveUpdate={onRemoveUpdate}
        />
      )}
    </>
  );
};

export default ContactCard;
