import classNames from "classnames";
import dayjs from "dayjs";
import { useContext } from "react";
import LanguageContext from "../context/language";
import { getFilenameDetails, getItemIcon, getResourceDocDownloadUrl } from "../utils/documents";
import { tealiumOnEvent } from "../utils/tealium";
import ConditionalLink from "./conditional-link";
import Content from "./content";
import CopyLink from "./copy-link";
import Icon from "./icon";
import FavoriteBtn from "./favorite-btn";
import Translate from "./translate";

const ResourceCard = ({ resource, onUpdate, policies }) => {
  const isSkeletonLoading = resource?.init;
  const { labels, cmsLang } = useContext(LanguageContext);
  const singleDocument = resource?.attachments?.length === 1;
  const singleDocumentIsExternalLink =
    singleDocument && resource?.attachments?.[0]?.type === "CMExternalLink";

  const resourceUrl =
    resource?.businessHub?.[0]?.externalReference ||
    (resource?.regionHub?.[0]?.externalReference &&
      resource?.regionHub?.[0]?.externalReference !== "corporate")
      ? `/${resource?.businessHub?.[0]?.externalReference ? "business-hubs" : "regional-hubs"}/${
          resource?.businessHub?.[0]?.externalReference ||
          resource?.regionHub?.[0]?.externalReference
        }/${policies ? "policies-and-procedures-detail" : "resource"}/${resource?.masterID}`
      : `/${policies ? "policies-and-procedures-detail" : "resource"}/${resource?.masterID}`;

  return (
    <article
      className={classNames("resource-card", isSkeletonLoading && "loading-skeleton")}
      data-id={resource?.masterID}
    >
      <div className="resource-card__wrapper">
        <div className="resource-card__header-top">
          <div className="resource-card__category loading-highlighted">
            {resource?.categoryString?.map(
              (cat, i) =>
                `${
                  labels?.[cat?.toUpperCase()] ||
                  `${cat?.[0]?.toUpperCase()}${cat.slice(1)?.toLowerCase()}`
                }${i < resource.categoryString.length - 1 ? ", " : ""}`
            )}
          </div>
          <FavoriteBtn item={resource} onUpdate={onUpdate} />
        </div>
        <div className="resource-card__info">
          <div className="resource-card__title-wrapper">
            <Icon
              name={
                singleDocument
                  ? singleDocumentIsExternalLink
                    ? "link-external"
                    : getItemIcon({ filename: resource?.attachments?.[0]?.data?.uri })
                  : "page"
              }
              className="resource-card__icon"
            />
            <h2 className="resource-card__title title-h5 loading-highlighted">
              <ConditionalLink
                disabled={isSkeletonLoading}
                to={resourceUrl}
                href={resource?.attachments?.[0]?.url}
                target={singleDocumentIsExternalLink ? "_blank" : "_self"}
              >
                <Translate text={resource?.title} />
              </ConditionalLink>
            </h2>
          </div>
          {(isSkeletonLoading || resource.description) && (
            <div className="text">
              <Content news={resource} />
            </div>
          )}
        </div>
        <div className="resource-card__box">
          <time className="resource-card__date loading-highlighted">
            {resource?.customPublicationDate &&
              resource?.customPublicationDate !== resource?.creationDate &&
              dayjs(resource?.customPublicationDate, [
                "YYYY-MM-DDThh:mm:ssZ[GMT]",
                "YYYY-MM-DDThh:mmZ[GMT]",
              ])
                .locale(cmsLang)
                .format("LL")}
          </time>
          <div className="resource-card__actions">
            {singleDocument && !singleDocumentIsExternalLink && (
              <a
                href={getResourceDocDownloadUrl(resource?.attachments?.[0]?.id, policies)}
                className="resource-card__action"
                download={resource?.attachments?.[0]?.title}
                onClick={() => {
                  tealiumOnEvent({
                    eventType: "EditorialFile",
                    eventAction: "Download",
                    eventLabel: resource?.attachments?.[0]?.data?.uri,
                  });
                }}
              >
                {getFilenameDetails(resource?.attachments?.[0]?.data?.uri)?.dotExtension}
                <Icon name="download" />
              </a>
            )}
            <CopyLink
              className="resource-card__action"
              link={
                singleDocument
                  ? singleDocumentIsExternalLink
                    ? resource?.attachments?.[0]?.url
                    : getResourceDocDownloadUrl(resource?.attachments?.[0]?.id, policies)
                  : `${window.location.origin}${resourceUrl}`
              }
            >
              {labels?.COPY_LINK || "Copy link"}
              <Icon name="share-link" />
            </CopyLink>
          </div>
        </div>
        <ConditionalLink
          disabled={isSkeletonLoading}
          to={resourceUrl}
          href={resource?.attachments?.[0]?.url}
          target={singleDocumentIsExternalLink ? "_blank" : "_self"}
          alt={resource?.attachments?.[0]?.title}
          aria-label={resource?.attachments?.[0]?.title}
        >
          <Icon className="resource-card__arrow" name="arrow" />
        </ConditionalLink>
      </div>
    </article>
  );
};

export default ResourceCard;
