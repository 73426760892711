import classNames from "classnames";
import { Link } from "react-router-dom";

const Error = ({ msg, variant, left, right, link }) => {
  return (
    <div
      className={classNames("error", {
        "error--small": variant === "small",
        "error--left": !!left,
        "error--right": !!right,
      })}
    >
      {msg && <p>{msg}</p>}
      {link?.to && link?.text && <Link to={link.to}>{link.text}</Link>}
    </div>
  );
};

export default Error;
