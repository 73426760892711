import { useCallback, useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Icon from "../components/icon";
import CookiePolicyEN from "../components/cookie-policies/cookie-policy_EN";
import CookiePolicyIT from "../components/cookie-policies/cookie-policy_IT";
import CookiePolicyFR from "../components/cookie-policies/cookie-policy_FR";
import CookiePolicyES from "../components/cookie-policies/cookie-policy_ES";
import CookiePolicyPT from "../components/cookie-policies/cookie-policy_PT";
import LanguageContext from "../context/language";
import useTealiumOnPageLoad from "../utils/tealium";
import logo from "../images/OneEssilorLuxotticaLogo.svg";
import CookiePolicyZH from "../components/cookie-policies/cookie-policy_ZH";

const CookiePolicy = ({ logged }) => {
  const { labels, cmsLang, cmsLanguages } = useContext(LanguageContext);

  useTealiumOnPageLoad({ pageSection1: "cookie-policy" });

  const [currentLanguage, setCurrentLanguage] = useState(
    cmsLanguages.find((lang) => lang.value === cmsLang) ||
      cmsLanguages.find((lang) => lang.value === "en-us")
  );

  const updateLanguage = useCallback((selectedOption) => {
    setCurrentLanguage(selectedOption);
  }, []);

  useEffect(() => {
    if (cmsLang) {
      updateLanguage(cmsLanguages.find((lang) => lang.value === cmsLang));
    }
  }, [cmsLang, cmsLanguages, updateLanguage]);

  return (
    <>
      {!logged && (
        <header className="header">
          <div className="header__top">
            <div className="wrapper">
              <div className="header__row header__row--cookie-policy">
                <Link to="/login" className="header__logo-cta">
                  <img className="header__logo" src={logo} alt="OneEssilorLuxottica" />
                </Link>
              </div>
            </div>
          </div>
        </header>
      )}
      <div className="cookie-policy wrapper page-content">
        {!logged && (
          <div className="">
            <div className="return-bar full-width" role="region" aria-label="Return bar">
              <div className="wrapper">
                <Link className="return-bar__btn" to="/login">
                  <Icon name="chevron" />
                  <span>{labels?.BACK_TO_LOGIN}</span>
                </Link>
              </div>
            </div>
          </div>
        )}
        {
          {
            "en-us": <CookiePolicyEN logged={logged} />,
            "it-it": <CookiePolicyIT logged={logged} />,
            "fr-fr": <CookiePolicyFR logged={logged} />,
            "es-es": <CookiePolicyES logged={logged} />,
            "pt-br": <CookiePolicyPT logged={logged} />,
            "zh-cn": <CookiePolicyZH logged={logged} />,
          }[currentLanguage?.value]
        }
      </div>
    </>
  );
};

export default CookiePolicy;
