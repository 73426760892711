import { useContext, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import LanguageContext from "../../context/language";
import Tabs from "../../components/tabs";
import CommunitiesAll from "./communities-all";
import MyCommunities from "./my-communities";
import useTealiumOnPageLoad from "../../utils/tealium";

const Communities = ({ page: pageContent }) => {
  const { labels } = useContext(LanguageContext);
  const { pathname } = useLocation();

  useTealiumOnPageLoad({ pageType: "Landing", pageSection1: "Communities" });

  const communitiesTabs = useMemo(() => {
    return [
      {
        label: labels?.MY_COMMUNITIES || "My communities",
        link: `${pathname}`,
        end: true,
      },
      {
        label: labels?.ALL_COMMUNITIES || "All communities",
        link: `${pathname}?section=all`,
        end: true,
      },
    ];
  }, [labels, pathname]);

  const [searchParams] = useSearchParams();
  const section = searchParams.get("section");

  return (
    <main className="page communities">
      <h1 className="loading-highlighted">{pageContent.label}</h1>
      <Tabs tabs={communitiesTabs} />
      {
        {
          myCommunities: <MyCommunities />,
          all: <CommunitiesAll />,
        }[section || "myCommunities"]
      }
    </main>
  );
};

export default Communities;
