import { useContext } from "react";
import UserContext from "../context/user";
import useTealiumOnPageLoad from "../utils/tealium";
import ColdPageContent from "../components/coldpage-content";
import LoadingError from "./loading-error";
import useColdpage from "../hooks/queries/useColdpage";
import useCustomResourceDetail from "../hooks/queries/useCustomResourceDetail";
import { LightModeBanner } from "../components/toggle-light-mode";

const Page = ({ page, internalMenuOpen, setInternalMenuOpen }) => {
  const { lightMode, setLightMode } = useContext(UserContext);
  const origin = page.origin || "top-menu";
  const link = page?.link;
  const isHub = origin?.split("/")?.[0] === "hubs";

  useTealiumOnPageLoad({
    pageSection1: page?.link?.split("/")?.[1],
    pageSection2: page?.link?.split("/")?.[2],
  });

  const {
    data: resourceId,
    error: coldpageError,
    isFetching: isColdpageLoading,
  } = useColdpage({
    path: `${origin}${link}`,
    select: (data) => data?.grid?.rows?.[0]?.placements?.[0]?.items?.[0]?.id,
  });

  const {
    data: pageContent,
    error: resourceError,
    isFetching: isResourceLoading,
  } = useCustomResourceDetail({ resourceId });

  return coldpageError || resourceError ? (
    <LoadingError />
  ) : (
    <>
      {!isHub && <LightModeBanner status={lightMode} setStatus={setLightMode} />}
      <ColdPageContent
        page={page}
        pageContent={pageContent}
        loading={isColdpageLoading || isResourceLoading}
        internalMenuOpen={internalMenuOpen}
        setInternalMenuOpen={setInternalMenuOpen}
      />
    </>
  );
};

export default Page;
