import { useEffect, useRef, useState } from "react";

function useHover() {
  const [value, setValue] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const node = ref.current;
    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);
    if (node) {
      node.addEventListener("mouseover", handleMouseOver);
      node.addEventListener("mouseout", handleMouseOut);
      return () => {
        node.removeEventListener("mouseover", handleMouseOver);
        node.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, []);

  return [ref, value];
}

export default useHover;
