import { useContext, useMemo, lazy, Suspense, useRef } from "react";
import classNames from "classnames";
import LanguageContext from "../context/language";
import Icon from "./icon";

const Select = lazy(() => import("react-select"));

const OrderBySelect = ({
  options,
  orderBy,
  onChange,
  open,
  setOpen,
  className,
  label = true,
  resourceType,
  term,
}) => {
  const { labels } = useContext(LanguageContext);
  const selectRef = useRef();
  const internalOpenRef = useRef(false);

  const currentOrderBy = useMemo(
    () =>
      options.find(({ value }) => value === orderBy) ||
      (resourceType === "contact" && term
        ? options.find((option) => option.value === "MOST_RELEVANT")
        : options[0]) ||
      "",
    [options, orderBy, resourceType, term]
  );

  const ValueContainer = ({ children }) => {
    return (
      <>
        {children}
        <div className="order-by-select__icon-wrapper">
          <Icon name="sort" />
        </div>
      </>
    );
  };

  return (
    <div className={classNames("order-by-select__wrapper loading-highlighted", className)}>
      {label && <label id="order-by-label">{labels?.ORDER_BY}: </label>}
      <span className="order-by-select__container">
        <Suspense>
          <Select
            ref={selectRef}
            options={options}
            value={currentOrderBy}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !(internalOpenRef.current || open)) {
                setOpen(true);
              }
            }}
            aria-labelledby="order-by-label"
            onChange={(e) => {
              onChange(e);
              selectRef.current?.blur();
            }}
            className="order-by-select"
            classNamePrefix="select"
            blurInputOnSelect={true}
            onInputChange={() => {
              setOpen(false);
              selectRef.current?.blur();
            }}
            menuIsOpen={open || undefined}
            onMenuOpen={() => {
              internalOpenRef.current = true;
            }}
            onMenuClose={() => {
              internalOpenRef.current = false;
              setOpen(false);
            }}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              Input: () => null,
              ValueContainer,
            }}
            isSearchable={false}
          />
        </Suspense>
      </span>
    </div>
  );
};

export default OrderBySelect;
