import classNames from "classnames";
import { useEffect, useState, useCallback } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import useBlockScroll from "../hooks/useBlockScroll";
import Icon from "./icon";

const LinkPageMenu = ({ menu, mobileLabel, mobileMenu, setMobileMenu }) => {
  const toggleMobileMenu = () => setMobileMenu((value) => !value);
  const location = useLocation();

  const isActive = useCallback(
    (link) => {
      return !!matchPath({ path: `${link}/*`, end: false }, location.pathname);
    },
    [location]
  );
  const [subMenuOpen, setSubMenuOpen] = useState(-1);
  const [subSubMenuOpen, setSubSubMenuOpen] = useState(-1);

  useBlockScroll(mobileMenu, true);
  useEffect(() => {
    /* If user is located in subpage get second level current page */
    const secondLevelPage = menu?.findIndex((page) =>
      page.children?.find((subPage) => `/${subPage.link}` === location.pathname)
    );
    /* If user is located in subsubpage get third level current page */
    const thirdLevelPageArray = menu?.map((page) =>
      page.children?.findIndex((subPage) =>
        subPage.children?.find((subSubPage) => `/${subSubPage.link}` === location.pathname)
      )
    );
    if (thirdLevelPageArray?.find((x) => x > 0)) {
      /* Extract submenu and subsubmenu values */
      setSubMenuOpen(thirdLevelPageArray?.findIndex((x) => x > 0));
      setSubSubMenuOpen(thirdLevelPageArray?.find((x) => x > 0));
    } else if (secondLevelPage) {
      setSubMenuOpen(secondLevelPage);
      setSubSubMenuOpen(undefined);
    }
  }, [menu, location]);

  return (
    <div className="internal-menu internal-menu--hub full-width">
      <button
        className={classNames(
          "internal-menu__mobile-btn-wrapper d-none d-tsmall-inline-flex",
          mobileLabel && "internal-menu__mobile-btn-wrapper--with-label"
        )}
        onClick={toggleMobileMenu}
      >
        {!!mobileMenu && (
          <span className="internal-menu__mobile-btn d-none d-tsmall-inline-flex">
            <Icon name="x-icon" />
          </span>
        )}
        {mobileLabel && (
          <span className="internal-menu__mobile-label d-none d-tsmall-inline-flex">
            {mobileLabel}
          </span>
        )}
      </button>
      <nav
        className={classNames("internal-menu__nav", {
          "internal-menu__nav--open": mobileMenu,
        })}
      >
        <div
          className={classNames("internal-menu__list-wrapper internal-menu__list-wrapper--1st", {
            "internal-menu__list-wrapper--level2": subMenuOpen >= 0,
          })}
        >
          <ul className="internal-menu__list">
            {menu?.map((item, i) => (
              <li
                className={classNames(
                  "internal-menu__item",
                  i === subMenuOpen && "internal-menu__item--open"
                )}
                key={item.link}
              >
                {isActive(item.link) ? (
                  <span className={classNames(item.children.length && "d-tsmall-none")}>
                    {item.label}
                  </span>
                ) : (
                  <Link
                    className={classNames(item.children.length && "d-tsmall-none")}
                    to={item.children.length ? `${item.children[0].link}` : `${item.link}`}
                    onClick={() => setMobileMenu(false)}
                  >
                    {item.label}
                  </Link>
                )}
                {item.children.length ? (
                  <button
                    onClick={() => setSubMenuOpen(i)}
                    className={classNames("d-none d-tsmall-flex", isActive(item.link) && "active")}
                  >
                    {item.label}
                    <Icon name="chevron-right" className="right" />
                  </button>
                ) : null}
                {item.children.length ? (
                  <div
                    className={classNames(
                      "internal-menu__list-wrapper internal-menu__list-wrapper--2nd",
                      subSubMenuOpen >= 0 && "internal-menu__list-wrapper--level3",
                      subMenuOpen === i && "internal-menu__list-wrapper--selected"
                    )}
                  >
                    <ul className="internal-menu__list internal-menu__list--sublist">
                      <li className="internal-menu__item internal-menu__item--back">
                        <button
                          onClick={() => setSubMenuOpen(-1)}
                          className={classNames("d-none d-tsmall-flex active")}
                        >
                          <Icon name="chevron" />
                          {item.label}
                        </button>
                      </li>
                      {item?.children?.map((subitem, j) => (
                        <li
                          className={classNames(
                            "internal-menu__item",
                            j === subSubMenuOpen && "internal-menu__item--open"
                          )}
                          key={subitem.link}
                        >
                          {isActive(subitem.link) ? (
                            <span
                              className={classNames(subitem.children.length && "d-tsmall-none")}
                            >
                              {subitem.label}
                            </span>
                          ) : (
                            <Link
                              className={classNames(subitem.children.length && "d-tsmall-none")}
                              to={
                                subitem.children.length
                                  ? `${subitem.children[0].link}`
                                  : `${subitem.link}`
                              }
                              onClick={() => setMobileMenu(false)}
                            >
                              {subitem.label}
                            </Link>
                          )}
                          {subitem.children.length ? (
                            <button
                              onClick={() => setSubSubMenuOpen(j)}
                              className={classNames(
                                "d-none d-tsmall-flex",
                                isActive(subitem.link) && "active"
                              )}
                            >
                              {subitem.label}
                              <Icon name="chevron-right" className="right" />
                            </button>
                          ) : null}
                          {subitem.children.length ? (
                            <div
                              className={classNames(
                                "internal-menu__list-wrapper",
                                subSubMenuOpen === j &&
                                  subMenuOpen === i &&
                                  "internal-menu__list-wrapper--selected"
                              )}
                            >
                              <ul className="internal-menu__list internal-menu__list--subsublist">
                                <li className="internal-menu__item internal-menu__item--back">
                                  <button
                                    onClick={() => setSubSubMenuOpen(-1)}
                                    className={classNames("d-none d-tsmall-flex active")}
                                  >
                                    <Icon name="chevron" />
                                    {subitem.label}
                                  </button>
                                </li>
                                {subitem.children.map((subsubitem) => (
                                  <li className="internal-menu__item" key={subsubitem.link}>
                                    {isActive(subsubitem.link) ? (
                                      <span>{subsubitem.label}</span>
                                    ) : (
                                      <Link
                                        to={`${subsubitem.link}`}
                                        onClick={() => setMobileMenu(false)}
                                      >
                                        {subsubitem.label}
                                      </Link>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : null}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default LinkPageMenu;
