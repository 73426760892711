import { useCallback, useContext, useMemo, useState } from "react";
import classNames from "classnames";
import OrderBySelect from "../order-by-select";
import LanguageContext from "../../context/language";
import Icon from "../icon";

const orderByMapping = {
  news: [
    { value: "NEWEST", label: "NEWEST_FIRST" },
    { value: "MOST_LIKED", label: "MOST_LIKED" },
    { value: "MOST_COMMENTED", label: "MOST_COMMENTED" },
    { value: "MOST_RELEVANT", label: "MOST_RELEVANT" },
  ],
  mynews: [
    { value: "NEWEST", label: "NEWEST_FIRST" },
    { value: "MOST_LIKED", label: "MOST_LIKED" },
    { value: "MOST_COMMENTED", label: "MOST_COMMENTED" },
  ],
  notes: [],
  press: [],
  "contact-search": [
    { value: "", label: "MOST_RELEVANT" },
    { value: "SURNAME_AZ", label: "SURNAME_AZ" },
    { value: "SURNAME_ZA", label: "SURNAME_ZA" },
    { value: "NAME_AZ", label: "NAME_AZ" },
    { value: "NAME_ZA", label: "NAME_ZA" },
  ],
  contact: [
    { value: "SURNAME_AZ", label: "SURNAME_AZ" },
    { value: "SURNAME_ZA", label: "SURNAME_ZA" },
    { value: "NAME_AZ", label: "NAME_AZ" },
    { value: "NAME_ZA", label: "NAME_ZA" },
    { value: "MOST_RELEVANT", label: "MOST_RELEVANT" },
  ],
  apps: [],
  contents: [
    { value: "", label: "MOST_RELEVANT" },
    { value: "NEWEST", label: "NEWEST_FIRST" },
  ],
  communities: [
    { value: "NEWEST_POST", label: "NEWEST_POST" },
    { value: "FEATURED", label: "FEATURED_FIRST" },
    { value: "NEWEST", label: "NEWEST_COMMUNITY" },
    { value: "AZ", label: "NAME_AZ" },
    { value: "ZA", label: "NAME_ZA" },
  ],
  mycommunities: [
    { value: "NEWEST_POST", label: "NEWEST_POST" },
    { value: "NEWEST", label: "NEWEST_COMMUNITY" },
    { value: "AZ", label: "NAME_AZ" },
    { value: "ZA", label: "NAME_ZA" },
  ],
  members: [
    { value: "SURNAME_AZ", label: "SURNAME_AZ" },
    { value: "SURNAME_ZA", label: "SURNAME_ZA" },
    { value: "NAME_AZ", label: "NAME_AZ" },
    { value: "NAME_ZA", label: "NAME_ZA" },
  ],
  post: [
    {
      value: "RELEVANCE",
      label: "MOST_RELEVANT",
    },
    {
      value: "NEWEST",
      label: "NEWEST_POST",
    },
  ],
  resources: [
    { value: "", label: "MOST_RELEVANT" },
    { value: "NEWEST", label: "NEWEST_FIRST" },
    { value: "TITLE_AZ", label: "TITLE_AZ" },
    { value: "TITLE_ZA", label: "TITLE_ZA" },
  ],
  policies: [
    { value: "", label: "MOST_RELEVANT" },
    { value: "NEWEST", label: "NEWEST_FIRST" },
    { value: "TITLE_AZ", label: "TITLE_AZ" },
    { value: "TITLE_ZA", label: "TITLE_ZA" },
  ],
};

const NO_FILTERS = ["none", "mynews"];

const FilterBar = ({
  query,
  filterColumn,
  setFilterColumn,
  setFilterData,
  totalCounter,
  customCounterLabels,
  resourceType = "news",
  hidden,
  customElement,
  customFilterLabel,
  customIcon,
}) => {
  const { labels } = useContext(LanguageContext);
  const { booleans = {}, term, ...others } = query;
  const { orderBy, ...otherFilters } = others;
  const currentFilters = Object.values(otherFilters || {}).concat(Object.values(booleans)?.flat());
  const [openMenu, setOpenMenu] = useState(false);

  const orderByOpts = useMemo(
    () =>
      orderByMapping[resourceType]?.map((current) => ({
        ...current,
        label: labels?.[current.label],
      })),
    [labels, resourceType]
  );

  const onChange = useCallback(
    (selectedOption) => {
      setOpenMenu(false);
      setFilterData(({ orderBy, ...rest }) => ({
        ...rest,
        orderBy: selectedOption.value,
      }));
    },
    [setFilterData]
  );

  const filterLabel = customFilterLabel
    ? filterColumn
      ? customFilterLabel?.hide
      : customFilterLabel?.show
    : filterColumn
    ? labels?.HIDE_FILTERS
    : labels?.SHOW_FILTERS;

  return (
    <div
      className={classNames(
        "filter-bar",
        !orderByOpts && "filter-bar--no-sort",
        resourceType === "none" && "filter-bar--counter-only",
        NO_FILTERS.includes(resourceType) && "filter-bar--no-filters",
        Object.entries(labels).length === 0 && "loading-skeleton",
        hidden && "d-none"
      )}
      role="region"
      aria-label="Filter bar"
    >
      {!NO_FILTERS.includes(resourceType) && (
        <button
          className={classNames(
            "filter-bar__show-filters loading-highlighted",
            filterColumn && "filter-bar__show-filters--open"
          )}
          onClick={() => setFilterColumn(!filterColumn)}
          aria-label={filterLabel}
        >
          <span className="filter-bar__icon-wrapper">
            {customIcon ? customIcon : <Icon name="filters" />}
          </span>
          <span className="filter-bar__show-filters-text">{filterLabel}</span>
          {currentFilters?.length > 0 && (
            <span className="filter-bar__filter-counter">{currentFilters?.length}</span>
          )}
        </button>
      )}
      <div className="filter-bar__counter loading-highlighted">
        {!isNaN(totalCounter) && !(resourceType === "shared-documents" && totalCounter === 0)
          ? `${new Intl.NumberFormat("it").format(totalCounter)} ${
              totalCounter === 1
                ? (customCounterLabels?.singular
                    ? labels?.[customCounterLabels?.singular]
                    : labels?.RESULT
                  )?.toLowerCase()
                : (customCounterLabels?.plural
                    ? labels?.[customCounterLabels?.plural]
                    : labels?.RESULTS
                  )?.toLowerCase()
            }`
          : ""}
      </div>
      {orderByOpts?.length > 0 && (
        <OrderBySelect
          options={orderByOpts}
          orderBy={orderBy}
          open={openMenu}
          setOpen={setOpenMenu}
          onChange={onChange}
          resourceType={resourceType}
          term={term}
        />
      )}
      {customElement}
    </div>
  );
};

export default FilterBar;
