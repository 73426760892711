import { useContext } from "react";
import { Link } from "react-router-dom";
import LanguageContext from "../../context/language";
import LoadingError from "../../routes/loading-error";
import Error from "../error";
import CommunityCard from "./community-card";
import Pagination from "../pagination";
import Icon from "../icon";

const scrollToTop = () => window.scrollTo(0, 0);

const CommunitiesCardWrapper = ({
  communities,
  pagination,
  loadMore,
  updateFav,
  noFilters,
  navBack = true,
  favourites,
  isLoading,
}) => {
  const { labels } = useContext(LanguageContext);

  return (
    <div className="communities__section">
      {communities ? (
        communities?.length > 0 ? (
          <div className="communities__card-wrapper">
            {communities?.map((item) => (
              <CommunityCard
                community={item}
                onUpdate={updateFav}
                navBack={navBack}
                key={item.id}
              />
            ))}
          </div>
        ) : (
          <Error
            msg={
              noFilters
                ? labels?.NO_FAVORITE_COMMUNITIES || "You haven't added any community yet."
                : labels?.NO_MATCHING_RESULTS
            }
          />
        )
      ) : (
        <LoadingError />
      )}
      <Pagination
        currentItemCount={communities?.length}
        pagination={pagination}
        loadMore={loadMore}
        isLoading={isLoading}
      />
      {favourites && (
        <Link to="?section=all" onClick={scrollToTop} className="cta-link">
          <span>{labels?.EDIT_YOUR_FAVORITE_COMMUNITIES || "Edit your favorite communities"}</span>
          <Icon name="arrow" />
        </Link>
      )}
    </div>
  );
};

export default CommunitiesCardWrapper;
