import { useState, useContext } from "react";
import Modal from "../modal";
import LanguageContext from "../../context/language";
import UserContext from "../../context/user";

const RemoveMemberModal = ({ show, setShow, communityId, username, onRemoveUpdate }) => {
  const { labels } = useContext(LanguageContext);
  const { callApi } = useContext(UserContext);
  const [removing, setRemoving] = useState(false);

  const removeMember = async () => {
    setRemoving(true);
    try {
      await callApi(`/community/${communityId}/members`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify([
          {
            targetUsername: username,
          },
        ]),
      });
      onRemoveUpdate(username);
    } catch (err) {
      console.error("Error removing member: ", err);
    }
    setRemoving(false);
  };

  return (
    <Modal show={show} setShow={setShow} className="intranet-modal--warning">
      <div className="intranet-modal__title">
        {labels?.REMOVE_MEMBER_WARNING || "Are you sure you want to remove this member?"}
      </div>
      <div className="intranet-modal__buttons">
        <button className="button tertiary" onClick={() => setShow(false)}>
          {labels?.NO_KEEP_MEMBER || "No, keep member"}
        </button>
        <button className="button primary" onClick={removeMember}>
          <>
            {removing ? <span className="loading-spinner" /> : null}{" "}
            <span>{labels?.YES_REMOVE || "Yes, remove"}</span>
          </>
        </button>
      </div>
    </Modal>
  );
};

export default RemoveMemberModal;
