import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import useDelayedCall from "../hooks/useDelayedCall";

const UserContext = createContext({
  userProfile: undefined,
  token: undefined,
  login: () => 0,
  logout: () => 0,
  notificationLanguage: "en",
  setNotificationLanguage: () => 0,
  lightMode: false,
  setLightMode: () => 0,
});

const PREVIEW_KEY = "lux-preview-active";
const PREVIEW_GROUP = "editorial-staff";
const AUTH_COOKIE = window.AUTH_COOKIE || "X_AUTH_LUXOTTICATOKEN";
const LOGGED_COOKIE = "X_LUXUSER_LOGGED";
const LIGHT_MODE_KEY = "light-mode-lux";
//const LEONARDO_COOKIE = "UserToken";
const config = { hideSurveys: false, hideEvents: false };

const getPreviewUrl = (path) => {
  const current = new URL(`/bff${path}`, window.location.origin);
  const params = new URLSearchParams(current.search);
  params.set("preview", true);
  return `${current.pathname}?${params.toString()}`;
};

export const UserProvider = ({ children }) => {
  const [isLogged, setIsLogged] = useState(!!Cookies.get(LOGGED_COOKIE));
  const [userProfile, setUserProfile] = useState();
  const [notificationLanguage, setNotificationLanguage] = useState();
  const loadedProfile = useRef(false);
  const notificationLanguageRef = useRef(false);
  const [isSAMLLoggingOut, setIsSAMLLoggingOut] = useState(false);
  const [lightMode, setLightMode] = useState(() => localStorage.getItem(LIGHT_MODE_KEY) === "true");
  const [searchParams, setSearchParams] = useSearchParams();
  const previewParam = searchParams.get("preview");
  const [previewRequested, setPreviewRequested] = useState(
    () =>
      previewParam === "true" ||
      (previewParam !== "false" && sessionStorage.getItem(PREVIEW_KEY) === "true")
  );
  const previewActive = useMemo(() => {
    if (previewRequested) {
      return userProfile
        ? previewRequested && userProfile.groups?.includes(PREVIEW_GROUP)
        : undefined;
    }
    return false;
  }, [userProfile, previewRequested]);

  useEffect(() => {
    sessionStorage.setItem(PREVIEW_KEY, previewRequested);
  }, [previewRequested]);

  const clearUserData = useCallback(() => {
    loadedProfile.current = false;
    notificationLanguageRef.current = false;
    //Cookies.remove(LEONARDO_COOKIE, { path: "", domain: `.${window.location.host}` });
    setIsLogged();
    setUserProfile();
  }, []);

  const showPreview = useCallback(
    (active) => {
      if (active) {
        setPreviewRequested(true);
      } else {
        const params = new URLSearchParams(searchParams);
        params.delete("preview");
        setSearchParams(params);
        setPreviewRequested(false);
      }
    },
    [searchParams, setSearchParams]
  );

  const fetchApi = useCallback(
    async (path, options = {}) => {
      const {
        headers,
        mock = false,
        skipPreview = false,
        preventLogout = false,
        ...otherOptions
      } = options;
      if (!isLogged) {
        console.warn("User not logged");
        clearUserData();
        return;
      }

      const response = await fetch(
        mock ? `/mock${path}` : previewActive && !skipPreview ? getPreviewUrl(path) : `/bff${path}`,
        {
          headers: {
            X_CSFR: "IBFsrw65WAoevFitnuY8VeyMajKgaPpg",
            Accept: "*/*",
            ...headers,
          },
          ...otherOptions,
        }
      );
      if (!response.ok) {
        if (response.status === 401 && !preventLogout) {
          clearUserData();
        }
        const error = new Error(`Error calling ${path} ${response.status} ${response.statusText}`);
        error.status = response.status;
        const resHeaders = Object.fromEntries(response.headers.entries());
        if (
          Number(resHeaders["content-length"]) > 0 &&
          resHeaders["content-type"].toLowerCase() === "application/json"
        ) {
          try {
            const data = await response.json();
            error.data = data;
            console.error("Error content: ", data);
          } catch (e) {
            console.warn("Could not parse error response: ", e);
          }
        }
        throw error;
      }
      const resHeaders = Object.fromEntries(response.headers.entries());
      if (resHeaders["content-type"]?.startsWith("text/plain")) {
        const data = await response.text();
        return data;
      } else if (Number(resHeaders["content-length"]) > 0) {
        if (resHeaders["content-type"].toLowerCase() === "application/json") {
          const data = await response.json();
          return data;
        } else {
          const file = await response.blob();
          return file;
        }
      } else {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let text = "";
        while (true) {
          const { value, done } = await reader.read();
          const chunk = decoder.decode(value || new Uint8Array(), { stream: !done });
          text += chunk;
          if (done) break;
        }
        if (text) {
          return JSON.parse(text);
        }
      }
    },
    [isLogged, clearUserData, previewActive]
  );

  const callApi = useDelayedCall(fetchApi, userProfile || !previewRequested);

  const loadProfile = useCallback(async () => {
    loadedProfile.current = true;
    try {
      const data = await fetchApi(`/profile`);
      setUserProfile(data);
      if (data.language) {
        localStorage.setItem("lang-interface", data.language);
      }
    } catch (err) {
      console.error("Error loading profile: ", err);
      setUserProfile(null);
      loadedProfile.current = false;
      // TODO feedback errore
    }
  }, [fetchApi]);

  const loadNotificationLanguage = useCallback(async () => {
    notificationLanguageRef.current = true;
    try {
      const notificationLang = await callApi("/profile/getNotificationLanguage");
      setNotificationLanguage(notificationLang?.language);
    } catch (err) {
      console.error("Error loading notification language: ", err);
      notificationLanguageRef.current = false;
    }
  }, [callApi]);

  useEffect(() => {
    if (isLogged && !loadedProfile.current) {
      loadProfile();
    }
  }, [isLogged, loadProfile]);

  useEffect(() => {
    if (isLogged && !notificationLanguageRef.current) {
      loadNotificationLanguage();
    }
  }, [isLogged, loadNotificationLanguage]);

  const login = useCallback(async (username, password, mock = false) => {
    const response = await fetch(mock ? `/mock/authorization/login` : `/bff/authorization/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });
    if (!response.ok) {
      throw new Error(`Login error ${response.status} ${response.statusText}`);
    }
    const { redirectUrl } = await response.json();
    if (redirectUrl) {
      window.location.href = redirectUrl;
      return;
    }
    setIsLogged(true);
  }, []);

  const logout = useCallback(async () => {
    const response = await callApi(`/authorization/logout`, {
      method: "POST",
    });
    if (response.redirectUrl) {
      setIsSAMLLoggingOut(true);
      console.log("RedirectUrl found, SAML logout in progress", response.redirectUrl);
      window.location.href = response.redirectUrl;
      return;
    }
    clearUserData();
  }, [callApi, clearUserData]);

  const openAuthLink = useCallback(
    (e) => {
      e.preventDefault();
      const url = e.currentTarget.href;
      if (isLogged && !!Cookies.get(LOGGED_COOKIE)) {
        // TODO remove this case
        const authToken = Cookies.get(AUTH_COOKIE);
        if (url.split("/").includes("tamtamy") && authToken) {
          const form = document.createElement("form");
          form.target = "_blank";
          form.method = "POST";
          form.action = url;
          form.enctype = "multipart/form-data";

          const jwtInput = document.createElement("input");
          jwtInput.type = "hidden";
          jwtInput.name = "jwt";
          jwtInput.value = authToken;
          form.appendChild(jwtInput);

          document.body.appendChild(form);
          form.submit();
          document.body.removeChild(form);
        } else {
          window.open(url, "_blank");
        }
      } else {
        clearUserData();
      }
    },
    [isLogged, clearUserData]
  );

  useEffect(() => {
    localStorage.setItem(LIGHT_MODE_KEY, lightMode);
    document.body.classList.toggle("light-mode", lightMode);
  }, [lightMode]);

  const contextValue = useMemo(
    () => ({
      logged: !!isLogged,
      isPreview: previewActive,
      isSAMLLoggingOut,
      openAuthLink,
      showPreview,
      userProfile,
      login,
      logout,
      callApi,
      loadProfile,
      config,
      notificationLanguage,
      setNotificationLanguage,
      lightMode,
      setLightMode,
    }),
    [
      isLogged,
      openAuthLink,
      isSAMLLoggingOut,
      previewActive,
      showPreview,
      userProfile,
      login,
      logout,
      callApi,
      loadProfile,
      notificationLanguage,
      setNotificationLanguage,
      lightMode,
      setLightMode,
    ]
  );

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export default UserContext;
