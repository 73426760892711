import { useContext, useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Tabs from "../../components/tabs";
import SectionAll from "./section-all";
import SectionLinks from "./section-links";
import useFilters, { searchBodyFromQuery } from "../../hooks/useFilters";
import UserContext from "../../context/user";
import LanguageContext from "../../context/language";
import FavouriteContext from "../../context/favourites";
import placeholders from "../../utils/placeholders";
import useTealiumOnPageLoad from "../../utils/tealium";
import { addFavouriteAppsLinksFlags } from "../../utils/addFavouriteFlags";
import LoadingError from "../loading-error";

const updateList = (list, dragIndex, hoverIndex) => {
  const updated = [...list];
  const [toMove] = updated.splice(dragIndex, 1);
  updated.splice(hoverIndex, 0, toMove);
  return updated;
};

const pageSize = 9;
const category = "WELFARE";

const AppsLinks = ({ page: pageContent }) => {
  const { callApi } = useContext(UserContext);
  const { getFavourite, updateFavAppLink, updateAppsLinks } = useContext(FavouriteContext);
  const [linksData, setLinksData] = useState({
    init: true,
    favLinks: { appLinkDtoList: placeholders(), pinned: [] },
    allLinks: { appLinkDtoList: placeholders(6), pinned: [] },
    welfareLinks: { appLinkDtoList: placeholders(), pinned: [] },
  });
  const [page, setPage] = useState(0);
  const [pageWelfare, setPageWelfare] = useState(0);
  const currentPage = linksData?.allLinks?.page || 0;
  const currentPageWelfare = linksData?.welfareLinks?.page || 0;
  const favLinks = linksData.favLinks;
  const welfareLinks = linksData.welfareLinks;
  const allLinks = linksData.allLinks;
  const initFavs = !linksData?.init;

  const [error, setError] = useState();
  const loadedPage = useRef();
  const loadedFavourites = useRef();
  const loadedWelfare = useRef();
  const { labels, cmsLang } = useContext(LanguageContext);
  const { pathname } = useLocation();

  const [searchParams] = useSearchParams();
  const section = searchParams.get("section");

  const [query, setFilterData] = useFilters("appLink");
  const [queryWelfareLinks, setFilterDataWelfareLinks] = useFilters("appLink");

  useEffect(() => {
    const loadFilteredAppsLinks = async () => {
      const pageNumber = page === currentPage ? 0 : page;
      const params = new URLSearchParams(Object.entries({ pageNumber, pageSize, locale: cmsLang }));
      try {
        loadedPage.current = JSON.stringify(query) + cmsLang;
        const allData = await callApi(`/resource/appsLinks?${params.toString()}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(searchBodyFromQuery(query, "appLink")),
        });
        setPage(pageNumber);
        setLinksData((current) => ({
          ...current,
          allLinks:
            page > currentPage
              ? {
                  ...current.allLinks,
                  ...allData,
                  pinned: current.allLinks.pinned
                    .concat(allData.appLinkDtoList)
                    .filter((item) => item.pinned),
                  appLinkDtoList: current.allLinks.appLinkDtoList.concat(
                    addFavouriteAppsLinksFlags(
                      allData.appLinkDtoList.filter((item) => !item.pinned),
                      current.favLinks.appLinkDtoList
                    )
                  ),
                  page: pageNumber,
                }
              : {
                  ...allData,
                  pinned: allData.appLinkDtoList.filter((item) => item.pinned),
                  appLinkDtoList: addFavouriteAppsLinksFlags(
                    allData.appLinkDtoList.filter((item) => !item.pinned),
                    current.favLinks.appLinkDtoList
                  ),
                  page: pageNumber,
                },
        }));
      } catch (err) {
        // loadedPage.current = false;
        console.error(`Error loading links`, err);
        setError("Errore nel recupero dei link");
      }
    };
    if (
      initFavs &&
      (section === "all" ? true : !loadedPage.current) &&
      (page > currentPage || JSON.stringify(query) + cmsLang !== loadedPage.current)
    ) {
      loadFilteredAppsLinks();
    }
  }, [query, page, currentPage, cmsLang, callApi, initFavs, section]);

  useEffect(() => {
    const loadWelfareLinks = async () => {
      const pageNumberWelfare = pageWelfare === currentPageWelfare ? 0 : pageWelfare;
      const paramsWelfare = new URLSearchParams(
        Object.entries({ pageNumber: pageNumberWelfare, pageSize, locale: cmsLang })
      );
      try {
        loadedWelfare.current = JSON.stringify(queryWelfareLinks) + cmsLang;
        const welfareLinks = await callApi(
          `/resource/appsLinks/${category}?${paramsWelfare.toString()}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(searchBodyFromQuery(queryWelfareLinks, "appLink")),
          }
        );
        setPageWelfare(pageNumberWelfare);
        setLinksData((current) => ({
          ...current,
          welfareLinks:
            pageWelfare > currentPageWelfare
              ? {
                  ...current.welfareLinks,
                  ...welfareLinks,
                  pinned: current.welfareLinks.pinned
                    .concat(welfareLinks.appLinkDtoList)
                    .filter((item) => item.pinned),
                  appLinkDtoList: current.welfareLinks.appLinkDtoList.concat(
                    addFavouriteAppsLinksFlags(
                      welfareLinks.appLinkDtoList.filter((item) => !item.pinned),
                      current.favLinks.appLinkDtoList
                    )
                  ),
                  page: pageWelfare,
                }
              : {
                  ...welfareLinks,
                  pinned: welfareLinks.appLinkDtoList.filter((item) => item.pinned),
                  appLinkDtoList: addFavouriteAppsLinksFlags(
                    welfareLinks.appLinkDtoList.filter((item) => !item.pinned),
                    current.favLinks.appLinkDtoList
                  ),
                  page: pageWelfare,
                },
        }));
      } catch (err) {
        // loadedWelfare.current = false;
        console.error(`Error loading links`, err);
        setError("Errore nel recupero dei link");
      }
    };
    if (
      initFavs &&
      (section === "welfare" ? true : !loadedWelfare.current) &&
      (pageWelfare > currentPageWelfare ||
        JSON.stringify(queryWelfareLinks) + cmsLang !== loadedWelfare.current)
    ) {
      loadWelfareLinks();
    }
  }, [queryWelfareLinks, cmsLang, initFavs, callApi, currentPageWelfare, pageWelfare, section]);

  useTealiumOnPageLoad({ pageSection1: pageContent?.segment });

  const linksSections = useMemo(
    () => [
      {
        link: "",
        label: labels?.MY_LINKS,
        end: true,
      },
      {
        link: "?section=welfare",
        label: labels?.WELFARE_LINKS || "Welfare links",
        end: true,
      },
      {
        link: "?section=all",
        label: labels?.ALL_LINKS || "All links",
        end: true,
      },
    ],
    [labels]
  );

  const updateFav = useCallback(
    async (type) => {
      try {
        const updated = await updateFavAppLink(type, cmsLang);
        setLinksData((current) => {
          const updatedData = {
            ...current,
            favLinks: {
              ...current.favLinks,
              pinned: updated.appLinkDtoList.filter((item) => item.pinned),
              appLinkDtoList: updated.appLinkDtoList
                .filter((item) => !item.pinned)
                .map((item) => ({ ...item, favourite: true })),
            },
          };
          return {
            ...updatedData,
            allLinks: {
              ...updatedData.allLinks,
              // pinned: updatedData.allLinks.appLinkDtoList.filter(item => item.pinned),
              appLinkDtoList: addFavouriteAppsLinksFlags(
                updatedData.allLinks.appLinkDtoList.filter((item) => !item.pinned),
                updatedData.favLinks.appLinkDtoList
              ),
            },
            welfareLinks: {
              ...updatedData.welfareLinks,
              appLinkDtoList: addFavouriteAppsLinksFlags(
                updatedData.welfareLinks.appLinkDtoList.filter((item) => !item.pinned),
                updatedData.favLinks.appLinkDtoList
              ),
            },
          };
        });
      } catch (err) {
        console.error("Error updating favourites: ", err);
      }
    },
    [updateFavAppLink, cmsLang]
  );

  const loadMore = useCallback(() => setPage((page) => page + 1), []);
  const loadMoreWelfareLinks = useCallback(() => setPageWelfare((page) => page + 1), []);

  useEffect(() => {
    if (loadedFavourites.current !== cmsLang) {
      loadedFavourites.current = cmsLang;
      const loadFavorites = async () => {
        try {
          const favLinks = await getFavourite("links", cmsLang, 50);
          setLinksData((current) => ({
            ...current,
            init: false,
            favLinks: {
              pinned: favLinks.filter((item) => item.pinned),
              appLinkDtoList: favLinks
                .filter((item) => !item.pinned)
                .map((item) => ({ ...item, favourite: true })),
            },
          }));
        } catch (err) {
          console.error("Errore nel recupero dei link preferiti: ", err);
          loadedFavourites.current = false;
          setError("Errore nel recupero dei link preferiti");
        }
      };
      loadFavorites();
    }
  }, [getFavourite, cmsLang, callApi]);

  const myLinksCount = useMemo(
    () => favLinks?.pinned?.length + favLinks?.appLinkDtoList?.length,
    [favLinks]
  );
  const linksCount = useMemo(() => welfareLinks?.paginationInfo?.total, [welfareLinks]);
  const allCount = useMemo(() => allLinks?.paginationInfo?.total, [allLinks]);

  const linksTabs = useMemo(() => {
    if (!isNaN(myLinksCount) && !isNaN(linksCount) && !isNaN(allCount)) {
      return linksSections.map((section, i) => ({
        ...section,
        link: `${pathname}${section.link}`,
        count: { 0: myLinksCount, 1: linksCount, 2: allCount }[i],
      }));
    }
    return linksSections;
  }, [pathname, myLinksCount, linksCount, allCount, linksSections]);

  const onDropLink = useCallback(
    async (event) => {
      const prevIndex = event.active?.data?.current?.sortable?.index;
      const index = event.over?.data?.current?.sortable?.index;
      if (!isNaN(prevIndex) && prevIndex !== index) {
        const currentList = favLinks.appLinkDtoList;
        try {
          const sortedLinks = updateList(currentList, prevIndex, index);
          setLinksData((current) => ({
            ...current,
            favLinks: {
              ...current.favLinks,
              appLinkDtoList: sortedLinks,
            },
          }));
          await callApi(`/profile/favorites/links`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              sort: sortedLinks.map((link, index) => ({
                resourceType: link.newsType?.externalReference,
                resourceId: link.masterID,
                position: index,
              })),
            }),
          });
          updateAppsLinks("links", favLinks.pinned.concat(sortedLinks));
        } catch (err) {
          console.error("Error swapping links: ", err);
          setLinksData((current) => ({
            ...current,
            favLinks: {
              ...current.favLinks,
              appLinkDtoList: currentList,
            },
          }));
        }
      }
    },
    [favLinks, callApi, updateAppsLinks]
  );

  return error ? (
    <LoadingError />
  ) : (
    <main className="page apps-links">
      <h1> {pageContent.label}</h1>
      {linksTabs && <Tabs tabs={linksTabs} count={true} />}
      {
        {
          mylinks: <SectionLinks data={favLinks} updateFav={updateFav} onDrop={onDropLink} />,
          welfare: (
            <SectionAll
              data={welfareLinks}
              labels={labels}
              pagination={welfareLinks?.paginationInfo}
              query={queryWelfareLinks}
              setFilterData={setFilterDataWelfareLinks}
              loadMore={loadMoreWelfareLinks}
              updateFav={updateFav}
            />
          ),
          all: (
            <SectionAll
              data={allLinks}
              labels={labels}
              pagination={linksData.allLinks?.paginationInfo}
              query={query}
              setFilterData={setFilterData}
              loadMore={loadMore}
              updateFav={updateFav}
            />
          ),
        }[section || "mylinks"]
      }
    </main>
  );
};

export default AppsLinks;
