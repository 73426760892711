import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import UserContext from "../../context/user";
import LanguageContext from "../../context/language";

const useResourceDetail = ({ resourceId }) => {
  const { callApi } = useContext(UserContext);
  const { cmsLang: locale } = useContext(LanguageContext);

  return useQuery({
    queryKey: ["resource-detail", resourceId, locale],
    queryFn: () => callApi(`/resource/${resourceId}?locale=${locale}`),
    placeholderData: {},
  });
};

export default useResourceDetail;
