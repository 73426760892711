import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  Suspense,
  lazy,
} from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import Icon from "./icon";
import CommentInput from "./comment-input";
import UserContext from "../context/user";
import LanguageContext from "../context/language";
import placeholders from "../utils/placeholders";
import localizedFormat from "dayjs/plugin/localizedFormat";
import Like from "./like";
import ToggleTranslateButton, { useToggleTranslate } from "./toggle-translate-btn";
import DeleteCommentModal from "./communities/delete-comment-modal";
dayjs.extend(localizedFormat);

const ActionButton = lazy(() => import("./action-button"));

const chunkSize = 5;

const Comment = React.forwardRef(
  (
    {
      comment,
      reply,
      loadComments,
      updateComment,
      deleteComment,
      fetchReplies,
      postComment,
      increaseCommentsCount,
      fetchLikes,
      postLike,
      onUpdate,
      post,
      isAdmin,
      hubName,
    },
    ref
  ) => {
    const { userProfile } = useContext(UserContext);
    const { labels, language } = useContext(LanguageContext);

    /* const Comment = ({ comment, reply, loadComments, increaseCommentsCount, newsId }) => {
  const { userProfile, callApi } = useContext(UserContext);
  const { labels, languages, language, cmsLang, browserLanguage } = useContext(LanguageContext);
 */
    const [seeReplies, setSeeReplies] = useState();
    const [pagination, setPagination] = useState();
    const [focus, setFocus] = useState(false);
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const loadedReplies = useRef();
    const {
      toggle: toggleTranslate,
      loading: translating,
      result: translatedText,
      resetted: resettedTranslation,
    } = useToggleTranslate(comment?.comment?.comment);

    const repliesCount = useMemo(
      () => (pagination ? pagination.total : comment?.counter?.commentCounter || 0),
      [comment, pagination]
    );
    const [replies, setReplies] = useState(placeholders(repliesCount, "comment.id"));

    const hasReplies = !!pagination && repliesCount > 0;
    const commentId = comment.comment?.id;

    const [showActions, setShowActions] = useState(false);

    const [editMode, setEditMode] = useState(false);
    const editComment = () => setEditMode(true);

    const [editedComment, setEditedComment] = useState(comment?.comment?.comment);
    const onChange = useCallback((event) => setEditedComment(event.target.value), []);

    const submitEditComment = useCallback(() => {
      updateComment(commentId, editedComment);
      setEditMode(false);
    }, [commentId, editedComment, updateComment]);

    const cancelEditComment = useCallback(() => {
      setEditMode(false);
    }, []);

    const [deleteCommentModalVisible, setDeleteCommentModalVisible] = useState(false);
    const handleDeleteComment = () => setDeleteCommentModalVisible(true);

    const loadReplies = useCallback(
      async (reset = false) => {
        try {
          setIsLoading(true);
          if (reset) {
            const maxPageSize = 10;
            let result = { list: [], paginationInfo: null };
            do {
              const temp = await fetchReplies(
                commentId,
                maxPageSize,
                result?.paginationInfo?.nextOffset || 0
              );
              result.list = result.list.concat(temp?.list);
              result.paginationInfo = temp.paginationInfo;
            } while (!result?.paginationInfo || result?.paginationInfo?.nextOffset);
            if (result?.list && result?.paginationInfo) {
              setReplies(result.list);
              setPage(result?.paginationInfo?.startOffset / chunkSize + 1);
              setPagination(result.paginationInfo);
              loadedReplies.current = result?.paginationInfo?.startOffset / chunkSize + 1;
            }
          } else {
            const result = await fetchReplies(commentId, chunkSize, page * chunkSize);
            if (result?.list && result?.paginationInfo) {
              setReplies((current) =>
                current?.length > 0 && !reset && hasReplies
                  ? current.concat(result.list)
                  : result.list
              );
              setPagination(result.paginationInfo);
            }
            setIsLoading(false);
          }
        } catch (err) {
          console.error("Error loading comments", err);
        }
      },
      [page, hasReplies, fetchReplies, commentId]
    );

    useEffect(() => {
      if (loadedReplies.current !== page && seeReplies) {
        loadedReplies.current = page;
        loadReplies();
      }
    }, [loadReplies, page, seeReplies]);

    const fetchCommentLikes = useCallback(() => fetchLikes(commentId), [fetchLikes, commentId]);
    const postCommentLike = useCallback(
      (method) => postLike(commentId, method),
      [postLike, commentId]
    );

    const onUpdateReply = useCallback((removedReplyId) => {
      if (removedReplyId) {
        setReplies((current) =>
          current.filter((comment) => comment?.comment?.id !== removedReplyId)
        );
        setPagination((current) => ({ ...current, total: current.total - 1 }));
      }
    }, []);

    const handleUpdateComment = useCallback(
      (updatedPostId, updatedComment) => {
        if (updatedPostId && updatedComment) {
          updateComment(updatedPostId, updatedComment);
          setReplies((current) =>
            current.map((comment) =>
              comment?.comment?.id === updatedPostId
                ? { ...comment, comment: { ...comment.comment, comment: updatedComment } }
                : comment
            )
          );
        }
      },
      [updateComment]
    );

    const scrollToRef = useRef();
    const [focusOnFirstReply, setFocusOnFirstReply] = useState();

    useEffect(() => {
      if (focusOnFirstReply) {
        scrollToRef?.current?.scrollIntoView({ behavior: "smooth" });
        setFocusOnFirstReply(false);
      }
    }, [focusOnFirstReply]);

    return (
      <div
        className={classNames(
          "comments__comment-wrapper",
          reply && "comments__comment-wrapper--reply",
          seeReplies && "comments__comment-wrapper--see-replies"
        )}
        data-id={comment.comment.id}
      >
        <span className="comments__comment-scroll-ref" ref={ref} />
        <div
          className={classNames(
            "comments__comment-user loading-highlighted",
            !comment?.comment?.user?.avatarUrl && "comments__comment-user--placeholder"
          )}
        >
          {comment?.comment?.user?.avatarUrl ? (
            <img src={comment.comment.user.avatarUrl} alt="User" />
          ) : (
            <span>{`${comment?.comment?.user?.firstName?.[0]?.toUpperCase()}${comment?.comment?.user?.lastName?.[0]?.toUpperCase()}`}</span>
          )}
        </div>
        <div className="comments__comment">
          <span className="comments__arrow" />
          <span className="comments__chevron" />
          <div className="comments__comment-header">
            <span className="loading-highlighted" translate="no">
              {comment?.comment?.user?.firstName} {comment?.comment?.user?.lastName}
            </span>
            <div className="comments__opt">
              <span className="comments__date loading-highlighted">
                {comment?.comment?.creationDate &&
                  dayjs(comment?.comment.creationDate)
                    .locale(language || "en-us")
                    .format("LL")}
              </span>
              {(comment?.comment?.user?.username === userProfile?.username ||
                (post && isAdmin) ||
                (!post &&
                  (userProfile?.groups?.includes("editorial-staff") ||
                    userProfile?.adminForHubs?.includes(hubName)))) && (
                <Suspense>
                  <ActionButton
                    showActions={showActions}
                    setShowActions={setShowActions}
                    disabled={editMode}
                    actions={[
                      {
                        action: editComment,
                        hasCondition: true,
                        condition: comment?.comment?.user?.username === userProfile?.username,
                        icon: "edit",
                        label: labels?.EDIT || "Edit",
                      },
                      {
                        action: handleDeleteComment,
                        hasCondition: false,
                        icon: "trash",
                        label: labels?.DELETE || "Delete",
                      },
                    ]}
                  />
                </Suspense>
              )}
            </div>
          </div>
          {editMode ? (
            <div className="comments__comment-text comments__comment-text--edit-mode">
              <textarea
                placeholder={labels?.WRITE_A_COMMENT}
                value={editedComment}
                onChange={onChange}
                autoFocus={focus}
              ></textarea>
              <div className="comments__edit-controls">
                <button
                  className="button leading-icon leading-icon--red"
                  onClick={cancelEditComment}
                >
                  <Icon name="x-icon" /> <span>{labels?.CANCEL || "Cancel"}</span>
                </button>
                <button
                  className="button leading-icon leading-icon--green"
                  disabled={
                    editedComment?.length === 0 || editedComment === comment.comment.comment
                  }
                  onClick={submitEditComment}
                >
                  <Icon name="checkmark" />
                  <span>{labels?.DONE || "Done"}</span>
                </button>
              </div>
            </div>
          ) : (
            <div className="comments__comment-text loading-highlighted">
              {translatedText && !resettedTranslation ? translatedText : comment?.comment?.comment}
            </div>
          )}
          {!editMode && (
            <div className="comments__info">
              <ToggleTranslateButton
                toggle={toggleTranslate}
                loading={translating}
                result={translatedText}
                resetted={resettedTranslation}
                backLabel={labels?.BACK_TO_ORIGINAL_COMMENT}
              />
              <Like
                likesCounter={comment?.counter?.likeCounter}
                isLiked={comment?.liked?.liked}
                fetchLikes={fetchCommentLikes}
                postLike={postCommentLike}
              />
            </div>
          )}
        </div>
        {seeReplies ? (
          <div
            className={classNames("comments__replies-wrapper", !hasReplies && "loading-skeleton")}
          >
            {replies.map((reply) => (
              <Comment
                comment={reply}
                key={reply?.comment?.id}
                reply={true}
                loadComments={loadComments}
                updateComment={handleUpdateComment}
                deleteComment={deleteComment}
                fetchLikes={fetchLikes}
                postLike={postLike}
                onUpdate={onUpdateReply}
                ref={scrollToRef}
              />
            ))}
            {repliesCount > 0 && (
              <div className="comments__replies-visual">
                {repliesCount > (page + 1) * chunkSize &&
                  (isLoading ? (
                    <span className="loading-spinner" />
                  ) : (
                    <button
                      className="comments__load-more-replies"
                      onClick={() => setPage((value) => value + 1)}
                    >
                      {labels?.LOAD_MORE}
                    </button>
                  ))}
                <button className="comments__collapse-replies" onClick={() => setSeeReplies(false)}>
                  <Icon name="minus" className="icon--circled" />
                  {labels?.COLLAPSE_REPLIES}
                </button>
              </div>
            )}
            <div className="comments__comment-wrapper comments__comment-wrapper--insert">
              <div
                className={classNames(
                  "comments__comment-user",
                  !userProfile?.avatarUrl && "comments__comment-user--placeholder"
                )}
              >
                {userProfile?.avatarUrl ? (
                  <img src={userProfile.avatarUrl} alt="User" />
                ) : (
                  <span>{`${userProfile.firstName?.[0]?.toUpperCase()}${userProfile.lastName?.[0]?.toUpperCase()}`}</span>
                )}
              </div>
              <CommentInput
                postComment={postComment}
                loadComments={loadReplies}
                parentCommentId={comment?.comment?.id}
                focus={focus}
                increaseCommentsCount={increaseCommentsCount}
                setFocusOnFirstComment={() => setFocusOnFirstReply(true)}
              />
            </div>
          </div>
        ) : (
          !comment?.comment?.parentCommentId && (
            <div className="comments__actions">
              <button
                className="comments__see-replies"
                onClick={() => {
                  setSeeReplies(true);
                  setFocus(false);
                }}
                disabled={!repliesCount}
              >
                <Icon name="comment" />
                {`${repliesCount || 0} ${
                  repliesCount === 1 ? labels?.REPLY?.toLowerCase() : labels?.REPLIES?.toLowerCase()
                }`}
              </button>
              <button
                className="comments__reply button tertiary small"
                onClick={() => {
                  setSeeReplies(true);
                  setFocus(true);
                }}
              >
                {labels?.DO_REPLY}
              </button>
            </div>
          )
        )}
        <DeleteCommentModal
          show={deleteCommentModalVisible}
          setShow={setDeleteCommentModalVisible}
          commentId={commentId}
          deleteComment={deleteComment}
          onUpdate={onUpdate}
        />
      </div>
    );
  }
);

export default Comment;
