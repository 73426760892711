import { createContext, useMemo, useEffect, useRef, useState, useContext } from "react";
import UserContext from "./user";

const cmsLanguages = [
  { value: "en-us", label: "English", profile: "en" },
  { value: "it-it", label: "Italiano", profile: "it" },
  { value: "fr-fr", label: "Français", profile: "fr" },
  { value: "es-es", label: "Español", profile: "es" },
  { value: "pt-br", label: "Português", profile: "pt" },
  { value: "zh-cn", label: "中文", profile: "zh" },
];

const newsletterLanguages = [
  { value: "en-us", label: "English", profile: "en" },
  { value: "it-it", label: "Italiano", profile: "it" },
  { value: "fr-fr", label: "Français", profile: "fr" },
];

const LanguageContext = createContext({
  language: "en",
  labels: {},
  cmsLang: "en-us",
  browserLanguage: "en",
  cmsLanguages,
  newsletterLanguages,
  languages: [],
});

export default LanguageContext;

const LABELS_KEY = "labels";

export const LanguageProvider = ({ children }) => {
  const [labels, setLabels] = useState(JSON.parse(localStorage.getItem(LABELS_KEY) || "{}"));
  const loadedLabels = useRef(false);
  const { logged, userProfile } = useContext(UserContext);
  const language = userProfile?.language
    ? cmsLanguages?.find((lang) => lang.profile === userProfile.language)?.profile || "en"
    : null;
  const cmsLang = language
    ? cmsLanguages?.find((lang) => lang.profile === language)?.value || "en-us"
    : null;
  const [browserLanguage, setBrowserLanguage] = useState();
  const rawBroswerLanguage = (navigator?.language || navigator?.userLanguage || "en-us")?.split(
    "-"
  )[0];

  const [languages, setLanguages] = useState();
  const languagesRef = useRef();

  useEffect(() => {
    if (!languagesRef.current) {
      const loadLanguages = async () => {
        languagesRef.current = true;
        try {
          const response = await fetch("/bff/translation/language", {
            headers: {
              Accept: "*/*",
            },
          });
          if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
          }
          const result = await response.json();
          const formattedResult = result.map(({ code, description }) => {
            return { value: code, label: description };
          });
          setLanguages(formattedResult);
          setBrowserLanguage(
            formattedResult?.find((lang) => lang.value === rawBroswerLanguage)?.value || "en"
          );
        } catch (err) {
          languagesRef.current = false;
          console.error("Err: ", err);
        }
      };
      loadLanguages();
    }
  }, [rawBroswerLanguage]);

  useEffect(() => {
    const lang = language || localStorage.getItem("lang-interface") || browserLanguage;
    const needToLoadLang = !loadedLabels.current || loadedLabels.current !== lang;
    if (lang && needToLoadLang && (!logged || language)) {
      const getLabels = async (lang) => {
        loadedLabels.current = lang;
        let defaultLabels = {};
        if (lang !== "en") {
          try {
            const response = await fetch(`/bff/translation?locale=en`, {
              headers: {
                Accept: "*/*",
              },
            });
            if (!response.ok) {
              throw new Error(`${response.status} ${response.statusText}`);
            }
            const result = await response.json();
            defaultLabels = Object.fromEntries(
              Object.entries(result)?.filter(
                ([key, value]) => !key.startsWith("MENU_") && value !== "-"
              )
            );
          } catch (err) {
            console.error(err);
          }
        }
        try {
          const response = await fetch(`/bff/translation?locale=${lang}`, {
            headers: {
              Accept: "*/*",
            },
          });
          if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
          }
          const result = await response.json();
          const cleanResult = Object.fromEntries(
            Object.entries(result).filter(([_key, value]) => value !== "-")
          );
          const mergedLabels = {
            ...defaultLabels,
            ...cleanResult,
            _LANGUAGE: lang,
          };
          localStorage.setItem(LABELS_KEY, JSON.stringify(mergedLabels));
          setLabels(mergedLabels);
        } catch (err) {
          console.error(err);
        }
      };
      //console.log("LANGUAGE: ", lang);
      getLabels(lang);
    }
  }, [logged, language, browserLanguage]);

  const contextValue = useMemo(
    () => ({
      labels,
      cmsLang,
      browserLanguage,
      cmsLanguages,
      newsletterLanguages,
      language,
      languages,
    }),
    [labels, cmsLang, browserLanguage, language, languages]
  );

  return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
};
