import { useContext } from "react";
import LanguageContext from "../context/language";
import LoadingError from "../routes/loading-error";
import ContactCard from "./contact-card";
import Error from "./error";
import Pagination from "./pagination";

const ContactsCardWrapper = ({
  contacts,
  pagination,
  loadMore,
  updateFav,
  search,
  remove = false,
  onRemoveUpdate = null,
  communityId,
  isInAdminPage,
}) => {
  const { labels } = useContext(LanguageContext);

  return (
    <div className="who-is-who__section">
      {contacts ? (
        contacts?.length > 0 ? (
          <div className="who-is-who__card-wrapper">
            {contacts?.map((contact) => (
              <ContactCard
                contact={contact}
                onUpdate={updateFav}
                key={contact.username}
                remove={remove}
                communityId={communityId}
                onRemoveUpdate={onRemoveUpdate}
              />
            ))}
          </div>
        ) : (
          <Error
            msg={
              search
                ? labels?.NO_MATCHING_RESULTS
                : isInAdminPage
                ? labels?.NO_ADMIN_COMMUNITY || "No Admins have been added to this community yet"
                : labels?.NO_FAVORITE_CONTACTS
            }
          />
        )
      ) : (
        <LoadingError />
      )}
      <Pagination currentItemCount={contacts?.length} pagination={pagination} loadMore={loadMore} />
    </div>
  );
};

export default ContactsCardWrapper;
