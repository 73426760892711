import { useContext } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import LanguageContext from "../../context/language";
import UserContext from "../../context/user";
import placeholders from "../../utils/placeholders";

const PAGE_SIZE = 12;
const STALE_TIME = 300000; //5 min = 60*5*1000

const useMyNews = ({
  orderBy = "NEWEST",
  pageSize = PAGE_SIZE,
  placeholderData = { pages: [{ data: placeholders(4), init: true }] },
  enabled = true,
  staleTime = STALE_TIME,
  onSuccess = () => 0,
}) => {
  const { callApi } = useContext(UserContext);
  const { cmsLang: locale } = useContext(LanguageContext);

  return useInfiniteQuery({
    queryKey: ["my-news", pageSize, locale, orderBy],
    queryFn: ({ pageParam = 0 }) => {
      const params = new URLSearchParams(
        Object.entries({ pageNumber: pageParam, pageSize, locale, orderBy })
      );
      return callApi(`/resource/news/my?${params?.toString()}`);
    },
    enabled,
    staleTime,
    placeholderData,
    getNextPageParam: (_lastPage, pages) => {
      return pages?.length;
    },
    select: (data) => {
      const list = data?.pages?.map((page) => page?.data)?.flat();
      const lastPage = data?.pages.slice(-1)?.[0];
      return { ...lastPage, data: list };
    },
    onSuccess,
    onError: (err) => {
      console.error(`Error loading my news`, err);
    },
  });
};

export default useMyNews;
